import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import ProductsApiService from '../services/api/products';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PRODUCT_PREFIX = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_REQUEST_ACTION = `${FETCH_PRODUCT_PREFIX}_REQUEST_ACTION`;
export const FETCH_PRODUCT_SUCCESS_ACTION = `${FETCH_PRODUCT_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PRODUCT_FAILURE_ACTION = `${FETCH_PRODUCT_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SUCCESS_ACTION:
      return produce(state, draft => {
        const productIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (productIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchProductAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_PRODUCT_REQUEST_ACTION });
    const productsService = new ProductsApiService();
    return productsService
      .show(id)
      .then(response => {
        dispatch({ type: FETCH_PRODUCT_SUCCESS_ACTION, payload: response.product });
      })
      .catch(error => {
        dispatch({ type: FETCH_PRODUCT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
