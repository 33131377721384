import React from 'react';
import PropTypes from 'prop-types';
import { replaceUnderscoreWithSpace } from '../../helpers';
import StatusTag from '../StatusTag';

const PaymentStatusTag = ({ paymentStatus }) => {
  let color = '';
  const label = paymentStatus || 'CURRENT';

  switch (label) {
    case 'NOT_PAID':
      color = 'red';
      break;
    case 'PENDING':
      color = 'blue';
      break;
    case 'PAID':
      color = 'green';
      break;
    case 'SUSPENDED':
      color = 'green';
      break;
    case 'BLOCKED':
      color = 'red';
      break;
    default:
      break;
  }

  return <StatusTag label={replaceUnderscoreWithSpace(label)} color={color} />;
};

PaymentStatusTag.propTypes = {
  paymentStatus: PropTypes.string
};

PaymentStatusTag.defaultProps = {
  paymentStatus: 'PAID'
};

export default PaymentStatusTag;
