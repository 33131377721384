import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ATTRIBUTES } from '../constants';
import MiscApiService from '../../../services/api/misc';
import InfoDialog from '../../InfoDialog';
import InputErrorMessage from '../../InputErrorMessage';

const useStyles = makeStyles(() => ({
  clearButton: {
    visibility: 'hidden'
  },
  hasValue: {},
  textRoot: {
    '@media (pointer: fine)': {
      '&:hover $hasValue': {
        visibility: 'visible'
      }
    }
  }
}));

const BondingDateField = ({ name, label, createdAt }) => {
  const {
    values: { [ATTRIBUTES.SHIPPING_METHOD]: shippingMethod }
  } = useFormikContext();

  const classes = useStyles();

  const [minDate, setMinDate] = useState(undefined);
  const [minDateChanging, setMinDateChanging] = useState(false);
  const [businessDays, setBusinessDays] = useState({});
  const [fetchedCalendarDays, setFetchedCalendarDays] = useState([]);
  const [dialogStatus, setDialogStatus] = useState('NOT_SHOWN');
  const [isOpen, setIsOpen] = useState(false);

  const ERROR_MESSAGE = 'Invalid date';
  const DATE_FORMAT = 'YYYY-MM-DD';

  const getBusinessDays = useCallback(
    yearMonth => {
      const service = new MiscApiService();

      if (fetchedCalendarDays.includes(yearMonth)) {
        return new Promise(resolve => {
          resolve(businessDays);
        });
      }

      return service
        .getCalendarDays({
          yearMonth
        })
        .then(dates => {
          setFetchedCalendarDays(prevState => [...prevState, yearMonth]);
          setBusinessDays(prevState => {
            return { ...prevState, ..._.keyBy(dates, 'date') };
          });
          return dates;
        });
    },
    [businessDays, fetchedCalendarDays]
  );

  useEffect(() => {
    getBusinessDays(moment().format('YYYY-MM'));
  }, [getBusinessDays]);

  useEffect(() => {
    if (!minDate) {
      return;
    }
    getBusinessDays(moment(minDate.toDateString()).format('YYYY-MM'));
  }, [minDate, getBusinessDays]);

  const datetime = useMemo(() => {
    let date = moment();

    if (createdAt && moment(createdAt).isValid()) {
      date = moment(createdAt);
    }

    return date.format('YYYY-MM-DD HH:mm');
  }, [createdAt]);

  useEffect(() => {
    let isCurrent = true;
    const service = new MiscApiService();
    setMinDateChanging(true);
    service
      .getNextBusinessDate({
        datetime,
        shippingMethod
      })
      .then(response => {
        if (
          isCurrent &&
          response.next_business_date &&
          moment(response.next_business_date).isValid()
        ) {
          setMinDate(moment(response.next_business_date).toDate());
        }
      })
      .finally(() => {
        setMinDateChanging(false);
      });

    return () => {
      isCurrent = false;
    };
  }, [shippingMethod, createdAt, datetime]);

  const validateBondingDate = value => {
    // bonding date is not required
    if (!value) {
      return undefined;
    }

    if (!moment(value, DATE_FORMAT, true).isValid()) {
      // handled by format prop
      return undefined;
    }

    const service = new MiscApiService();
    return service
      .isValidBusinessDate({ businessDate: value, datetime, shippingMethod })
      .then(isValid => {
        if (!isValid) {
          return 'Selected date is not a valid business date';
        }

        return undefined;
      })
      .catch(() => {
        return 'Server Error';
      });
  };

  const handleOnMonthChange = d => {
    const date = d.toDate();
    return getBusinessDays(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`);
  };

  const handleShouldDisableDate = d =>
    _.get(businessDays[d.format(DATE_FORMAT)], 'is_business_day', false) !== true;

  return (
    <Field name={name} id={name} validate={validateBondingDate}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched, errors } }) => {
        const currentError = errors[name];
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                InputAdornmentProps={{ position: 'start' }}
                InputProps={{
                  readOnly: true,
                  onClick: () => {
                    setIsOpen(true);
                  },
                  className: classes.textRoot,
                  endAdornment: (
                    <IconButton
                      className={`${classes.clearButton} ${value ? classes.hasValue : ''}`}
                      aria-label="delete"
                      size="small"
                      onClick={e => {
                        setFieldValue(name, null, false);
                        e.stopPropagation();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                }}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                variant="inline"
                disabled={minDateChanging || !minDate}
                autoOk
                fullWidth
                disableToolbar
                label={label}
                required={false}
                inputVariant="outlined"
                format={DATE_FORMAT}
                value={value}
                onBlur={() => setFieldTouched(name, true, false)}
                onChange={newValue => {
                  setFieldTouched(name, true, false);

                  if (moment.isMoment(newValue) && newValue.isValid()) {
                    setFieldValue(
                      name,
                      moment.isMoment(newValue) ? newValue.format(DATE_FORMAT) : newValue,
                      true
                    );

                    if (dialogStatus === 'NOT_SHOWN' && !currentError) {
                      setDialogStatus('TO_SHOW');
                    }
                  }
                }}
                initialFocusedDate={minDate}
                minDateMessage={ERROR_MESSAGE}
                minDate={minDate}
                maxDate={moment()
                  .add(6, 'months')
                  .toDate()}
                onMonthChange={handleOnMonthChange}
                shouldDisableDate={handleShouldDisableDate}
              />
              {minDateChanging && (
                <div style={{ width: '100%' }}>
                  <LinearProgress />
                </div>
              )}
              <ErrorMessage
                name={name}
                render={message => <InputErrorMessage>{message}</InputErrorMessage>}
              />
              <InfoDialog
                open={dialogStatus === 'TO_SHOW'}
                onClose={() => {
                  setDialogStatus('SHOWN');
                }}
                title="Note"
                content={
                  <ol>
                    <li>Contingent upon doctor approval within 24 hours.</li>
                    <li>
                      Expect arrival no earlier than end-of-business day of the selected date.
                    </li>
                  </ol>
                }
              />
            </Grid>
          </MuiPickersUtilsProvider>
        );
      }}
    </Field>
  );
};

BondingDateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
};

BondingDateField.defaultProps = {};

export default BondingDateField;
