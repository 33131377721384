import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { produce } from 'immer';

import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import { FETCH_ORGANIZATIONS_PREFIX, fetchOrganizationsAction } from '../../reducers/organizations';
import { formatAccountNumber } from '../../helpers';
import Content from '../../components/Layout/Content';
import InviteToCreateOrganization from './components/InviteToCreateOrganization';
import Can from '../../components/Can';

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginBottom: theme.spacing(2)
  }
}));

const Organizations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const globalParams = useSelector(state => state.organizations.globalParams);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_ORGANIZATIONS_PREFIX])(state)
  );
  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const rows = useSelector(state => state.organizations.items);
  const total = useSelector(state => state.organizations.total);

  const handleUpdateData = useCallback(
    params => {
      dispatch(fetchOrganizationsAction({ ...params }));
    },
    [dispatch]
  );

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[additionalParameterKey] = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleShowItem = id => {
    history.push(`/organizations/${id}`);
  };

  const handleStartACase = id => {
    history.push(`/organizations/${id}/rx-forms/add`);
  };

  const viewLocations = id => {
    history.push(`/organizations/${id}/locations`);
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name'
    },
    {
      id: 'account_number',
      numeric: false,
      disablePadding: false,
      disabledSorting: true,
      label: 'Account Number',
      formatMethod: formatAccountNumber
    },
    {
      id: 'organization_type',
      numeric: false,
      disablePadding: false,
      label: 'Type'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      type: 'datetime'
    }
  ];

  const renderFilters = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            placeholder="Name or Account Number"
            className={classes.searchInput}
            defaultValue={globalParams.searchString}
            onChange={e => handleOnChangeSearchInput(e.target.value, 'searchString')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SearchInput
            placeholder="Organization Type"
            className={classes.searchInput}
            defaultValue={globalParams.customerServiceManager}
            onChange={e => handleOnChangeSearchInput(e.target.value, 'customerServiceManager')}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Can
            permissions={['organizations.send-invite-to-create']}
            yes={() => <InviteToCreateOrganization />}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Content filters={renderFilters()}>
      <DataTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        total={total}
        updateData={handleUpdateData}
        defaultOrderBy={globalParams.orderBy}
        additionalParams={additionalParams}
        globalParams={globalParams}
        customActions={[
          {
            name: 'view',
            handleOnAction: handleShowItem,
            title: 'View',
            isIconButton: true,
            icon: <VisibilityIcon />
          },
          {
            name: 'start',
            handleOnAction: handleStartACase,
            title: 'Start A Case',
            isIconButton: true,
            icon: <AssignmentIcon />
          },
          {
            name: 'locations',
            handleOnAction: viewLocations,
            title: 'Locations',
            isIconButton: true,
            icon: <ApartmentIcon />
          }
        ]}
      />
    </Content>
  );
};

Organizations.propTypes = {};

Organizations.defaultProps = {};

export default Organizations;
