import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGlobalPricing } from '../../../../reducers/pricing';
import PricingApiService from '../../../../services/api/pricing';
import CustomizeFees from '../../../../components/CustomizeFees';
import { enqueueNotification } from '../../../../reducers/notifications';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const Main = ({ organization }) => {
  const defaultFees = useSelector(state => state.pricing.global);
  const [original, setOriginal] = useState(null);
  const [fees, setFees] = useState(null);
  const dispatch = useDispatch();

  const fetchPricing = useCallback(() => {
    const pricingService = new PricingApiService();
    pricingService.getOrganization(organization.id).then(response => {
      setOriginal(response.data);
    });
  }, [organization.id]);

  const handleOnSave = async () => {
    const data = {
      ...fees,
      arch_volume_discounts: fees.arch_volume_discounts.filter(
        ({ is_global: isGlobal }) => isGlobal === false
      )
    };
    const pricingService = new PricingApiService();
    pricingService
      .updateOrganization(organization.id, data)
      .then(response => {
        setOriginal(response.data);
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };

  useEffect(() => {
    if (!original) {
      fetchPricing();
      return;
    }
    if (!defaultFees) {
      dispatch(fetchGlobalPricing());
      return;
    }
    setFees(cloneDeep(original));
  }, [organization, original, defaultFees, dispatch, fetchPricing]);

  if (!fees || !defaultFees) {
    return <LoadingIndicator />;
  }

  return (
    <CustomizeFees
      fees={fees}
      defaultFees={{ ...original, ...defaultFees }}
      originalFees={original}
      discountProps={{
        global: false
      }}
      onSave={handleOnSave}
      onChange={setFees}
    />
  );
};

Main.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default Main;
