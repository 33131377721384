import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CardActions } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import DetailItem from '../../components/DetailItem';
import StatusTag from '../../components/StatusTag';
import { getColorByStatus, UTCDateTimeToLocalTime } from '../../helpers';
import Can from '../../components/Can';
import {
  CANCEL_REQUEST_PREFIX,
  cancelRequestAction,
  COMPLETE_REQUEST_PREFIX,
  completeRequestAction,
  FETCH_REQUEST_PREFIX,
  fetchRequestAction
} from '../../reducers/requests';
import FormDialog from '../../components/FormDialog';
import PrintingRequestItem from '../PrintingRequests/components/PrintingRequestItem';
import PrintingRequestItemList from '../PrintingRequests/components/PrintingRequestItemList';
import ConfirmationDialogOnClickWrapper from '../../components/ConfirmationDialogOnClickWrapper';
import LoadingButton from '../../components/LoadingButton';
import FlawedTrayRequestHandler from '../FlawedTrays/components/FlawedTrayRequestHandler';
import { fetchDownloadUrlPrintingFileAction } from '../../reducers/printingFiles';
import ShippingDetails from './components/ShippingDetails';
import { REQUEST_STATUSES } from '../../constants';
import useOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import PatientWorkflowContext from '../PatientWorkflow/context/PatientWorkflowContext';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInput-underline:before': {
      content: 'none'
    },
    '& label.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .Mui-disabled input': {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    width: '75%'
  },
  submit: {
    marginLeft: 'auto'
  },
  details: {
    marginBottom: theme.spacing(1)
  }
}));

const RequestDetails = props => {
  const {
    match: { params }
  } = props;

  const requestId = parseInt(params.id, 10);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const request = useSelector(state => {
    return state.requests.items[state.requests.items.findIndex(item => item.id === requestId)];
  });
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [shippingInformation, setShippingInformation] = useState({ tracking_number: '' });

  const isLoading = useSelector(state => createLoadingSelector([FETCH_REQUEST_PREFIX])(state));

  const isCancelling = useSelector(state => createLoadingSelector([CANCEL_REQUEST_PREFIX])(state));

  const isCompleting = useSelector(state =>
    createLoadingSelector([COMPLETE_REQUEST_PREFIX])(state)
  );

  const { isLoading: isLoadingOrganizationPermissions } = useOrganizationPermissions({
    organizationId: get(request, 'patient.organization_id')
  });

  const handleDownloadFile = fileId => {
    dispatch(fetchDownloadUrlPrintingFileAction(fileId));
  };

  useEffect(() => {
    dispatch(fetchRequestAction(requestId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFormDialogElement = ({ target }) => {
    setShippingInformation({ ...shippingInformation, [target.name]: target.value });
  };

  const completeRequest = () => {
    const completeParams = shippingInformation.tracking_number ? shippingInformation : {};
    dispatch(completeRequestAction(requestId, completeParams));
  };

  const handleOnCloseFormDialog = response => {
    if (response && shippingInformation.tracking_number) {
      completeRequest();
    }

    setShippingInformation({
      tracking_number: ''
    });
  };

  const complete = () => {
    if (request.printing_request) {
      setOpenFormDialog(true);
    } else {
      completeRequest();
    }
  };

  const cancel = () => {
    dispatch(cancelRequestAction(requestId));
  };

  const goToPatientDetails = () => {
    history.push({
      pathname: `/patient-details/${request.patient_id}`
    });
  };

  if (isLoading || isLoadingOrganizationPermissions) {
    return <LoadingIndicator />;
  }

  if (!request || !request.flawed_trays) {
    return null;
  }

  return (
    <PatientWorkflowContext.Provider value={{ patient: request.patient }}>
      <Container component="main" className={classes.root}>
        <CssBaseline />
        <Box my={2}>
          <Card>
            <CardHeader title="Request Details" />
            <Divider />
            <CardContent>
              <Grid container className={classes.details}>
                <DetailItem xs={6} label="Doctor" value={request.patient.user.full_name} />
                <DetailItem xs={6} label="Patient" value={request.patient.full_name} />
                <DetailItem xs={6} label="Case Number" value={request.patient.case_number_text || '-'} />
                <DetailItem xs={6} label="Suffix" value={request.patient.numeric_suffix || '-'} />
                <DetailItem
                  xs={6}
                  label="Status"
                  value={
                    <StatusTag
                      label={REQUEST_STATUSES[request.status_id]}
                      color={getColorByStatus(REQUEST_STATUSES[request.status_id])}
                    />
                  }
                />
                <DetailItem
                  xs={6}
                  label="Submitted Date"
                  value={UTCDateTimeToLocalTime(request.created_at)}
                />
                <DetailItem
                  xs={6}
                  label="Modified Date"
                  value={UTCDateTimeToLocalTime(request.updated_at)}
                />
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {request.flawed_trays.length > 0 && (
          <Box my={2}>
            <Card>
              <CardHeader
                title="Flawed Trays"
                action={
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDownloadFile(request.flawed_trays[0].file_id)}
                      size="small"
                      startIcon={<CloudDownloadIcon />}
                    >
                      Original IOS
                    </Button>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <List>
                  {request.flawed_trays.map(flawedTray => (
                    <FlawedTrayRequestHandler
                      requestId={request.id}
                      key={flawedTray.id}
                      patientId={request.patient.id}
                      item={flawedTray}
                    />
                  ))}
                </List>
              </CardContent>
            </Card>
          </Box>
        )}
        {request.printing_request && (
          <>
            <Box my={2}>
              <Card>
                <CardHeader
                  title={
                    <>
                      <Typography variant="h5">Print Request</Typography>
                    </>
                  }
                  disableTypography
                />
                <Divider />
                <CardContent>
                  {request.printing_request.printing_request_items.length > 0 && (
                    <PrintingRequestItemList
                      ItemComponent={PrintingRequestItem}
                      items={request.printing_request.printing_request_items}
                      patientId={request.patient.id}
                      requestId={requestId}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
            <Box my={2}>
              <ShippingDetails
                printingRequest={request.printing_request}
                patient={request.patient}
              />
            </Box>
          </>
        )}
        <CardActions>
          <Button
            onClick={() => goToPatientDetails()}
            color="primary"
            className={classes.submit}
            variant="contained"
          >
            See Patient
          </Button>
          {request.status_id === 1 && (
            <Can
              permissions={['customer-requests.manage', 'customer-requests.cancel']}
              yes={() => (
                <>
                  <ConfirmationDialogOnClickWrapper
                    confirmationTitle="Cancel Request"
                    confirmationBody="Are you sure you want to cancel this request?"
                  >
                    <LoadingButton
                      type="button"
                      variant="contained"
                      color="secondary"
                      loading={isCancelling}
                      disabled={isCancelling}
                      className={classes.submit}
                      onClick={cancel}
                    >
                      Cancel
                    </LoadingButton>
                  </ConfirmationDialogOnClickWrapper>
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="primary"
                    loading={isCompleting}
                    disabled={isCompleting}
                    className={classes.submit}
                    onClick={complete}
                  >
                    Complete
                  </LoadingButton>
                </>
              )}
            />
          )}
        </CardActions>
        <FormDialog
          setOpen={setOpenFormDialog}
          open={openFormDialog}
          titleText="Shipping Information"
          acceptButtonText="Continue"
          handleClose={handleOnCloseFormDialog}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="tracking_number"
            label="Tracking Number"
            type="text"
            required
            value={shippingInformation.tracking_number}
            onChange={handleChangeFormDialogElement}
            fullWidth
          />
        </FormDialog>
      </Container>
    </PatientWorkflowContext.Provider>
  );
};

RequestDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

RequestDetails.defaultProps = {
  match: { params: {} }
};

export default RequestDetails;
