import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { get } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/ImageListItem';
import GridListTileBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Img from 'react-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DELETE_PATIENT_FILE_PREFIX, deletePatientFileAction } from '../../../reducers/patients';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import createLoadingSelector from '../../../selectors/loading';
import CustomFullScreenImagesView from '../../../components/CustomFullScreenImagesView';
import Can from '../../../components/Can';

const useStyles = makeStyles(theme => ({
  noFoundChip: {
    marginLeft: theme.spacing(2)
  },
  addButton: {
    marginLeft: theme.spacing(2)
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  icon: {
    color: 'white'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -15,
    marginLeft: -15
  },
  image: {
    cursor: 'zoom-in'
  },
  gridList: {
    width: '100%'
  },
  AccordionSummary: {
    '& > .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between'
    }
  },
  titleContainer: {
    alignItems: 'center'
  },
  mainTitle: {
    display: 'flex'
  },
  extraTitle: {
    textAlign: 'right'
  }
}));

const modalCustomStyles = {
  blanket: base => ({
    ...base,
    zIndex: 1200
  }),
  positioner: base => ({
    ...base,
    zIndex: 1201
  })
};

const PatientFilesGallery = ({
  patient,
  patientFiles,
  title,
  loadImages,
  fileTypeId,
  openUploadDialog,
  showNumberOfImages
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [lightBoxModalIsOpen, setLightBoxModalIsOpen] = useState(false);
  const isDeleting = useSelector(state =>
    createLoadingSelector([DELETE_PATIENT_FILE_PREFIX])(state)
  );

  const openRemoveConfirmation = id => {
    setSelectedItem(id);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseConfirmation = answer => {
    setOpenDeleteConfirmation(false);
    if (answer) {
      dispatch(deletePatientFileAction(patient.id, fileTypeId, selectedItem));
    }
  };

  const toggleLightBoxModal = () => {
    setLightBoxModalIsOpen(false);
  };

  const openLightBoxModel = id => {
    setSelectedItem(id);
    setLightBoxModalIsOpen(true);
  };

  const renderAddButton = () => (
    <Button
      className={classes.addButton}
      variant="contained"
      size="small"
      startIcon={<AddIcon />}
      color="primary"
      onClick={event => {
        event.stopPropagation();
        openUploadDialog(title, fileTypeId);
      }}
    >
      Add
    </Button>
  );

  const renderImagesCount = count => <Typography>({count})</Typography>;

  if (!patientFiles.length) {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="subtitle1">{title}</Typography>
          <Chip size="small" className={classes.noFoundChip} label={`No ${title} found`} />
          {!patient.deleted_at && renderAddButton()}
        </Toolbar>
      </Paper>
    );
  }

  const imageClassName = clsx(classes.image, 'MuiGridListTile-imgFullWidth');

  return (
    <>
      <Accordion onChange={(event, isExpanded) => loadImages(event, isExpanded, fileTypeId)}>
        <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Grid container className={classes.titleContainer}>
            <Grid item xs={11} className={classes.mainTitle}>
              <Typography className={classes.heading}>{title}</Typography>
              {!patient.deleted_at && renderAddButton()}
            </Grid>
            <Grid item xs={1} className={classes.extraTitle}>
              {showNumberOfImages && renderImagesCount(patientFiles.length)}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <ImageList rowHeight={160} className={classes.gridList} cols={3}>
            {get(patient, `files[${fileTypeId}]`, []).map((tile, tileIndex) => (
              <GridListTile key={tile.id} cols={tile.cols || 1}>
                <Img
                  title={tile.created_at}
                  src={[tile.thumbnail_url, tile.url]}
                  alt={tile.caption}
                  className={imageClassName}
                  onClick={() => openLightBoxModel(tileIndex)}
                  loader={<LinearProgress />}
                />
                <GridListTileBar
                  actionIcon={
                    <div className={classes.wrapper}>
                      {!patient.deleted_at && (
                        <Can
                          permissions={[
                            'patient-files.manage',
                            'organization.patient-files.delete',
                            'patient-files.delete'
                          ]}
                          yes={() => (
                            <IconButton
                              title="Remove"
                              className={classes.icon}
                              onClick={() => openRemoveConfirmation(tile.id)}
                              disabled={isDeleting}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        />
                      )}
                      {isDeleting && tile.id === selectedItem && (
                        <CircularProgress size={30} className={classes.buttonProgress} />
                      )}
                    </div>
                  }
                  className={classes.titleBar}
                />
              </GridListTile>
            ))}
          </ImageList>
        </AccordionDetails>
      </Accordion>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleCloseConfirmation}
        title="Delete Confirmation"
        bodyText="Are you sure that you want to remove this image?"
      />
      <ModalGateway>
        {lightBoxModalIsOpen ? (
          <Modal onClose={toggleLightBoxModal} styles={modalCustomStyles}>
            <Carousel
              currentIndex={selectedItem}
              views={get(patient, `files[${fileTypeId}]`, []).map(image => ({
                src: image.url
              }))}
              components={{ View: CustomFullScreenImagesView }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

PatientFilesGallery.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  patientFiles: PropTypes.array.isRequired,
  loadImages: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fileTypeId: PropTypes.number.isRequired,
  openUploadDialog: PropTypes.func.isRequired,
  showNumberOfImages: PropTypes.bool
};

PatientFilesGallery.defaultProps = {
  patient: {},
  showNumberOfImages: false
};

export default PatientFilesGallery;
