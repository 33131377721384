import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%'
  }
}));

const Content = ({ children, filters, ...props }) => {
  const classes = useStyles();

  return (
    <Container component="main" {...props}>
      <CssBaseline />
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        <div className={classes.toolbar}>{filters}</div>
        {children}
      </Box>
    </Container>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default Content;
