import { useContext } from 'react';
import { RxFormContext } from './RxFormProvider';
import { RxFormPreferenceContext } from './RxFormPreferenceProvider';

export const useRxForm = () => {
  return useContext(RxFormContext);
};

export const useRxFormPreference = () => {
  return useContext(RxFormPreferenceContext);
};
