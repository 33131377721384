import React from 'react';
import PropTypes from 'prop-types';
import { connectHighlight } from 'react-instantsearch-dom';
import _ from 'lodash';
import { formatHitValue, isDateAttribute } from './helpers';

const specialDateHighlighter = value => {
  return [
    {
      value,
      isHighlighted: false
    }
  ];
};

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = isDateAttribute(attribute, hit[attribute])
    ? specialDateHighlighter(hit[attribute])
    : highlight({
        highlightProperty: '_highlightResult',
        attribute,
        hit
      });

  return (
    <span>
      {parsedHit.map(part => {
        const parsedValue = formatHitValue(part.value, attribute);

        if (_.isArray(parsedValue)) {
          return _.map(parsedValue, subvalue => {
            return (
              <li key={Math.random()}>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: subvalue.replaceAll('<em>', '<mark>').replaceAll('</em>', '</mark>')
                  }}
                />
              </li>
            );
          });
        }

        return part.isHighlighted ? (
          <mark key={Math.random()}>{parsedValue}</mark>
        ) : (
          <span key={Math.random()}>{parsedValue}</span>
        );
      })}
    </span>
  );
};

const CustomHighlight = connectHighlight(Highlight);

Highlight.propTypes = {
  highlight: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

export default CustomHighlight;
