import React, { useEffect } from 'react';
import { Box, Card, Container, Divider } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Checklist from '../../components/Onboarding/Checklist';
import { proceedNextStep, showOnboarding } from '../../reducers/onboardings';
import hasPermission from '../../selectors/hasPermission';

const Manage = ({ id }) => {
  const dispatch = useDispatch();
  const onboarding = useSelector(state => {
    const object = state.onboardings.current;
    if (object && object.id === id) {
      return object;
    }
    return null;
  });
  const canProceed = useSelector(state =>
    hasPermission(state, {
      permissions: ['onboardings.proceed', 'onboardings.manage', 'organization.onboarding.proceed']
    })
  );

  useEffect(() => {
    if (!onboarding) {
      dispatch(showOnboarding(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding]);

  const handleStepChanged = () => {
    if (!canProceed) {
      return;
    }
    dispatch(proceedNextStep(onboarding));
  };
  return (
    onboarding && (
      <Container>
        <Box>
          <Card>
            <CardHeader title={`Onboarding Organization: ${onboarding.organization.name}`} />
            <Divider />
            <Checklist onboarding={onboarding} setCurrentStep={handleStepChanged} />
          </Card>
        </Box>
      </Container>
    )
  );
};

Manage.propTypes = {
  id: PropTypes.number.isRequired
};

// eslint-disable-next-line react/prop-types
export default function({ match: { params } }) {
  // eslint-disable-next-line radix,react/prop-types
  return <Manage id={parseInt(params.id)} />;
}
