import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NOTIFICATION_TYPES } from '../WSNotificationHandler/constants';
import RushCaseAlert from './RushCaseAlert';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    height: '100%'
  },
  alert_container: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));

const AlertContainer = () => {
  const classes = useStyles();
  const userNotifications = useSelector(state => state.auth.userNotificationAlerts);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(userNotifications);
  }, [userNotifications]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.alert_container}>
            {notifications.map(notification => {
              const type = notification.type || '';

              switch (type) {
                case NOTIFICATION_TYPES.RUSH_CASE:
                  return (
                    <RushCaseAlert
                      patientId={notification.patient_id}
                      patientName={notification.patient_name}
                      id={notification.id}
                      key={notification.id}
                    />
                  );

                default:
                  return null;
              }
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlertContainer;
