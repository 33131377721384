import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import RxFormsApiService from '../../services/api/rxForms';
import { enqueueNotification } from '../../reducers/notifications';
import LoadingIndicator from '../LoadingIndicator';
import RxFormPreferenceProvider from './RxFormPreferenceProvider';

const defaultContext = {
  rxForm: null,
  modificationRules: {}
};

export const RxFormContext = createContext(defaultContext);

const RxFormProvider = ({ id, withPreference, loader, loaderProps, children }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(defaultContext);

  useEffect(() => {
    if (!id || (data.rxForm && data.rxForm.id === id)) {
      return () => {};
    }

    let updateState = responseData => {
      setData(responseData);
    };
    let handleError = error => {
      dispatch(enqueueNotification('error', error));
    };

    const rxFormsService = new RxFormsApiService();
    rxFormsService
      .show(id, {
        include: 'organization,location,shippingLocation,bracketLibraries,createdByUser'
      })
      .then(({ rx_form: rxForm, metadata: { modification_rules: modificationRules = [] } }) => {
        updateState({
          rxForm,
          modificationRules
        });
      })
      .catch(handleError);

    return () => {
      handleError = () => {};
      updateState = () => {};
    };
  }, [id, data, setData, dispatch]);

  if (!data.rxForm || data.rxForm.id !== id) {
    return loader && <LoadingIndicator {...loaderProps} />;
  }

  if (withPreference) {
    return (
      <RxFormContext.Provider value={data}>
        <RxFormPreferenceProvider
          loader={loader}
          loaderProps={loaderProps}
          organizationId={Number(data.rxForm.organization_id)}
        >
          {children}
        </RxFormPreferenceProvider>
      </RxFormContext.Provider>
    );
  }

  return <RxFormContext.Provider value={data}>{children}</RxFormContext.Provider>;
};

RxFormProvider.defaultProps = {
  withPreference: false,
  loader: true,
  loaderProps: {}
};

RxFormProvider.propTypes = {
  id: PropTypes.number.isRequired,
  withPreference: PropTypes.bool,
  children: PropTypes.element.isRequired,
  loader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  loaderProps: PropTypes.object
};

export default RxFormProvider;
