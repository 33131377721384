import React, { useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import {
  createFattmerchantCustomerAction,
  createFattmerchantPaymentMethodAction
} from '../../../reducers/auth';
import LoadingButton from '../../LoadingButton';
import { enqueueNotification } from '../../../reducers/notifications';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '400px',
    maxWidth: '400px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    '& label': {
      position: 'relative',
      color: theme.palette.text.primary,
      fontWeight: '300',
      height: '40px',
      lineHeight: '40px',
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'row',
      '& > span': {
        width: '100px',
        textAlign: 'right',
        marginRight: '30px'
      }
    }
  },
  field: {
    background: 'transparent',
    border: 0,
    outline: 'none',
    flex: 1,
    paddingRight: '10px',
    paddingLeft: '10px',
    cursor: 'text',
    color: '#31325f',
    fontSize: '15px',
    fontWeight: '300'
  },
  cardNumber: {
    width: '100%',
    height: '40px',
    display: 'inline-block',
    margin: '2px'
  },
  cardCVV: {
    width: '100%',
    height: '40px',
    display: 'inline-block',
    margin: '2px'
  },
  group: {
    background: 'white',
    boxShadow: '0 7px 14px 0 rgba(49, 49, 93, 0.1) 0 3px 6px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    marginBottom: '20px'
  },
  expiryMonth: {
    width: '40px',
    height: '100%',
    display: 'inline-block'
  },
  expiryYear: {
    width: '55px',
    height: '100%',
    display: 'inline-block'
  },
  error: {
    textAlign: 'right',
    display: 'block',
    color: 'red',
    fontSize: 'small',
    margin: '0'
  }
}));

const CreditCardForm = ({ onCreditCardSaved }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const fattJs = useRef(null);
  const setFieldValueFormik = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const ErrorMessageText = message => {
    return <p className={classes.error}>{message.children}</p>;
  };

  useEffect(() => {
    const { FattJs } = window;

    const fattJsInstance = new FattJs(process.env.REACT_APP_FATTMERCHANT_WEB_PAYMENTS_TOKEN, {
      number: {
        id: 'card-number',
        placeholder: '0000 0000 0000 0000',
        style:
          'height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;'
      },
      cvv: {
        id: 'card-cvv',
        placeholder: 'CVV',
        style:
          'height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;' // 35px is the height of my card-cvv div
      }
    });

    fattJsInstance.showCardForm();

    fattJsInstance.on('card_form_complete', message => {
      setFieldValueFormik.current('valid_number', message.validNumber);
      setFieldValueFormik.current('valid_cvv', message.validCvv);
    });

    fattJsInstance.on('card_form_incomplete', message => {
      setFieldValueFormik.current('valid_number', message.validNumber);
      setFieldValueFormik.current('valid_cvv', message.validCvv);
    });

    fattJs.current = fattJsInstance;
  }, []);

  const savePaymentMethod = (values, paymentInfo) => {
    const extraDetails = {
      customer_id: paymentInfo.customer_id,
      url: 'https://omni.fattmerchant.com/#/bill/',
      method: 'card',
      month: values.expiry_month,
      year: values.expiry_year,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      validate: true
    };

    return fattJs.current
      .tokenize(extraDetails)
      .then(tokenizedPaymentMethod => {
        return dispatch(
          createFattmerchantPaymentMethodAction(tokenizedPaymentMethod.id, currentOrganization.id)
        ).then(() => {
          onCreditCardSaved(true);
          return true;
        });
      })
      .catch(err => {
        let formattedMessage = 'Something went wrong, please try again.';
        if (err.message) {
          formattedMessage = err.message;
        }
        if (err.fieldErrors) {
          formattedMessage = (
            <div>
              {err.fieldErrors.map(fieldError => (
                <div
                  key={fieldError.field}
                >{`Field: ${fieldError.field} Message: ${fieldError.message}`}</div>
              ))}
            </div>
          );
        }

        dispatch(enqueueNotification('error', formattedMessage));
      });
  };

  return (
    <Paper className={classes.paper}>
      <CssBaseline />
      <Formik
        initialValues={{
          expiry_month: '',
          expiry_year: '',
          valid_number: false,
          valid_cvv: false
        }}
        validationSchema={Yup.object().shape({
          expiry_month: Yup.string()
            .length(2)
            .required('Required'),
          expiry_year: Yup.string()
            .length(4)
            .required('Required')
            // eslint-disable-next-line func-names
            .test('valid_expiry', 'Invalid Expiration Date', function(value) {
              // eslint-disable-next-line camelcase,react/no-this-in-sfc
              const { expiry_month } = this.parent;
              const now = new Date();
              const year = now.getFullYear();
              const month = now.getMonth() + 1;

              // eslint-disable-next-line camelcase
              if (expiry_month > 12) {
                return false;
              }

              if (parseInt(value, 10) > year) {
                return true;
              }

              return !!(year === parseInt(value, 10) && month <= parseInt(expiry_month, 10));
            }),
          valid_number: Yup.bool()
            .required('Invalid Card Number')
            .oneOf([true], 'Invalid Card Number'),
          valid_cvv: Yup.bool()
            .required('Invalid CVV')
            .oneOf([true], 'Invalid CVV')
        })}
        onSubmit={async values => {
          const result = await executeRecaptcha('AddPaymentInformationPage');

          if (!result) {
            return false;
          }

          if (currentOrganization.payment_info) {
            return savePaymentMethod(values, currentOrganization.payment_info);
          }

          await dispatch(createFattmerchantCustomerAction(currentOrganization.id)).then(
            response => {
              return savePaymentMethod(values, response.payment_info);
            }
          );

          return true;
        }}
      >
        {({ setFieldValue, errors, touched, isSubmitting }) => {
          setFieldValueFormik.current = setFieldValue;

          return (
            <Form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.group}>
                    <label htmlFor="card-number">
                      <span>Card Number</span>
                      <div id="card-element" className={classes.field}>
                        <div id="card-number" className={classes.cardNumber} />
                      </div>
                    </label>
                    <ErrorMessage name="valid_number" component={ErrorMessageText} />
                    <label>
                      <span>CVV</span>
                      <div id="card-element" className={classes.field}>
                        <div id="card-cvv" className={classes.cardCVV} />
                      </div>
                    </label>
                    <ErrorMessage name="valid_cvv" component={ErrorMessageText} />
                    <label>
                      <span>Expiration Date</span>
                      <div className={classes.expiryMonth}>
                        <Field
                          className={classes.field}
                          name="expiry_month"
                          maxLength="2"
                          id="expiry-month"
                          placeholder="MM"
                          required
                          onKeyUp={e => {
                            const keyCode = e.which || e.keyCode;

                            const { target } = e;

                            // Tab = 9
                            // Shift + Tab = 16
                            if (keyCode === 9 || keyCode === 16 || target.value.length !== 2)
                              return;

                            target.blur();

                            target
                              .closest('form')
                              .querySelector('input[name=expiry_year]')
                              .focus();
                          }}
                        />
                      </div>
                      <div>/</div>
                      <div className={classes.expiryYear}>
                        <Field
                          className={classes.field}
                          name="expiry_year"
                          maxLength="4"
                          required
                          placeholder="YYYY"
                        />
                      </div>
                    </label>
                    {(touched.expiry_month || touched.expiry_year) &&
                      (errors.expiry_month || errors.expiry_year) && (
                        <ErrorMessageText>Invalid Expiration Date</ErrorMessageText>
                      )}
                  </div>
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                className={classes.submit}
              >
                Save
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

CreditCardForm.propTypes = {
  onCreditCardSaved: PropTypes.func
};

CreditCardForm.defaultProps = {
  onCreditCardSaved: () => {}
};

export default CreditCardForm;
