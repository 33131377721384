import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import OrganizationsApiService from '../services/api/organizations';

const POSTFIX = '_ORGANIZATION_USERS';

export const FETCHING_ORGANIZATION_USERS = `FETCHING${POSTFIX}`;
export const FETCHED_ORGANIZATION_USERS = `FETCHED${POSTFIX}`;

const initialState = {
  organizations: {}
};

const ACTIONS = [FETCHING_ORGANIZATION_USERS, FETCHED_ORGANIZATION_USERS];

const compactUser = user => {
  // limit information availability for now
  return {
    id: user.id,
    full_name: user.full_name,
    role: user.pivot.role.name
  };
};

export default (state = initialState, action) => {
  if (ACTIONS.indexOf(action.type) === -1) {
    return state;
  }
  const organizationId = action.payload.organization_id;
  const organizationDraftState = state.organizations[organizationId] || {
    fetching: false,
    users: null
  };

  switch (action.type) {
    case FETCHING_ORGANIZATION_USERS:
      return produce(state, draft => {
        const orgState = {
          ...organizationDraftState,
          fetching: true
        };
        orgState.fetching = true;
        orgState.users = null;
        draft.organizations[organizationId] = orgState;
      });
    case FETCHED_ORGANIZATION_USERS:
      return produce(state, draft => {
        const orgState = draft.organizations[organizationId];
        orgState.fetching = false;
        orgState.users = action.payload.users.map(user => compactUser(user));
        draft.organizations[organizationId] = orgState;
      });
    default:
      return state;
  }
};

export function fetchOrganizationUsers(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_ORGANIZATION_USERS, payload: { organization_id: organizationId } });
    const api = new OrganizationsApiService();

    return api
      .getOrganizationMembers(organizationId)
      .then(response => {
        dispatch({
          type: FETCHED_ORGANIZATION_USERS,
          payload: { users: response.members, organization_id: organizationId }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
