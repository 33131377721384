import { useEffect, useState } from 'react';

const time = () => {
  return Math.floor(Date.now() / 1000);
};

export default (Component, timeout) => props => {
  const [idle, setIdle] = useState(false);
  const [lastMovement, setLastMovement] = useState(time());

  const move = () => {
    setIdle(false);
    setLastMovement(time());
  };

  useEffect(() => {
    document.body.addEventListener('keypress', move);
    document.body.addEventListener('mousemove', move);
  }, [setIdle]);

  useEffect(() => {
    if (idle) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setIdle(true);
    }, timeout * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [idle, lastMovement, setIdle]);

  return Component({ ...props, idle });
};
