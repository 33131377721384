import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardForm from './CreditCardForm';
import ACHForm from './ACHForm';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  pleaseNote: {
    color: theme.palette.secondary.main
  }
}));

const PaymentMethodFormSelector = ({ onPaymentMethodSaved }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={(event, newValue) => setSelectedTab(newValue)}
      >
        <Tab label="ACH" />
        <Tab label="Credit Card" />
      </Tabs>
      <Box display="flex" alignItems="center" justifyContent="center" p={2}>
        {selectedTab === 0 && <ACHForm onACHSaved={onPaymentMethodSaved} />}
        {selectedTab === 1 && <CreditCardForm onCreditCardSaved={onPaymentMethodSaved} />}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" p={2}>
        <Alert severity="info">
          <p>
            <span className={classes.pleaseNote}>Please Note: </span>
            <span>ACH is free. Credit cards have a 1.49% flat fee.</span>
          </p>
        </Alert>
      </Box>
    </div>
  );
};

PaymentMethodFormSelector.propTypes = {
  onPaymentMethodSaved: PropTypes.func.isRequired
};

PaymentMethodFormSelector.defaultProps = {};

export default PaymentMethodFormSelector;
