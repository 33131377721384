import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class RxFormsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    includes = [],
    searchDoctorFullNameQuery = '',
    searchFullNameQuery = '',
    status = -1,
    organizationId = '',
    organizations = [],
    locations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for('rx_forms')
        .include(...includes)
        .where('user_id', userId)
        .where('user_full_name_like', searchDoctorFullNameQuery)
        .where('patient_full_name_like', searchFullNameQuery)
        .where('status', status || -1)
        .where('organization_id', organizationId)
        .whereIn('chosen_wi_organization_id', organizations)
        .whereIn('chosen_wi_location_id', locations)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id, params = {}) {
    return this.request({
      method: 'get',
      url: `/rx_forms/${id}`,
      params
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/rx_forms',
      data
    });
  }

  markAsReady(id) {
    return this.request({
      method: 'post',
      url: `/rx_forms/${id}/mark_as_ready`
    });
  }

  cancel(id) {
    return this.request({
      method: 'post',
      url: `/rx_forms/${id}/cancel`
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/rx_forms/${id}`,
      data
    });
  }

  patch(id, data) {
    return this.request({
      method: 'patch',
      url: `/rx_forms/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/rx_forms/${id}`
    });
  }

  getUploadLink(name, organizationId) {
    return this.request({
      method: 'get',
      url: `/rx_forms/upload_link`,
      params: {
        file_name: name,
        organization_id: organizationId
      }
    });
  }

  assign(id, assignToId) {
    return this.request({
      method: 'post',
      url: `/rx_forms/${id}/assign`,
      data: {
        assign_to: assignToId
      }
    });
  }

  updateManagementData(id, data) {
    return this.request({
      method: 'post',
      url: `/rx_forms/${id}/management_data`,
      data
    });
  }

  createOldCase(data) {
    return this.request({
      method: 'post',
      url: '/rx_forms/old_case',
      data
    });
  }
}
