import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import LoadingButton from '../LoadingButton';
import { validatePromotionCodeAction } from '../../reducers/promotionCodes';

const EnterPromotionCode = ({ onValidatePromotionCode, disabled }) => {
  const dispatch = useDispatch();
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [code, setCode] = useState('');

  const validatePromotionCode = () => {
    setIsValidatingCode(true);
    dispatch(validatePromotionCodeAction(code, 1)).then(promotionCode => {
      setIsValidatingCode(false);
      if (promotionCode) {
        onValidatePromotionCode(promotionCode);
      }
    });
  };

  const onChange = e => {
    setCode(e.target.value);
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
      <TextField label="Add Promo Code" type="text" value={code} onChange={onChange} />
      <LoadingButton
        type="button"
        size="small"
        variant="contained"
        color="primary"
        loading={isValidatingCode}
        disabled={isValidatingCode || disabled || !code}
        onClick={() => validatePromotionCode()}
      >
        Apply
      </LoadingButton>
    </Box>
  );
};

EnterPromotionCode.propTypes = {
  onValidatePromotionCode: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

EnterPromotionCode.defaultProps = {
  disabled: false
};

export default EnterPromotionCode;
