import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Footer, Sidebar, Topbar } from './components';
import OrganizationsTopBar from './OrganizationsTopBar';
import LoadingIndicator from '../../LoadingIndicator';
import withOnboarding from '../../../hooks/withOnboarding';
import AlertContainer from '../../AlertContainer';
import AnnouncementContainer from '../../AnnouncementContainer';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%',
    padding: theme.spacing(1)
  },
  coverSidebar: {
    zIndex: `${theme.zIndex.appBar - 1} !important;`
  }
}));

const Main = props => {
  const { children, onboarding } = props;

  const isCurrentOrganizationUpdating = useSelector(
    state => state.auth.isCurrentOrganizationUpdating
  );

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <OrganizationsTopBar />
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        drawerClassName={onboarding.inProgress ? classes.coverSidebar : ''}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <AnnouncementContainer />
      <AlertContainer />

      {isCurrentOrganizationUpdating ? (
        <LoadingIndicator />
      ) : (
        <main className={classes.content}>
          {children}
          <Footer />
        </main>
      )}
      {onboarding.modal}
    </div>
  );
};

Main.defaultProps = {
  onboarding: {
    strict: false,
    modal: null,
    inProgress: false
  }
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  onboarding: PropTypes.shape({
    modal: PropTypes.node,
    inProgress: PropTypes.bool
  })
};

export default withOnboarding(Main);
