import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LabelDialog from './LabelDialog';
import { markShippingLabelAsPrintedInBulk } from '../../../reducers/requests';
import { closeShippingLabel, fetchSpecificPatientsOnly } from '../../../reducers/patients';
import { COMMON_PATIENT_INCLUDES } from '../../../constants/queries';
import ShippingLabel from '../Labels/ShippingLabel';

const ShippingLabelDialog = ({ patients, close, markingAsPrinted, markPrinted }) => {
  const location =
    patients !== null ? patients[0].customer_request.printing_request.location : null;
  const allPrinted =
    location &&
    patients.filter(patient => {
      return patient.customer_request.printing_request.shipping_label_printed;
    }).length === patients.length;

  return (
    <LabelDialog
      open={location !== null}
      onClose={close}
      title="Shipping Label"
      printed={allPrinted}
      markingAsPrinted={markingAsPrinted}
      onPrinted={() => markPrinted(patients)}
    >
      {location !== null && <ShippingLabel location={location} />}
    </LabelDialog>
  );
};

ShippingLabelDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patients: PropTypes.array,
  close: PropTypes.func.isRequired,
  markingAsPrinted: PropTypes.bool.isRequired,
  markPrinted: PropTypes.func.isRequired
};

ShippingLabelDialog.defaultProps = {
  patients: null
};

export default connect(
  state => {
    return {
      patients: state.patients.selection.shippingLabel,
      markingAsPrinted: state.requests.actions.markingShippingLabelInBulk
    };
  },
  dispatch => {
    return {
      markPrinted: patients => {
        const requestIds = patients.map(patient => patient.customer_request.id);
        const patientIds = patients.map(patient => patient.id);
        dispatch(markShippingLabelAsPrintedInBulk(requestIds)).then(() => {
          dispatch(fetchSpecificPatientsOnly(patientIds, COMMON_PATIENT_INCLUDES));
          dispatch(closeShippingLabel());
        });
      },
      close: () => dispatch(closeShippingLabel())
    };
  }
)(ShippingLabelDialog);
