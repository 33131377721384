import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const SearchInput = props => {
  const {
    className,
    onChange,
    style,
    ignore,
    inputRef = null,
    disabled,
    delay,
    value: currentValue,
    defaultValue,
    ...rest
  } = props;

  const [value, setValue] = useState(currentValue === undefined ? defaultValue : currentValue);
  const [typing, setTyping] = useState({
    timestamp: 0,
    status: false
  });
  const typingTimeout = useRef();

  const classes = useStyles();

  const onKeyPress = e => {
    if (!ignore && ignore.length === 0) return true;

    const key =
      e.key ||
      String.fromCharCode(e.which) ||
      String.fromCharCode(e.keyCode) ||
      String.fromCharCode(e.charCode);
    if (ignore.includes(key)) {
      e.preventDefault();
      return false;
    }

    return true;
  };

  const handleChangeWithDelay = e => {
    e.persist();

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    const newValue = e.target.value;
    if (!typing.status) {
      setTyping({
        timestamp: Date.now(),
        status: true
      });
    }
    setValue(newValue);
    typingTimeout.current = setTimeout(() => {
      setTyping({
        timestamp: Date.now(),
        status: false
      });
      onChange(e);
    }, delay);
  };

  useEffect(() => {
    if (typing.status || Date.now() - typing.timestamp <= 1000) {
      return;
    }
    setValue(currentValue);
  }, [currentValue, value, typing, delay, setValue]);

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        disabled={disabled}
        value={value}
        {...rest}
        inputRef={inputRef}
        className={classes.input}
        disableUnderline
        onChange={delay ? handleChangeWithDelay : onChange}
        onKeyPress={onKeyPress}
      />
    </Paper>
  );
};

SearchInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  ignore: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  delay: PropTypes.number,
  defaultValue: PropTypes.string
};

SearchInput.defaultProps = {
  className: '',
  onChange: () => {},
  style: {},
  ignore: [],
  inputRef: null,
  disabled: false,
  value: '',
  delay: 1000,
  defaultValue: ''
};

export default SearchInput;
