import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  detailItem: {
    padding: theme.spacing(0, 2, 0, 2)
  },
  label: {
    fontWeight: 'bold'
  }
}));

const DetailItem = ({ label, value, xs, noPadding, labelXs }) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs} className={clsx(!noPadding && classes.detailItem)}>
      <Grid container alignItems="center">
        <Grid item xs={labelXs}>
          <Typography gutterBottom variant="subtitle1" className={classes.label}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  xs: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
  noPadding: PropTypes.bool,
  labelXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

DetailItem.defaultProps = {
  xs: 12,
  noPadding: false,
  labelXs: true
};

export default DetailItem;
