import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { fetchOrganizationPricing } from '../../reducers/organizationPricing';

export const PricingContext = createContext({});

const PricingDataProvider = ({ children, fetchPricing, pricing }) => {
  useEffect(() => {
    if (!pricing) {
      fetchPricing();
    }
  }, [pricing, fetchPricing]);

  if (!pricing || pricing.fetching) {
    return <LoadingIndicator />;
  }

  return (
    <PricingContext.Provider value={pricing}>
      {typeof children === 'function' ? children(pricing) : children}
    </PricingContext.Provider>
  );
};

PricingDataProvider.propTypes = {
  children: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pricing: PropTypes.object,
  fetchPricing: PropTypes.func.isRequired
};

PricingDataProvider.defaultProps = {
  pricing: null
};

export default connect(
  (state, ownProps) => {
    const pricing = state.organizationPricing.organizations[ownProps.organizationId];

    return { pricing };
  },
  (dispatch, ownProps) => {
    return {
      fetchPricing: () => dispatch(fetchOrganizationPricing(ownProps.organizationId))
    };
  }
)(PricingDataProvider);
