import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  FETCH_PATIENT_PREFIX,
  fetchPatientAction,
  fetchPatientFilesDownloadUrlsAction
} from '../../reducers/patients';
import PatientFilesTable from './components/PatientFilesTable';
import PatientFilesGallery from './components/PatientFilesGallery';
import TreatmentNotes from './components/TreatmentNotes';
import UploadFilesDialog from '../../components/UploadFilesDialog';
import UpdatePatient from './components/UpdatePatient';
import { FILE_TYPE_IDS } from '../../constants';
import { filterFilesByType, isDoctor, isLabPartner, isTechnicianOrAdmin } from '../../helpers';
import PatientNote from '../../components/PatientNote';
import NotesWrapper from './components/NotesWrapper';
import RxFormInfo from './components/RxFormInfo';
import withOrganization from '../../hooks/withOrganization';
import ConfirmationDialogWrapper from '../../components/ConfirmationDialogWrapper';
import OnHoldNotes from '../../components/OnHoldNotes';
import Can from '../../components/Can';
import { createDesktopURI } from '../../helpers/urls';
import iosFileNameValidator from '../../helpers/iosFileNameValidator';

const useStyles = makeStyles(theme => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2)
  }
}));

const PatientDetails = props => {
  const {
    match: { params },
    location
  } = props;

  const urlQueryParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const IOSFileId = urlQueryParams.get('open-ios-file');
  const patientId = parseInt(params.id, 10);
  const patient = useSelector(state => {
    return state.patients.items[state.patients.items.findIndex(item => item.id === patientId)];
  });
  const rxFormId = patient && patient.rx_form && patient.rx_form.id;
  const patientFiles = get(patient, 'patient_files');
  const iosFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.ios);
  const stlFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.stl);
  const iosFile = iosFiles.length ? iosFiles[0] : null;

  const [uploadDialogParams, setUploadDialogParams] = useState({
    title: '',
    open: false,
    patientId,
    fileTypeId: FILE_TYPE_IDS.photos,
    isImage: true
  });

  const actions = {
    IOS: [],
    STL: [
      { name: 'download' },
      {
        name: 'delete',
        roles: ['super_admin', 'tech_support', 'supervisor', 'admin', 'lab_partner']
      }
    ],
    ANALYSIS_REPORT: [
      { name: 'download' },
      {
        name: 'delete',
        roles: ['super_admin', 'tech_support', 'supervisor', 'admin', 'lab_partner']
      }
    ]
  };
  if (!iosFile && isDoctor(currentUser)) {
    actions.STL[1].roles.push('doctor');
  }

  const openUploadDialog = (
    title,
    fileTypeId,
    isImage = true,
    maxFiles,
    preventDuplicate,
    existingFiles,
    nameValidator
  ) => {
    setUploadDialogParams({
      ...uploadDialogParams,
      title,
      fileTypeId,
      open: true,
      isImage,
      maxFiles,
      preventDuplicate,
      existingFiles,
      nameValidator
    });
  };

  const loadImages = (event, isExpanded, fileTypeId, forceUpdate = false) => {
    if ((isExpanded && !get(patient, `files[${fileTypeId}].length`, false)) || forceUpdate) {
      dispatch(fetchPatientFilesDownloadUrlsAction(patientId, fileTypeId));
    }
  };

  const handleUploadDialogClose = () => {
    setUploadDialogParams({ ...uploadDialogParams, open: false });
  };

  const handleOpenPatientOnDibs = () => {
    window.location.href = createDesktopURI(`open_patient/${patient.id}`);
  };

  const isLoadingPatient = useSelector(state =>
    createLoadingSelector([FETCH_PATIENT_PREFIX])(state)
  );

  const filterFiles = (currentPatient, fileTypeId) => {
    if (!currentPatient || !currentPatient.patient_files) {
      return [];
    }

    return filterFilesByType(currentPatient.patient_files, fileTypeId);
  };

  useEffect(() => {
    if (IOSFileId) {
      window.location.href = createDesktopURI(`open_file/${IOSFileId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchPatientAction(patientId));
  }, [location, patientId, dispatch]);

  if (isLoadingPatient) {
    return <LoadingIndicator />;
  }

  if (!patient || !patient.patient_files || !patient.treatment_notes || !patient.user) {
    return null;
  }

  const children = (
    <>
      <UpdatePatient patient={patient} />
      {patient.rx_form && (
        <RxFormInfo
          className={classes.form}
          id={rxFormId}
          patient={patient}
          additionalButtons={() => {
            if (
              !iosFile &&
              stlFiles.length > 0 &&
              Number(patient.rx_form.submission_completed_by) === 2
            ) {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  title="Create IOS File"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => handleOpenPatientOnDibs()}
                >
                  Create IOS File
                </Button>
              );
            }
            return '';
          }}
        />
      )}
      <Card className={classes.form}>
        <CardHeader title="Additional Information" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NotesWrapper title="Doctor Notes">
                <PatientNote
                  patientId={patient.id}
                  note={patient.doctor_notes || ''}
                  noteIdentifier="doctor_notes"
                  label="Doctor Notes"
                  rows={6}
                  disabled={isTechnicianOrAdmin(currentUser) || isLabPartner(currentUser)}
                />
              </NotesWrapper>
            </Grid>
            <Grid item xs={12}>
              <NotesWrapper title="Technician Notes">
                <PatientNote
                  patientId={patient.id}
                  note={patient.management_notes || ''}
                  noteIdentifier="management_notes"
                  label="Tech Notes"
                  rows={6}
                  disabled={isDoctor(currentUser)}
                />
              </NotesWrapper>
            </Grid>
            <Can
              permissions={['patients.internal-notes.create']}
              yes={() => (
                <Grid item xs={12}>
                  <NotesWrapper title="Internal Notes">
                    <PatientNote
                      patientId={patient.id}
                      note={patient.internal_notes || ''}
                      noteIdentifier="internal_notes"
                      label="Internal Notes"
                      rows={6}
                    />
                  </NotesWrapper>
                </Grid>
              )}
            />
            <Grid item xs={12}>
              <NotesWrapper title="On Hold Notes">
                <OnHoldNotes
                  patientId={patient.id}
                  notes={patient.on_hold_notes || []}
                  label="On Hold Notes"
                  rows={6}
                  disabled={!patient.is_on_hold}
                />
              </NotesWrapper>
            </Grid>
            <Grid item xs={12}>
              <TreatmentNotes treatmentNotes={get(patient, 'treatment_notes', [])} />
            </Grid>
            <Grid item xs={12}>
              <ConfirmationDialogWrapper declineText="No" confirmText="Yes">
                {({ confirm }) => {
                  const handler = (...args) => {
                    if (iosFiles.length !== 0) {
                      confirm(
                        'Warning: IOS File already exists!',
                        'Patient with multiple IOS files can cause synchronization problem on DIBS. Do you want to proceed?',
                        () => {
                          openUploadDialog(...args, name =>
                            iosFileNameValidator(name, args[5], patient)
                          );
                        }
                      );
                    } else {
                      openUploadDialog(...args, name =>
                        iosFileNameValidator(name, args[5], patient)
                      );
                    }
                  };

                  return (
                    <PatientFilesTable
                      patient={patient}
                      patientModificationWarningId="Patients-PatientDetails-IOS"
                      organizationId={patient.organization_id}
                      patientFiles={iosFiles}
                      fileTypeId={FILE_TYPE_IDS.ios}
                      actions={[
                        { name: 'open' },
                        { name: 'download' },
                        {
                          name: 'delete',
                          roles: [
                            'super_admin',
                            'admin',
                            'lab_partner',
                            'tech_support',
                            'supervisor'
                          ]
                        }
                      ]}
                      title="IOS Files"
                      openUploadDialog={handler}
                      isPatientDeleted={patient && Boolean(patient.deleted_at)}
                      preventDuplicate
                      disableUpload={isDoctor(currentUser)}
                    />
                  );
                }}
              </ConfirmationDialogWrapper>
            </Grid>
            <Grid item xs={12}>
              <PatientFilesTable
                patient={patient}
                patientModificationWarningId="Patients-PatientDetails-STL"
                organizationId={patient.organization_id}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.stl)}
                fileTypeId={FILE_TYPE_IDS.stl}
                actions={actions.STL}
                title="STL Files"
                openUploadDialog={openUploadDialog}
                isPatientDeleted={patient && Boolean(patient.deleted_at)}
                maxFiles={20}
                disableUpload={isDoctor(currentUser) && !!iosFile}
                preventDuplicate
              />
            </Grid>
            <Grid item xs={12}>
              <PatientFilesGallery
                title="Photos"
                patient={patient}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.photos)}
                fileTypeId={FILE_TYPE_IDS.photos}
                loadImages={loadImages}
                openUploadDialog={openUploadDialog}
                showNumberOfImages
              />
            </Grid>
            <Grid item xs={12}>
              <PatientFilesTable
                patient={patient}
                patientModificationWarningId="Patients-PatientDetails-Analysis-Report"
                organizationId={patient.organization_id}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.analysis_report)}
                fileTypeId={FILE_TYPE_IDS.analysis_report}
                actions={actions.ANALYSIS_REPORT}
                title="Analysis Report"
                openUploadDialog={openUploadDialog}
                isPatientDeleted={patient && Boolean(patient.deleted_at)}
                disableUpload={isDoctor(currentUser)}
                preventDuplicate
              />
            </Grid>
            {false && (
              <Grid item xs={12}>
                <PatientFilesGallery
                  title="IDB Tray Images"
                  patient={patient}
                  patientFiles={filterFiles(patient, FILE_TYPE_IDS.brackets)}
                  fileTypeId={FILE_TYPE_IDS.brackets}
                  loadImages={loadImages}
                  openUploadDialog={openUploadDialog}
                  showNumberOfImages
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <UploadFilesDialog
        title={uploadDialogParams.title}
        patientFileTypeId={uploadDialogParams.fileTypeId}
        patientId={uploadDialogParams.patientId}
        handleClose={handleUploadDialogClose}
        open={uploadDialogParams.open}
        isImageUploader={uploadDialogParams.isImage}
        updatePatient
        maxFiles={uploadDialogParams.maxFiles}
        preventDuplicate={uploadDialogParams.preventDuplicate}
        existingFiles={uploadDialogParams.existingFiles}
        nameValidator={uploadDialogParams.nameValidator}
      />
    </>
  );

  if (isDoctor(currentUser) || isLabPartner(currentUser)) {
    // load user's organization permissions
    return withOrganization({ organizationId: patient.organization_id, children: () => children });
  }
  return children;
};

PatientDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
};

PatientDetails.defaultProps = {
  match: { params: {} }
};

export default PatientDetails;
