import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toggleCustomerPermissionAction } from '../../../reducers/users';
import LoadingSpinner from '../../../components/LoadingSpinner';

const PermissionListItem = ({ permission, userId, isEnabled }) => {
  const dispatch = useDispatch();
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const handleToggleCustomPermission = permissionValue => {
    setIsLoadingLocal(true);
    dispatch(toggleCustomerPermissionAction(userId, permissionValue)).then(() => {
      setIsLoadingLocal(false);
    });
  };

  return (
    <ListItem>
      <ListItemText primary={permission.label} secondary={permission.description} />
      <ListItemSecondaryAction>
        <FormControlLabel
          edge="end"
          control={<Switch color="primary" />}
          onChange={() => handleToggleCustomPermission(permission.permission)}
          label={<LoadingSpinner show={isLoadingLocal} />}
          labelPlacement="start"
          checked={isEnabled}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

PermissionListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  permission: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool
};

PermissionListItem.defaultProps = {
  isEnabled: false
};

export default PermissionListItem;
