import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { ATTRIBUTES, QUESTIONS } from '../constants';
import ShippingMethodsApiService from '../../../services/api/shippingMethods';
import withDependency from './hooks/withDependency';
import InputLabel from '../../Form/InputLabel';

const ShippingMethodField = ({ currentValue, preferenceValue, setValue }) => {
  const {
    values: {
      [ATTRIBUTES.SHIPPING_LOCATION]: locationId,
      [ATTRIBUTES.PRINT_ASSIGNMENT]: printAssignment
    },
    setStatus,
    initialValues: {
      [ATTRIBUTES.SHIPPING_METHOD]: originalValue,
      [ATTRIBUTES.PRINT_ASSIGNMENT]: originalAssignment
    }
  } = useFormikContext();
  const [shippingMethods, setShippingMethods] = useState([]);
  useEffect(() => {
    if (locationId === null || locationId === '') {
      return () => {};
    }
    const api = new ShippingMethodsApiService();
    // eslint-disable-next-line camelcase
    let updateShippingMethod = data => {
      setStatus(null);
      setShippingMethods(data);
    };
    setStatus('loading_shipping_methods');
    api
      .get({
        locationId,
        assignment: printAssignment,
        availableOnly: originalAssignment !== printAssignment
      })
      .then(({ shipping_methods: result }) => {
        updateShippingMethod(
          result.filter(item => item.available || item.id_string === originalValue)
        );
      });

    return () => {
      updateShippingMethod = () => {
        setStatus(null);
      };
    };
  }, [
    locationId,
    printAssignment,
    originalValue,
    setShippingMethods,
    setStatus,
    originalAssignment
  ]);

  useEffect(() => {
    if (
      shippingMethods.length === 0 ||
      (currentValue !== null && shippingMethods.find(item => item.id_string === currentValue))
    ) {
      return;
    }
    const preferredValueExists =
      preferenceValue && !!shippingMethods.find(item => item.id_string === preferenceValue);

    setValue(preferredValueExists ? preferenceValue : shippingMethods[0].id_string);
  }, [currentValue, shippingMethods, setValue, preferenceValue]);

  if (!shippingMethods.find(item => item.id_string === currentValue)) {
    return '';
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{QUESTIONS[ATTRIBUTES.SHIPPING_METHOD]}</InputLabel>
      <Field required name={ATTRIBUTES.SHIPPING_METHOD} component={Select} labelWidth={65}>
        {shippingMethods.map(shippingMethod => {
          return (
            <MenuItem key={shippingMethod.id} value={shippingMethod.id_string}>
              {`${shippingMethod.name}`}
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

ShippingMethodField.propTypes = {
  currentValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  preferenceValue: PropTypes.string.isRequired
};

export default withDependency(ShippingMethodField);
