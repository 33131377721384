import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CardActions, CardContent } from '@material-ui/core';
import { replaceNullToEmpty } from '../../helpers';
import LoadingButton from '../LoadingButton';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import hasPermission from '../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const OrganizationForm = ({
  onSubmit,
  isSaving,
  organization,
  onDelete,
  isDeleting,
  mode,
  ContentWrapper,
  ActionsWrapper
}) => {
  const classes = useStyles();
  const canAssignOrgType = useSelector(state =>
    hasPermission(state, {
      permissions: ['organizations.assign-organization-type', 'organization-types.manage']
    })
  );
  const validationSchema = {
    name: Yup.string().required('Required')
  };
  if (canAssignOrgType) {
    validationSchema.organization_type = Yup.string()
      .min(2)
      .max(20)
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name');
  }

  return (
    <Formik
      initialValues={replaceNullToEmpty(organization)}
      enableReinitialize
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className={classes.form} noValidate>
          <ContentWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Organization"
                  name="name"
                  disabled={mode === 'show'}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  disabled
                  fullWidth
                  id="account_number"
                  label="Account Number"
                  name="account_number"
                  component={TextField}
                />
              </Grid>
              {canAssignOrgType && (
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    fullWidth
                    id="organization_type"
                    label="Organization Type"
                    name="organization_type"
                    disabled={mode === 'show'}
                    component={TextField}
                  />
                </Grid>
              )}
            </Grid>
          </ContentWrapper>
          {mode !== 'show' && (
            <ActionsWrapper>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving}
                className={classes.actionButton}
              >
                Save
              </LoadingButton>
              {organization && onDelete && (
                <ConfirmationDialogOnClickWrapper
                  confirmationBody="Are you sure you want to delete this organization?"
                  confirmationTitle="Delete Confirmation"
                >
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isDeleting}
                    disabled={isDeleting || isSaving}
                    onClick={() => onDelete(organization.id)}
                    className={classes.actionButton}
                  >
                    Delete
                  </LoadingButton>
                </ConfirmationDialogOnClickWrapper>
              )}
            </ActionsWrapper>
          )}
        </Form>
      )}
    </Formik>
  );
};

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  mode: PropTypes.string,
  ContentWrapper: PropTypes.elementType,
  ActionsWrapper: PropTypes.elementType
};

OrganizationForm.defaultProps = {
  onDelete: null,
  isDeleting: false,
  mode: '',
  ContentWrapper: CardContent,
  ActionsWrapper: CardActions
};

export default OrganizationForm;
