import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Modal, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  closeModal,
  proceedNextStep,
  selectCurrentOrganizationOnboarding,
  showOnboarding
} from '../../reducers/onboardings';
import Checklist from './Checklist';
import Can from '../Can';

const useStyles = makeStyles(theme => ({
  modal: {
    overflow: 'auto',
    // must above the drawer but below the top bar
    zIndex: `${theme.zIndex.appBar + 1} !important`
  },
  header: {
    fontWeight: 700,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  headerDesc: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(3)
  }
}));

const Component = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const onboarding = useSelector(state => selectCurrentOrganizationOnboarding(state));
  const modalOpened = useSelector(state => state.onboardings.modalOpened);
  const allowedSteps = useSelector(state => state.onboardings.meta.doctor_allowed_steps);
  const initialized = useRef(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      !currentOrganization ||
      !currentOrganization.onboarding ||
      (onboarding && onboarding.status === 'Completed')
    ) {
      return () => {};
    }
    if (!onboarding || onboarding.id !== currentOrganization.onboarding.id) {
      dispatch(showOnboarding(currentOrganization.onboarding.id));
    } else {
      if (!initialized.current) {
        initialized.current = true;
      }
      const timeout = setTimeout(() => {
        dispatch(showOnboarding(onboarding.id));
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, onboarding]);

  if (!onboarding) {
    return '';
  }

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleCheck = step => {
    if (allowedSteps.indexOf(step) !== -1) {
      dispatch(proceedNextStep(onboarding));
    }
  };

  return (
    modalOpened && (
      <Can
        permissions={['organization.onboarding.view']}
        yes={() => (
          <Modal
            className={classes.modal}
            open={modalOpened}
            onClose={handleClose}
            aria-labelledby="onboarding"
            aria-describedby="doctor-onboarding"
            disableAutoFocus
            disableEnforceFocus
          >
            <Paper elevation={3} style={{ margin: '100px auto 50px auto', maxWidth: 1000 }}>
              <Grid container>
                <Grid item xs={12}>
                  <h1 className={classes.header}>Onboarding Checklist</h1>
                  <Typography variant="subtitle1" className={classes.headerDesc}>
                    Excellent! You’re all set and ready to start Onboarding. As you can see, there
                    are a few steps to complete. However, once the first "Brackets" step is
                    finished, this Onboarding checklist will minimize and you can begin submitting
                    cases!
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Checklist onboarding={onboarding} setCurrentStep={handleCheck} />
                </Grid>
              </Grid>
            </Paper>
          </Modal>
        )}
      />
    )
  );
};

export default Component;
