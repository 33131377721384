import { get } from 'lodash';

const setRequiredPatientFields = patient => {
  const data = { ...patient };
  data.id = get(patient, 'id', 0);
  data.patient_status_id = get(patient, 'patient_status_id', 0);
  data.organization_id = get(patient, 'organization_id', 0);
  data.patient_files = get(patient, 'patient_files', []);
  data.has_pending_flawed_tray_request = get(patient, 'has_pending_flawed_tray_request', false);
  data.organization_type = get(patient, 'organization_type', '');
  return data;
};

const setRequiredDoctorFields = doctor => {
  const data = { ...doctor };
  data.id = get(doctor, 'id', 0);
  data.full_name = get(doctor, 'full_name', '');
  data.patients_count = get(doctor, 'patients_count', 0);
  return data;
};

const setRequiredOrganizationFields = organization => {
  const data = { ...organization };
  data.id = get(organization, 'id', 0);
  return data;
};

const parseCaseTraysAsPatients = patients => {
  return patients.map(row => {
    const user = setRequiredDoctorFields(row.user);
    const organization = setRequiredOrganizationFields(row.organization);
    const data = setRequiredPatientFields(row);
    return { ...data, user, organization };
  });
};

export default {
  parseCaseTraysAsPatients
};
