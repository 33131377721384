import React from 'react';
import PropTypes from 'prop-types';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { UTCDateTimeToLocalTime } from '../../helpers';
import OrderSummaryHandler from '../OrderSummary/OrderSummaryHandler';
import { PAYMENT_STATUS } from '../../constants';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textTransform: 'uppercase'
  },
  orderDetails: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  largeIcon: {
    width: '1.3em',
    height: '1.3em'
  }
}));

const OrdersList = ({ orders }) => {
  const classes = useStyles();

  if (!orders.length) {
    return <Typography className={classes.heading}>No orders yet.</Typography>;
  }

  return (
    <div className={classes.root}>
      {orders.map(order => (
        <Accordion key={order.id} TransitionProps={{ mountOnEnter: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
            <Typography className={classes.heading}>
              {order.fm_paid_at
                ? UTCDateTimeToLocalTime(order.fm_paid_at)
                : UTCDateTimeToLocalTime(order.created_at)}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {order.fm_invoice_status && get(PAYMENT_STATUS[order.fm_invoice_status], 'label')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper className={classes.orderDetails}>
              <OrderSummaryHandler orderId={order.id} />
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

OrdersList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orders: PropTypes.array
};

OrdersList.defaultProps = {
  orders: []
};

export default OrdersList;
