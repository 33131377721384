/* eslint-disable camelcase */
import _ from 'lodash';

export default function extractInitialValues(
  user,
  organization,
  signUpQuestion,
  mainLocation = null,
  surveyResponse = null
) {
  const first_name = _.get(user, 'first_name', '');
  const last_name = _.get(user, 'last_name', '');
  const email = _.get(user, 'email', '');
  const organizationName = _.get(organization, 'name', '');
  const phone_number = _.get(mainLocation, 'phone_number', '');

  const street_address = _.get(mainLocation, 'street_address', '');
  const street_address_line_2 = _.get(mainLocation, 'street_address_line_2', '');
  const city = _.get(mainLocation, 'city', '');
  const state = _.get(mainLocation, 'state', '');
  const country = _.get(mainLocation, 'country', '');
  const zip_code = _.get(mainLocation, 'zip_code', '');

  const questionId = _.get(signUpQuestion, 'id', '');

  const survey_question_id = _.get(surveyResponse, 'survey_question_id', questionId);
  const survey_answer_id = _.get(surveyResponse, 'survey_answer_id', '');
  const survey_response_other = _.get(surveyResponse, 'survey_response_other', '');

  return {
    first_name,
    last_name,
    email,
    organization: organizationName.match(/^Account\s\d+$/) !== null ? '' : organizationName,
    phone_number,
    street_address,
    street_address_line_2,
    city,
    state,
    country,
    zip_code,
    survey_question_id,
    survey_answer_id,
    survey_response_other
  };
}
