import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  changeOrganizationMemberRoleAction,
  FETCH_ORGANIZATION_PREFIX,
  fetchOrganizationAction,
  removeOrganizationMemberAction
} from '../../reducers/organizations';
import LoadingIndicator from '../LoadingIndicator';
import createLoadingSelector from '../../selectors/loading';
import { ORGANIZATION_ROLE_OPTIONS } from '../../constants';
import LoadingSpinner from '../LoadingSpinner';
import hasPermission from '../../selectors/hasPermission';
import SubSectionWrapper from '../../pages/Account/components/SubSectionWrapper';

const useStyles = makeStyles(() => {
  return {
    tableActions: {
      display: 'flex'
    }
  };
});

const OrganizationMembers = ({ organizationId, disabledOwnUser, readOnly }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const [isUpdatingRole, setIsUpdatingRole] = useState({});
  const [isRemovingMember, setIsRemovingMember] = useState({});
  const organization = useSelector(state => {
    return state.organizations.items[
      state.organizations.items.findIndex(item => item.id === organizationId)
    ];
  });
  const canUpdateOrganizationManagers = useSelector(state =>
    hasPermission(state, {
      permissions: ['organizations.change-member-role', 'organizations.manage']
    })
  );
  const canRemoveOrganizationManagers = useSelector(state =>
    hasPermission(state, { permissions: ['organizations.remove-manager', 'organizations.manage'] })
  );
  const canUpdateOrganizationUser = useSelector(state =>
    hasPermission(state, { permissions: ['organizations.manage', 'organizations.user.update'] })
  );
  const isLoading = useSelector(state => createLoadingSelector([FETCH_ORGANIZATION_PREFIX])(state));
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!organization || !organization.users) {
      dispatch(fetchOrganizationAction(organizationId, ['users']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const handleChangeRole = (value, userId) => {
    setIsUpdatingRole({ ...isUpdatingRole, [userId]: true });
    dispatch(changeOrganizationMemberRoleAction(organizationId, userId, value)).then(() => {
      setIsUpdatingRole(omit(isUpdatingRole, [userId]));
    });
  };

  const handleOnDelete = userId => {
    setIsRemovingMember({ ...isRemovingMember, [userId]: true });
    dispatch(removeOrganizationMemberAction(organizationId, userId)).then(() => {
      setIsRemovingMember(omit(isRemovingMember, [userId]));
    });
  };

  const canChangeRole = role => {
    if (role.name === 'organization_manager') {
      return canUpdateOrganizationManagers;
    }

    return true;
  };

  const canRemoveMember = member => {
    if (member.role.name === 'organization_manager') {
      return canRemoveOrganizationManagers;
    }

    return true;
  };

  const canManageUser = member => {
    if (member.role.name === 'organization_manager') {
      return canUpdateOrganizationUser;
    }
    return true;
  };

  const filterOrganizationRoles = roleKey => {
    if (!canUpdateOrganizationManagers) {
      return roleKey !== 'organization_manager';
    }

    return true;
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!organization || !organization.users) {
    return null;
  }

  return (
    <SubSectionWrapper title="Organization Members">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.users.map(organizationMember => (
              <TableRow key={organizationMember.id}>
                <TableCell component="th" scope="row">
                  {organizationMember.full_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {organizationMember.email}
                </TableCell>
                <TableCell>
                  <Select
                    value={organizationMember.pivot.role.name}
                    disabled={
                      readOnly ||
                      (disabledOwnUser && organizationMember.id === currentUser.id) ||
                      !canChangeRole(organizationMember.pivot.role)
                    }
                    onChange={event => handleChangeRole(event.target.value, organizationMember.id)}
                  >
                    {Object.keys(ORGANIZATION_ROLE_OPTIONS)
                      .filter(roleKey =>
                        canChangeRole(organizationMember.pivot.role)
                          ? filterOrganizationRoles(roleKey)
                          : true
                      )
                      .map(key => {
                        return (
                          <MenuItem key={key} value={key}>
                            {ORGANIZATION_ROLE_OPTIONS[key].label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <LoadingSpinner show={isUpdatingRole[organizationMember.id]} />
                </TableCell>
                <TableCell>
                  {!readOnly && !(disabledOwnUser && organizationMember.id === currentUser.id) && (
                    <div className={classes.tableActions}>
                      {canManageUser(organizationMember.pivot) && (
                        <IconButton onClick={() => history.push(`/users/${organizationMember.id}`)}>
                          <VisibilityIcon />
                        </IconButton>
                      )}
                      {canRemoveMember(organizationMember.pivot) && (
                        <IconButton onClick={() => handleOnDelete(organizationMember.id)}>
                          <RemoveCircleIcon color="secondary" />
                          <LoadingSpinner show={isRemovingMember[organizationMember.id]} />
                        </IconButton>
                      )}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubSectionWrapper>
  );
};

OrganizationMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizationId: PropTypes.number.isRequired,
  disabledOwnUser: PropTypes.bool,
  readOnly: PropTypes.bool
};

OrganizationMembers.defaultProps = {
  disabledOwnUser: false,
  readOnly: false
};

export default OrganizationMembers;
