import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardActions, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Can from '../../../../components/Can';
import LoadingButton from '../../../../components/LoadingButton';
import createLoadingSelector from '../../../../selectors/loading';
import { RESUME_CONTRACT_PREFIX, SUSPEND_CONTRACT_PREFIX } from '../../../../reducers/contracts';
import PeriodicRules from '../../../PeriodicRule';

const Main = ({ contract }) => {
  const history = useHistory();

  const isClosingSubscription = useSelector(state =>
    createLoadingSelector([SUSPEND_CONTRACT_PREFIX, RESUME_CONTRACT_PREFIX])(state)
  );

  const openCreatePeriodicRule = () => {
    history.push(`/subscriptions/${contract.id}/periodic-rules/add`);
  };

  return (
    <Can
      permissions={['periodic-rules.manage']}
      yes={() => (
        <Card>
          <CardHeader title="Periodic Rules" />
          <Divider />
          <CardContent>
            <PeriodicRules contract={contract} />
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={openCreatePeriodicRule}
              loading={isClosingSubscription}
              disabled={isClosingSubscription}
            >
              Add Periodic Rule
            </LoadingButton>
          </CardActions>
        </Card>
      )}
    />
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contract: PropTypes.object.isRequired
};

export default Main;
