import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  deleteButton: {
    marginRight: -2,
    padding: 0,
    visibility: 'hidden'
  },
  deleteContainer: {
    padding: 0,
    '@media (pointer: fine)': {
      '&:hover $deleteButton': {
        visibility: 'visible'
      }
    }
  },
  deleteIcon: {
    width: '0.75em',
    height: '0.75em'
  }
}));

const DeleteCellButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.deleteContainer}
    >
      <Grid item>{children}</Grid>
      <Grid item>
        <IconButton
          className={classes.deleteButton}
          aria-label="delete"
          size="small"
          onClick={onClick}
        >
          <CloseIcon width={14} height={14} className={classes.deleteIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

DeleteCellButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

DeleteCellButton.defaultProps = {};

export default DeleteCellButton;
