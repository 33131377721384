import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import appReducer from './reducers';

const initialState = {};
const enhancers = [];
const middleware = [thunk];

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { auth } = state;
    // eslint-disable-next-line no-param-reassign
    state = { auth };
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

// In case of development mode add dev tools extension and redux-logger middleware
if (process.env.NODE_ENV === 'development') {
  /* eslint-disable no-underscore-dangle */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  /* eslint-enable */

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

  if (process.env.REACT_APP_REDUX_LOGGER !== 'false') {
    middleware.push(logger);
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer, initialState, composedEnhancers);
