const hasLinkOrButtonChild = element => {
  return element.querySelectorAll('a, button').length !== 0;
};

const hasParentLinkOrButton = element => {
  if (element.tagName === 'TD' || element.tagName === 'TR') {
    return false;
  }
  if (element.tagName === 'BUTTON' || element.tagName === 'A') {
    return true;
  }
  return hasParentLinkOrButton(element.parentElement);
};

export default element => {
  if (element.tagName === 'TD' || element.tagName === 'TR') {
    return false;
  }
  return hasLinkOrButtonChild(element) || hasParentLinkOrButton(element);
};
