import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PrintingRequestsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId,
    searchFullNameQuery = '',
    includes = [],
    searchDoctorFullNameQuery = '',
    statusId = '',
    organizationId = '',
    organizations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for('printing_requests')
        .include(...includes)
        .where('patient.user_id', userId)
        .where('patient_full_name_like', searchFullNameQuery)
        .where('doctor_full_name_like', searchDoctorFullNameQuery)
        .where('printing_request_status_id', statusId)
        .where('only_with_patient', true)
        .where('organization_id', organizationId)
        .whereIn('chosen_wi_organization_id', organizations)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/printing_requests/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/printing_requests',
      data
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/printing_requests/${id}`,
      data
    });
  }

  complete(id, data) {
    return this.request({
      method: 'post',
      url: `/printing_requests/${id}/completed`,
      data
    });
  }

  updatePrintingRequestItem(id, data) {
    return this.request({
      method: 'put',
      url: `/printing_request_items/${id}`,
      data
    });
  }

  removePrintingRequestItem(id) {
    return this.request({
      method: 'delete',
      url: `/printing_request_items/${id}`
    });
  }

  assign(id, assignToId) {
    return this.request({
      method: 'post',
      url: `/printing_requests/${id}/assign`,
      data: {
        assign_to: assignToId
      }
    });
  }
}
