import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';

const LoadingButton = ({ children, loading, ...rest }) => {
  return (
    <Button {...rest}>
      {children}
      <LoadingSpinner show={loading} />
    </Button>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default LoadingButton;
