import * as Yup from 'yup';
import { set } from 'lodash';

export const groupedInputs = {
  arch: {
    software: {
      label: 'Software'
    },
    initial_case_setup: {
      label: 'Initial Case Setup'
    },
    print: {
      label: '3D Tray Printing'
    },
    insert_brackets: {
      label: 'Insert Brackets'
    },
    rush_case: {
      label: 'Rush Case'
    },
    root_integration: {
      label: 'Root Integration',
      name: 'root_integration'
    }
  },
  exports: {
    export_with_guides: {
      label: 'Export w/ Guides',
      name: 'arch.export_with_guides'
    },
    export_with_brackets: {
      label: 'Export w/ Brackets',
      name: 'arch.export_with_brackets'
    },
    export_tray: {
      label: 'Export Tray',
      name: 'arch.export_tray'
    },
    export_aligner_models: {
      label: 'Export Aligner Models',
      name: 'arch.export_aligner_models'
    },
    tray_design: {
      label: 'Tray Design',
      name: 'arch.tray_design'
    }
  },
  shipping_fees: {
    express: {
      label: 'Express'
    },
    urgent: {
      label: 'Urgent'
    },
    lightspeed: {
      label: 'LightSpeed'
    },
    international: {
      label: 'International'
    }
  },
  settings: {
    delay_case_charging: {
      label: 'Delay Case Charging',
      adornment: 'Day(s)',
      validator: Yup.number()
        .required()
        .min(1, 'Must not be less than 1 day.')
        .max(20, 'Must not exceed 20 days.'),
      inputProps: {
        min: 5,
        max: 20
      }
    },
    delay_payment_processing: {
      label: 'Delay Payment Processing',
      adornment: 'Day(s)',
      validator: Yup.number()
        .required()
        .min(1, 'Must not be less than 1 day.')
        .max(10, 'Must not exceed 10 days.'),
      inputProps: {
        min: 1,
        max: 20
      }
    },
    flat_monthly: {
      label: 'Flat Monthly Fee',
      validator: Yup.number().min(0, 'Invalid value'),
      name: 'flat_monthly'
    }
  }
};

export const validator = (exclude = []) => {
  const numberValidator = () => {
    return Yup.number()
      .required('Required')
      .min(0, 'Invalid value');
  };
  const schema = {};

  Object.keys(groupedInputs).forEach(parentKey => {
    Object.keys(groupedInputs[parentKey]).forEach(childKey => {
      if (exclude.indexOf(childKey) !== -1) {
        return;
      }
      const {
        name = `${parentKey}.${childKey}`,
        validator: attrValidator = numberValidator()
      } = groupedInputs[parentKey][childKey];
      set(schema, name, attrValidator);
    });
  });

  return Yup.object().shape({
    arch: Yup.object().shape(schema.arch),
    settings: Yup.object().shape(schema.settings),
    flat_monthly: schema.flat_monthly,
    shipping_fees: Yup.object().shape(schema.shipping_fees),
    root_integration: schema.root_integration,
    arch_volume_discounts: Yup.array().of(
      Yup.object().shape({
        discount: Yup.number()
          .required()
          .min(0, 'Invalid discount percentage.')
          .max(100, 'Invalid discount percentage.'),
        volume: Yup.number().required()
      })
    )
  });
};

export const validate = (fees, exclude = []) => {
  const errorMessages = {};
  return new Promise((resolve, reject) => {
    validator(exclude)
      .validate(fees, { abortEarly: false })
      .then(resolve)
      .catch(errors => {
        errors.inner.forEach(({ path, message }) => {
          set(errorMessages, path, message);
        });
        reject(errorMessages);
      });
  });
};
