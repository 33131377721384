import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper, Table, Toolbar } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import { findRolesInUser, UTCDateTimeToLocalTime } from '../../../helpers';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import Can from '../../../components/Can';
import { deletePatientFileAction } from '../../../reducers/patients';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import { create3DViewerURI, createDesktopURI } from '../../../helpers/urls';
import { FILE_TYPE_IDS } from '../../../constants';
import IOS3DViewerWarning from '../../../components/IOD3DViewerWarning';
import { openIOS3DViewerWarning } from '../../../reducers/modals';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.light
  },
  noFoundChip: {
    marginLeft: theme.spacing(2)
  },
  addButton: {
    marginLeft: theme.spacing(2)
  },
  AccordionSummary: {
    '& > .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between'
    }
  }
}));

const PatientFilesTable = ({
  patientFiles,
  patient,
  patientModificationWarningId,
  actions,
  title,
  openUploadDialog,
  fileTypeId,
  isPatientDeleted,
  preventDuplicate,
  maxFiles,
  organizationId,
  disableUpload
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(state => state.auth.user);
  const [isDeletingFile, setIsDeletingFile] = useState(false);

  const handleOpenFile = file => {
    if (fileTypeId === FILE_TYPE_IDS.ios) {
      if (file.viewer_id) {
        window.open(create3DViewerURI(file, patient), '_blank');
      } else {
        dispatch(openIOS3DViewerWarning());
      }
    } else {
      window.location.href = createDesktopURI(`open_file/${file.id}`);
    }
  };

  const renderAddButton = () => (
    <Can
      permissions={[
        'patient-files.manage',
        'patient-files.create',
        'organization.patient-files.create'
      ]}
      organizationId={organizationId}
      yes={() => (
        <Button
          className={classes.addButton}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          color="primary"
          onClick={event => {
            event.stopPropagation();
            openUploadDialog(title, fileTypeId, false, maxFiles, preventDuplicate, patientFiles);
          }}
        >
          Add
        </Button>
      )}
    />
  );

  const handleDownloadFile = file => {
    if (file.patient_file_type_id === FILE_TYPE_IDS.ios) {
      if (file.viewer_id) {
        const url = `${process.env.REACT_APP_DSM_API_URL}?viewerId=${file.viewer_id}&method=downloadIOS`;
        window.open(url);
      } else {
        dispatch(openIOS3DViewerWarning());
      }
    } else {
      dispatch(fetchDownloadUrlPrintingFileAction(file.id));
    }
  };

  const handleDeleteFile = (patientId, patientFileTypeId, id) => {
    setIsDeletingFile(true);
    dispatch(deletePatientFileAction(patientId, patientFileTypeId, id)).finally(() =>
      setIsDeletingFile(false)
    );
  };

  const renderActions = patientFile => {
    return actions
      .filter(action => {
        if (action.roles) {
          return findRolesInUser(action.roles, currentUser);
        }
        return true;
      })
      .map(action => {
        switch (action.name) {
          case 'open':
            return (
              <IconButton color="inherit" key="open" onClick={() => handleOpenFile(patientFile)}>
                <LaunchIcon />
              </IconButton>
            );
          case 'download':
            return (
              <IconButton
                color="inherit"
                title="Download File"
                key="download"
                onClick={() => handleDownloadFile(patientFile)}
              >
                <CloudDownloadIcon />
              </IconButton>
            );
          case 'delete':
            return (
              <Fragment key="delete">
                {!isPatientDeleted && (
                  <ConfirmationDialogOnClickWrapper
                    confirmationBody={`Are you sure you want to delete file "${patientFile.original_name ||
                      patientFile.name}"?`}
                    confirmationTitle="Delete Files"
                  >
                    <IconButton
                      color="inherit"
                      title="Delete File"
                      key="delete"
                      onClick={() =>
                        handleDeleteFile(patientFile.patient_id, fileTypeId, patientFile.id)
                      }
                    >
                      <DeleteIcon color="secondary" />
                      <LoadingSpinner show={isDeletingFile} />
                    </IconButton>
                  </ConfirmationDialogOnClickWrapper>
                )}
              </Fragment>
            );
          default:
            return null;
        }
      });
  };

  if (!patientFiles.length) {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="subtitle1">{title}</Typography>
          <Chip size="small" className={classes.noFoundChip} label={`No ${title} found`} />
          {!isPatientDeleted && !disableUpload && renderAddButton()}
        </Toolbar>
      </Paper>
    );
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{title}</Typography>
        {!isPatientDeleted && !disableUpload && renderAddButton()}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Uploaded Date</TableCell>
                <TableCell align="right">Modification Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientFiles.map(patientFile => (
                <TableRow key={patientFile.id}>
                  <TableCell component="th" scope="row">
                    {patientFile.original_name || patientFile.name}
                  </TableCell>
                  <TableCell align="right">
                    {UTCDateTimeToLocalTime(patientFile.created_at)}
                  </TableCell>
                  <TableCell align="right">
                    {patientFile.file_modification_date
                      ? UTCDateTimeToLocalTime(patientFile.file_modification_date)
                      : UTCDateTimeToLocalTime(patientFile.updated_at)}
                  </TableCell>
                  <TableCell align="right">{renderActions(patientFile)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>

      {fileTypeId === FILE_TYPE_IDS.ios && <IOS3DViewerWarning />}
    </Accordion>
  );
};

PatientFilesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patientFiles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  patientModificationWarningId: PropTypes.string.isRequired,
  title: PropTypes.string,
  openUploadDialog: PropTypes.func.isRequired,
  fileTypeId: PropTypes.number.isRequired,
  isPatientDeleted: PropTypes.bool,
  preventDuplicate: PropTypes.bool,
  maxFiles: PropTypes.number,
  organizationId: PropTypes.number,
  disableUpload: PropTypes.bool
};

PatientFilesTable.defaultProps = {
  patientFiles: [],
  actions: [],
  title: '',
  isPatientDeleted: false,
  preventDuplicate: false,
  maxFiles: 1,
  organizationId: null,
  disableUpload: false
};

export default PatientFilesTable;
