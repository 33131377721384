import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import {
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormHelperText
} from '@material-ui/core';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LoadingButton from '../../../components/LoadingButton';
import announcementVisibilities from '../../../constants/announcementVisibilities';

import { deleteAnnouncementAction, updateAnnouncementAction } from '../../../reducers/annoucement';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  cardActions: {
    justifyContent: 'flex-end'
  },
  submit: {
    marginLeft: 'auto'
  }
}));

const UpdateAnnouncement = ({ announcement }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const initialValues = useMemo(() => {
    return {
      body: announcement && announcement.body ? announcement.body : ''
    };
  }, [announcement]);

  const handleOnSubmit = (values, { resetForm }) => {
    setIsSaving(true);
    return dispatch(updateAnnouncementAction(announcement.id, values.body))
      .then(result => {
        if (result) {
          resetForm({ values });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleOnDelete = e => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);

    e.preventDefault();
    dispatch(deleteAnnouncementAction(announcement.id)).finally(() => {
      setIsDeleting(false);
    });
  };

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .nullable()
      .max(16777000, 'Maximum character limit exceeded.')
  });

  const lastUpdatedText = useMemo(() => {
    if (!announcement.updated_at) {
      return '';
    }

    const date = moment.utc(announcement.updated_at).local();

    let text = 'Last updated';

    if (announcement.last_updated_by) {
      text += ` by ${announcement.last_updated_by}`;
    }

    return `${text} on ${date.format('YYYY-MM-DD HH:mm:ss')}`;
  }, [announcement]);

  return (
    <Box my={1} width={1}>
      <Container maxWidth="md">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isValid, dirty }) => (
            <Card>
              <CardHeader
                title={`Announcement${
                  announcement.visibility === announcementVisibilities.INTERNAL ? ' (Internal)' : ''
                }`}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <Form className={classes.form} noValidate>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        variant="outlined"
                        id="body"
                        fullWidth
                        label="Message"
                        name="body"
                        multiline
                        minRows={8}
                        maxRows={8}
                        component={TextField}
                        disabled={isSaving || isDeleting}
                      />
                      {!!lastUpdatedText && <FormHelperText>{lastUpdatedText}</FormHelperText>}
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions className={classes.cardActions}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSaving}
                    disabled={!dirty || !isValid || isSaving || isDeleting}
                    className={classes.submit}
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    type="reset"
                    variant="contained"
                    color="secondary"
                    loading={isDeleting}
                    disabled={isSaving || isDeleting || !(announcement.body || false)}
                    className={classes.submit}
                    onClick={handleOnDelete}
                  >
                    Delete
                  </LoadingButton>
                </CardActions>
              </Form>
            </Card>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

UpdateAnnouncement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  announcement: PropTypes.object.isRequired
};

export default UpdateAnnouncement;
