import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { produce } from 'immer';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import {
  FETCH_PATIENTS_PREFIX,
  fetchPatientsAction,
  fetchPatientCountsPerStatusAction,
  setPatientsGlobalParams
} from '../../reducers/patients';
import { NEW_PATIENT_STATUSES } from '../../constants';
import Content from '../../components/Layout/Content';
import Can from '../../components/Can';
import LocationSelectorCurrentOrganizationHandler from '../../components/LocationSelector/LocationSelectorCurrentOrganizationHandler';
import { ASSIGNMENTS } from '../../components/RxForm/constants';
import { create3DViewerURI, createDesktopURI } from '../../helpers/urls';
import IOS3DViewerWarning from '../../components/IOD3DViewerWarning';
import { openIOS3DViewerWarning } from '../../reducers/modals';

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginBottom: theme.spacing(0)
  },
  totalCount: {
    border: '1px solid',
    marginLeft: '5px',
    padding: '0px 4px',
    borderRadius: '3px'
  }
}));

const styledBy = (property, mapping, append) => props => ({
  ...mapping[props[property]],
  ...append
});

const StyledToggleButton = withStyles({
  root: styledBy(
    'color',
    {
      blue: {
        background: '#008CD2',
        color: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRightWidth: '2px',
        '&:hover': {
          background: 'rgb(0, 98, 147)'
        }
      },
      default: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)'
        }
      }
    },
    {
      '&.Mui-selected': {
        background: '#019934',
        color: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeftWidth: '0px'
      }
    }
  )
})(({ classes, color, ...other }) => <ToggleButton classes={classes} {...other} />);

const Patients = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasMount = useRef(false);
  const history = useHistory();

  const defaultSelectedPatientStatus = 'new';

  const globalParams = useSelector(state => state.patients.globalParams);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_PATIENTS_PREFIX])(state));

  const [additionalParams, setAdditionalParams] = useState({
    params: {
      ...globalParams,
      onlyOnhold: 'with',
      newPatientStatus: globalParams.newPatientStatus || defaultSelectedPatientStatus,
      orderBy: 'created_at',
      order: ['approved', 'completed'].includes(
        globalParams.newPatientStatus || defaultSelectedPatientStatus
      )
        ? 'desc'
        : 'asc'
    },
    resetPage: false
  });

  const rows = useSelector(state => state.patients.items);
  const total = useSelector(state => state.patients.total);

  const [selectedCaseStatus, setSelectedCaseStatus] = React.useState(
    additionalParams.params.newPatientStatus
  );

  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const [onlyOnhold, setOnlyOnhold] = React.useState(globalParams.onlyOnhold);
  useEffect(() => {
    if (hasMount.current) {
      setAdditionalParams(currentParameters => {
        return produce(currentParameters, draft => {
          draft.params.userId = params.userId || '';
          draft.resetPage = true;
        });
      });
    } else {
      hasMount.current = true;
    }

    dispatch(
      setPatientsGlobalParams({
        newPatientStatus: selectedCaseStatus || ''
      })
    );
  }, [params, selectedCaseStatus, dispatch]);

  const handleChangeCaseStatus = (event, caseStatus) => {
    setSelectedCaseStatus(caseStatus);
    let patientStatusId = caseStatus === 'onhold' ? null : caseStatus;

    if (isNull(caseStatus)) {
      patientStatusId = 'none';
    }

    dispatch(
      setPatientsGlobalParams({
        newPatientStatus: caseStatus && onlyOnhold !== 'only' ? caseStatus : ''
      })
    );

    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.orderBy = 'created_at';
        draft.params.order = ['approved', 'completed'].includes(caseStatus) ? 'desc' : 'asc';
        draft.params.newPatientStatus = patientStatusId;
        draft.params.onlyOnhold = caseStatus === 'onhold' ? 'only' : 'with';
        draft.resetPage = true;
      })
    );
  };

  const handleUpdateData = useCallback(
    parameters => {
      const includes = ['patient_status', 'patient_files', 'user', 'rx_form'];
      const getParams = { ...parameters, organizationId: currentOrganization.id };
      if (getParams.onlyOnhold === 'only') {
        getParams.newPatientStatus = '';
      }
      dispatch(
        fetchPatientsAction({
          ...getParams,
          includes
        })
      );
      if (getParams.organizationId) {
        dispatch(fetchPatientCountsPerStatusAction(getParams));
      }
    },
    [dispatch, currentOrganization]
  );

  const patientCountsPerStatus = useSelector(state => state.patients.patientCountsPerStatus);

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[additionalParameterKey] = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleShowPatient = id => {
    history.push(`/patient-details/${id}`);
  };

  const handleOpenDesktop = (id, patient) => {
    const iosFile = patient.ios_files && patient.ios_files.length ? patient.ios_files[0] : null;
    if (iosFile) {
      if (iosFile.viewer_id) {
        window.open(create3DViewerURI(iosFile, patient), '_blank');
      } else {
        dispatch(openIOS3DViewerWarning());
      }
    } else {
      window.location.href = createDesktopURI(`open_patient/${patient.id}`);
    }
  };

  const handleChangeOnlyOnhold = event => {
    setSelectedCaseStatus(event.target.checked ? '' : defaultSelectedPatientStatus);
    setOnlyOnhold(event.target.checked ? 'only' : 'with');
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.onlyOnhold = event.target.checked ? 'only' : 'with';
        draft.params.newPatientStatus = event.target.checked ? null : defaultSelectedPatientStatus;
        draft.resetPage = true;
      })
    );
  };

  const handleChangeLocations = debounce(locations => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.locations = locations;
        draft.resetPage = true;
      })
    );
  }, 1000);

  const handleSortChange = sortChange => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.orderBy = sortChange.property;
        draft.params.order = sortChange.direction;
      })
    );
  };

  const isDisabled = patient => {
    const isInHouse =
      !patient.rx_form || patient.rx_form.submission_completed_by === ASSIGNMENTS.IN_OFFICE;

    return (
      !isInHouse &&
      (globalParams.newPatientStatus === 'new' ||
        globalParams.newPatientStatus === 'in_progress' ||
        !patient.ios_file_id)
    );
  };

  const renderLabel = (value, row) => {
    return row.is_on_hold ? <span style={{ color: 'rgba(0,0,0,0.34)' }}>{value}</span> : value;
  };

  const columns = [
    {
      id: 'formatted_name',
      customSortId: 'last_name',
      numeric: false,
      disablePadding: false,
      label: 'Patient Details',
      formatMethod: renderLabel
    },
    {
      id: 'user.full_name',
      numeric: false,
      disablePadding: false,
      label: 'Doctor',
      customSortId: 'doctor_full_name_sort',
      formatMethod: renderLabel
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Start Date',
      formatMethod: renderLabel
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Modified Date',
      formatMethod: renderLabel
    }
  ];

  const renderFilters = () => {
    return (
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            placeholder="Search by Patient"
            className={classes.searchInput}
            defaultValue={globalParams.searchFullNameQuery}
            onChange={e => handleOnChangeSearchInput(e.target.value, 'searchFullNameQuery')}
          />
        </Grid>
        <Can
          organizationId={currentOrganization.id}
          permissions={['organization.patients.view-any']}
          yes={() => (
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Doctor"
                className={classes.searchInput}
                defaultValue={globalParams.searchDoctorFullNameOrOrganizationQuery}
                onChange={e =>
                  handleOnChangeSearchInput(
                    e.target.value,
                    'searchDoctorFullNameOrOrganizationQuery'
                  )
                }
              />
            </Grid>
          )}
        />
        <Grid item xs={12} sm={4}>
          <LocationSelectorCurrentOrganizationHandler
            onChange={handleChangeLocations}
            defaultValue={globalParams.locations}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item />
            <Grid item>
              <ToggleButtonGroup
                value={onlyOnhold !== 'only' ? selectedCaseStatus : null}
                exclusive
                onChange={handleChangeCaseStatus}
              >
                {Object.keys(NEW_PATIENT_STATUSES).map(key => (
                  <StyledToggleButton
                    color={
                      onlyOnhold !== 'only' &&
                      selectedCaseStatus &&
                      NEW_PATIENT_STATUSES[key].order <
                        NEW_PATIENT_STATUSES[selectedCaseStatus].order
                        ? 'blue'
                        : 'default'
                    }
                    value={`${key}`}
                    key={key}
                    disabled={+key === +selectedCaseStatus || onlyOnhold === 'only'}
                  >
                    {NEW_PATIENT_STATUSES[key].name}
                    <span className={classes.totalCount}>{patientCountsPerStatus[key]}</span>
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={onlyOnhold === 'only'}
                    onChange={handleChangeOnlyOnhold}
                    name="onlyOnhold"
                  />
                }
                label="Only On-hold"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Content filters={renderFilters()}>
      <DataTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        total={total}
        updateData={handleUpdateData}
        defaultOrderBy={globalParams.orderBy}
        customActions={[
          {
            name: 'showPatient',
            handleOnAction: handleShowPatient,
            text: 'Details',
            title: 'Show patient details'
          },
          {
            name: 'openDesktop',
            handleOnAction: handleOpenDesktop,
            color: 'primary',
            text: 'View',
            disabled: isDisabled
          }
        ]}
        additionalParams={additionalParams}
        globalParams={globalParams}
        onSortChange={handleSortChange}
      />
      <IOS3DViewerWarning />
    </Content>
  );
};

Patients.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

Patients.defaultProps = {
  match: { params: {} }
};

export default Patients;
