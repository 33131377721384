import React, { useEffect, useState } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';

const SortBy = ({ disabled, defaultRefinement, currentRefinement, refine, items }) => {
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [value, setValue] = useState(currentRefinement || defaultRefinement);

  useEffect(() => {
    if (disabled !== isDisabled) {
      setIsDisabled(disabled);
      if (disabled) {
        refine('main_index');
      } else {
        refine(value);
      }
    }
  }, [disabled, isDisabled, setIsDisabled, refine, value]);

  const handleChange = e => {
    setValue(e.target.value);
    refine(e.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <Select value={value || defaultRefinement} onChange={handleChange} disabled={isDisabled}>
        {items.map(({ value: itemValue, label }) => (
          <MenuItem value={itemValue} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SortBy.propTypes = {
  disabled: PropTypes.bool.isRequired,
  defaultRefinement: PropTypes.string.isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default connectSortBy(SortBy, {
  $$widgetType: 'custom.sortBy'
});
