/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import countries from './countries.json';

function countryToFlag(isoCode, image = null) {
  return (
    image ||
    `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/${isoCode.toLowerCase()}.svg`
  );
}

const defaultCountry = countries.find(country => country.iso_3 === 'USA');

const useStyles = makeStyles({
  flag: {
    width: '25px',
    height: '25px',
    marginRight: 10
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

export default function CountryList({ field, form, ...props }) {
  const classes = useStyles();

  const [value, setValue] = useState(defaultCountry);

  const handleChange = (e, newValue) => {
    const value = newValue && newValue.iso_3 ? newValue.iso_3 : defaultCountry.iso_3;
    form.setFieldValue(field.name, value);
  };

  const getInitialValue = useCallback(() => {
    return countries.find(country => country.iso_3 === field.value) || defaultCountry;
  }, [field.value]);

  useEffect(() => {
    const initialValue = getInitialValue();
    setValue(initialValue);
    if (initialValue.iso_3 !== field.value) {
      form.setFieldValue(field.name, initialValue.iso_3);
    }
  }, [getInitialValue, field, form]);

  return (
    <Autocomplete
      id="country-select-demo"
      style={{ width: '100%' }}
      options={countries}
      classes={{
        option: classes.option
      }}
      disabled={props.disabled}
      autoHighlight
      getOptionLabel={option => option.label}
      value={value}
      onChange={handleChange}
      renderOption={option => (
        <>
          <img
            className={classes.flag}
            src={countryToFlag(option.iso_2, option.image)}
            alt={`${option.label} flag`}
          />
          {option.label}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Country *"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            ...props,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
