import { ASSIGNMENTS, ASSIGNMENT_LABELS, ATTRIBUTES } from './constants';

const { ORTHOSELECT, THIRD_PARTY } = ASSIGNMENTS;

export function assignmentOptions({
  assignments = Object.values(ASSIGNMENTS),
  thirdPartyName = 'Organization'
}) {
  return assignments.map(value => {
    const label = ASSIGNMENT_LABELS[value];
    return { value, label: value === THIRD_PARTY ? `${thirdPartyName} (3PP)` : label };
  });
}

export function getDefaultAssignment(options) {
  return options.indexOf(ORTHOSELECT) !== -1 ? ORTHOSELECT : THIRD_PARTY;
}

const attributeTypes = {
  [ATTRIBUTES.TREATMENT_LOCATION]: Number,
  [ATTRIBUTES.SHIPPING_LOCATION]: Number,
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: Number,
  [ATTRIBUTES.RUSH_CASE]: Boolean,
  [ATTRIBUTES.IMPRESSION_TYPE_METHOD]: Number,
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: Number,
  [ATTRIBUTES.PRODUCTS_LIST_METHOD]: Number,
  [ATTRIBUTES.ADDON_OPTIONS_METHOD]: Number,
  [ATTRIBUTES.SCANNER]: Number,
  [ATTRIBUTES.ARCH_TYPE]: Number,
  [ATTRIBUTES.ARCH_MODE]: Number,
  [ATTRIBUTES.EXPORT_TYPE]: Number,
  [ATTRIBUTES.PRINT_ASSIGNMENT]: Number,
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: Number,
  [ATTRIBUTES.ROOT_INTEGRATION]: Boolean
};

export function encodeFormikValues(values) {
  return Object.fromEntries(
    Object.entries(values).map(([attr, value]) => {
      let encodeValue = value;
      if (attributeTypes[attr]) {
        if (attributeTypes[attr] === Boolean) {
          encodeValue = Number(value);
        }
        encodeValue = value === null ? null : String(encodeValue);
      }
      return [attr, encodeValue];
    })
  );
}

export function decodeFormikValues(encodedValues) {
  return Object.fromEntries(
    Object.entries(encodedValues).map(([attr, value]) => {
      let decodedValue = value;
      if (decodedValue !== null && attributeTypes[attr]) {
        if (decodedValue === '' && attributeTypes[attr] === Number) {
          decodedValue = null;
        } else {
          decodedValue = Number(decodedValue);
        }

        if (attributeTypes[attr] === Boolean) {
          decodedValue = Boolean(decodedValue);
        }
      }
      return [attr, decodedValue];
    })
  );
}

export const overridePreferenceWithModificationRules = (preference, modificationRules) => {
  const override = {};
  if (modificationRules.submission_completed_by) {
    override.initial_case_setup_assignment_options =
      modificationRules.submission_completed_by.allowed_values;
  }

  if (modificationRules.print_assignment) {
    override.print_assignment_options = modificationRules.print_assignment.allowed_values;
  }
  return { ...preference, ...override };
};
