import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';

const ConfirmationDialogWrapper = ({ children, ...props }) => {
  const initialState = {
    title: '',
    body: '',
    callback: null
  };
  const [state, setState] = useState(initialState);

  const confirm = (title, body, callback) => {
    setState({ title, body, callback });
  };

  const handleClose = confirmed => {
    if (confirmed) {
      state.callback();
    }
    setState(initialState);
  };

  return (
    <>
      <ConfirmationDialog
        handleClose={handleClose}
        open={state.title !== ''}
        title={state.title}
        bodyText={state.body}
        {...props}
      />
      {children({ confirm })}
    </>
  );
};

ConfirmationDialogWrapper.propTypes = {
  children: PropTypes.func.isRequired
};

export default ConfirmationDialogWrapper;
