import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Img from 'react-image';
import clsx from 'clsx';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import CustomFullScreenImagesView from '../CustomFullScreenImagesView';

const useStyles = makeStyles(() => ({
  image: {
    cursor: 'zoom-in'
  },
  fullWidth: {
    width: '100%'
  }
}));

const modalCustomStyles = {
  blanket: base => ({
    ...base,
    zIndex: 1200
  }),
  positioner: base => ({
    ...base,
    zIndex: 1201
  })
};

const BracketImage = ({ bracket, title }) => {
  const classes = useStyles();
  const [lightBoxModalIsOpen, setLightBoxModalIsOpen] = useState(false);
  const images = [{ src: bracket.urls.url }];

  const imageClassName = clsx(classes.image, classes.fullWidth);
  const toggleLightBoxModal = () => {
    setLightBoxModalIsOpen(false);
  };

  const openLightBoxModel = () => {
    setLightBoxModalIsOpen(true);
  };

  return (
    <>
      <Typography component="h2" variant="body1">
        {title}
      </Typography>
      <Box py={1}>
        <Img
          src={[bracket.urls.thumbnail_url, bracket.urls.url]}
          alt={bracket.patient_file.original_file_name}
          className={imageClassName}
          onClick={() => openLightBoxModel()}
          loader={<LinearProgress />}
        />
      </Box>
      <ModalGateway>
        {lightBoxModalIsOpen ? (
          <Modal onClose={toggleLightBoxModal} styles={modalCustomStyles}>
            <Carousel views={images} components={{ View: CustomFullScreenImagesView }} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

BracketImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bracket: PropTypes.object.isRequired,
  title: PropTypes.string
};

BracketImage.defaultProps = {
  title: ''
};

export default BracketImage;
