import React from 'react';
import PropTypes from 'prop-types';
import V2 from './V2';
import V1 from './V1';

const Template = ({ order, ...props }) => {
  switch (order.template_version) {
    case 2:
      return <V2 order={order} {...props} />;
    default:
      return <V1 order={order} {...props} />;
  }
};

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired
};

export default Template;
