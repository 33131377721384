import { useSelector } from 'react-redux';
import hasPermission from '../../selectors/hasPermission';

const Can = ({ yes, no, permissions = [], organizationId }) => {
  const isAllowed = useSelector(state => hasPermission(state, { permissions, organizationId }));

  if (!permissions.length) {
    return yes();
  }

  if (isAllowed) {
    return yes();
  }

  return no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;
