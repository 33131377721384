import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Select } from 'formik-material-ui';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { debounce, map } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import {
  ADD_ORGANIZATION_MEMBER_PREFIX,
  addOrganizationMemberAction
} from '../../reducers/organizations';
import LoadingButton from '../LoadingButton';
import createLoadingSelector from '../../selectors/loading';
import { ORGANIZATION_ROLE_OPTIONS } from '../../constants';
import FormikAutocomplete from '../FormikAutocomplete';
import UsersApiService from '../../services/api/users';
import SubSectionWrapper, {
  SubSectionActionWrapper
} from '../../pages/Account/components/SubSectionWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const AddOrganizationMember = ({ organization }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector(state =>
    createLoadingSelector([ADD_ORGANIZATION_MEMBER_PREFIX])(state)
  );

  const [users, setUsers] = useState([]);

  const handleOnChangeSearchQuery = debounce(async value => {
    const usersApiService = new UsersApiService();
    const {
      users: { data }
    } = await usersApiService.get({
      searchFullNameEmailQuery: value,
      orderBy: 'first_name'
    });
    setUsers(data);
  }, 500);

  const filterOptions = options => {
    const membersIds = map(organization.users, 'id');
    return options.filter(user => membersIds.indexOf(user.id) === -1);
  };

  const handleOnSubmit = (values, { resetForm }) => {
    return dispatch(
      addOrganizationMemberAction(organization.id, { ...values, user_id: values.user.id })
    ).then(result => {
      if (result) {
        resetForm();
      }
    });
  };

  return (
    <SubSectionWrapper
      title="Add Member"
      subTitle="The users will be added directly  to the organization without an invitation."
    >
      <Formik
        initialValues={{
          user: null,
          role: 'organization_member'
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          user: Yup.string()
            .nullable()
            .required('Required')
        })}
        onSubmit={handleOnSubmit}
      >
        {({ isValid, dirty }) => (
          <Form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  id="user"
                  options={users}
                  label="User"
                  getOptionLabel={option => option && `${option.full_name} | ${option.email}`}
                  autoComplete
                  getOptionSelected={(option, value) => {
                    if (!value) {
                      return null;
                    }
                    return option.id === value.id;
                  }}
                  textFieldProps={{
                    label: 'User',
                    variant: 'outlined',
                    fullWidth: true,
                    required: true
                  }}
                  name="user"
                  filterOptions={filterOptions}
                  onInputChange={(event, newInputValue) => handleOnChangeSearchQuery(newInputValue)}
                  component={FormikAutocomplete}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Field required name="role" component={Select} labelWidth={35}>
                    {Object.keys(ORGANIZATION_ROLE_OPTIONS).map(key => {
                      return (
                        <MenuItem key={key} value={key}>
                          {ORGANIZATION_ROLE_OPTIONS[key].label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
            <SubSectionActionWrapper>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving}
                className={classes.actionButton}
              >
                Add Member
              </LoadingButton>
            </SubSectionActionWrapper>
          </Form>
        )}
      </Formik>
    </SubSectionWrapper>
  );
};

AddOrganizationMember.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

AddOrganizationMember.defaultProps = {};

export default AddOrganizationMember;
