import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import LicensesApiService from '../services/api/licenses';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_LICENSES_PREFIX = 'FETCH_LICENSES';
export const FETCH_LICENSES_REQUEST_ACTION = `${FETCH_LICENSES_PREFIX}_REQUEST_ACTION`;
export const FETCH_LICENSES_SUCCESS_ACTION = `${FETCH_LICENSES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LICENSES_FAILURE_ACTION = `${FETCH_LICENSES_PREFIX}_FAILURE_ACTION`;

export const FETCH_LICENSE_PREFIX = 'FETCH_LICENSE';
export const FETCH_LICENSE_REQUEST_ACTION = `${FETCH_LICENSE_PREFIX}_REQUEST_ACTION`;
export const FETCH_LICENSE_SUCCESS_ACTION = `${FETCH_LICENSE_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LICENSE_FAILURE_ACTION = `${FETCH_LICENSE_PREFIX}_FAILURE_ACTION`;

export const CREATE_LICENSE_PREFIX = 'CREATE_LICENSE';
export const CREATE_LICENSE_REQUEST_ACTION = `${CREATE_LICENSE_PREFIX}_REQUEST_ACTION`;
export const CREATE_LICENSE_SUCCESS_ACTION = `${CREATE_LICENSE_PREFIX}_SUCCESS_ACTION`;
export const CREATE_LICENSE_FAILURE_ACTION = `${CREATE_LICENSE_PREFIX}_FAILURE_ACTION`;

export const UPDATE_LICENSE_PREFIX = 'UPDATE_LICENSE';
export const UPDATE_LICENSE_REQUEST_ACTION = `${UPDATE_LICENSE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_LICENSE_SUCCESS_ACTION = `${UPDATE_LICENSE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_LICENSE_FAILURE_ACTION = `${UPDATE_LICENSE_PREFIX}_FAILURE_ACTION`;

export const SET_LICENSE_STATUS_PREFIX = 'SET_LICENSE_STATUS';
export const SET_LICENSE_STATUS_REQUEST_ACTION = `${SET_LICENSE_STATUS_PREFIX}_REQUEST_ACTION`;
export const SET_LICENSE_STATUS_SUCCESS_ACTION = `${SET_LICENSE_STATUS_PREFIX}_SUCCESS_ACTION`;
export const SET_LICENSE_STATUS_FAILURE_ACTION = `${SET_LICENSE_STATUS_PREFIX}_FAILURE_ACTION`;

export const DELETE_LICENSE_PREFIX = 'DELETE_LICENSE';
export const DELETE_LICENSE_REQUEST_ACTION = `${DELETE_LICENSE_PREFIX}_REQUEST_ACTION`;
export const DELETE_LICENSE_SUCCESS_ACTION = `${DELETE_LICENSE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_LICENSE_FAILURE_ACTION = `${DELETE_LICENSE_PREFIX}_FAILURE_ACTION`;

export const DELETE_DEVICE_LICENSE_PREFIX = 'DELETE_DEVICE_LICENSE';
export const DELETE_DEVICE_LICENSE_REQUEST_ACTION = `${DELETE_DEVICE_LICENSE_PREFIX}_REQUEST_ACTION`;
export const DELETE_DEVICE_LICENSE_SUCCESS_ACTION = `${DELETE_DEVICE_LICENSE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_DEVICE_LICENSE_FAILURE_ACTION = `${DELETE_DEVICE_LICENSE_PREFIX}_FAILURE_ACTION`;

export const FETCH_LICENSE_TYPES_PREFIX = 'FETCH_LICENSE_TYPES';
export const FETCH_LICENSE_TYPES_REQUEST_ACTION = `${FETCH_LICENSE_TYPES_PREFIX}_REQUEST_ACTION`;
export const FETCH_LICENSE_TYPES_SUCCESS_ACTION = `${FETCH_LICENSE_TYPES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LICENSE_TYPES_FAILURE_ACTION = `${FETCH_LICENSE_TYPES_PREFIX}_FAILURE_ACTION`;
// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  licenseTypes: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LICENSES_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.total
      };
    case FETCH_LICENSE_SUCCESS_ACTION:
      return produce(state, draft => {
        const licenseIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (licenseIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_LICENSE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case SET_LICENSE_STATUS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case UPDATE_LICENSE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case DELETE_LICENSE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case DELETE_DEVICE_LICENSE_SUCCESS_ACTION:
      return produce(state, draft => {
        const licenseIndex = state.items.findIndex(item => item.id === action.payload.licenseId);
        draft.items[licenseIndex].devices.splice(
          state.items[licenseIndex].devices.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case FETCH_LICENSE_TYPES_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.licenseTypes = action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchLicensesAction(params) {
  return dispatch => {
    dispatch({ type: FETCH_LICENSES_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .get(params)
      .then(response => {
        dispatch({ type: FETCH_LICENSES_SUCCESS_ACTION, payload: response.licenses });
      })
      .catch(error => {
        dispatch({ type: FETCH_LICENSES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchLicenseAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_LICENSE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .show(id)
      .then(response => {
        dispatch({ type: FETCH_LICENSE_SUCCESS_ACTION, payload: response.license });
      })
      .catch(error => {
        dispatch({ type: FETCH_LICENSE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createLicenseAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_LICENSE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .create(params)
      .then(response => {
        dispatch({ type: CREATE_LICENSE_SUCCESS_ACTION, payload: response.license });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: CREATE_LICENSE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateLicenseAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_LICENSE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .update(id, params)
      .then(response => {
        dispatch({ type: UPDATE_LICENSE_SUCCESS_ACTION, payload: response.license });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_LICENSE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteLicenseAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_LICENSE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .delete(id)
      .then(response => {
        dispatch({ type: DELETE_LICENSE_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: DELETE_LICENSE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteDeviceLicenseAction(id, licenseId) {
  return dispatch => {
    dispatch({ type: DELETE_DEVICE_LICENSE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .deleteDevice(id)
      .then(response => {
        dispatch({ type: DELETE_DEVICE_LICENSE_SUCCESS_ACTION, payload: { id, licenseId } });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: DELETE_DEVICE_LICENSE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchLicenseTypesAction() {
  return dispatch => {
    dispatch({ type: FETCH_LICENSE_TYPES_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .getLicenseTypes()
      .then(response => {
        dispatch({ type: FETCH_LICENSE_TYPES_SUCCESS_ACTION, payload: response.license_types });
      })
      .catch(error => {
        dispatch({ type: FETCH_LICENSE_TYPES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setLicenseStatusAction(id, active) {
  return dispatch => {
    dispatch({ type: SET_LICENSE_STATUS_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .setStatus(id, active)
      .then(response => {
        dispatch({ type: SET_LICENSE_STATUS_SUCCESS_ACTION, payload: response.license });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: SET_LICENSE_STATUS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
