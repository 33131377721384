import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CardActions from '@material-ui/core/CardActions';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  CREATE_LICENSE_PREFIX,
  FETCH_LICENSE_PREFIX,
  fetchLicenseAction,
  fetchLicenseTypesAction,
  UPDATE_LICENSE_PREFIX,
  updateLicenseAction
} from '../../reducers/licenses';
import LoadingButton from '../../components/LoadingButton';
import { fetchUsersAction } from '../../reducers/users';
import FormikAutocomplete from '../../components/FormikAutocomplete';
import createLoadingSelector from '../../selectors/loading';
import createErrorMessageSelector from '../../selectors/error';
import LoadingIndicator from '../../components/LoadingIndicator';
import LicenseDevices from './components/LicenseDevices';
import { UTCDateTimeToLocalTime } from '../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  submit: {
    marginLeft: 'auto'
  }
}));

const AddLicense = props => {
  const {
    match: { params }
  } = props;

  const licenseId = parseInt(params.id, 10);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const editMode = !!licenseId;
  const licenseTypes = useSelector(state => state.licenses.licenseTypes);
  const license = useSelector(state => {
    return state.licenses.items[state.licenses.items.findIndex(item => item.id === licenseId)];
  });

  const [initialValues, setInitialValues] = useState({
    user: { full_name: '' },
    license_type: { name: '' },
    seats: ''
  });
  const hasMount = useRef(false);
  const isLoadingLicense = useSelector(state =>
    createLoadingSelector([FETCH_LICENSE_PREFIX])(state)
  );
  const isCreating = useSelector(state =>
    createLoadingSelector([CREATE_LICENSE_PREFIX, UPDATE_LICENSE_PREFIX])(state)
  );
  const errorOnCreating = useSelector(state =>
    createErrorMessageSelector([CREATE_LICENSE_PREFIX, UPDATE_LICENSE_PREFIX])(state)
  );

  useEffect(() => {
    if (hasMount.current && !isCreating && !errorOnCreating) {
      history.goBack();
    } else {
      hasMount.current = true;
    }
  }, [isCreating, errorOnCreating, history]);

  useEffect(() => {
    if (licenseId) {
      dispatch(fetchLicenseAction(licenseId));
    }
    dispatch(fetchLicenseTypesAction());
    dispatch(fetchUsersAction({ perPage: 500 }));
  }, [params, dispatch, licenseId]);

  useEffect(() => {
    if (license) {
      setInitialValues(license);
    }
  }, [license]);

  if (isLoadingLicense) {
    return <LoadingIndicator />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        license_type: Yup.object().required('Required'),
        seats: Yup.number().required('Required')
      })}
      onSubmit={values => {
        // eslint-disable-next-line camelcase
        const { license_type, seats } = values;
        dispatch(updateLicenseAction(licenseId, { license_type_id: license_type.id, seats }));

        return true;
      }}
    >
      {({ isValid, dirty }) => (
        <Container component="main">
          <Card className={clsx(classes.root)}>
            <Form autoComplete="off" noValidate>
              {!editMode ? (
                <CardHeader subheader="Add a new license to an user" title="New License" />
              ) : (
                <>
                  <CardHeader subheader="Change license parameters" title="Edit License" />
                  <Divider />
                  <CardContent>
                    <Typography variant="body2" gutterBottom>
                      Activation Date: {UTCDateTimeToLocalTime(get(license, 'activated_at', null))}
                    </Typography>
                    <Typography variant="body2">
                      Expiration Date:{' '}
                      {UTCDateTimeToLocalTime(get(license, 'expiration_date', '-'))}
                    </Typography>
                  </CardContent>
                </>
              )}
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Field
                      id="license_type"
                      options={licenseTypes}
                      disabled={editMode}
                      getOptionLabel={option => option.name}
                      textFieldProps={{
                        label: 'License Type',
                        variant: 'outlined',
                        fullWidth: true,
                        required: true
                      }}
                      name="license_type"
                      component={FormikAutocomplete}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      fullWidth
                      label="Seats"
                      id="seats"
                      name="seats"
                      required
                      variant="outlined"
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isCreating}
                  disabled={!dirty || !isValid || isCreating}
                  className={classes.submit}
                >
                  Save
                </LoadingButton>
              </CardActions>
            </Form>
          </Card>
          {editMode && license && license.devices && (
            <LicenseDevices devices={license.devices} licenseId={license.id} />
          )}
        </Container>
      )}
    </Formik>
  );
};

AddLicense.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

AddLicense.defaultProps = {
  match: { params: {} }
};

export default AddLicense;
