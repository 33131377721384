import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function CompletionMessage({ isLastStepCompleted }) {
  return (
    isLastStepCompleted && (
      <Typography variant="h5" gutterBottom>
        Thank you for your order.
      </Typography>
    )
  );
}

CompletionMessage.propTypes = {
  isLastStepCompleted: PropTypes.bool.isRequired
};
export default CompletionMessage;
