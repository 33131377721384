import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PromotionCodesApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    code = '',
    amount = '',
    includes = [],
    promotionCodeTypeId = '',
    selectedStatus = ''
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for('promotion_codes')
        .include(...includes)
        .where('code', code)
        .where('value', amount)
        .where('promotion_code_type_id', promotionCodeTypeId)
        .where('is_active', selectedStatus)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/promotion_codes/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/promotion_codes',
      data
    });
  }

  update(data, id) {
    return this.request({
      method: 'put',
      url: `/promotion_codes/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/promotion_codes/${id}`
    });
  }

  validatePromotionCode(code, promotionCodeType) {
    return this.request({
      method: 'get',
      url: `/promotion_codes/validate_code/${code}?promotion_code_type_id=${promotionCodeType}`
    });
  }
}
