export const ATTRIBUTES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  CASE_NUMBER_TEXT: 'case_number_text',
  DOCTOR: 'user_id',
  TREATMENT_LOCATION: 'location_id',
  IMPRESSION_DATE: 'impression_date',
  INITIAL_CASE_SETUP_ASSIGNMENT: 'submission_completed_by',
  IMPRESSION_TYPE_METHOD: 'impression_type',
  SCAN_SUBMISSION_METHOD: 'scan_submission_type',
  SCANNER: 'scanner_type',
  CUSTOM_SCANNER_NAME: 'custom_scanner_type_name',
  STL_FILES: 'stl_files',
  INTRAORAL_PHOTOS: 'rx_intraoral_photos',
  IOS_FILES: 'ios_files',
  PRODUCTS_LIST_METHOD: 'products_list',
  ADDON_OPTIONS_METHOD: 'addon_options',
  ORDER_MORE_SUPPLIES_METHOD: 'order_more_supplies',
  ARCH_TYPE: 'jaws_id',
  ARCH_MODE: 'arch_mode',
  RUSH_CASE_ENABLED: 'rush_case_enabled',
  RUSH_CASE: 'rush_case',
  RUSH_CASE_AUTHORIZED_BY_NAME: 'rush_case_authorized_by',
  EXPORT_TYPE: 'export_type',
  PRINT_ASSIGNMENT: 'print_assignment',
  INSERT_BRACKETS_ASSIGNMENT: 'insert_brackets_assignment',
  SHIPPING_LOCATION: 'shipping_location_id',
  SHIPPING_METHOD: 'shipping_method',
  ROOT_INTEGRATION: 'root_integration',
  NOTES: 'special_instructions',
  BRACKET_LIBRARIES: 'bracket_libraries',
  BONDING_DATE: 'bonding_date',
  BOXES: 'boxes',
  IMPRESSION_BAGGIES: 'impression_baggies'
};

export const STATEMENTS = {
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: 'Your Initial case setup will be completed by:',
  [ATTRIBUTES.IMPRESSION_TYPE_METHOD]: 'Select impresion type.',
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: 'Upload Intraoral Scans.',
  [ATTRIBUTES.ADDON_OPTIONS_METHOD]: 'Select Add-on Options:',
  [ATTRIBUTES.ORDER_MORE_SUPPLIES_METHOD]: 'Order More Supplies.',
  [ATTRIBUTES.PRINT_ASSIGNMENT]: 'Print by:',
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: 'Insert brackets by:',
  [ATTRIBUTES.EXPORT_TYPE]: 'Export:'
};

export const QUESTIONS = {
  [ATTRIBUTES.FIRST_NAME]: 'First Name',
  [ATTRIBUTES.LAST_NAME]: 'Last Name',
  [ATTRIBUTES.CASE_NUMBER_TEXT]: 'Case Number',
  [ATTRIBUTES.TREATMENT_LOCATION]: 'Which Treatment Location?',
  [ATTRIBUTES.IMPRESSION_DATE]: 'Impression Date',
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]:
    'Who would you like to complete the initial case setup?',
  [ATTRIBUTES.RUSH_CASE_ENABLED]: 'Is rush case enabled?',
  [ATTRIBUTES.RUSH_CASE]: 'Is this a rush case?',
  [ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME]: 'Name of the person authorizing the rush.',
  [ATTRIBUTES.IMPRESSION_TYPE_METHOD]: 'Impression Type Being Submitted',
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: 'How will you be submitting your scans?',
  [ATTRIBUTES.SCANNER]: 'What type of scanner do you have?',
  [ATTRIBUTES.CUSTOM_SCANNER_NAME]: 'What type of scanner do you have?',
  [ATTRIBUTES.STL_FILES]: 'Upload intraoral STL files.',
  [ATTRIBUTES.INTRAORAL_PHOTOS]: 'Upload intraoral STL files.',
  [ATTRIBUTES.IOS_FILES]: 'Upload IOS files.',
  [ATTRIBUTES.ADDON_OPTIONS_METHOD]: 'Which add-on options do you need?',
  [ATTRIBUTES.ORDER_MORE_SUPPLIES_METHOD]: 'Order More Supplies.',
  [ATTRIBUTES.ARCH_TYPE]: 'Which arches will you be printing?',
  [ATTRIBUTES.ARCH_MODE]: 'Which option are you interested in?',
  [ATTRIBUTES.EXPORT_TYPE]: 'What do you want to export?',
  [ATTRIBUTES.PRINT_ASSIGNMENT]: 'Who is printing?',
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: 'Who is inserting brackets?',
  [ATTRIBUTES.SHIPPING_LOCATION]: 'Which Shipping Location?',
  [ATTRIBUTES.SHIPPING_METHOD]: 'Which shipping option do you prefer?',
  [ATTRIBUTES.ROOT_INTEGRATION]: 'Do you want to add Tooth Root Integration to your case?',
  [ATTRIBUTES.NOTES]: 'Special instructions? Bite description?',
  [ATTRIBUTES.BRACKET_LIBRARIES]:
    'Choose the brackets for this case (these can easily be changed later) ',
  [ATTRIBUTES.DOCTOR]: 'Choose Doctor',
  [ATTRIBUTES.BONDING_DATE]: 'Is bonding date enabled?'
};

export const VIEW_LABEL = {
  [ATTRIBUTES.BRACKET_LIBRARIES]: 'Choose the brackets for this case',
  created_by_user_id: 'Filled By',
  [ATTRIBUTES.BONDING_DATE]: 'When would you like to receive this case?',
  [ATTRIBUTES.PRODUCTS_LIST_METHOD]: 'Selected Product(s)',
  [ATTRIBUTES.ARCH_MODE]: 'Which arch mode option are you interested?',
  [ATTRIBUTES.BOXES]: 'Boxes',
  [ATTRIBUTES.IMPRESSION_BAGGIES]: 'Impression Baggies'
};

export const HELPER_TEXT = {
  [ATTRIBUTES.RUSH_CASE]:
    'This case will be ready for doctor approval within one hour when submitted during regular business hours: Mon. - Fri. 8am - 4pm (MST).'
};

const createOptions = options => {
  return Object.entries(options).map(([value, label]) => ({
    value,
    label
  }));
};

export const ASSIGNMENTS = {
  ORTHOSELECT: 1,
  IN_OFFICE: 2,
  THIRD_PARTY: 3
};

export const IMPRESSION_TYPE_METHODS = {
  PVS_ALIGNATE: 1,
  STONE_MODEL: 2,
  INTRA_ORAL_SCANS: 4
};

export const SCAN_SUBMISSION_METHODS = {
  UPLOAD: 1,
  SCANNER: 2
};

export const ARCHES = {
  MAXILLARY: 1,
  MANDIBULAR: 2,
  BOTH: 3
};

export const ARCH_MODES = {
  HORSESHOE: 1,
  PALATE: 2,
  LOW: 3,
  FULL: 4
};

export const EXPORTS = {
  TRAYS: 1,
  GUIDES: 2,
  BRACKETS: 3
};

export const SCANNERS = {
  ITERO: 1,
  THREE_SHAPE: 2,
  // THREE_M: 3,
  CARESTREAM: 4,
  CEREC: 5,
  MEDIT: 6,
  OTHER: 7
};

export const PRODUCTS_LIST_METHODS = {
  FULL_DIGITAL_MODEL: 1,
  SEMI_FINISH_DIGITAL_MODEL: 2,
  ROUGH_DIGITAL_MODEL: 3,
  DIGITAL_STORAGE_ONLY: 4,
  CLEANUP: 5,
  CLEANUP_BASE: 6,
  PRINTING_OPTIONS: 7
};

export const ADDON_OPTIONS_METHODS = {
  ANALYSIS_REPORT: 1,
  DOUBLE_OCCLUSAL_VIEW: 2,
  RETURN_STONE_MODELS: 3,
  RETURN_IMPRESSION_TRAYS: 4,
  CONVERT_MODEL_FORMAT_TO_STL: 5
};

export const ASSIGNMENT_LABELS = {
  [ASSIGNMENTS.ORTHOSELECT]: 'OrthoSelect',
  [ASSIGNMENTS.IN_OFFICE]: 'In-office',
  [ASSIGNMENTS.THIRD_PARTY]: '3PP'
};

export const ASSIGNMENT_SHORT_LABELS = {
  [ASSIGNMENTS.ORTHOSELECT]: 'O',
  [ASSIGNMENTS.IN_OFFICE]: 'H',
  [ASSIGNMENTS.THIRD_PARTY]: '3'
};

export const IMPRESSION_TYPE_LABELS = {
  [IMPRESSION_TYPE_METHODS.PVS_ALIGNATE]: 'PVS / Alignate Impression',
  [IMPRESSION_TYPE_METHODS.STONE_MODEL]: 'Stone Model',
  [IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]: 'Intra-Oral Scan'
};

export const IMPRESSION_TYPE_VALUES = {
  [IMPRESSION_TYPE_METHODS.PVS_ALIGNATE]: 1,
  [IMPRESSION_TYPE_METHODS.STONE_MODEL]: 2,
  [IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]: 4
};

export const SCAN_SUBMISSION_LABELS = {
  [SCAN_SUBMISSION_METHODS.UPLOAD]: 'I am uploading STL files directly to this form.',
  [SCAN_SUBMISSION_METHODS.SCANNER]:
    'I am sending scans directly to {ASSIGNMENT} from my intraoral scanner.'
};

export const ARCH_LABELS = {
  [ARCHES.MAXILLARY]: 'Maxillary',
  [ARCHES.MANDIBULAR]: 'Mandibular',
  [ARCHES.BOTH]: 'Both'
};

export const ARCH_MODE_LABELS = {
  [ARCH_MODES.HORSESHOE]: 'Horseshoe',
  [ARCH_MODES.PALATE]: 'Palate / Lingual (no base)',
  [ARCH_MODES.LOW]: 'Low Profile Base',
  [ARCH_MODES.FULL]: 'Full Profile Base'
};

export const EXPORT_LABELS = {
  [EXPORTS.TRAYS]: 'Trays',
  [EXPORTS.GUIDES]: 'Guides',
  [EXPORTS.BRACKETS]: 'Brackets'
};

export const SCANNER_LABELS = {
  [SCANNERS.ITERO]: 'iTero',
  [SCANNERS.THREE_SHAPE]: '3Shape',
  // [SCANNERS.THREE_M]: '3M',
  [SCANNERS.CARESTREAM]: 'Carestream',
  [SCANNERS.CEREC]: 'Cerec',
  [SCANNERS.MEDIT]: 'Medit',
  [SCANNERS.OTHER]: 'Other'
};

export const PRODUCTS_LIST_LABELS = {
  [PRODUCTS_LIST_METHODS.FULL_DIGITAL_MODEL]: 'Full Digital Model',
  [PRODUCTS_LIST_METHODS.SEMI_FINISH_DIGITAL_MODEL]: 'Semi-Finish Digital Model ',
  [PRODUCTS_LIST_METHODS.ROUGH_DIGITAL_MODEL]: 'Rough Digital Model',
  [PRODUCTS_LIST_METHODS.DIGITAL_STORAGE_ONLY]: 'Digital Storage Only',
  [PRODUCTS_LIST_METHODS.CLEANUP]: 'Clean-up',
  [PRODUCTS_LIST_METHODS.CLEANUP_BASE]: 'Clean-up and Base',
  [PRODUCTS_LIST_METHODS.PRINTING_OPTIONS]: '3D Printing Options'
};

export const PRODUCTS_LIST_VALUES = {
  [PRODUCTS_LIST_METHODS.FULL_DIGITAL_MODEL]: 1,
  [PRODUCTS_LIST_METHODS.SEMI_FINISH_DIGITAL_MODEL]: 2,
  [PRODUCTS_LIST_METHODS.ROUGH_DIGITAL_MODEL]: 4,
  [PRODUCTS_LIST_METHODS.DIGITAL_STORAGE_ONLY]: 8,
  [PRODUCTS_LIST_METHODS.CLEANUP]: 16,
  [PRODUCTS_LIST_METHODS.CLEANUP_BASE]: 32,
  [PRODUCTS_LIST_METHODS.PRINTING_OPTIONS]: 64
};

export const ADDON_OPTIONS_LABELS = {
  [ADDON_OPTIONS_METHODS.ANALYSIS_REPORT]: 'Analysis Report',
  [ADDON_OPTIONS_METHODS.DOUBLE_OCCLUSAL_VIEW]: 'Double Occlusal View (Progressive Ortho)',
  [ADDON_OPTIONS_METHODS.RETURN_STONE_MODELS]: 'Return Stone Models',
  [ADDON_OPTIONS_METHODS.RETURN_IMPRESSION_TRAYS]: 'Return Impression Trays',
  [ADDON_OPTIONS_METHODS.CONVERT_MODEL_FORMAT_TO_STL]: 'Convert Model Format to STL'
};

export const ADDON_OPTIONS_VALUES = {
  [ADDON_OPTIONS_METHODS.ANALYSIS_REPORT]: 1,
  [ADDON_OPTIONS_METHODS.DOUBLE_OCCLUSAL_VIEW]: 2,
  [ADDON_OPTIONS_METHODS.RETURN_STONE_MODELS]: 4,
  [ADDON_OPTIONS_METHODS.RETURN_IMPRESSION_TRAYS]: 8,
  [ADDON_OPTIONS_METHODS.CONVERT_MODEL_FORMAT_TO_STL]: 16
};

export const ARCH_OPTIONS = createOptions(ARCH_LABELS);

export const ARCH_MODE_OPTIONS = createOptions(ARCH_MODE_LABELS);

export const EXPORT_OPTIONS = createOptions(EXPORT_LABELS);

export const SCANNER_OPTIONS = createOptions(SCANNER_LABELS);

export const BOXES_OPTIONS = [25, 50, 100];

export const IMPRESSION_BAGGIE_OPTIONS = [50, 100, 200];

export const OPTIONS = {
  [ATTRIBUTES.ARCH_TYPE]: ARCH_OPTIONS,
  [ATTRIBUTES.ARCH_MODE]: ARCH_MODE_OPTIONS,
  [ATTRIBUTES.EXPORT_TYPE]: EXPORT_OPTIONS,
  [ATTRIBUTES.SCANNER]: SCANNER_OPTIONS
};

export const ATTRIBUTE_VALUE_LABELS = {
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.PRINT_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.IMPRESSION_TYPE_METHOD]: IMPRESSION_TYPE_LABELS,
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: SCAN_SUBMISSION_LABELS,
  [ATTRIBUTES.ARCH_TYPE]: ARCH_LABELS,
  [ATTRIBUTES.SCANNER]: SCANNER_LABELS,
  [ATTRIBUTES.PRODUCTS_LIST_METHOD]: PRODUCTS_LIST_LABELS,
  [ATTRIBUTES.ADDON_OPTIONS_METHOD]: ADDON_OPTIONS_LABELS,
  [ATTRIBUTES.EXPORT_TYPE]: EXPORT_LABELS
};
