import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dropzone from 'react-dropzone-uploader';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    '& .dzu-previewFileName': { width: '65%', overflowWrap: 'break-word' }
  }
}));

const UploadFilesDialogBase = ({
  open,
  handleClose,
  title,
  showContentText,
  getUploadParams,
  handleChangeStatus,
  handleSubmit,
  maxFiles,
  customActions,
  ...props
}) => {
  const classes = useStyles();
  const actions = customActions || [
    {
      label: 'Close',
      onClick: () => handleClose(true),
      color: 'primary'
    }
  ];

  const onClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {showContentText && (
          <DialogContentText id="alert-dialog-description">Upload Files</DialogContentText>
        )}
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={maxFiles}
          SubmitButtonComponent={null}
          styles={{
            dropzone: { minHeight: 200, maxHeight: 250 },
            inputLabelWithFiles: { display: 'none'}
          }}
          {...props}
        />
      </DialogContent>
      <DialogActions>
        {actions.map(({ label, ...actionProps }) => (
          <Button key={label} {...actionProps}>
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

UploadFilesDialogBase.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  showContentText: PropTypes.bool,
  getUploadParams: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customActions: PropTypes.array
};

UploadFilesDialogBase.defaultProps = {
  maxFiles: 100,
  showContentText: false,
  customActions: null
};

export default UploadFilesDialogBase;
