import React, { useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import LaunchIcon from '@material-ui/icons/Launch';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import StatusBullet from '../../../components/StatusBullet';
import { sortByDate, UTCDateTimeToLocalTime, getShowPatientRedirectURL } from '../../../helpers';
import createLoadingSelector from '../../../selectors/loading';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  FETCH_LATEST_PATIENTS_PREFIX,
  fetchLatestPatientsAction
} from '../../../reducers/latestPatients';
import { PATIENT_FILES_IDS } from '../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'inline-block'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  recentlyAddedTag: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#8BC24A',
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white'
  },
  recentlyUpdatedTag: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#9C26B0',
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white'
  }
}));

const LatestPatients = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const patients = useSelector(state => state.latestPatients.items);
  const globalParams = useSelector(state => state.latestPatients.globalParams);
  const user = useSelector(state => state.auth.user);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_LATEST_PATIENTS_PREFIX])(state)
  );

  const isRecently = date => {
    const duration = moment.duration(moment({}).diff(moment(UTCDateTimeToLocalTime(date))));
    return duration.asMinutes() < 5;
  };

  const showTag = patient => {
    let label = 'Recently Added';
    let date = patient.created_at;
    let cssClass = classes.recentlyAddedTag;

    if (patient.created_at !== patient.updated_at) {
      label = 'Recently Updated';
      date = patient.updated_at;
      cssClass = classes.recentlyUpdatedTag;
    }

    return isRecently(date) && <Chip size="small" className={cssClass} label={label} />;
  };

  const renderStatus = patient => {
    let statusText = 'Started';
    let bulletStatus = 'info';

    if (patient.setup_completed_at) {
      statusText = 'Completed';
      bulletStatus = 'success';
    }

    return (
      <div className={classes.statusContainer}>
        <StatusBullet className={classes.status} color={bulletStatus} size="sm" />
        {statusText}
      </div>
    );
  };

  const handleOpenFile = data => {
    window.open(`/3dviewer/${data.viewerId}/${data.patientId}/${data.patientName}`, '_blank')
  };

  const renderFile = patient => {
    if (!patient.patient_files) {
      return '';
    }

    const lastFile = sortByDate(
      patient.patient_files.filter(file => file.patient_file_type_id === PATIENT_FILES_IDS.ios),
      'updated_at'
    )[0];

    if (lastFile && lastFile.viewer_id) {
      const data = {
        viewerId: lastFile.viewer_id,
        patientId: patient.id,
        patientName: patient.full_name
      }

      return (
        <IconButton
          color="inherit"
          title="Open in 3D Viewer"
          onClick={() => handleOpenFile(data)}
        >
          <LaunchIcon />
        </IconButton>
      );
    }
    return '';
  };

  const handleShowPatientDetails = patientId => {
    history.push(getShowPatientRedirectURL(user, patientId));
  };

  const renderActions = patient => {
    return (
      <>
        <IconButton onClick={() => handleShowPatientDetails(patient.id)}>
          <DescriptionIcon />
        </IconButton>
        {renderFile(patient)}
      </>
    );
  };

  useEffect(() => {
    dispatch(
      fetchLatestPatientsAction({
        ...globalParams,
        includes: ['patient_files', 'patient_status'],
        userId: '',
        organizationId: get(currentOrganization, 'id', '')
      })
    );
  }, [dispatch, user.id, currentOrganization, globalParams]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Latest Patients" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Case #</TableCell>
                  <TableCell>Suffix</TableCell>
                  <TableCell>Modification Date</TableCell>
                  <TableCell>Setup</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortByDate(patients, 'updated_at').map(patient => (
                  <TableRow hover key={patient.id}>
                    <TableCell>{patient.first_name}</TableCell>
                    <TableCell>{patient.last_name}</TableCell>
                    <TableCell>{patient.case_number_text}</TableCell>
                    <TableCell>
                      {patient.numeric_suffix ? `(${patient.numeric_suffix})` : '-'}
                    </TableCell>
                    <TableCell>{UTCDateTimeToLocalTime(patient.updated_at)}</TableCell>
                    <TableCell>
                      {renderStatus(patient)}
                      {showTag(patient)}
                    </TableCell>
                    <TableCell>{patient.patient_status && patient.patient_status.name}</TableCell>
                    <TableCell>{renderActions(patient)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestPatients.propTypes = {
  className: PropTypes.string
};

LatestPatients.defaultProps = {
  className: ''
};

export default LatestPatients;
