import BaseApiService from './base';

export default class GlobalSearchApiService extends BaseApiService {
  config() {
    return this.request({
      method: 'get',
      url: 'meilisearch/config'
    });
  }

  organizationTypes() {
    return this.request({
      method: 'get',
      url: 'organization/types'
    });
  }
}
