import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import HitCardDetails from '../Common/HitCardDetails';
import HitCardHeader from '../Common/HitCardHeader';
import HitCardActions from '../Common/HitCardActions';
import OrganizationNotes from '../../OrganizationNotes';
import { useSelector } from 'react-redux';
import hasPermission from '../../../selectors/hasPermission';

const visibleAttributes = [
  'organization_type',
  'account_number',
  'number_of_users',
  'number_of_patients',
  'manager',
  'main_location',
  'joined_at',
  'first_case_submission',
  'newest_case_submission'
];

const HitCard = ({ hit }) => {
  const history = useHistory();

  const [expanded, setExpanded] = React.useState(false);
  const [expandedNotes, setExpandedNotes] = useState(false);

  const canAddOldCases = useSelector(state =>
    hasPermission(state, {
      permissions: ['old-cases.upload']
    })
  );

  const handleStartACase = () => {
    history.push(`/organizations/${hit.model_id}/rx-forms/add`);
  };

  let isArchived = false;

  if (hit.model === 'organization' && hit.is_archived) {
    isArchived = true;
  }

  const handleExpand = expand => {
    setExpanded(expand);
    setExpandedNotes(expand);
  };

  const toggleNotes = e => {
    e.preventDefault();
    setExpandedNotes(!expandedNotes);
  };

  return (
    <>
      <HitCardHeader hit={hit} expanded={expanded} setExpanded={handleExpand} />

      {!isArchived && (
        <HitCardActions
          hit={hit}
          actions={[
            {
              label: 'Subscription',
              url: `/subscriptions/${hit.contract_id}`
            },
            {
              label: 'Locations',
              url: `/organizations/${hit.model_id}/locations`
            },
            {
              label: 'Users',
              url: `/users?queryOrganization=${hit.model_id}`
            },
            {
              label: 'Patients',
              url: `/patients-page?queryOrganization=${hit.model_id}&queryName=${hit.full_name}`
            },
            {
              label: 'Onboardings',
              url: `/onboardings/${hit.onboarding_id}`
            },
            {
              label: 'Start Case',
              onclick: handleStartACase
            },
            {
              label: 'Add Old Case',
              url: () => (canAddOldCases ? `/organizations/${hit.model_id}/old-cases/add` : null)
            },
            {
              label: 'Notes',
              onclick: toggleNotes
            }
          ]}
        />
      )}

      {isArchived && (
        <HitCardActions
          hit={hit}
          actions={[
            {
              label: 'Patients',
              url: `/patients-page?queryOrganization=${hit.model_id}&queryName=${hit.full_name}`
            }
          ]}
        />
      )}

      <HitCardDetails hit={hit} visibleAttributes={visibleAttributes} expanded={expanded} />

      <Collapse in={expandedNotes} timeout="auto" unmountOnExit>
        <OrganizationNotes notes={hit.management_notes} organizationId={hit.model_id} />
      </Collapse>
    </>
  );
};

HitCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

HitCard.defaultProps = {};

export default HitCard;
