import BaseApiService from './base';

export default class ProductsApiService extends BaseApiService {
  show(id) {
    return this.request({
      method: 'get',
      url: `/products/${id}`
    });
  }
}
