import React from 'react';
import PropTypes from 'prop-types';
import HitCard from './HitCard';

const UserHit = ({ hit }) => {
  return (
    <div key={hit.model_id}>
      <HitCard hit={hit} />
    </div>
  );
};

UserHit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

UserHit.defaultProps = {};

export default UserHit;
