import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AccountCircle } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { CONFIRM_INVITATION_PREFIX, confirmInvitationAction } from '../../reducers/auth';
import createLoadingSelector from '../../selectors/loading';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatarRed: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  avatarGreen: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main
  },
  message: {
    marginTop: theme.spacing(3)
  }
}));

const ConfirmInvitation = ({ location = {} }) => {
  const urlQueryParams = new URLSearchParams(location.search);
  const verificationCode = urlQueryParams.get('token');
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => createLoadingSelector([CONFIRM_INVITATION_PREFIX])(state));
  const [isVerified, setIsVerified] = useState();
  const [message, setMessage] = useState('');
  const avatarClassname = clsx(!isVerified && classes.avatarRed, isVerified && classes.avatarGreen);

  useEffect(() => {
    if (verificationCode) {
      dispatch(confirmInvitationAction(verificationCode)).then(response => {
        if (response) {
          setIsVerified(true);
          setMessage('The invitation was confirmed. Please, try to log in now.');
        } else {
          setMessage('There was an issue confirming your invitation.');
        }
      });
    }
  }, [verificationCode, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={avatarClassname}>
          <AccountCircle />
        </Avatar>
        <Typography component="h1" variant="h5">
          Confirming Invitation
        </Typography>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <Typography className={classes.message} variant="subtitle2">
              {message}
            </Typography>
            {/* eslint-disable-next-line no-return-assign */}
            <Link component={RouterLink} onClick={() => (window.location = '/login')}>
              Go to Home
            </Link>
          </>
        )}
      </div>
    </Container>
  );
};

ConfirmInvitation.propTypes = {
  location: PropTypes.shape({})
};

ConfirmInvitation.defaultProps = {
  location: {}
};

export default ConfirmInvitation;
