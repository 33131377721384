import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
  ATTRIBUTES,
  IMPRESSION_TYPE_METHODS,
  IMPRESSION_TYPE_LABELS,
  IMPRESSION_TYPE_VALUES,
} from '../constants';
import CheckboxGroupField from './CheckboxGroupField';
import { decodeFormikValues } from '../helpers';

const { PVS_ALIGNATE, STONE_MODEL, INTRA_ORAL_SCANS } = IMPRESSION_TYPE_METHODS;

const ImpressionTypeField = ({
  currentValue,
  setValue,
  preferenceValue,
  ...props
}) => {
  const options = [];
  // if (Array.isArray(preferenceValue)) {
  //   if (preferenceValue.indexOf(PVS_ALIGNATE) !== -1) {
  options.push({
    label: IMPRESSION_TYPE_LABELS[PVS_ALIGNATE],
    value: IMPRESSION_TYPE_VALUES[PVS_ALIGNATE],
    checked: (currentValue & IMPRESSION_TYPE_VALUES[PVS_ALIGNATE]) > 0,
  });
  // }
  // if (preferenceValue.indexOf(STONE_MODEL) !== -1) {
  options.push({
    label: IMPRESSION_TYPE_LABELS[STONE_MODEL],
    value: IMPRESSION_TYPE_VALUES[STONE_MODEL],
    checked: (currentValue & IMPRESSION_TYPE_VALUES[STONE_MODEL]) > 0,
  });
  // }
  // if (preferenceValue.indexOf(INTRA_ORAL_SCANS) !== -1) {
  options.push({
    label: IMPRESSION_TYPE_LABELS[INTRA_ORAL_SCANS],
    value: IMPRESSION_TYPE_VALUES[INTRA_ORAL_SCANS],
    checked: (currentValue & IMPRESSION_TYPE_VALUES[INTRA_ORAL_SCANS]) > 0,
  });
  //   }
  // }

  const handleChange = (checked, val) => {

    if (checked)
      setValue(val);
    else
      setValue(0);

  }

  return (
    <>
      <CheckboxGroupField
        options={options}
        name={ATTRIBUTES.IMPRESSION_TYPE_METHOD}
        {...props}
        fieldProps={{ row: false }}
        onChange={handleChange}
      />
    </>
  );
};

ImpressionTypeField.defaultProps = {
};

ImpressionTypeField.propTypes = {
  preferenceValue: PropTypes.number, // TODO(Marcin): Should be required!!!
  currentValue: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default connect(({ formik: { values: encodeValues, setFieldValue }, ...props }) => {
  const values = decodeFormikValues(encodeValues);
  const currentValue = values[ATTRIBUTES.IMPRESSION_TYPE_METHOD];

  return (
    <ImpressionTypeField
      setValue={value => setFieldValue(ATTRIBUTES.IMPRESSION_TYPE_METHOD, String(value))}
      currentValue={currentValue}
      {...props}
    />
  );
});
