import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Box component="div" role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired
};

export default TabPanel;
