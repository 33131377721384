import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import { Field } from 'formik';
import { RadioGroup as RadioGroupFormik } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { isBoolean } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { OPTIONS, QUESTIONS, STATEMENTS } from '../constants';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    marginLeft: '16px'
  },
  verticalLine: {
    borderLeft: '1px solid #e0e0e0',
    position: 'absolute',
    top: '0',
    height: '86%',
    left: '-16px'
  }
}));

const QuestionLabel = ({ name, appendLabel }) => {
  return (
    <>
      {QUESTIONS[name]} {appendLabel && appendLabel}
    </>
  );
};

const RadioGroupField = ({
  name,
  options,
  isSubmitting,
  readOnly,
  fieldProps,
  appendLabel,
  appendAfterOptions
}) => {
  const classes = useStyles();
  const availableOptions = options || OPTIONS[name];

  return (
    <div className={classes.container}>
      <div className={classes.verticalLine} />
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {options && options.length === 1 ? (
            STATEMENTS[name] || <QuestionLabel name={name} appendLabel={appendLabel} />
          ) : (
            <QuestionLabel name={name} appendLabel={appendLabel} />
          )}
        </FormLabel>
        <Field component={RadioGroupFormik} name={name} row {...fieldProps}>
          {availableOptions.map(({ value, label, ...labelProps }) => (
            <FormControlLabel
              key={label}
              value={String(isBoolean(value) ? Number(value) : value)}
              control={<Radio color="primary" disabled={isSubmitting} />}
              label={label}
              disabled={readOnly || isSubmitting}
              {...labelProps}
            />
          ))}
          {appendAfterOptions}
        </Field>
      </FormControl>
    </div>
  );
};

QuestionLabel.defaultProps = {
  appendLabel: null
};

QuestionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  appendLabel: PropTypes.any
};

RadioGroupField.defaultProps = {
  readOnly: false,
  options: null,
  fieldProps: {},
  appendLabel: null,
  appendAfterOptions: null
};

RadioGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isSubmitting: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fieldProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  appendLabel: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  appendAfterOptions: PropTypes.any
};

export default RadioGroupField;
