import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { produce } from 'immer';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DibsRangePickerFilter from '../../../components/DibsRangePickerFilter';
import { getDateRange, getRangeTextFromDates } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  filter: {
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2)
  }
}));

function Filters({ additionalParams, setAdditionalParams, excludeFilters }) {
  const classes = useStyles();

  const timeFilterOptions = [
    'custom',
    'today',
    'yesterday',
    'divider',
    'this_week',
    'last_week',
    'divider',
    'this_month',
    'last_month',
    'day_to_month',
    'divider',
    'all_time'
  ];

  const renderTimeFilterOptions = () => {
    return _.map(
      _.filter(timeFilterOptions, f => !excludeFilters.includes(f)),
      (value, i) => {
        if (value === 'divider') {
          return <Divider key={`divider_${i}`} />;
        }
        return (
          <MenuItem value={value} key={value} disabled={value === 'custom'}>
            {_.startCase(value)}
          </MenuItem>
        );
      }
    );
  };

  const handleDateFilterChange = dates => {
    const selectedValue = getRangeTextFromDates(dates);
    setAdditionalParams(
      produce(additionalParams, draft => {
        if (dates) {
          draft.params.dateFilterFrom = dates[0].startOf('day');
          draft.params.dateFilterTo = dates[1].endOf('day');
        } else {
          draft.params.dateFilterFrom = '';
          draft.params.dateFilterTo = '';
        }
        draft.params.timeFilter = selectedValue;
        draft.resetPage = true;
      })
    );
  };

  const handleTimeFilterChange = selectedValue => {
    const [from, to] = getDateRange(selectedValue);
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.timeFilter = selectedValue;
        draft.params.dateFilterFrom = from;
        draft.params.dateFilterTo = to;
        draft.resetPage = true;
      })
    );
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} md={5} className={classes.filter}>
        <DibsRangePickerFilter
          onChange={handleDateFilterChange}
          style={{ width: '100%' }}
          setAdditionalParams={setAdditionalParams}
          additionalParams={additionalParams}
          fromParamKey="dateFilterFrom"
          toParamKey="dateFilterTo"
          value={[additionalParams.params.dateFilterFrom, additionalParams.params.dateFilterTo]}
          convertToUtc={false}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.filter}>
        <FormControl fullWidth>
          <Select
            value={additionalParams.params.timeFilter}
            onChange={e => handleTimeFilterChange(e.target.value)}
          >
            {renderTimeFilterOptions()}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

Filters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  additionalParams: PropTypes.object.isRequired,
  setAdditionalParams: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  excludeFilters: PropTypes.array
};

Filters.defaultProps = {
  excludeFilters: []
};

export default Filters;
