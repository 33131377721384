import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';

const ConfirmationDialogOnClickWrapper = ({
  children,
  confirmationTitle,
  confirmationBody,
  eventName
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const onCloseConfirmation = response => {
    setOpenConfirmation(false);
    if (response) {
      children.props[eventName](currentEvent);
    }
  };

  const confirmationDialogProps = {
    open: openConfirmation,
    handleClose: onCloseConfirmation,
    ...(confirmationTitle && { title: confirmationTitle }),
    ...(confirmationBody && { bodyText: confirmationBody })
  };

  const clonedChild = cloneElement(children, {
    [eventName]: event => {
      setOpenConfirmation(true);
      setCurrentEvent(event);
    }
  });

  return (
    <>
      {clonedChild}
      <ConfirmationDialog {...confirmationDialogProps} />
    </>
  );
};

ConfirmationDialogOnClickWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  showConfirmation: PropTypes.bool,
  confirmationTitle: PropTypes.string,
  confirmationBody: PropTypes.string,
  eventName: PropTypes.string,
  onCancelConfirmation: PropTypes.func
};

ConfirmationDialogOnClickWrapper.defaultProps = {
  showConfirmation: false,
  confirmationTitle: '',
  confirmationBody: '',
  eventName: 'onClick',
  onCancelConfirmation: () => {}
};

export default ConfirmationDialogOnClickWrapper;
