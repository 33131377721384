import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { CardActions, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card_actions: {
    padding: theme.spacing(0, 1)
  }
}));

const HitCardActions = ({ hit, actions }) => {
  const classes = useStyles();

  const lastAction = index => {
    return _.size(actions) - 1 === index;
  };

  const renderLinkSeprator = () => (
    <Typography variant="overline" color="primary">
      {' '}
      |{' '}
    </Typography>
  );

  const renderLink = (text, path = '#', onClick) => {
    if (_.isNil(path) && _.isNil(onClick)) return null;

    const parsedPath = _.endsWith(path, 'null') || !_.isNil(onClick) ? '#' : path;

    const disabled = _.endsWith(path, 'null');

    const clickHandler = onClick || (() => {});

    return (
      <Typography variant="overline" color="primary">
        <Link
          to={parsedPath}
          disabled={disabled}
          onClick={clickHandler}
          replace={!_.isNil(onClick)}
        >
          {text}
        </Link>
      </Typography>
    );
  };

  const renderAction = (action, index) => {
    const { label, url: urlRaw, path, onclick } = action;

    const url = _.isFunction(urlRaw) ? urlRaw() : urlRaw;
    if (!url && !onclick) {
      return null;
    }

    const target = _.get(hit, [path], null);

    const fullPath = url ? url.replace('$__REPLACE__$', target) : null;

    return (
      <Box key={Math.random()} display="inline">
        {renderLink(label, fullPath, onclick)}

        {!lastAction(index) && renderLinkSeprator()}
      </Box>
    );
  };

  return (
    <CardActions className={classes.card_actions}>
      <Box px={1}>
        <Typography variant="overline" color="textSecondary">
          Quick Links:{' '}
        </Typography>

        {actions.map((action, index) => renderAction(action, index))}
      </Box>
    </CardActions>
  );
};

HitCardActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired
};

HitCardActions.defaultProps = {};

export default HitCardActions;
