import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

function LoadingSnackbar({ show, message }) {
  const [vertical] = useState('top');
  const [horizontal] = useState('center');
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={show}
      message={message}
      key={vertical + horizontal}
    />
  );
}

LoadingSnackbar.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool
};

LoadingSnackbar.defaultProps = {
  message: 'Loading data...',
  show: false
};

export default LoadingSnackbar;
