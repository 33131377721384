import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class LocationsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    organizationId = '',
    locationInfo = '',
    searchByOrganization = '',
    orderBy,
    includes = [],
    organizations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .where('organization_id', organizationId)
        .where('search', locationInfo)
        .where('organization_search', searchByOrganization)
        .whereIn('chosen_wi_organization_id', organizations)
        .for('locations')
        .include(...includes)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/locations/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/locations',
      data
    });
  }

  update(data, id) {
    return this.request({
      method: 'put',
      url: `/locations/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/locations/${id}`
    });
  }
}
