import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import UsersApiService from '../services/api/users';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_USERS_PREFIX = 'FETCH_USERS';
export const FETCH_USERS_REQUEST_ACTION = `${FETCH_USERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_USERS_SUCCESS_ACTION = `${FETCH_USERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_USERS_FAILURE_ACTION = `${FETCH_USERS_PREFIX}_FAILURE_ACTION`;

export const FETCH_USER_PREFIX = 'FETCH_USER';
export const FETCH_USER_REQUEST_ACTION = `${FETCH_USER_PREFIX}_REQUEST_ACTION`;
export const FETCH_USER_SUCCESS_ACTION = `${FETCH_USER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_USER_FAILURE_ACTION = `${FETCH_USER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_USER_PREFIX = 'UPDATE_USER';
export const UPDATE_USER_REQUEST_ACTION = `${UPDATE_USER_PREFIX}_REQUEST_ACTION`;
export const UPDATE_USER_SUCCESS_ACTION = `${UPDATE_USER_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_USER_FAILURE_ACTION = `${UPDATE_USER_PREFIX}_FAILURE_ACTION`;

export const CREATE_USER_PREFIX = 'CREATE_USER';
export const CREATE_USER_REQUEST_ACTION = `${CREATE_USER_PREFIX}_REQUEST_ACTION`;
export const CREATE_USER_SUCCESS_ACTION = `${CREATE_USER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_USER_FAILURE_ACTION = `${CREATE_USER_PREFIX}_FAILURE_ACTION`;

export const DELETE_USER_PREFIX = 'DELETE_USER';
export const DELETE_USER_REQUEST_ACTION = `${DELETE_USER_PREFIX}_REQUEST_ACTION`;
export const DELETE_USER_SUCCESS_ACTION = `${DELETE_USER_PREFIX}_SUCCESS_ACTION`;
export const DELETE_USER_FAILURE_ACTION = `${DELETE_USER_PREFIX}_FAILURE_ACTION`;

export const ARCHIVE_USER_PREFIX = 'ARCHIVE_USER';
export const ARCHIVE_USER_REQUEST_ACTION = `${ARCHIVE_USER_PREFIX}_REQUEST_ACTION`;
export const ARCHIVE_USER_SUCCESS_ACTION = `${ARCHIVE_USER_PREFIX}_SUCCESS_ACTION`;
export const ARCHIVE_USER_FAILURE_ACTION = `${ARCHIVE_USER_PREFIX}_FAILURE_ACTION`;

export const RESTORE_USER_PREFIX = 'RESTORE_USER';
export const RESTORE_USER_REQUEST_ACTION = `${RESTORE_USER_PREFIX}_REQUEST_ACTION`;
export const RESTORE_USER_SUCCESS_ACTION = `${RESTORE_USER_PREFIX}_SUCCESS_ACTION`;
export const RESTORE_USER_FAILURE_ACTION = `${RESTORE_USER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_USER_ROLE_PREFIX = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_REQUEST_ACTION = `${UPDATE_USER_ROLE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_USER_ROLE_SUCCESS_ACTION = `${UPDATE_USER_ROLE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_USER_ROLE_FAILURE_ACTION = `${UPDATE_USER_ROLE_PREFIX}_FAILURE_ACTION`;

export const TOGGLE_CUSTOM_PERMISSION_PREFIX = 'TOGGLE_CUSTOM_PERMISSION';
export const TOGGLE_CUSTOM_PERMISSION_REQUEST_ACTION = `${TOGGLE_CUSTOM_PERMISSION_PREFIX}_REQUEST_ACTION`;
export const TOGGLE_CUSTOM_PERMISSION_SUCCESS_ACTION = `${TOGGLE_CUSTOM_PERMISSION_PREFIX}_SUCCESS_ACTION`;
export const TOGGLE_CUSTOM_PERMISSION_FAILURE_ACTION = `${TOGGLE_CUSTOM_PERMISSION_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    order: 'desc',
    orderBy: 'created_at',
    page: 1,
    perPage: 25,
    searchFullNameEmailQuery: '',
    roles: ['admin', 'tech_support', 'supervisor', 'lab_partner', 'doctor']
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.users.data,
        total: action.payload.users.total,
        globalParams: action.payload.params
      };
    case FETCH_USER_SUCCESS_ACTION:
      return produce(state, draft => {
        const userIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (userIndex > -1) {
          draft.items[userIndex] = action.payload;
        } else {
          draft.items.push(action.payload);
          draft.total = state.total + 1;
        }
      });
    case DELETE_USER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
        draft.total = state.total - 1;
      });
    case UPDATE_USER_SUCCESS_ACTION:
    case TOGGLE_CUSTOM_PERMISSION_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case CREATE_USER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_USER_ROLE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchUsersAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_USERS_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.get(params)
      .then(response => {
        dispatch({
          type: FETCH_USERS_SUCCESS_ACTION,
          payload: { users: response.users, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_USERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchUserAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.getUser(id)
      .then(response => {
        dispatch({ type: FETCH_USER_SUCCESS_ACTION, payload: response.user });
      })
      .catch(error => {
        dispatch({ type: FETCH_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteUserAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.delete(id)
      .then(response => {
        dispatch({ type: DELETE_USER_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function archiveUserAction(id) {
  return dispatch => {
    dispatch({ type: ARCHIVE_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.archive(id)
      .then(response => {
        dispatch({ type: ARCHIVE_USER_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ARCHIVE_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function restoreUserAction(id) {
  return dispatch => {
    dispatch({ type: RESTORE_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.restore(id)
      .then(response => {
        dispatch({ type: RESTORE_USER_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: RESTORE_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createUserAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.create(params)
      .then(response => {
        dispatch({ type: CREATE_USER_SUCCESS_ACTION, payload: response.user });
        dispatch(enqueueNotification('success', response.message));
        return response.user;
      })
      .catch(error => {
        dispatch({ type: CREATE_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateUserAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_USER_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.update(id, params)
      .then(response => {
        dispatch({ type: UPDATE_USER_SUCCESS_ACTION, payload: response.user });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function toggleCustomerPermissionAction(userId, permission) {
  return dispatch => {
    dispatch({ type: TOGGLE_CUSTOM_PERMISSION_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.toggleCustomPermission(userId, permission)
      .then(response => {
        dispatch({ type: TOGGLE_CUSTOM_PERMISSION_SUCCESS_ACTION, payload: response.user });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: TOGGLE_CUSTOM_PERMISSION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
