import _ from 'lodash';
import * as Yup from 'yup';

/**
 *
 * Phone Number is validated against (E.164) Standard for international phone number format.
 * @see: https://www.twilio.com/docs/glossary/what-e164
 *
 */
export default function getSchema(showSignUpQuestion) {
  return Yup.object().shape({
    organization: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string()
      .email()
      .required('Required'),
    phone_number: Yup.string()
      .required('Required')
      .matches(/^\+[1-9]\d{6,14}$/, 'Invalid phone number.'),

    zip_code: Yup.string().required('Required'),
    street_address: Yup.string().required('Required'),
    street_address_line_2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string()
      .required('Required')
      .length(2),
    country: Yup.string().required('Required'),

    survey_answer_id: Yup.number().test('Survey Answer Test', 'Must not be empty.', value => {
      // Signup survey is disabled
      if (!showSignUpQuestion) return true;

      if (value) {
        return _.isFinite(value);
      }

      return false;
    }),

    survey_response_other: Yup.string().when('survey_answer_id', {
      is: val => val === 7,
      then: showSignUpQuestion ? Yup.string().required('Required') : Yup.string().optional()
    })
  });
}
