import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { CardContent, Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoadingButton';
import hasPermission from '../../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  },
  title: {
    marginTop: theme.spacing(2)
  }
}));

const OrgForm = ({
  onSubmit,
  isSaving,
  isArchiving,
  isRestoring
}) => {
  const classes = useStyles();

  const initialValues = {
    organization: '',
    account_number: '',

    first_name: '',
    last_name: '',
    email: '',
    username: '',
    no_startup_fee: false,
  };

  const canUpdate = useSelector(state =>
    hasPermission(state, {
      permissions: ['users.update', 'users.manage']
    })
  );

  const isManagement = useSelector(state => state.auth.isManagement);

  const alphanumeric = /^([A-Za-z0-9]+)([A-Za-z0-9-]+)([A-Za-z0-9]+)$/;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        organization: Yup.string(),
        account_number: Yup.string()
          .max(20, 'Only up to 20 characters is allowed.')
          .min(6, 'Must contain at least 6 characters.')
          .matches(
            alphanumeric,
            'Must only contain alphanumeric characters or "-" in between.'
          ),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
          .email()
          .required('Required'),
        username: Yup.string()
          .min(6, 'Must contain at least 6 characters.')
          .max(255, 'Only up to 255 characters is allowed.')
          .matches(
            alphanumeric,
            'Must only contain alphanumeric characters or "-" in between.'
          ),
        no_startup_fee: Yup.boolean(),
      })}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Field
                    variant="outlined"
                    fullWidth
                    id="organization"
                    label="Organization Name (optional)"
                    name="organization"
                    component={TextField}
                    disabled={!canUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Field
                    variant="outlined"
                    fullWidth
                    id="account_number"
                    label="User Account Number (optional)"
                    name="account_number"
                    component={TextField}
                    disabled={!(canUpdate || isManagement)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} className={classes.title}>
                    <h3>Owner Information</h3>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Field
                    name="first_name"
                    variant="outlined"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    component={TextField}
                    disabled={!canUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    component={TextField}
                    disabled={!canUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    component={TextField}
                    disabled={!canUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Field
                    variant="outlined"
                    fullWidth
                    id="username"
                    label="Username (optional)"
                    name="username"
                    component={TextField}
                    disabled={!canUpdate}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Field
                      type="checkbox"
                      name="no_startup_fee"
                      as={FormControlLabel}
                      control={<Checkbox color='primary' />}
                      label="Waive Startup Fee"
                  />
                </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            { (canUpdate || isManagement) && (
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving || isArchiving || isRestoring}
                className={classes.actionButton}
              >
                Create
              </LoadingButton>
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

OrgForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default OrgForm;
