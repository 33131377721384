import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import LoadingButton from '../../../components/LoadingButton';
import createLoadingSelector from '../../../selectors/loading';
import {
  CREATE_ADDITIONAL_EMAIL_PREFIX,
  createAdditionalEmailAction,
  DELETE_ADDITIONAL_EMAIL_PREFIX,
  deleteAdditionalEmailAction
} from '../../../reducers/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: 'none'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  card: {
    width: '100%'
  }
}));

const AdditionalEmails = ({ emails, userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const isCreating = useSelector(state =>
    createLoadingSelector([CREATE_ADDITIONAL_EMAIL_PREFIX])(state)
  );
  const isDeleting = useSelector(state =>
    createLoadingSelector([DELETE_ADDITIONAL_EMAIL_PREFIX])(state)
  );

  const onChange = e => {
    setEmail(e.target.value);
  };

  const handleDelete = emailId => {
    dispatch(deleteAdditionalEmailAction(emailId));
  };

  const handleAddEmail = () => {
    dispatch(createAdditionalEmailAction(userId, { email })).then(response => {
      if (response) {
        setEmail('');
      }
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="Additional Emails" />
        <Divider />
        <CardContent>
          <Box pb={1} width="100%">
            <Paper component="ul" className={classes.root}>
              {emails.map(item => {
                return (
                  <li key={item.id}>
                    <Chip
                      label={item.email}
                      onDelete={() => handleDelete(item.id)}
                      className={classes.chip}
                      disabled={isDeleting}
                    />
                  </li>
                );
              })}
            </Paper>
          </Box>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Email For Notifications"
                type="text"
                value={email}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                <LoadingButton
                  type="button"
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isCreating}
                  disabled={isCreating || !email || isDeleting}
                  onClick={handleAddEmail}
                >
                  Add
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

AdditionalEmails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.array.isRequired,
  userId: PropTypes.number.isRequired
};

AdditionalEmails.defaultProps = {};

export default AdditionalEmails;
