import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
  ATTRIBUTES,
  ADDON_OPTIONS_METHODS,
  ADDON_OPTIONS_LABELS,
  ADDON_OPTIONS_VALUES,
  PRODUCTS_LIST_METHODS,
  PRODUCTS_LIST_VALUES,
} from '../constants';
import CheckboxGroupField from './CheckboxGroupField';
import { decodeFormikValues } from '../helpers';

const { ANALYSIS_REPORT,
  DOUBLE_OCCLUSAL_VIEW,
  RETURN_STONE_MODELS,
  RETURN_IMPRESSION_TRAYS,
  CONVERT_MODEL_FORMAT_TO_STL } = ADDON_OPTIONS_METHODS;

const AddonOptionsField = ({
  currentProducts,
  currentValue,
  setValue,
  preferenceValue,
  ...props
}) => {
  const options = [];

  // if (Array.isArray(preferenceValue)) {

  const haveProducts = (currentProducts & ~PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.PRINTING_OPTIONS]) > 0;
  const haveFullModel = (currentProducts & (PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.FULL_DIGITAL_MODEL])) > 0;
  const haveCleanBase = (currentProducts & (PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.CLEANUP_BASE])) > 0;

  if (haveProducts) {

    if (haveFullModel || haveCleanBase) {

      //   if (preferenceValue.indexOf(ANALYSIS_REPORT) !== -1) {

      options.push({
        label: ADDON_OPTIONS_LABELS[ANALYSIS_REPORT],
        value: ADDON_OPTIONS_VALUES[ANALYSIS_REPORT],
        checked: (currentValue & ADDON_OPTIONS_VALUES[ANALYSIS_REPORT]) > 0,
      });

      // }
      // if (preferenceValue.indexOf(DOUBLE_OCCLUSAL_VIEW) !== -1) {
      options.push({
        label: ADDON_OPTIONS_LABELS[DOUBLE_OCCLUSAL_VIEW],
        value: ADDON_OPTIONS_VALUES[DOUBLE_OCCLUSAL_VIEW],
        checked: (currentValue & ADDON_OPTIONS_VALUES[DOUBLE_OCCLUSAL_VIEW]) > 0,
      });
      // }
    }

    if (!haveCleanBase) {
      // if (preferenceValue.indexOf(RETURN_STONE_MODELS) !== -1) {
      options.push({
        label: ADDON_OPTIONS_LABELS[RETURN_STONE_MODELS],
        value: ADDON_OPTIONS_VALUES[RETURN_STONE_MODELS],
        checked: (currentValue & ADDON_OPTIONS_VALUES[RETURN_STONE_MODELS]) > 0,
      });
      //   }
      // }
      // if (preferenceValue.indexOf(RETURN_IMPRESSION_TRAYS) !== -1) {
      options.push({
        label: ADDON_OPTIONS_LABELS[RETURN_IMPRESSION_TRAYS],
        value: ADDON_OPTIONS_VALUES[RETURN_IMPRESSION_TRAYS],
        checked: (currentValue & ADDON_OPTIONS_VALUES[RETURN_IMPRESSION_TRAYS]) > 0,
      });
      //   }
      // }
    }

    // if (preferenceValue.indexOf(CONVERT_MODEL_FORMAT_TO_STL) !== -1) {
    options.push({
      label: ADDON_OPTIONS_LABELS[CONVERT_MODEL_FORMAT_TO_STL],
      value: ADDON_OPTIONS_VALUES[CONVERT_MODEL_FORMAT_TO_STL],
      checked: (currentValue & ADDON_OPTIONS_VALUES[CONVERT_MODEL_FORMAT_TO_STL]) > 0,
    });
    //   }

  }
  // }


  useEffect(() => {
    const haveProducts = (currentProducts & ~PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.PRINTING_OPTIONS]) > 0;
    const haveFullModel = (currentProducts & (PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.FULL_DIGITAL_MODEL])) > 0;
    const haveCleanBase = (currentProducts & (PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.CLEANUP_BASE])) > 0;

    const AnalysisOrOcclusal = ADDON_OPTIONS_VALUES[ADDON_OPTIONS_METHODS.ANALYSIS_REPORT] |
        ADDON_OPTIONS_VALUES[ADDON_OPTIONS_METHODS.DOUBLE_OCCLUSAL_VIEW];
    const ReturnStoneOrReturnTray = ADDON_OPTIONS_VALUES[ADDON_OPTIONS_METHODS.RETURN_STONE_MODELS] |
        ADDON_OPTIONS_VALUES[ADDON_OPTIONS_METHODS.RETURN_IMPRESSION_TRAYS];

    if (!haveProducts && currentValue > 0) {
      setValue(0);
    }

    if (!haveFullModel) {
      if (!haveCleanBase && (currentValue & AnalysisOrOcclusal) > 0) {
        setValue(currentValue & ~AnalysisOrOcclusal);
      } else if (haveCleanBase && (currentValue & ReturnStoneOrReturnTray) > 0) {
        setValue(currentValue & ~ReturnStoneOrReturnTray);
      }
    }

  }, [currentProducts, currentValue, setValue]);

  const handleChange = (checked, val) => {

    if (checked)
      setValue(currentValue | val);
    else
      setValue(currentValue & ~val);

  }

  return (
    <>
      <CheckboxGroupField
        options={options}
        name={ATTRIBUTES.ADDON_OPTIONS_METHOD}
        {...props}
        fieldProps={{ row: false }}
        onChange={handleChange}
      />
    </>
  );
};

AddonOptionsField.defaultProps = {
};

AddonOptionsField.propTypes = {
  preferenceValue: PropTypes.number, // TODO(Marcin): Should be required!!!
  currentValue: PropTypes.number.isRequired,
  currentProducts: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default connect(({ formik: { values: encodeValues, setFieldValue }, ...props }) => {
  const values = decodeFormikValues(encodeValues);
  const currentValue = values[ATTRIBUTES.ADDON_OPTIONS_METHOD];
  const currentProducts = values[ATTRIBUTES.PRODUCTS_LIST_METHOD];

  return (
    <AddonOptionsField
      setValue={value => setFieldValue(ATTRIBUTES.ADDON_OPTIONS_METHOD, String(value))}
      currentValue={currentValue}
      currentProducts={currentProducts}
      {...props}
    />
  );
});
