import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Modal,
  styled,
  Tooltip
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Formik, Field, Form } from 'formik';
import { Build as BuildIcon } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { Checkbox } from 'formik-material-ui';
import { connect } from 'react-redux';
import {
  FILE_TYPE_IDS,
  JAWS_TYPES,
  JAWS_TYPES_IDS,
  PATIENT_STATUSES_VALUE,
  ROLES
} from '../../constants';
import { filterFilesByType, findRolesInUser } from '../../helpers';
import { ARCHES, ASSIGNMENTS } from '../RxForm/constants';
import { requestFlawedTrayCorrection } from '../../reducers/requests';
import CommonPropTypes from '../../common/PropTypes';
import { fetchPatientAction } from '../../reducers/patients';
import hasPermission from '../../selectors/hasPermission';

const Container = styled(Card)(({ theme }) => ({
  width: 500,
  margin: '10% auto',
  padding: theme.spacing(2)
}));

const useStyles = makeStyles(() => {
  return {
    actions: {
      justifyContent: 'flex-end'
    },
    title: {
      textAlign: 'center'
    }
  };
});

const initializeJaws = patient => {
  const jaws = {};
  ['maxillary', 'mandibular'].forEach(value => {
    if (
      patient.rx_form.jaws_id === JAWS_TYPES_IDS[value] ||
      patient.rx_form.jaws_id === JAWS_TYPES_IDS.both
    ) {
      jaws[value] = true;
    } else {
      jaws[value] = null;
    }
  });
  return jaws;
};

const isNotApplicable = (user, patient) => {
  const iosFiles = filterFilesByType(patient.patient_files, FILE_TYPE_IDS.ios);
  const stlFiles = filterFilesByType(patient.patient_files, FILE_TYPE_IDS.stl);

  const isDoctor = findRolesInUser([ROLES.doctor], user);
  const is3PP = findRolesInUser([ROLES.labPartner], user);

  const doctorIsNotAllowedIfPrintIsNotInHouse =
    isDoctor && patient?.rx_form?.print_assignment !== ASSIGNMENTS.IN_OFFICE;
  const labPartnerIsNotAllowedIfPrintIsNotAssignedToThem =
    is3PP && patient?.rx_form?.print_assignment !== ASSIGNMENTS.THIRD_PARTY;

  return (
    !patient.rx_form || // must have an rxform
    patient.rx_form.print_assignment === ASSIGNMENTS.ORTHOSELECT || // it wouldn't make sense for ortho to send flawed tray for themselves
    patient.patient_status_id !== PATIENT_STATUSES_VALUE.COMPLETED || // must be completed
    !iosFiles.length || // must have an IOS file
    !stlFiles.length || // must have stl Files
    patient.has_pending_flawed_tray_request || // doesn't have any pending flawed tray request
    (patient.customer_request && patient.customer_request.status_id !== 1) || // if there's an existing request, then the status must be "new"
    doctorIsNotAllowedIfPrintIsNotInHouse || // doctor is not allowed if print assignment is not in-office
    labPartnerIsNotAllowedIfPrintIsNotAssignedToThem // 3pp is not allowed if print is not for them
  );
};

const checkForWarnings = patient => {
  const iosFiles = filterFilesByType(patient.patient_files, FILE_TYPE_IDS.ios);

  if (iosFiles.length > 1) {
    return 'Multiple IOS files have been found.';
  }

  return null;
};

const PopupForm = ({ jaws, onSubmit, handleClose, open }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Container elevation={1}>
        <Formik initialValues={jaws} enableReinitialize onSubmit={onSubmit}>
          {({ values, isSubmitting, submitForm }) => {
            const hasSelectedArches =
              Object.keys(values).filter(name => {
                return values[name];
              }).length > 0;

            return (
              <Form noValidate>
                <CardHeader className={classes.title} title="Flawed Trays Correction Request" />
                <CardContent>
                  <List>
                    {Object.keys(jaws).map(jaw => {
                      if (!jaws[jaw]) {
                        return null;
                      }
                      return (
                        <ListItem key={jaw}>
                          <ListItemAvatar>
                            <Avatar>
                              <BuildIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${JAWS_TYPES[JAWS_TYPES_IDS[jaw]].name} IDB Tray`}
                            secondary="Select tray for correction"
                          />
                          <Field component={Checkbox} checked={values[jaw]} name={jaw} />
                        </ListItem>
                      );
                    })}
                  </List>
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={!hasSelectedArches || isSubmitting}
                  >
                    Send Request
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

PopupForm.propTypes = {
  jaws: PropTypes.shape({
    mandibular: PropTypes.bool.isRequired,
    maxillary: PropTypes.bool.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const FlawedTrayCorrectionButton = ({ patient, request, withPermission, actingUser, ...props }) => {
  const [open, setOpen] = useState(false);
  const notApplicable = isNotApplicable(actingUser, patient);

  if (!withPermission || notApplicable) {
    return '';
  }

  const warning = checkForWarnings(patient);
  const disable = warning !== null;
  const jaws = initializeJaws(patient);
  const iosFile = filterFilesByType(patient.patient_files, FILE_TYPE_IDS.ios)[0];

  const button = (
    <Button onClick={() => !disable && setOpen(true)} color="primary" variant="outlined" {...props}>
      Request Tray Correction
    </Button>
  );

  const handleSubmit = arches => {
    const data = {
      patient_id: patient.id,
      location_id: null,
      arches: []
    };

    const archData = {
      ios_file_id: iosFile.id,
      is_flawed: true,
      print_id: ASSIGNMENTS.IN_OFFICE,
      expedited_shipping: false,
      insert_brackets: false
    };

    if (arches.mandibular) {
      data.arches.push({
        jaw_type_id: ARCHES.MANDIBULAR,
        ...archData
      });
    }
    if (arches.maxillary) {
      data.arches.push({
        jaw_type_id: ARCHES.MAXILLARY,
        ...archData
      });
    }

    request(patient.organization_id, data);
    setOpen(false);
  };

  if (disable) {
    return <Tooltip title={warning}>{button}</Tooltip>;
  }

  return (
    <>
      {button}
      <PopupForm
        open={open}
        handleClose={() => setOpen(false)}
        jaws={jaws}
        onSubmit={handleSubmit}
      />
    </>
  );
};

FlawedTrayCorrectionButton.defaultProps = { withPermission: false };

FlawedTrayCorrectionButton.propTypes = {
  patient: CommonPropTypes.patient.isRequired,
  request: PropTypes.func.isRequired,
  withPermission: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  actingUser: PropTypes.object
};

export default connect(
  (state, props) => {
    return {
      withPermission: hasPermission(state, {
        permissions: ['organization.add-arch', 'organizations.manage'],
        organizationId: props.patient.organization_id
      }),
      actingUser: state.auth.user
    };
  },
  (dispatch, props) => {
    return {
      request: (organizationId, data) => {
        dispatch(requestFlawedTrayCorrection(organizationId, data)).then(() => {
          dispatch(fetchPatientAction(props.patient.id));
        });
      }
    };
  }
)(FlawedTrayCorrectionButton);
