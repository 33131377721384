import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Select, TextField } from 'formik-material-ui';
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, CardActions, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { replaceNullToEmpty, UTCDateTimeToLocalTime } from '../../../helpers';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../components/Can';
import { PATIENT_STATUSES } from '../../../constants';
import PatientHoldingAction from '../../../components/PatientHoldingAction';
import patterns from '../../../constants/patterns';
import FlawedTrayCorrectionButton from '../../../components/PatientRequest/FlawedTrayCorrectionButton';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    marginLeft: 'auto'
  }
}));

const PatientForm = ({
  onSubmit,
  isLoading,
  patient,
  isDeleting,
  onDelete,
  isRestoring,
  onRestore,
  isMarkingAsReady,
  onMarkAsReady,
  isCompleting,
  onComplete,
  initialValues,
  isArchiving,
  onArchive
}) => {
  const isManagement = useSelector(state => state.auth.isManagement);

  const classes = useStyles();
  const emptyPatient = {
    first_name: '',
    last_name: '',
    case_number_text: '',
    numeric_suffix: '',
    doctor_number: ''
  };

  const formatPatient = patientToFormat => {
    const formattedPatient = cloneDeep(patientToFormat);
    formattedPatient.created_at = UTCDateTimeToLocalTime(formattedPatient.created_at);
    formattedPatient.updated_at = UTCDateTimeToLocalTime(formattedPatient.updated_at);
    formattedPatient.doctor_number = get(formattedPatient, 'user.account_number');

    return formattedPatient;
  };

  return (
    <Formik
      initialValues={
        patient ? formatPatient(replaceNullToEmpty(patient)) : { ...emptyPatient, ...initialValues }
      }
      enableReinitialize
      validationSchema={Yup.object().shape({
        first_name: Yup.string()
          .required('Required')
          .matches(
            patterns.name,
            'Name may only contain letters (first letter uppercase), spaces, points, brackets, and apostrophes.'
          ),
        last_name: Yup.string()
          .required('Required')
          .matches(
            patterns.name,
            'Name may only contain letters (first letter uppercase), spaces, points, brackets, and apostrophes.'
          ),
        case_number_text: Yup.string()
          .nullable()
          .matches(
            patterns.case_number,
            'Case number may only contain letters, spaces, points, brackets, and apostrophes.'
          ),
        numeric_suffix: Yup.string().nullable()
      })}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  component={TextField}
                  disabled={patient && patient.deleted_at}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  name="last_name"
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  component={TextField}
                  disabled={patient && patient.deleted_at}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  name="case_number_text"
                  fullWidth
                  id="case_number_text"
                  label="Case Number"
                  component={TextField}
                  disabled={patient && patient.deleted_at}
                />
              </Grid>
              {patient && (
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    name="numeric_suffix"
                    fullWidth
                    id="numeric_suffix"
                    label="Suffix"
                    component={TextField}
                    disabled={patient && patient.deleted_at}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  name="doctor_number"
                  fullWidth
                  id="doctor_number"
                  label="Doctor Number"
                  component={TextField}
                  disabled
                />
              </Grid>
              {patient && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Field
                        required
                        name="patient_status_id"
                        disabled
                        component={Select}
                        labelWidth={45}
                      >
                        {Object.keys(PATIENT_STATUSES).map(key => {
                          return (
                            <MenuItem key={key} value={key}>
                              {PATIENT_STATUSES[key].name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      variant="outlined"
                      name="created_at"
                      fullWidth
                      disabled
                      label="Creation Date"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      variant="outlined"
                      name="updated_at"
                      fullWidth
                      disabled
                      label="Modification Date"
                      component={TextField}
                    />
                  </Grid>
                  {patient.deleted_at && (
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        name="deleted_at"
                        fullWidth
                        disabled
                        label="Deletion Date"
                        component={TextField}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            {patient && !patient.deleted_at && patient.rx_form && (
              <>
                {!patient.rx_form.ready_at &&
                  !patient.rx_form.cancelled_at &&
                  Number(patient.rx_form.submission_completed_by) === 1 &&
                  Number(patient.patient_status_id) === 1 && (
                    <Can
                      permissions={['rx-forms.manage', 'rx-forms.mark-as-ready']}
                      yes={() => (
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={isMarkingAsReady}
                          loading={isMarkingAsReady}
                          onClick={() => onMarkAsReady(patient.rx_form.id)}
                        >
                          Notify
                        </LoadingButton>
                      )}
                    />
                  )}
              </>
            )}
            <FlawedTrayCorrectionButton patient={patient} className={classes.submit} />
            {patient && !patient.deleted_at && (
              <Can
                permissions={['patients.manage', 'patients.complete-setup', 'patients.supervise']}
                yes={() => (
                  <>
                    {patient.patient_status_id === 1 && (
                      <ConfirmationDialogOnClickWrapper
                        confirmationBody="Are you sure you want to change the patient's status to completed?"
                        confirmationTitle="Complete Patient"
                      >
                        <LoadingButton
                          loading={isCompleting}
                          color="primary"
                          variant="contained"
                          className={classes.submit}
                          disabled={isLoading || isCompleting}
                          onClick={onComplete}
                        >
                          Complete
                        </LoadingButton>
                      </ConfirmationDialogOnClickWrapper>
                    )}
                  </>
                )}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => window.open(`/patient-details/${patient.id}/print-rxform`, '_blank')}
            >
              Print
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={(!dirty && isEmpty(initialValues)) || !isValid || isLoading}
              className={classes.submit}
            >
              {patient ? 'Update' : 'Create'}
            </LoadingButton>
            {patient && (
              <>
                <PatientHoldingAction patient={patient} isLoading={isLoading} />
                {isManagement && ( // ToDo: Client requested to temporarily disable deletion for doctors
                  <Can
                    permissions={[
                      'patients.manage',
                      'patients.delete',
                      'organization.patients.delete'
                    ]}
                    yes={() => (
                      <ConfirmationDialogOnClickWrapper
                        confirmationBody="Are you sure you want to delete the current patient?"
                        confirmationTitle="Delete Patient"
                      >
                        <LoadingButton
                          loading={isDeleting}
                          color="secondary"
                          variant="contained"
                          disabled={isLoading || isDeleting}
                          onClick={onDelete}
                        >
                          {patient.deleted_at ? 'Permanently Delete' : 'Delete'}
                        </LoadingButton>
                      </ConfirmationDialogOnClickWrapper>
                    )}
                  />
                )}

                {!patient.deleted_at && (
                  <Can
                    permissions={['patients.archive']}
                    yes={() => (
                      <ConfirmationDialogOnClickWrapper
                        confirmationBody="Are you sure you want to archive the current patient?"
                        confirmationTitle="Archive Patient"
                      >
                        <LoadingButton
                          loading={isDeleting}
                          color="secondary"
                          variant="contained"
                          disabled={isLoading || isArchiving}
                          onClick={onArchive}
                        >
                          Archive
                        </LoadingButton>
                      </ConfirmationDialogOnClickWrapper>
                    )}
                  />
                )}
                {patient.deleted_at && (
                  <Can
                    permissions={['patients.manage', 'patients.supervise', 'patients.delete']}
                    yes={() => (
                      <LoadingButton
                        loading={isRestoring}
                        color="primary"
                        variant="contained"
                        disabled={isLoading || isDeleting || isRestoring}
                        onClick={onRestore}
                      >
                        Restore
                      </LoadingButton>
                    )}
                  />
                )}
              </>
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

PatientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  onRestore: PropTypes.func,
  isRestoring: PropTypes.bool,
  isMarkingAsReady: PropTypes.bool,
  onMarkAsReady: PropTypes.func,
  isCompleting: PropTypes.bool,
  onComplete: PropTypes.func,
  isArchiving: PropTypes.bool,
  onArchive: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object
};

PatientForm.defaultProps = {
  patient: null,
  onDelete: () => {},
  isDeleting: false,
  onRestore: () => {},
  isRestoring: false,
  isMarkingAsReady: false,
  onMarkAsReady: () => {},
  isCompleting: false,
  onComplete: () => {},
  isArchiving: false,
  onArchive: () => {},
  initialValues: {}
};

export default PatientForm;
