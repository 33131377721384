import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { getDateDifferenceFromNow } from '../../../helpers';

const useStyles = makeStyles(theme => {
  return {
    container: {
      fontSize: theme.typography.pxToRem(12),
      display: 'flex',
      gap: theme.spacing(1.5),
      justifyContent: 'center'
    },
    period: {
      fontWeight: 600
    }
  };
});

const DefaultView = ({ timestamps }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {timestamps.map(({ shortLabel, date }) => {
        return (
          <div key={shortLabel}>
            <span className={classes.period}>{shortLabel}:</span> {date}
          </div>
        );
      })}
    </div>
  );
};

DefaultView.propTypes = {
  timestamps: PropTypes.arrayOf(
    PropTypes.shape({
      shortLabel: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired
    })
  ).isRequired
};

const useStylesCollapsed = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    period: {
      fontWeight: 600
    },
    item: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(12)
    },
    label: {
      fontWeight: 'bolder'
    },
    lapse: {},
    time: {}
  };
});

const formattedTimestamp = timestamp => {
  const time = moment(`${timestamp}Z`).local();

  return {
    date: time.format('YY-MMM-DD'),
    time: time.format('hh:mm A'),
    lapse: getDateDifferenceFromNow(timestamp)
  };
};

const DateColumn = ({ collapsed, started, modified, completed }) => {
  const classes = useStylesCollapsed();

  const timestamps = [];
  if (started) {
    timestamps.push({
      shortLabel: 'S',
      label: 'Started',
      ...formattedTimestamp(started)
    });
  }
  if (modified) {
    timestamps.push({
      shortLabel: 'M',
      label: 'Modified',
      ...formattedTimestamp(modified)
    });
  }
  if (completed) {
    timestamps.push({
      shortLabel: 'C',
      label: 'Completed',
      ...formattedTimestamp(completed)
    });
  }

  if (!collapsed) {
    return <DefaultView timestamps={timestamps} />;
  }

  return (
    <div className={classes.container}>
      {timestamps.map(({ label, date, time, lapse }) => {
        return (
          <div className={classes.item} key={label}>
            <div className={classes.label}>{label}</div>
            <div className={classes.date}>{date}</div>
            <div className={classes.time}>{time}</div>
            <div className={classes.lapse}>{lapse}</div>
          </div>
        );
      })}
    </div>
  );
};

DateColumn.defaultProps = {
  collapsed: false,
  started: null,
  modified: null,
  completed: null
};

DateColumn.propTypes = {
  collapsed: PropTypes.bool,
  started: PropTypes.string,
  modified: PropTypes.string,
  completed: PropTypes.string
};

export default DateColumn;
