import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { VERIFY_EMAIL_PREFIX, verifyEmailAction } from '../../reducers/auth';
import createLoadingSelector from '../../selectors/loading';
import LOGO from '../../assets/images/logo3x.png';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  avatar: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    width: '30vw',
    objectFit: 'contain',
    padding: '10px'
  },
  message: {
    margin: theme.spacing(3, 0)
  }
}));

const UserVerification = ({ location = {} }) => {
  const urlQueryParams = new URLSearchParams(location.search);
  const verificationCode = urlQueryParams.get('token');
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => createLoadingSelector([VERIFY_EMAIL_PREFIX])(state));
  const isVerified = useSelector(state => state.auth.isVerified);
  const isInvalidToken = useSelector(state => state.auth.isInvalidToken);
  const [message, setMessage] = useState('Verifying account...');

  useEffect(() => {
    if (verificationCode) {
      dispatch(verifyEmailAction(verificationCode));
    }
  }, [verificationCode, dispatch]);

  useEffect(() => {
    if (isVerified && !isInvalidToken) {
      setMessage('Logging you in...');
      window.location.href = '/';
    }

    if (isVerified && isInvalidToken) setMessage('There was an issue verifying your account.');
  }, [history, isVerified, isInvalidToken]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.avatar} src={LOGO} alt="Logo" />

        <Typography variant="h5" className={classes.message}>
          {message}
        </Typography>

        <Box height="80px">{isLoading && <CircularProgress />}</Box>
      </div>
    </Container>
  );
};

UserVerification.propTypes = {
  location: PropTypes.shape({})
};

UserVerification.defaultProps = {
  location: {}
};

export default UserVerification;
