const iosFileNameValidator = (name, existingFiles, patient) => {
  const lowercaseName = name.toLowerCase();
  const firstName = patient.first_name;
  const lastName = patient.last_name;
  if (
    lowercaseName.indexOf(firstName.toLowerCase()) === -1 ||
    lowercaseName.indexOf(lastName.toLowerCase()) === -1
  ) {
    const caseNo = patient.case_number ? `, ${patient.case_number}` : '';
    const fileExt = name.includes('.') ? `.${name.split('.').pop()}` : '';
    let suffixIndex = 0;
    let alreadyExists;
    let finalFileName;
    do {
      const suffix = suffixIndex ? ` (${suffixIndex})` : '';
      const fileName = `${lastName}, ${firstName}${caseNo}${suffix}${fileExt}`;
      alreadyExists = !!existingFiles.find(file => file.original_name === fileName);
      if (alreadyExists) {
        suffixIndex += 1;
      } else {
        finalFileName = fileName;
      }
    } while (alreadyExists);
    return finalFileName;
  }
  return name;
};

export default iosFileNameValidator;