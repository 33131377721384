import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { formatAccountNumber } from '../../../../../../../helpers';
import { ORGANIZATION_ROLE_OPTIONS, ROLE_OPTIONS } from '../../../../../../../constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 60,
    height: 60,
    margin: 'auto'
  },
  avatarColumn: {
    maxWidth: 100,
    marginTop: 16,
    marginRight: 0,
    marginLeft: 0
  },
  name: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold'
  },
  label: {
    lineHeight: 2,
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  title: {
    lineHeight: 2,
    fontSize: '11px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  bio: {
    textTransform: 'capitalize'
  },
  smallText: {
    fontSize: '10px'
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const currentUser = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const classes = useStyles();

  const user = {
    name: `${currentUser.first_name} ${currentUser.last_name}`,
    avatar: '',
    accountNumber: formatAccountNumber(currentUser.account_number),
    practiceName: currentUser.organization
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} className={classes.avatarColumn}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={user.avatar}
            to="/account"
          />
          <Typography className={classes.name} variant="subtitle1">
            {user.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isManagement && (
            <>
              <Typography className={classes.label} variant="caption">
                Role
              </Typography>
              <Typography variant="body2" className={classes.bio}>
                {ROLE_OPTIONS[currentUser.roles[0].name].label}
              </Typography>
            </>
          )}
          {!isManagement && currentOrganization && (
            <>
              <Typography className={classes.title} variant="caption" component="div">
                Organization Info
              </Typography>
              <Divider />
              <Typography className={classes.label} variant="caption">
                Name
              </Typography>
              <Typography variant="body2" className={classes.smallText}>
                {currentOrganization.name}
              </Typography>
              <Typography className={classes.label} variant="caption">
                Account Number
              </Typography>
              <Typography variant="body2">
                {formatAccountNumber(currentOrganization.account_number)}
              </Typography>
              <Typography className={classes.label} variant="caption">
                Role
              </Typography>
              <Typography variant="body2">
                {ORGANIZATION_ROLE_OPTIONS[currentOrganization.pivot.role.name].shortLabel}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

Profile.defaultProps = {
  className: ''
};

export default Profile;
