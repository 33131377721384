import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SubSectionWrapper, {
  SubSectionActionWrapper
} from '../../Account/components/SubSectionWrapper';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import LoadingButton from '../../../components/LoadingButton';
import createLoadingSelector from '../../../selectors/loading';
import {
  UN_ARCHIVE_ORGANIZATION_PREFIX,
  unArchiveOrganizationAction
} from '../../../reducers/organizations';
import { resetDashboardDataAction } from '../../../reducers/dashboard';

const UnArchiveOrganization = ({ organization }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUnArchivingOrganization = useSelector(state =>
    createLoadingSelector([UN_ARCHIVE_ORGANIZATION_PREFIX])(state)
  );

  const handleUnArchivingOrganization = () => {
    return dispatch(unArchiveOrganizationAction(organization.id)).then(response => {
      if (!response) return;
      dispatch(resetDashboardDataAction());
      history.go(0);
    });
  };

  if (!organization.deleted_at) {
    return null;
  }

  return (
    <SubSectionWrapper title="Restore Organization">
      <SubSectionActionWrapper>
        <ConfirmationDialogOnClickWrapper
          confirmationBody="Are you sure you want to restore this archived organization?"
          confirmationTitle="Restore Confirmation"
        >
          <LoadingButton
            type="button"
            variant="contained"
            color="secondary"
            loading={isUnArchivingOrganization}
            disabled={isUnArchivingOrganization}
            onClick={handleUnArchivingOrganization}
          >
            Restore
          </LoadingButton>
        </ConfirmationDialogOnClickWrapper>
      </SubSectionActionWrapper>
    </SubSectionWrapper>
  );
};

UnArchiveOrganization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

UnArchiveOrganization.defaultProps = {};

export default UnArchiveOrganization;
