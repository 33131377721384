import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  spacing: {
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(5)
  }
}));

const LoadingIndicator = ({ withSpacing, ...props }) => {
  const classes = useStyles();

  const classNames = [classes.loadingIndicator];
  if (withSpacing) {
    classNames.push(classes.spacing);
  }

  return (
    <div className={classNames.join(' ')}>
      <CircularProgress {...props} />
    </div>
  );
};

LoadingIndicator.defaultProps = {
  withSpacing: true
};

LoadingIndicator.propTypes = {
  withSpacing: PropTypes.bool
};

export default LoadingIndicator;
