import moment from 'moment/moment';
import _ from 'lodash';
import React from 'react';

const dateAttributes = ['joined_at', 'first_case_submission', 'newest_case_submission'];

export const isDateAttribute = (attribute, value) =>
  dateAttributes.includes(attribute) &&
  moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ', true).isValid();

const formatDateAttribute = date => {
  const momentDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ', true);
  return `${momentDate.format('ddd, MMMM Do YYYY')} (${momentDate.fromNow()})`;
};

export const formatHitValue = (value, attribute) => {
  // Empty, null, undefined values
  if (
    _.isEmpty(value) ||
    value === 'null' ||
    value === 'undefined' ||
    value === '[]' ||
    value === '{}'
  ) {
    return 'N/A';
  }

  if (_.startsWith(value, '[')) return JSON.parse(value);

  if (isDateAttribute(attribute, value)) return formatDateAttribute(value);

  if (_.isArray(value)) {
    return (
      <ul>
        {_.map(value, item => (
          <li key={Math.random()}>{item}</li>
        ))}
      </ul>
    );
  }
  return value;
};
