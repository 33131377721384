import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PeriodicRuleApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    includes = [],
    selectedStatus = '',
    contractId = ''
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for('periodic_rules')
        .include(...includes)
        .where('is_active', selectedStatus)
        .where('contract_id', contractId)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/periodic_rules/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/periodic_rules',
      data
    });
  }

  update(data, id) {
    return this.request({
      method: 'put',
      url: `/periodic_rules/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/periodic_rules/${id}`
    });
  }
}
