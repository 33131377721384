import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
  ATTRIBUTES,
  PRODUCTS_LIST_METHODS,
  PRODUCTS_LIST_VALUES,
  ARCH_MODE_OPTIONS,
  ARCH_MODES
} from '../constants';
import { decodeFormikValues } from '../helpers';

import RadioGroupField from './RadioGroupField';

const modesToExclude = new Set([ARCH_MODES.FULL, ARCH_MODES.LOW]);

const ArchModeField = ({ productList, currentValue, setValue, isSubmitting, ...props }) => {
  const roughStorageOrCleanup =
    PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.ROUGH_DIGITAL_MODEL] |
    PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.DIGITAL_STORAGE_ONLY] |
    PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.CLEANUP];

  let options = ARCH_MODE_OPTIONS;
  if ((productList & roughStorageOrCleanup) > 0) {
    options = options.filter(item => !modesToExclude.has(parseInt(item.value)));
  }

  useEffect(() => {
    if ((productList & roughStorageOrCleanup) > 0) {
      if (modesToExclude.has(currentValue)) {
        setValue(ARCH_MODES.HORSESHOE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  return (
    <>
      <RadioGroupField
        options={options}
        isSubmitting={isSubmitting}
        name={ATTRIBUTES.ARCH_MODE}
        {...props}
      />
    </>
  );
};

ArchModeField.propTypes = {
  currentValue: PropTypes.number.isRequired,
  productList: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired
};

export default connect(({ formik: { values: encodeValues, setFieldValue }, ...props }) => {
  const values = decodeFormikValues(encodeValues);
  const currentValue = values[ATTRIBUTES.ARCH_MODE];
  const productList = values[ATTRIBUTES.PRODUCTS_LIST_METHOD];

  return (
    <ArchModeField
      setValue={value => setFieldValue(ATTRIBUTES.ARCH_MODE, String(value))}
      currentValue={currentValue}
      productList={productList}
      {...props}
    />
  );
});
