import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import IOS3DViewerWarning from '../../../components/IOD3DViewerWarning';
import { openIOS3DViewerWarning } from '../../../reducers/modals';
import { create3DViewerURI } from '../../../helpers/urls';

const IOSFileActions = ({ iosFile, patient }) => {
  const dispatch = useDispatch();

  const handleOpenFile = file => {
    if (file.viewer_id) {
      window.open(create3DViewerURI(iosFile, patient), '_blank');
    } else {
      dispatch(openIOS3DViewerWarning());
    }
  };

  const handleDownloadFile = id => {
    dispatch(fetchDownloadUrlPrintingFileAction(id));
  };

  if (!iosFile || !iosFile.id) {
    return <span>Invalid IOS file provided</span>;
  }

  return (
    <Grid container>
      <IconButton color="inherit" key="open" onClick={() => handleOpenFile(iosFile)}>
        <LaunchIcon />
      </IconButton>

      <IconButton
        color="inherit"
        title="Download File"
        key="download"
        onClick={() => handleDownloadFile(iosFile.id)}
      >
        <CloudDownloadIcon />
      </IconButton>

      <IOS3DViewerWarning />
    </Grid>
  );
};

IOSFileActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  iosFile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

IOSFileActions.defaultProps = {};

export default IOSFileActions;
