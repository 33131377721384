import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import LoadingButton from '../LoadingButton';

const SimpleMenu = ({ options, onOptionSelected, buttonTitle, isLoading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickOption = option => {
    handleClose();
    onOptionSelected(option);
  };

  return (
    <>
      <LoadingButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        size="small"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
      >
        {buttonTitle}
      </LoadingButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option.label} onClick={() => onClickOption(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SimpleMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  onOptionSelected: PropTypes.func,
  buttonTitle: PropTypes.string,
  isLoading: PropTypes.bool
};

SimpleMenu.defaultProps = {
  options: [],
  onOptionSelected: () => {},
  buttonTitle: 'Actions',
  isLoading: false
};

export default SimpleMenu;
