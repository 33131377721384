import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Table } from 'antd';
import { produce } from 'immer';
import Box from '@material-ui/core/Box';
import { isEqual } from 'lodash';
import Typography from '@material-ui/core/Typography';
import EditableCell from './EditableCell';
import LoadingButton from '../LoadingButton';
import { productionTime } from '../../helpers/techWorkflow';

const useStyles = makeStyles(() => ({
  table: {
    '& .ant-table': {
      fontSize: 12
    },
    '& .ant-table-cell': {
      padding: 2,
      wordBreak: 'break-all',
      '& .ant-input': {
        lineHeight: 0,
        fontSize: 12,
        padding: 0
      },
      '& .ant-picker': {
        lineHeight: 0,
        fontSize: 8,
        padding: 0,
        '& input': {
          fontSize: 12
        }
      }
    }
  }
}));

const Workflow = ({ title, workflowData, columnsData, isSaving, save, readOnly }) => {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(workflowData);
  const components = {
    body: {
      cell: EditableCell
    }
  };

  const handleSave = row => {
    setDataSource(
      produce(dataSource, draft => {
        const index = dataSource.findIndex(item => row.id === item.id);
        draft[index] = {
          ...row,
          production_time: productionTime(row.started_at, row.finished_at)
        };
      })
    );
  };

  const columns = columnsData.map(col => {
    let { editable } = col;
    if (readOnly) {
      editable = false;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        handleSave,
        deletable: col.deletable ? col.deletable : false
      })
    };
  });

  if (!workflowData.length) {
    return null;
  }

  return (
    <>
      <Box my={1}>
        <Typography>{title}</Typography>
      </Box>
      <Table
        components={components}
        rowClassName={classes.row}
        cellClassName={classes.cell}
        bordered
        rowKey="id"
        dataSource={dataSource}
        pagination={false}
        columns={columns}
        className={classes.table}
      />
      {!readOnly && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <LoadingButton
            color="primary"
            variant="contained"
            className={classes.hideOnPrint}
            onClick={() => save(dataSource)}
            loading={isSaving}
            disabled={isSaving || isEqual(dataSource, workflowData)}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

Workflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workflowData: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnsData: PropTypes.array.isRequired,
  isSaving: PropTypes.bool,
  save: PropTypes.func,
  title: PropTypes.string,
  readOnly: PropTypes.bool
};

Workflow.defaultProps = {
  isSaving: false,
  save: () => {},
  title: '',
  readOnly: false
};

export default Workflow;
