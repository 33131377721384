import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import SubSectionWrapper from '../../../Account/components/SubSectionWrapper';
import General from './General';
import Credits from './Credits';
import PeriodicRules from './PeriodicRules';

const Main = ({ organization }) => {
  return (
    <SubSectionWrapper title="Customize Fees">
      <Box width="100%">
        <General organization={organization} />
        <Credits organization={organization} />
        {organization.contract && <PeriodicRules contract={organization.contract} />}
      </Box>
    </SubSectionWrapper>
  );
};

Main.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default Main;
