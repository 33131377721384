import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from '../../../selectors/loading';
import BillingAddress from '../../../components/BillingAddress';
import {
  UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX,
  updateCurrentOrganizationBillingAddress
} from '../../../reducers/auth';
import SubSectionWrapper from './SubSectionWrapper';

const CurrentOrganizationBillingAddress = () => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const isSaving = useSelector(state =>
    createLoadingSelector([UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX])(state)
  );

  const updateBillingAddress = values => {
    dispatch(updateCurrentOrganizationBillingAddress(currentOrganization.id, { ...values }));
    return true;
  };

  if (!currentOrganization || !currentOrganization.payment_info) {
    return null;
  }

  return (
    <SubSectionWrapper title="Billing Address">
      <BillingAddress
        onSubmit={updateBillingAddress}
        billingAddress={currentOrganization.payment_info.billing_address}
        isSaving={isSaving}
      />
    </SubSectionWrapper>
  );
};

export default CurrentOrganizationBillingAddress;
