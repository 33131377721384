import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/Business';
import Chip from '@material-ui/core/Chip';
import { Box, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setIsOpenOrganizationTopBar } from '../../../../../reducers/auth';

const OrganizationsSelector = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);

  const handleOpenOrganizationSelector = () => {
    dispatch(setIsOpenOrganizationTopBar(true));
  };

  const openOrganizationDetails = id => {
    history.push(`/organizations/${id}`);
  };

  return (
    <Box ml={2}>
      {selectedOrganizations.length === 0 && <span>No Organizations Selected</span>}
      {selectedOrganizations.length === 1 && (
        <Chip
          icon={<BusinessIcon />}
          label={selectedOrganizations[0].name}
          onClick={() => openOrganizationDetails(selectedOrganizations[0].id)}
        />
      )}
      {selectedOrganizations.length > 1 && <span>Some Organizations Selected</span>}
      <IconButton color="inherit" title="Organizations" onClick={handleOpenOrganizationSelector}>
        <ExpandMoreIcon />
      </IconButton>
    </Box>
  );
};

OrganizationsSelector.propTypes = {};

OrganizationsSelector.defaultProps = {};

export default OrganizationsSelector;
