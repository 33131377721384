import { createSelector } from 'reselect';

const createGetItemSelector = createSelector(
  selectedState => selectedState.items,
  (_, itemId) => itemId,
  (stateItems, itemId) => {
    return stateItems.find(item => item.id === itemId);
  }
);

export default createGetItemSelector;
