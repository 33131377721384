export default {
  New: {
    label: 'New',
    filter: 'New'
  },
  Old: {
    label: 'Old',
    filter: 'Old'
  }
};
