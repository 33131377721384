import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connectRefinementList } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { updateRefinements } from '../../../reducers/globalSearch';

const useStyles = makeStyles(() => ({
  dropdown: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

/**
 * By default only 10 dropdown items are supported according to the library behaviour
 * https://www.algolia.com/doc/api-reference/widgets/refinement-list/js/#connector-param-init-limit
 * If there are more than 10 items, use the limit attribute to override the default value
 */
const DropdownRefinementList = ({ items, currentRefinement, options, attribute, onClick }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const toggleRefine = (e, option) => {
    e.preventDefault();

    const removeOption = currentRefinement.find(refinement => refinement === option.filter);

    const updatedRefinements = removeOption
      ? currentRefinement.filter(refinement => refinement !== option.filter)
      : [...currentRefinement, option.filter];

    onClick(updatedRefinements);
    dispatch(updateRefinements(attribute, updatedRefinements));
  };

  return (
    <Select
      multiple
      value={currentRefinement || []}
      // onChange={handleChange}
      input={<Input id="select-multiple-chip" disableUnderline />}
      renderValue={selected => (
        <div className={classes.chips}>
          {selected.map(value => {
            const item = _.find(options, i => i.filter === value);
            return <Chip key={value} label={item ? item.label : value} className={classes.chip} />;
          })}
        </div>
      )}
      MenuProps={MenuProps}
      className={classes.dropdown}
    >
      {_.map(options, (option, key) => {
        const item = _.find(items, i => i.label === key);

        return (
          <MenuItem
            className="ais-RefinementList-item"
            key={key}
            value={key}
            onClick={e => toggleRefine(e, option)}
          >
            <Checkbox key={`${key}_chk`} checked={item ? item.isRefined : false} />
            <ListItemText key={`${key}_list`} primary={option.label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

DropdownRefinementList.propTypes = {
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  options: PropTypes.objectOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  currentRefinement: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

DropdownRefinementList.defaultProps = {
  options: {},
  onClick: () => {}
};

export default connectRefinementList(DropdownRefinementList);
