/* eslint-disable react/prop-types */
import React from 'react';
import { Div, Img } from 'react-images/lib/primitives';
import { className } from 'react-images/lib/utils';
import { getSource } from 'react-images/lib/components/component-helpers';

function svgPlaceholder(width, height) {
  return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
}

export default function CustomFullScreenImagesView(props) {
  const { data, formatters, getStyles, index, currentIndex, isFullscreen, isModal } = props;
  const overscan = 0;
  const inBounds = Math.abs(currentIndex - index) <= overscan;

  const getImageSource = () => {
    return getSource({ data, isFullscreen });
  };

  const innerProps = {
    alt: formatters.getAltText({ data, index }),
    src: inBounds ? getImageSource() : svgPlaceholder(data.width, data.height)
  };

  return (
    <Div css={getStyles('view', props)} className={className('view', { isFullscreen, isModal })}>
      <Img
        {...innerProps}
        className={className('view-image', { isFullscreen, isModal })}
        css={{
          height: 'auto',
          maxHeight: '100vh',
          maxWidth: '100vw',
          userSelect: 'none'
        }}
      />
    </Div>
  );
}
