import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUserAction, setIsCurrentOrganizationUpdating } from '../reducers/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    position: 'relative'
  }
}));

function Viewer3D({
  match: {
    params: { viewerId, orgID, patID, name }
  }
}) {
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (currentOrganization && currentOrganization.id !== parseInt(orgID)) {
      dispatch(setIsCurrentOrganizationUpdating(true));
      dispatch(fetchCurrentUserAction(parseInt(orgID))).then(() => {
        dispatch(setIsCurrentOrganizationUpdating(false));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orgID]);

  //resize happens automatically
  return (
    <div className={classes.layout}>
      <h5 style={{ margin: 0 }}>
        Viewing IOS file for <a href={`/patient-details/${patID}`}>{name}</a>
      </h5>
      <iframe
        title="3D Viewer"
        id={`frame3D-${viewerId}`}
        style={{ position: 'absolute', height: '90vh', width: '100%', border: 'none' }}
        src={`${process.env.REACT_APP_DSM_API_URL}?viewerId=${viewerId}&method=viewModel`}
      ></iframe>
    </div>
  );
}

export default Viewer3D;
