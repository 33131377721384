import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class AnnouncementApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get() {
    return this.request({
      method: 'get',
      url: 'settings/announcements'
    });
  }

  show() {
    return this.request({
      method: 'get',
      url: 'settings/announcement'
    });
  }

  update(id, body) {
    return this.request({
      method: 'put',
      data: { body },
      url: `settings/announcements/${id}`
    });
  }
}
