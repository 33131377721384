import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../ConfirmationDialog';
import { closePatientModificationWarning } from '../../reducers/modals';

const PatientModificationWarning = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modals.IS_OPEN_PATIENT_MODIFICATION_WARNING);
  const modalId = useSelector(state => state.modals.PATIENT_MODIFICATION_WARNING_ID);

  const handleClose = answer => {
    dispatch(closePatientModificationWarning(id));

    if (onClose) {
      onClose(answer);
    }
  };

  return (
    <ConfirmationDialog
      open={isOpen && modalId === id}
      handleClose={handleClose}
      title=""
      bodyText="This case has already been approved. If you open and modify this case, any changes made will not be implemented."
    />
  );
};

PatientModificationWarning.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PatientModificationWarning;
