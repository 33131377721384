import { enqueueNotification } from './notifications';
import { AuthApiService } from '../services';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_GLOBAL_SETTINGS_PREFIX = 'FETCH_GLOBAL_SETTINGS';
export const FETCH_GLOBAL_SETTINGS_REQUEST_ACTION = `${FETCH_GLOBAL_SETTINGS_PREFIX}_REQUEST_ACTION`;
export const FETCH_GLOBAL_SETTINGS_SUCCESS_ACTION = `${FETCH_GLOBAL_SETTINGS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_GLOBAL_SETTINGS_FAILURE_ACTION = `${FETCH_GLOBAL_SETTINGS_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  global: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_SETTINGS_SUCCESS_ACTION:
      return {
        ...state,
        global: action.payload
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchGlobalSettingsAction() {
  return dispatch => {
    dispatch({ type: FETCH_GLOBAL_SETTINGS_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .getGlobalSettings()
      .then(response => {
        dispatch({ type: FETCH_GLOBAL_SETTINGS_SUCCESS_ACTION, payload: response.settings });
      })
      .catch(error => {
        dispatch({
          type: FETCH_GLOBAL_SETTINGS_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
