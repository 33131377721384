import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import PatientFilesApiService from '../services/api/patientFiles';
import RequestsApiService from '../services/api/requests';
import RxFormsApiService from '../services/api/rxForms';
import ServiceManager from '../services/api/serviceManager';
import PatientApiService from '../services/api/patients';
import TechWorkflowApiService from '../services/api/techWorkflow';
import techWorkflowTypes from '../constants/techWorkflowTypes';
import { ASSIGNMENTS } from '../components/RxForm/constants';
import caseTrayHelpers from '../pages/CaseTrays/helpers';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PATIENTS_PREFIX = 'FETCH_PATIENTS';
export const FETCH_PATIENTS_REQUEST_ACTION = `${FETCH_PATIENTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_PATIENTS_SUCCESS_ACTION = `${FETCH_PATIENTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PATIENTS_FAILURE_ACTION = `${FETCH_PATIENTS_PREFIX}_FAILURE_ACTION`;
export const SET_PATIENTS_INITIAL_PARAMETERS = `SET_PATIENTS_INITIAL_PARAMETERS`;

export const FETCH_PATIENT_PREFIX = 'FETCH_PATIENT';
export const FETCH_PATIENT_REQUEST_ACTION = `${FETCH_PATIENT_PREFIX}_REQUEST_ACTION`;
export const FETCH_PATIENT_SUCCESS_ACTION = `${FETCH_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PATIENT_FAILURE_ACTION = `${FETCH_PATIENT_PREFIX}_FAILURE_ACTION`;

export const FETCH_PATIENT_FILES_DOWNLOAD_URLS_PREFIX = 'FETCH_PATIENT_FILES_DOWNLOAD_URLS';
export const FETCH_PATIENT_FILES_DOWNLOAD_URLS_REQUEST_ACTION = `${FETCH_PATIENT_FILES_DOWNLOAD_URLS_PREFIX}_REQUEST_ACTION`;
export const FETCH_PATIENT_FILES_DOWNLOAD_URLS_SUCCESS_ACTION = `${FETCH_PATIENT_FILES_DOWNLOAD_URLS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PATIENT_FILES_DOWNLOAD_URLS_FAILURE_ACTION = `${FETCH_PATIENT_FILES_DOWNLOAD_URLS_PREFIX}_FAILURE_ACTION`;

export const CREATE_PATIENT_PREFIX = 'CREATE_PATIENT';
export const CREATE_PATIENT_REQUEST_ACTION = `${CREATE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const CREATE_PATIENT_SUCCESS_ACTION = `${CREATE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const CREATE_PATIENT_FAILURE_ACTION = `${CREATE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PATIENT_PREFIX = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_REQUEST_ACTION = `${UPDATE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PATIENT_SUCCESS_ACTION = `${UPDATE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PATIENT_FAILURE_ACTION = `${UPDATE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PATIENT_FIELD_PREFIX = 'UPDATE_PATIENT_FIELD';
export const UPDATE_PATIENT_FIELD_REQUEST_ACTION = `${UPDATE_PATIENT_FIELD_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PATIENT_FIELD_SUCCESS_ACTION = `${UPDATE_PATIENT_FIELD_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PATIENT_FIELD_FAILURE_ACTION = `${UPDATE_PATIENT_FIELD_PREFIX}_FAILURE_ACTION`;

export const DELETE_PATIENT_PREFIX = 'DELETE_PATIENT';
export const DELETE_PATIENT_REQUEST_ACTION = `${DELETE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const DELETE_PATIENT_SUCCESS_ACTION = `${DELETE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const DELETE_PATIENT_FAILURE_ACTION = `${DELETE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const RESTORE_PATIENT_PREFIX = 'RESTORE_PATIENT';
export const RESTORE_PATIENT_REQUEST_ACTION = `${RESTORE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const RESTORE_PATIENT_SUCCESS_ACTION = `${RESTORE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const RESTORE_PATIENT_FAILURE_ACTION = `${RESTORE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const HOLD_PATIENT_PREFIX = 'HOLD_PATIENT';
export const HOLD_PATIENT_REQUEST_ACTION = `${HOLD_PATIENT_PREFIX}_REQUEST_ACTION`;
export const HOLD_PATIENT_SUCCESS_ACTION = `${HOLD_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const HOLD_PATIENT_FAILURE_ACTION = `${HOLD_PATIENT_PREFIX}_FAILURE_ACTION`;

export const COMPLETE_PATIENT_PREFIX = 'COMPLETE_PATIENT';
export const COMPLETE_PATIENT_REQUEST_ACTION = `${COMPLETE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const COMPLETE_PATIENT_SUCCESS_ACTION = `${COMPLETE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const COMPLETE_PATIENT_FAILURE_ACTION = `${COMPLETE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const DELETE_PATIENT_FILE_PREFIX = 'DELETE_PATIENT_FILE';
export const DELETE_PATIENT_FILE_REQUEST_ACTION = `${DELETE_PATIENT_FILE_PREFIX}_REQUEST_ACTION`;
export const DELETE_PATIENT_FILE_SUCCESS_ACTION = `${DELETE_PATIENT_FILE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_PATIENT_FILE_FAILURE_ACTION = `${DELETE_PATIENT_FILE_PREFIX}_FAILURE_ACTION`;

export const UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_PREFIX =
  'UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT';
export const UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_REQUEST_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_REQUEST_ACTION`;
export const UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_FAILURE_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_FAILURE_ACTION`;

export const UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_PREFIX =
  'UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_PREFIX';
export const UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_REQUEST_ACTION = `${UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_REQUEST_ACTION`;
export const UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION = `${UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_FAILURE_ACTION = `${UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_PREFIX}_FAILURE_ACTION`;

export const ASSIGN_RX_FORM_PATIENT_PREFIX = 'ASSIGN_RX_FORM_PATIENT';
export const ASSIGN_RX_FORM_PATIENT_REQUEST_ACTION = `${ASSIGN_RX_FORM_PATIENT_PREFIX}_REQUEST_ACTION`;
export const ASSIGN_RX_FORM_PATIENT_SUCCESS_ACTION = `${ASSIGN_RX_FORM_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const ASSIGN_RX_FORM_PATIENT_FAILURE_ACTION = `${ASSIGN_RX_FORM_PATIENT_PREFIX}_FAILURE_ACTION`;

export const ADD_PATIENT_FILE_ACTION = 'ADD_PATIENT_FILE_ACTION';
export const REMOVE_PATIENT_FILE_ACTION = 'REMOVE_PATIENT_FILE_ACTION';

export const UPDATE_PATIENT_RX_FORM_ACTION = 'UPDATE_PATIENT_RX_FORM_ACTION';
export const RESET_PATIENTS = 'RESET_PATIENTS';

export const SET_PATIENTS_GLOBAL_PARAMS = 'SET_PATIENTS_GLOBAL_PARAMS';

export const ARCHIVE_PATIENT_PREFIX = 'ARCHIVE_PATIENT';
export const ARCHIVE_PATIENT_REQUEST_ACTION = `${ARCHIVE_PATIENT_PREFIX}_REQUEST_ACTION`;
export const ARCHIVE_PATIENT_SUCCESS_ACTION = `${ARCHIVE_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const ARCHIVE_PATIENT_FAILURE_ACTION = `${ARCHIVE_PATIENT_PREFIX}_FAILURE_ACTION`;

export const PATIENT_COUNT_PER_STATUS_PREFIX = 'PATIENT_COUNT_PER_STATUS';
export const PATIENT_COUNT_PER_STATUS_REQUEST_ACTION = `${PATIENT_COUNT_PER_STATUS_PREFIX}_REQUEST_ACTION`;
export const PATIENT_COUNT_PER_STATUS_SUCCESS_ACTION = `${PATIENT_COUNT_PER_STATUS_PREFIX}_SUCCESS_ACTION`;
export const PATIENT_COUNT_PER_STATUS_FAILURE_ACTION = `${PATIENT_COUNT_PER_STATUS_PREFIX}_FAILURE_ACTION`;

export const UPDATE_TECH_WORKFLOW_PROCESSES_PREFIX = 'UPDATE_TECH_WORKFLOW_PROCESSES';
export const UPDATE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION = `${UPDATE_TECH_WORKFLOW_PROCESSES_PREFIX}_REQUEST_ACTION`;
export const UPDATE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION = `${UPDATE_TECH_WORKFLOW_PROCESSES_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_TECH_WORKFLOW_PROCESSES_FAILURE_ACTION = `${UPDATE_TECH_WORKFLOW_PROCESSES_PREFIX}_FAILURE_ACTION`;

export const CLOSE_PATIENT_LABEL = 'CLOSE_PATIENT_LABEL';
export const SHOW_PATIENT_LABEL = 'SHOW_PATIENT_LABEL';
export const SHOW_SHIPPING_LABEL = 'SHOW_SHIPPING_LABEL';
export const CLOSE_SHIPPING_LABEL = 'CLOSE_SHIPPING_LABEL';
export const SELECT_PATIENS_FOR_BUNDLING = 'SELECT_PATIENS_FOR_BUNDLING';
export const CANCEL_SELECTION_FOR_BUNDLING_PATIENTS = 'CANCEL_SELECTION_FOR_BUNDLING_PATIENTS';

export const ADD_CHARGE_TO_PATIENT_PREFIX = 'ADD_CHARGE_TO_PATIENT_PREFIX';
export const ADD_CHARGE_TO_PATIENT_REQUEST_ACTION = `${ADD_CHARGE_TO_PATIENT_PREFIX}_REQUEST_ACTION`;
export const ADD_CHARGE_TO_PATIENT_SUCCESS_ACTION = `${ADD_CHARGE_TO_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const ADD_CHARGE_TO_PATIENT_FAILURE_ACTION = `${ADD_CHARGE_TO_PATIENT_PREFIX}_FAILURE_ACTION`;

export const REMOVE_CHARGE_FROM_PATIENT_PREFIX = 'REMOVE_CHARGE_FROM_PATIENT';
export const REMOVE_CHARGE_FROM_PATIENT_REQUEST_ACTION = `${REMOVE_CHARGE_FROM_PATIENT_PREFIX}_REQUEST_ACTION`;
export const REMOVE_CHARGE_FROM_PATIENT_SUCCESS_ACTION = `${REMOVE_CHARGE_FROM_PATIENT_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_CHARGE_FROM_PATIENT_FAILURE_ACTION = `${REMOVE_CHARGE_FROM_PATIENT_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

export const initialState = {
  items: [],
  selection: {
    patientLabel: null,
    shippingLabel: null,
    bundleShipment: null
  },
  loadingItems: false,
  requestSteps: {
    request: null,
    data: []
  },
  total: 0,
  patientTypes: [],
  customGlobalParams: null,
  globalParams: {
    order: 'asc',
    orderBy: 'ship_date_sort',
    onlyDeleted: false,
    onlyOnhold: 'without',
    activeRequestOnly: true,
    archivedOnly: false,
    rushCaseOnly: false,
    shipping: null,
    patientStatusId: '',
    newPatientStatus: '',
    page: 1,
    perPage: 25,
    searchFullNameQuery: '',
    searchDoctorFullNameQuery: '',
    searchDoctorFullNameOrOrganizationQuery: '',
    customerServiceManager: '',
    requestTypeStatus: [],
    requestStep: null,
    shippingTypeFilter: [],
    shipDateFrom: '',
    shipDateTo: '',
    locations: [],
    completedCaseDateFrom: '',
    completedCaseDateTo: '',
    createdDateFrom: '',
    createdDateTo: '',
    cancelled: '',
    stats: null,
    organizationId: null,
    userId: null,
    shippedDateFrom: null,
    shippedDateTo: null,
    requestAssignments: [ASSIGNMENTS.ORTHOSELECT],
    trackingNumber: '',
    impressionType: null,
    productsList: null
  },
  patientCountsPerStatus: {
    approved: 0,
    awaiting_approval: 0,
    completed: 0,
    in_progress: 0,
    new: 0,
    all: 0
  },
  currentRequest: {
    list: {
      cancel: null
    }
  }
};

export const extractRequestType = ({ requestTypeStatus }) => {
  const requestTypeFromStatus = requestTypeStatus.length
    ? requestTypeStatus[0].split('.')[0]
    : null;

  return requestTypeFromStatus || null;
};

const refineParamsBeforeRequest = (currentParams, params) => {
  const currentRequest = extractRequestType(currentParams);
  const request = extractRequestType(params);
  const override = {};
  if (params.requestStep && (currentRequest !== request || !request)) {
    override.requestStep = null;
  }
  if (Object.keys(override).length === 0) {
    return params;
  }
  return {
    ...params,
    ...override
  };
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PATIENTS_INITIAL_PARAMETERS:
      return produce(state, draft => {
        draft.customGlobalParams = action.payload;
        draft.globalParams = {
          ...state.globalParams,
          ...action.payload
        };
      });

    case FETCH_PATIENTS_REQUEST_ACTION:
      return produce(state, draft => {
        const requestType = extractRequestType(action.payload.params);
        draft.requestSteps.request = requestType;
        draft.requestSteps.data =
          requestType !== null && requestType === state.requestSteps.request
            ? state.requestSteps.data
            : [];
        draft.globalParams = action.payload.params;
        draft.loadingItems = true;
        draft.customGlobalParamsInitialized = true;
        draft.currentRequest.list.cancel = action.payload.cancelFetching;
      });

    case FETCH_PATIENTS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.requestSteps.data = action.payload.requestSteps || [];
        draft.items = action.payload.patients.data;
        draft.total = action.payload.patients.total;
        draft.loadingItems = false;
        draft.currentRequest.list.cancel = null;
      });

    case FETCH_PATIENTS_FAILURE_ACTION:
      return produce(state, draft => {
        draft.currentRequest.list.cancel = null;
      });

    case FETCH_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (patientIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.unshift(action.payload);
      });
    case UPDATE_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case UPDATE_PATIENT_FIELD_SUCCESS_ACTION:
      return produce(state, draft => {
        let fields = action.field;
        if (!Array.isArray(action.field)) {
          fields = [action.field];
        }
        fields.forEach(field => {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)][field] =
            action.payload[field];
        });
      });
    case DELETE_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case ARCHIVE_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case HOLD_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[patientIndex].is_on_hold = action.payload.is_on_hold;
        draft.items[patientIndex].on_hold_notes = action.payload.on_hold_notes;
      });
    case FETCH_PATIENT_FILES_DOWNLOAD_URLS_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.patientId);

        if (state.items[patientIndex].files) {
          draft.items[patientIndex].files[action.fileTypeId] = action.payload;
        } else {
          draft.items[patientIndex].files = {
            [action.fileTypeId]: action.payload
          };
        }
      });
    case DELETE_PATIENT_FILE_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        if (
          state.items[patientIndex].files &&
          state.items[patientIndex].files[action.payload.fileTypeId]
        ) {
          const fileIndex = state.items[patientIndex].files[action.payload.fileTypeId].findIndex(
            file => file.id === action.payload.id
          );
          if (fileIndex > -1) {
            draft.items[patientIndex].files[action.payload.fileTypeId].splice(fileIndex, 1);
          }
        }
        const patientFileIndex = state.items[patientIndex].patient_files.findIndex(
          file => file.id === action.payload.id
        );
        draft.items[patientIndex].patient_files.splice(patientFileIndex, 1);
      });
    case ADD_PATIENT_FILE_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        draft.items[patientIndex].files = state.items[patientIndex].files || {};
        draft.items[patientIndex].files[action.payload.fileTypeId] =
          draft.items[patientIndex].files[action.payload.fileTypeId] || [];
        draft.items[patientIndex].files[action.payload.fileTypeId].push(action.payload.file);
        draft.items[patientIndex].patient_files.push(action.payload.file);
      });
    case REMOVE_PATIENT_FILE_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        const fileIndex = state.items[patientIndex].files[action.payload.fileTypeId].findIndex(
          file => file.id === action.payload.id
        );
        draft.items[patientIndex].files[action.payload.fileTypeId].splice(fileIndex, 1);
        const patientFileIndex = state.items[patientIndex].patient_files.findIndex(
          file => file.id === action.payload.id
        );
        draft.items[patientIndex].patient_files.splice(patientFileIndex, 1);
      });
    case UPDATE_PATIENT_RX_FORM_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        draft.items[patientIndex].rx_form = action.payload.rxForm;
      });
    case ASSIGN_RX_FORM_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        draft.items[patientIndex].rx_form.submission_completed_by = action.payload.assignToId;
      });
    case UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        draft.items[patientIndex].customer_request.customer_request_management_data =
          action.payload.requestManagementData;
      });
    case UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        draft.items[patientIndex].rx_form.rx_form_management_data =
          action.payload.rxFormManagementData;
      });
    case UPDATE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION:
      return produce(state, draft => {
        const patientIndex = state.items.findIndex(item => item.id === action.payload.patientId);
        switch (action.payload.type) {
          case techWorkflowTypes.INITIAL_CASE_SETUP:
            draft.items[patientIndex].rx_form.tech_workflow_initial_case_setup.processes =
              action.payload.processes;
            break;
          case techWorkflowTypes.FLAWED_TRAY:
            draft.items[patientIndex].customer_request.tech_workflow_flawed_tray.processes =
              action.payload.processes;
            break;
          case techWorkflowTypes.PRINTING_REQUEST:
            draft.items[
              patientIndex
            ].customer_request.printing_request.tech_workflow_printing_request.processes =
              action.payload.processes;
            break;
          default:
            break;
        }
      });
    case RESET_PATIENTS:
      return initialState;
    case SET_PATIENTS_GLOBAL_PARAMS:
      return {
        ...state,
        globalParams: {
          ...state.globalParams,
          ...action.payload.params
        }
      };
    case PATIENT_COUNT_PER_STATUS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.patientCountsPerStatus = { ...action.payload };
      });
    case SHOW_PATIENT_LABEL:
      return produce(state, draft => {
        draft.selection.patientLabel = action.payload;
      });
    case CLOSE_PATIENT_LABEL:
      return produce(state, draft => {
        draft.selection.patientLabel = null;
      });
    case SHOW_SHIPPING_LABEL:
      return produce(state, draft => {
        draft.selection.shippingLabel = action.payload;
      });
    case CLOSE_SHIPPING_LABEL:
      return produce(state, draft => {
        draft.selection.shippingLabel = null;
      });
    case SELECT_PATIENS_FOR_BUNDLING:
      return produce(state, draft => {
        draft.selection.bundleShipment = action.payload;
      });
    case CANCEL_SELECTION_FOR_BUNDLING_PATIENTS:
      return produce(state, draft => {
        draft.selection.bundleShipment = null;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchPatientsAction(params = {}, temporaryParams = {}) {
  return (dispatch, getState) => {
    const currentParams = getState().patients.globalParams;

    const refinedParams = refineParamsBeforeRequest(currentParams, params);
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    const cancelFetching = patientsService.getCancelRequestFn();
    const { currentRequest } = getState().patients;
    if (currentRequest.list.cancel !== null) {
      currentRequest.list.cancel();
    }

    dispatch({
      type: FETCH_PATIENTS_REQUEST_ACTION,
      payload: { params: refinedParams, cancelFetching }
    });

    return patientsService
      .get({ ...refinedParams, ...temporaryParams })
      .then(res => {
        const response = { ...res };

        if (temporaryParams.resource_type === 'trays') {
          response.patients.data = caseTrayHelpers.parseCaseTraysAsPatients(response.patients.data);
        }

        dispatch({
          type: FETCH_PATIENTS_SUCCESS_ACTION,
          payload: {
            patients: response.patients,
            requestSteps: (response.metadata && response.metadata.total_per_request_step) || []
          }
        });
      })
      .catch(error => {
        if (error.name === 'request aborted') {
          return;
        }
        dispatch({ type: FETCH_PATIENTS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

// Updates existing record if it exists
export function fetchSpecificPatientsOnly(ids, includes = []) {
  return (dispatch, getState) => {
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .getSpecific(ids, includes)
      .then(response => {
        response.patients.data.forEach(patient =>
          dispatch({ type: FETCH_PATIENT_SUCCESS_ACTION, payload: patient })
        );
      })
      .catch(error => {
        dispatch({ type: FETCH_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPatientAction(id, args = []) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .show(id, ...args)
      .then(response => {
        dispatch({ type: FETCH_PATIENT_SUCCESS_ACTION, payload: response.patient });
      })
      .catch(error => {
        dispatch({ type: FETCH_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createPatientAction(params) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .create(params)
      .then(response => {
        dispatch({ type: CREATE_PATIENT_SUCCESS_ACTION, payload: response.patient });
        dispatch(enqueueNotification('success', response.message));
        return response.patient;
      })
      .catch(error => {
        dispatch({ type: CREATE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updatePatientAction(id, params) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .update(id, { ...params, case_number: null })
      .then(response => {
        dispatch({ type: UPDATE_PATIENT_SUCCESS_ACTION, payload: response.patient });
        dispatch(enqueueNotification('success', response.message));
        return response.patient;
      })
      .catch(error => {
        dispatch({ type: UPDATE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function holdPatientAction(id, extraInfo) {
  return dispatch => {
    dispatch({ type: HOLD_PATIENT_REQUEST_ACTION });

    const patientsService = new PatientApiService();
    return patientsService
      .hold(id, extraInfo)
      .then(response => {
        dispatch({ type: HOLD_PATIENT_SUCCESS_ACTION, payload: response.patient });
        dispatch(enqueueNotification('success', response.message));
        return response.patient;
      })
      .catch(error => {
        dispatch({ type: HOLD_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updatePatientFieldAction(id, value, field, extraFieldsToFetch = []) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_PATIENT_FIELD_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .update(id, { [field]: value })
      .then(response => {
        dispatch({
          type: UPDATE_PATIENT_FIELD_SUCCESS_ACTION,
          payload: response.patient,
          field: Array.isArray(extraFieldsToFetch) ? extraFieldsToFetch.concat(field) : field
        });
        dispatch(enqueueNotification('success', response.message));
        return response.patient;
      })
      .catch(error => {
        dispatch({ type: UPDATE_PATIENT_FIELD_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deletePatientAction(id) {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .delete(id)
      .then(response => {
        dispatch({ type: DELETE_PATIENT_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function restorePatientAction(id) {
  return (dispatch, getState) => {
    dispatch({ type: RESTORE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .restore(id)
      .then(response => {
        dispatch({ type: RESTORE_PATIENT_SUCCESS_ACTION, payload: response.patient });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: RESTORE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function completePatientAction(id) {
  return (dispatch, getState) => {
    dispatch({ type: COMPLETE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .complete(id)
      .then(response => {
        dispatch({ type: COMPLETE_PATIENT_SUCCESS_ACTION, patientId: id });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: COMPLETE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPatientFilesDownloadUrlsAction(patientId, fileTypeId) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PATIENT_FILES_DOWNLOAD_URLS_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .getFilesDownloadUrls(patientId, fileTypeId)
      .then(response => {
        dispatch({
          type: FETCH_PATIENT_FILES_DOWNLOAD_URLS_SUCCESS_ACTION,
          payload: response.images,
          patientId,
          fileTypeId
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PATIENT_FILES_DOWNLOAD_URLS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deletePatientFileAction(patientId, fileTypeId, id) {
  return dispatch => {
    dispatch({ type: DELETE_PATIENT_FILE_REQUEST_ACTION });
    const patientFilesService = new PatientFilesApiService();
    return patientFilesService
      .deleteFile(id)
      .then(response => {
        dispatch({
          type: DELETE_PATIENT_FILE_SUCCESS_ACTION,
          payload: { patientId, fileTypeId, id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_PATIENT_FILE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function updateRequestManagementDataFromPatientAction(patientId, requestId, params) {
  return dispatch => {
    dispatch({ type: UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .updateManagementData(requestId, params)
      .then(response => {
        dispatch({
          type: UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION,
          payload: {
            patientId,
            requestId,
            requestManagementData: response.customer_request_management_data
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_REQUEST_MANAGEMENT_DATA_FROM_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateRxFormManagementDataFromPatientAction(patientId, requestId, params) {
  return dispatch => {
    dispatch({ type: UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_REQUEST_ACTION });
    const rxFormsApiService = new RxFormsApiService();
    return rxFormsApiService
      .updateManagementData(requestId, params)
      .then(response => {
        dispatch({
          type: UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_SUCCESS_ACTION,
          payload: {
            patientId,
            requestId,
            rxFormManagementData: response.rx_form_management_data
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_RX_FORM_MANAGEMENT_DATA_FROM_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function assignRxFormPatientAction(patientId, id, assignToId) {
  return dispatch => {
    dispatch({ type: ASSIGN_RX_FORM_PATIENT_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .assign(id, assignToId)
      .then(response => {
        dispatch({
          type: ASSIGN_RX_FORM_PATIENT_SUCCESS_ACTION,
          payload: { id, assignToId, patientId }
        });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({ type: ASSIGN_RX_FORM_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function addPatientFileAction(patientId, fileTypeId, file) {
  return dispatch => {
    dispatch({
      type: ADD_PATIENT_FILE_ACTION,
      payload: { patientId, fileTypeId, file }
    });
  };
}

export function removePatientFileAction(patientId, fileTypeId, id) {
  return dispatch => {
    dispatch({
      type: REMOVE_PATIENT_FILE_ACTION,
      payload: { patientId, fileTypeId, id }
    });
  };
}

export function updatePatientRxFormAction(patientId, rxForm) {
  return dispatch => {
    dispatch({
      type: UPDATE_PATIENT_RX_FORM_ACTION,
      payload: { patientId, rxForm }
    });
  };
}

export function resetPatients() {
  return dispatch => {
    dispatch({
      type: RESET_PATIENTS
    });
  };
}

export function setPatientsGlobalParams(params) {
  return dispatch => {
    dispatch({
      type: SET_PATIENTS_GLOBAL_PARAMS,
      payload: { params }
    });
  };
}

export function archivePatientAction(id) {
  return (dispatch, getState) => {
    dispatch({ type: ARCHIVE_PATIENT_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .archive(id)
      .then(response => {
        dispatch({ type: ARCHIVE_PATIENT_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ARCHIVE_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPatientCountsPerStatusAction(params) {
  return (dispatch, getState) => {
    dispatch({ type: PATIENT_COUNT_PER_STATUS_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .patientCountsPerStatus(params)
      .then(response => {
        dispatch({
          type: PATIENT_COUNT_PER_STATUS_SUCCESS_ACTION,
          payload: response.counts
        });
        return true;
      })
      .catch(error => {
        dispatch({ type: PATIENT_COUNT_PER_STATUS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function updateTechWorkflowProcessesAction(patientId, techWorkflowId, type, processes) {
  return dispatch => {
    dispatch({ type: UPDATE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION });
    const techWorkflowApiService = new TechWorkflowApiService();

    return techWorkflowApiService
      .updateProcesses(techWorkflowId, processes)
      .then(response => {
        dispatch({
          type: UPDATE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION,
          payload: {
            patientId,
            techWorkflowId,
            type,
            processes: response.processes
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_TECH_WORKFLOW_PROCESSES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function showPatientLabel(patient) {
  return dispatch => {
    dispatch({ type: SHOW_PATIENT_LABEL, payload: patient });
  };
}

export function closePatientLabel() {
  return dispatch => {
    dispatch({ type: CLOSE_PATIENT_LABEL });
  };
}

export function showShippingLabel(patients) {
  return dispatch => {
    const locationId = patients[0].customer_request.printing_request.location_id;
    const sameLocation =
      patients.filter(
        patient => patient.customer_request.printing_request.location_id === locationId
      ).length === patients.length;
    if (sameLocation) {
      dispatch({ type: SHOW_SHIPPING_LABEL, payload: patients });
    }
  };
}

export function closeShippingLabel() {
  return dispatch => {
    dispatch({ type: CLOSE_SHIPPING_LABEL });
  };
}

export function selectPatientsForBundling(patients) {
  return dispatch => {
    dispatch({ type: SELECT_PATIENS_FOR_BUNDLING, payload: patients });
  };
}

export function cancelSelectionForBundlingPatients() {
  return dispatch => {
    dispatch({ type: CANCEL_SELECTION_FOR_BUNDLING_PATIENTS });
  };
}

export function setInitialParameters(params) {
  return dispatch => {
    dispatch({ type: SET_PATIENTS_INITIAL_PARAMETERS, payload: params });
  };
}

export function addChargeToPatientAction(patientId, data) {
  return dispatch => {
    dispatch({ type: ADD_CHARGE_TO_PATIENT_REQUEST_ACTION });
    const apiService = new PatientApiService();
    return apiService
      .addCharge(patientId, data)
      .then(response => {
        dispatch({
          type: ADD_CHARGE_TO_PATIENT_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ADD_CHARGE_TO_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeChargeFromPatientAction(patientId, chargeId) {
  return dispatch => {
    dispatch({ type: REMOVE_CHARGE_FROM_PATIENT_REQUEST_ACTION });
    const apiService = new PatientApiService();
    return apiService
      .removeCharge(patientId, chargeId)
      .then(response => {
        dispatch({
          type: REMOVE_CHARGE_FROM_PATIENT_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_CHARGE_FROM_PATIENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function findCaseTray(id) {
  const apiService = new PatientApiService();
  return apiService.findCaseTray(id);
}

export function storePatientsTray(data) {
  const apiService = new PatientApiService();
  return apiService.storePatientsTray(data);
}

export function archiveCaseTray(id) {
  const apiService = new PatientApiService();
  return apiService.archiveCaseTray(id);
}

export function generateShippingId(id) {
  const apiService = new PatientApiService();
  return apiService.generateShippingId(id);
}
