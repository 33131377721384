import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import {
  blue,
  green,
  red,
  orange,
  purple,
  lime,
  amber,
  grey,
  yellow
} from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  red: {
    backgroundColor: red[500]
  },
  blue: {
    backgroundColor: blue[500]
  },
  green: {
    backgroundColor: green[500]
  },
  orange: {
    backgroundColor: orange[500]
  },
  purple: {
    backgroundColor: purple[500]
  },
  lime: {
    backgroundColor: lime[500]
  },
  amber: {
    backgroundColor: amber[500]
  },
  grey: {
    backgroundColor: grey[500]
  },
  yellow: {
    backgroundColor: yellow[500]
  },
  darkRed: {
    backgroundColor: red[900]
  },
  tag: {
    marginLeft: theme.spacing(1),
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white'
  }
}));

const StatusTag = ({ color, label, className, ...rest }) => {
  const classes = useStyles();
  const chipClass = clsx(classes.tag, classes[color]);

  return (
    <Chip size="small" className={`${chipClass} ${className || ''}`} label={label} {...rest} />
  );
};

StatusTag.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string
};

StatusTag.defaultProps = {
  className: ''
};

export default StatusTag;
