import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popper from '@material-ui/core/Popper';

import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import UsersApiService from '../../services/api/users';

const styles = () => ({
  popper: {
    maxWidth: 'fit-content'
  }
});

const CustomPopper = props => {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const UserAutoComplete = ({
  value: currentValue,
  roles,
  options: currentOptions,
  currentUserOnly,
  ...props
}) => {
  const currentUser = useSelector(state => state.auth.user);

  const [open, setOpen] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(
    // eslint-disable-next-line no-nested-ternary
    currentOptions.length > 0 ? currentOptions : currentValue ? [currentValue] : []
  );
  const loading = fetching && options.length === 0;

  const handleInputChange = debounce(async (event, search, reason) => {
    if (reason !== 'input') {
      return;
    }

    if (currentUserOnly) {
      setOptions([{ ...currentUser }]);
      return;
    }

    setFetching(true);
    setOptions([]);
    const usersApiService = new UsersApiService();
    const {
      users: { data }
    } = await usersApiService.get({
      searchFullNameEmailQuery: search,
      orderBy: 'first_name',
      roles
    });
    setFetching(false);
    setOptions(data);
  }, 500);

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PopperComponent={CustomPopper}
      getOptionLabel={option => option.full_name}
      getOptionSelected={(option, value) => {
        if (!value) {
          return undefined;
        }
        return +option.id === +value.id;
      }}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          autoFocus
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

UserAutoComplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  currentUserOnly: PropTypes.bool
};

UserAutoComplete.defaultProps = {
  value: undefined,
  roles: [],
  options: [],
  currentUserOnly: false
};

export default withStyles(styles)(UserAutoComplete);
