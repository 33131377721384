import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { CardContent, Divider } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import FormControl from '@material-ui/core/FormControl';
import { RadioGroup, TextField } from 'formik-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { replaceNullToEmpty } from '../../helpers';
import LoadingButton from '../LoadingButton';
import { ALLOWED_ROLES } from '../../constants';
import Can from '../Can';
import hasPermission from '../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const UserForm = ({
  onSubmit,
  isSaving,
  user,
  onDelete,
  onArchive,
  onRestore,
  isDeleting,
  isArchiving,
  isRestoring,
  isOwn,
  saveButtonLabel,
  mode
}) => {
  const classes = useStyles();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    role: '',
    account_number: ''
  };

  const canUpdate = useSelector(state =>
    hasPermission(state, {
      permissions: ['users.update', 'users.manage']
    })
  );
  const canDelete = useSelector(state => hasPermission(state, { permissions: ['users.delete'] }));
  const isManagement = useSelector(state => state.auth.isManagement);

  const alphanumeric = /^([A-Za-z0-9]+)([A-Za-z0-9-]+)([A-Za-z0-9]+)$/;

  return (
    <Formik
      initialValues={
        user
          ? replaceNullToEmpty({ ...user, role: get(user, 'roles[0].name', 'no_role') })
          : initialValues
      }
      enableReinitialize
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
          .email()
          .required('Required'),
        username: Yup.string()
          .min(6, 'Must contain at least 6 characters.')
          .max(255, 'Only up to 255 characters is allowed.')
          .matches(
            alphanumeric,
            'Must only contain alphanumeric characters or "-" in between.'
          ),
        role: Yup.string().required('Required'),
        account_number: Yup.string()
          .max(20, 'Only up to 20 characters is allowed.')
          .min(6, 'Must contain at least 6 characters.')
          .matches(
            alphanumeric,
            'Must only contain alphanumeric characters or "-" in between.'
          ).required('Required')
      })}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  component={TextField}
                  disabled={mode === 'show' || !canUpdate}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="account_number"
                  label="User Account Number"
                  name="account_number"
                  component={TextField}
                  disabled={mode === 'show' || !(canUpdate || isManagement)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="first_name"
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  component={TextField}
                  disabled={mode === 'show' || !canUpdate}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  component={TextField}
                  disabled={mode === 'show' || !canUpdate}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="username"
                  label="Username (optional)"
                  name="username"
                  component={TextField}
                  disabled={mode === 'show' || !canUpdate}
                />
              </Grid>

              {!isOwn && (
                <Grid item xs={12} sm={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Role *</FormLabel>
                    <Field required component={RadioGroup} name="role">
                      {Object.values(ALLOWED_ROLES).map(item => {
                        return (
                          <FormControlLabel
                            key={item.key}
                            value={item.key}
                            control={<Radio disabled={isSaving || mode === 'show'} />}
                            label={item.label}
                            disabled={isSaving || mode === 'show' || !canUpdate}
                          />
                        );
                      })}
                    </Field>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            {mode !== 'show' && (canUpdate || isManagement) && (
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving || isArchiving || isRestoring}
                className={classes.actionButton}
              >
                {saveButtonLabel}
              </LoadingButton>
            )}
            {canUpdate && onRestore && (
              <Can
                permissions={['users.restore']}
                yes={() => (
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="primary"
                    loading={isRestoring}
                    disabled={isDeleting || isSaving || isArchiving || isRestoring}
                    onClick={() => onRestore(user.id)}
                    className={classes.actionButton}
                  >
                    Restore
                  </LoadingButton>
                )}
              />
            )}
            {canUpdate && onArchive && (
              <Can
                permissions={['users.archive']}
                yes={() => (
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isArchiving}
                    disabled={isDeleting || isSaving || isArchiving || isRestoring}
                    onClick={() => onArchive(user.id)}
                    className={classes.actionButton}
                  >
                    Archive
                  </LoadingButton>
                )}
              />
            )}
            {canDelete && user && !isOwn && (
              <Can
                permissions={['users.delete', 'users.manage']}
                yes={() => (
                  <LoadingButton
                    type="button"
                    variant="contained"
                    color="secondary"
                    loading={isDeleting}
                    disabled={isDeleting || isSaving || isArchiving || isRestoring}
                    onClick={() => onDelete(user.id)}
                    className={classes.actionButton}
                  >
                    Delete
                  </LoadingButton>
                )}
              />
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onArchive: PropTypes.func,
  onRestore: PropTypes.func,
  isSaving: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  isArchiving: PropTypes.bool,
  isRestoring: PropTypes.bool,
  saveButtonLabel: PropTypes.string,
  isOwn: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  mode: PropTypes.string
};

UserForm.defaultProps = {
  user: null,
  onDelete: () => {},
  onArchive: null,
  onRestore: null,
  isDeleting: false,
  isArchiving: false,
  isRestoring: false,
  saveButtonLabel: 'Save',
  isOwn: false,
  mode: ''
};

export default UserForm;
