import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box
} from '@material-ui/core';
import CustomHighlight from '../Custom/Highlight';

const useStyles = makeStyles(() => ({
  key_cell: {
    width: '50%',
    fontWeight: 'bold',
    border: '1px solid #eee'
  },
  value_cell: {
    width: '50%',
    border: '1px solid #eee'
  }
}));

const HitCardDetails = ({ hit, expanded, visibleAttributes, attributeTitles }) => {
  const classes = useStyles();

  const filterRow = ([key]) => {
    return _.includes(visibleAttributes, key) && !_.startsWith(key, '_');
  };

  const filteredRows = _.filter(Object.entries(hit), filterRow);

  const rows = _.map(filteredRows, ([key, value]) => ({
    key,
    value,
    display: attributeTitles[key] || _.upperFirst(_.lowerCase(key))
  }));

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <TableContainer component={Box}>
          <Table className={classes.table} size="small">
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.key}>
                  <TableCell align="left" className={classes.key_cell}>
                    {row.display}
                  </TableCell>
                  <TableCell align="left" className={classes.value_cell}>
                    <CustomHighlight attribute={row.key} hit={hit} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Collapse>
  );
};

HitCardDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  visibleAttributes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributeTitles: PropTypes.object
};

HitCardDetails.defaultProps = {
  expanded: false,
  attributeTitles: {}
};

export default HitCardDetails;
