import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import FieldWrapper from './FieldWrapper';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  section: {
    padding: '10px',
    marginBottom: theme.spacing(1.5),
    '.customize-fees-section-label': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      color: theme.palette.primary.main
    }
  },
  subSection: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.RxForms,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  customizedField: {
    '& fieldset': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.1
    }
  }
}));

const READABLE_LABELS = {
  // main groupings
  general: 'Impression Types & Products',
  pvs_or_stone_model: 'From PVS / Alignate or Stone Model',
  intra_oral_scan: 'From Intra-Oral Scan',
  '3d_printed_study': '3D Printed Study Model',
  misc: 'Misc.',

  // categories
  impression_type: 'Impression Type',
  products: 'Products based on Impression Type',
  cleanup: 'Clean-Up',
  cleanup_base: 'Clean-Up and Base',

  // addon
  palate: 'Palate/Lingual'
};

const Section = ({ group, children }) => {
  const classes = useStyles();
  const title = READABLE_LABELS[group] || 'General';

  return (
    <div className={classes.section}>
      <Typography className="customize-fees-section-label" variant="h6">
        {title}
      </Typography>

      <div style={{ marginBottom: '20px' }}>{children}</div>

      <hr />
    </div>
  );
};

const CommonField = ({ label, inputProps, adornment, error, fieldWrapperProps, ...props }) => {
  const fieldProps = {
    error: !!error,
    helperText: error,
    ...props
  };

  return (
    <FieldWrapper label={label} {...fieldWrapperProps}>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          required
          InputProps={{
            min: 0,
            step: 1,
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
            ...inputProps
          }}
          {...fieldProps}
        />
      </FormControl>
    </FieldWrapper>
  );
};

const RxForms = ({ givenFees, defaultFees, setFieldValue }) => {
  const classes = useStyles();
  const [fees, setFees] = useState([]);

  useEffect(() => {
    if (!givenFees || !givenFees.rx_forms) return;
    const rxForm = givenFees.rx_forms;
    const formData = [];
    const shouldApplyColor = defaultFees && defaultFees.rx_forms;

    Object.keys(rxForm).forEach(key => {
      let data = { group: key, forms: [] };

      Object.keys(rxForm[key]).forEach(product_type => {
        let form = {
          product_type,
          label: READABLE_LABELS[product_type] || product_type.split('_').join(' '),
          fields: []
        };

        Object.keys(rxForm[key][product_type]).forEach(field => {
          const value = rxForm[key][product_type][field];

          const item = {
            name: field,
            value: value,
            label: READABLE_LABELS[field] || field.split('_').join(' ')
          };

          if (shouldApplyColor) {
            item.className =
              value !== get(defaultFees, `rx_forms.${key}.${product_type}.${field}`)
                ? classes.customizedField
                : '';

            if (item.className.trim()) {
              item.helperText = 'Modified';
            }
          }

          form.fields.push(item);
        });

        data.forms.push(form);
      });

      formData.push(data);
    });

    setFees([...formData]);
  }, [givenFees, defaultFees, classes.customizedField]);

  const fieldWrapperProps = { columns: { sm: 1, md: 1 } };

  return (
    <div>
      {fees.map(({ group, forms }, index) => {
        return (
          <Section group={group} key={index}>
            <Grid container spacing={4}>
              {forms.map(({ label, product_type, fields }) => {
                return (
                  <Grid item xs={6} key={product_type}>
                    {group.toLowerCase() !== 'misc' && (
                      <Typography variant="subtitle1" className={classes.subSection}>
                        {READABLE_LABELS[label] || label}
                      </Typography>
                    )}

                    {fields.map(({ name, value, label, className, helperText }) => {
                      const fieldProps = {
                        name,
                        value,
                        label,
                        className,
                        helperText,
                        adornment: '$',
                        onChange: e => {
                          const path = `rx_forms.${group}.${product_type}.${name}`;
                          setFieldValue(path, Number(e.currentTarget.value));
                        }
                      };

                      return (
                        <CommonField
                          key={name}
                          {...fieldProps}
                          fieldWrapperProps={{ key: name, ...fieldWrapperProps }}
                        />
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </Section>
        );
      })}
    </div>
  );
};

export default RxForms;
