import { createSelector } from 'reselect';
import { get, intersection } from 'lodash';

const hasPermission = createSelector(
  state => state.auth.isManagement,
  state => state.auth.currentOrganization,
  state => state.auth.user,
  state => state.auth.management.organizationsPermissions,
  (_, params) => params,
  (isManagement, currentOrganization, currentUser, organizationsPermissions, params) => {
    let organizationPermissions = [];

    if (isManagement && params.organizationId) {
      organizationPermissions = organizationsPermissions[params.organizationId] || [];
    } else if (currentOrganization) {
      organizationPermissions = get(currentOrganization, 'pivot.all_permissions', []);
    }

    const userPermissions = get(currentUser, 'all_permissions', []);
    const allUserPermissions = [...userPermissions, ...organizationPermissions];

    const foundPermissions = intersection(allUserPermissions, params.permissions);

    return foundPermissions.length > 0;
  }
);

export default hasPermission;
