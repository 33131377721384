import React, { useEffect, useState, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import { parse } from 'query-string';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SIGN_UP_PREFIX, signUpAction } from '../../reducers/auth';
import createLoadingSelector from '../../selectors/loading';
import LOGO from '../../assets/images/logo3x.png';
import FormContent from './components/FormContent';
import FormActions from './components/FormActions';
import validationSchema from './data/ValidationSchema';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  wrapperCard: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      alignItems: 'flex-start'
    }
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    background: theme.palette.primary.contrastText,
    boxShadow: '1px 1px 5px 1px #f5f5f5, -1px -1px 5px 1px #f5f5f5'
  },
  logoWrapper: {
    width: '50%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    width: '100%',
    maxWidth: 450
  },

  formWrapper: {
    width: '50%',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  login: {
    display: 'block',
    transition: 'background-color .3s, box-shadow .3s',
    padding: '12px 16px 12px 42px',
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)',
    color: '#757575',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif',

    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=)',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '12px 11px'
  }
}));

const totalStepsCount = 2;

const Signup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const signupSuccess = useSelector(state => state.auth.successfulSignup);
  const isLoading = useSelector(state => createLoadingSelector([SIGN_UP_PREFIX])(state));
  const queryStringParams = parse(location.search);
  const { lab_partner: labPartner } = queryStringParams;

  const [currentStep, setCurrentStep] = useState(1);

  const isStepsCompleted = useMemo(() => currentStep === totalStepsCount, [currentStep]);

  useEffect(() => {
    if (signupSuccess) {
      history.replace('/sign-up-success');
    }
  }, [history, signupSuccess]);

  return (
    <Container component="main" maxWidth="md" className={classes.wrapperCard}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid className={classes.logoWrapper}>
          <img className={classes.avatar} src={LOGO} alt="Logo" />
        </Grid>

        <Grid className={classes.formWrapper}>
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              password: '',
              password_confirmation: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async values => {
              const result = await executeRecaptcha('signUpPage');
              if (result) {
                const params = {};

                if (labPartner) {
                  params.lab_partner = labPartner;
                }

                dispatch(
                  signUpAction({
                    ...values,
                    ...params
                  })
                );
              }
              return true;
            }}
          >
            {({ isValid, dirty }) => (
              <Form className={classes.form} noValidate>
                <FormContent currentStep={currentStep} />

                <Box mt={2}>
                  <Typography variant="caption" color="textSecondary">
                    Note: In order to start submitting cases, there is a one-time $200 registration
                    fee
                  </Typography>
                </Box>

                <FormActions
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  isValid={isValid}
                  dirty={dirty}
                  isLoading={isLoading}
                  show={isStepsCompleted}
                />

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="caption">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>

                {/* <Box
                  display="flex"
                  justifyContent="center"
                  borderTop="1px solid #eee"
                  mt={3}
                  pt={3}
                >
                  <button type="button" className={classes.login}>
                    Sign up with Google
                  </button>
                </Box> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </div>
    </Container>
  );
};

export default Signup;
