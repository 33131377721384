import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect as OriginalRedirect } from 'react-router-dom';

const Redirect = ({ management, organization, ...props }) => {
  const isManagement = useSelector(state => state.auth.isManagement);
  const override = { to: organization };
  if (isManagement) {
    override.to = management;
  }
  return <OriginalRedirect {...{ ...props, ...override }} />;
};

Redirect.propTypes = {
  management: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired
};

export default Redirect;
