import { useEffect } from 'react';

export default function useRefresh(history, path) {
  let handler = null;

  const refresh = () => {
    history.push('/empty');

    handler = setTimeout(() => history.replace(path), 10);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
}
