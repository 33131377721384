import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Stepper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  }
}));

function CustomStepper({ activeStep, steps }) {
  const classes = useStyles();

  const renderStepsLabels = () => {
    return _.map(steps, step => (
      <Step key={step.key}>
        <StepLabel>{step.label}</StepLabel>
      </Step>
    ));
  };

  return (
    <Stepper activeStep={activeStep} className={classes.stepper}>
      {renderStepsLabels()}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.object.isRequired
};

export default CustomStepper;
