import * as Yup from 'yup';

export default Yup.object().shape({
  first_name: Yup.string().required('Required'),

  last_name: Yup.string().required('Required'),

  email: Yup.string()
    .email()
    .required('Required'),

  password: Yup.string()
    .required('Required')
    .max(32, 'Must contain max 32 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      'Must contain at least 8 characters, one uppercase, one lowercase and one number'
    ),

  password_confirmation: Yup.string()
    .required('Required')
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
});
