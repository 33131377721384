import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { fetchRxFormPreference } from '../../reducers/rxFormPreferences';

const defaultContext = {
  preference: null,
  thirdPartyPartner: null,
  organizationId: null
};
export const RxFormPreferenceContext = createContext(defaultContext);

const RxFormPreferenceProvider = ({
  children,
  rxFormPreference,
  retrieveRxFormPreference,
  loaderProps,
  loader
}) => {
  useEffect(() => {
    if (!rxFormPreference) {
      retrieveRxFormPreference();
    }
  }, [rxFormPreference, retrieveRxFormPreference]);

  if (!rxFormPreference || rxFormPreference.fetching) {
    return loader && <LoadingIndicator {...loaderProps} />;
  }

  return (
    <RxFormPreferenceContext.Provider value={rxFormPreference}>
      {typeof children === 'function' ? children(rxFormPreference) : children}
    </RxFormPreferenceContext.Provider>
  );
};

RxFormPreferenceProvider.defaultProps = {
  loader: true,
  loaderProps: {},
  rxFormPreference: null
};

RxFormPreferenceProvider.propTypes = {
  organizationId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rxFormPreference: PropTypes.object,
  retrieveRxFormPreference: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  loader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  loaderProps: PropTypes.object
};

export default connect(
  (state, ownProps) => {
    const rxFormPreference = state.rxFormPreferences.organizations[ownProps.organizationId];
    return { rxFormPreference };
  },
  (dispatch, ownProps) => {
    return {
      retrieveRxFormPreference: () => {
        return dispatch(fetchRxFormPreference(ownProps.organizationId));
      }
    };
  }
)(RxFormPreferenceProvider);
