import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CardHeader, Typography, Chip } from '@material-ui/core';
import clsx from 'clsx';
import green from '@material-ui/core/colors/green';
import CustomHighlight from '../Custom/Highlight';
import { ROLE_OPTIONS } from '../../../constants';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1, 2)
  },
  expand: {
    marginLeft: 'auto',
    padding: '0.25rem 0.75rem',
    background: '#eee',
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#777',
    textTransform: 'capitalize',
    marginTop: '0.75rem'
  },
  custom_header_link: {
    color: theme.palette.text.primary,
    '& [class^=ais-]': {
      fontSize: '0.9rem'
    }
  },
  custom_link: {
    color: theme.palette.text.secondary,
    textDecoration: 'underline #aaa',
    '&:hover': {
      textDecoration: 'underline #1890ff'
    },
    '& [class^=ais-]': {
      fontSize: '0.9rem'
    }
  },
  badge: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0),
    fontSize: theme.spacing(1.5),
    color: theme.palette.primary.contrastText,
    '&.organization': {
      backgroundColor: green[600]
    },
    '&.user': {
      backgroundColor: theme.palette.primary.main
    },
    '&.location': {
      color: '#000'
    }
  }
}));

const modelMap = {
  user: {
    path: 'users',
    organizationId: 'organization_id',
    organizationName: 'organization_name',
    organizationAccountNo: 'organization_account_number'
  },
  organization: {
    path: 'organizations',
    organizationId: 'model_id',
    organizationName: null,
    organizationAccountNo: 'account_number'
  },
  location: {
    path: model => `/organizations/${model.organization_id}/locations/${model.model_id}`,
    organizationId: 'organization_id',
    organizationName: 'organization_name',
    organizationAccountNo: 'organization_account_number'
  }
};

const HitCardHeader = ({ hit, expanded, setExpanded }) => {
  const classes = useStyles();

  const renderRoles = () => {
    const { roles } = hit;

    if (_.isEmpty(roles)) return '';

    return roles
      .filter(role => !!ROLE_OPTIONS[role])
      .map(role => ROLE_OPTIONS[role].label)
      .join(', ');
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const headerLabel = () => {
    const { model } = hit;

    const labelPostfix = model === 'user' ? ` - ${renderRoles()}` : '';

    return _.upperFirst(model) + labelPostfix;
  };

  const headerLink = () => {
    const modelPath = _.get(modelMap, [hit.model, 'path'], null);
    if (!modelPath) {
      return '#';
    }

    return _.isFunction(modelPath) ? modelPath(hit) : `/${modelPath}/${hit.model_id}`;
  };

  const renderHeader = () => (
    <Link to={headerLink()} className={classes.custom_header_link}>
      <CustomHighlight attribute="full_name" hit={hit} />
      <Chip label={headerLabel()} size="small" className={clsx(classes.badge, hit.model)} />
    </Link>
  );

  const renderSubHeader = () => {
    const organizationIdKey = _.get(modelMap, [hit.model, 'organizationId'], null);
    const organizationNameKey = _.get(modelMap, [hit.model, 'organizationName'], null);
    const organizationAccountKey = _.get(modelMap, [hit.model, 'organizationAccountNo'], null);

    const organizationId = _.get(hit, [organizationIdKey], null);

    const link = organizationId ? `/organizations/${organizationId}` : '#';

    return organizationNameKey ? (
      <Link to={link} className={classes.custom_link}>
        <CustomHighlight attribute={organizationNameKey} hit={hit} />
        <Typography component="span">{' | '}</Typography>
        <CustomHighlight attribute={organizationAccountKey} hit={hit} />
      </Link>
    ) : (
      <Link to={link} className={classes.custom_link}>
        <CustomHighlight attribute={organizationAccountKey} hit={hit} />
      </Link>
    );
  };

  return (
    <CardHeader
      className={classes.header}
      title={renderHeader()}
      subheader={renderSubHeader()}
      titleTypographyProps={{
        variant: 'h6'
      }}
      subheaderTypographyProps={{
        variant: 'caption',
        color: 'textSecondary'
      }}
      action={
        <Button size="small" className={classes.expand} onClick={handleExpandClick}>
          {`Quick Glance ${expanded ? '-' : '+'}`}
        </Button>
      }
    />
  );
};

HitCardHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired
};

HitCardHeader.defaultProps = {
  expanded: false
};

export default HitCardHeader;
