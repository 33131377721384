import PatientsApiService from './patients';
import OrganizationPatientsApiService from './organizationPatients';
import OrganizationCustomerRequestsApiService from './organizationCustomerRequests';
import RequestsApiService from './requests';
import RxFormsApiService from './rxForms';
import OrganizationRxFormsApiService from './organizationRxForms';

export default class ServiceManager {
  constructor(getState) {
    const state = getState();
    this.currentOrganization = state.auth.currentOrganization;
    this.isManagement = state.auth.isManagement;
  }

  getPatientServiceInstance() {
    return this.isManagement
      ? new PatientsApiService()
      : new OrganizationPatientsApiService({
          organization: this.currentOrganization
        });
  }

  getRequestServiceInstance() {
    return this.isManagement
      ? new RequestsApiService()
      : new OrganizationCustomerRequestsApiService({
          organization: this.currentOrganization
        });
  }

  getRxFormServiceInstance() {
    return this.isManagement
      ? new RxFormsApiService()
      : new OrganizationRxFormsApiService({
          organization: this.currentOrganization
        });
  }
}
