import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PatientFilesApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order = 'desc',
    orderBy = 'id',
    searchFullNameQuery = '',
    patientSearchFullNameQuery = ''
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';

    return this.request({
      method: 'get',
      url: this.query
        .for('patient_files')
        .include('patient', 'patient.user')
        .where('user_full_name_like', searchFullNameQuery)
        .where('patient_full_name_like', patientSearchFullNameQuery)
        .where('patient_file_type.name', 'STL')
        .where('only_with_patient', true)
        .sort(orderChar + orderByField, '-id')
        .params({ perPage, page })
        .get()
    });
  }

  getDownloadUrl(id) {
    return this.request({
      method: 'get',
      url: `/patient_files/${id}/download_link`
    });
  }

  // eslint-disable-next-line camelcase
  getUploadUrl(file_name, patient_id, patient_file_type_id, update_db) {
    return this.request({
      method: 'get',
      url: '/patient_files/upload_link',
      params: {
        file_name,
        patient_id,
        patient_file_type_id,
        update_db
      }
    });
  }

  deleteFile(id) {
    return this.request({
      method: 'delete',
      url: `/patient_files/${id}`
    });
  }

  storeUploadedFile(
    // eslint-disable-next-line camelcase
    file_path,
    // eslint-disable-next-line camelcase
    patient_id,
    // eslint-disable-next-line camelcase
    patient_file_type_id,
    // eslint-disable-next-line camelcase
    original_file_name,
    file_modification_date = null
  ) {
    return this.request({
      method: 'post',
      url: 'patient_files/store_uploaded_file',
      data: {
        file_path,
        patient_id,
        patient_file_type_id,
        original_file_name,
        file_modification_date
      }
    });
  }
}
