import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientAction } from '../../reducers/patients';
import './print.css';
import Table from './Table';

const PrintRxForm = ({ match: { params }, location }) => {
  const patientId = parseInt(params.id, 10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPatientAction(patientId));
  }, [location, patientId, dispatch]);

  const patient = useSelector(state => {
    return state.patients.items[state.patients.items.findIndex(item => item.id === patientId)];
  });

  useEffect(() => {
    if (patient) {
      setTimeout(() => {
        window.print();

        window.onafterprint = window.onfocus = function(e) {
          window.close();
        };
      }, 1000);
    }
  });

  if (!patient) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{patient.formatted_name}</title>
      </Helmet>

      <Table patient={patient} />
    </>
  );
};

PrintRxForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

PrintRxForm.defaultProps = {};

export default PrintRxForm;
