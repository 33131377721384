import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { makeStyles } from '@material-ui/core';
import {
  ATTRIBUTES,
  PRODUCTS_LIST_METHODS,
  PRODUCTS_LIST_LABELS,
  PRODUCTS_LIST_VALUES,
  IMPRESSION_TYPE_METHODS,
  IMPRESSION_TYPE_VALUES
} from '../constants';
import CheckboxGroupField from './CheckboxGroupField';
import { decodeFormikValues } from '../helpers';

import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    marginLeft: '16px'
  },
  verticalLine: {
    borderLeft: '1px solid #e0e0e0',
    position: 'absolute',
    top: '0',
    height: '86%',
    left: '-16px'
  },
  checkboxGroupContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '16px'
  },
  checkboxGroupContainer2: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '16px'
  },
  separator: {
    display: 'inline-block',
    width: '1px',
    height: '100%',
    marginRight: '16px',
    backgroundColor: '#ccc'
  },
  appendLabel: {
    fontSize: '12px'
  }
}));

const {
  FULL_DIGITAL_MODEL,
  SEMI_FINISH_DIGITAL_MODEL,
  ROUGH_DIGITAL_MODEL,
  DIGITAL_STORAGE_ONLY,
  CLEANUP,
  CLEANUP_BASE,
  PRINTING_OPTIONS
} = PRODUCTS_LIST_METHODS;

const ProductsListField = ({
  currentImpression,
  currentValue,
  setValue,
  preferenceValue,
  isSubmitting,
  ...props
}) => {
  const classes = useStyles();
  const optionsPVS = [];
  const optionsOral = [];
  const options3DP = [];
  // if (Array.isArray(preferenceValue)) {

  const isIntraOralScans =
    (currentImpression & IMPRESSION_TYPE_VALUES[IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]) > 0;

  if (!isIntraOralScans) {
    //   if (preferenceValue.indexOf(FULL_DIGITAL_MODEL) !== -1) {
    optionsPVS.push({
      label: PRODUCTS_LIST_LABELS[FULL_DIGITAL_MODEL],
      value: PRODUCTS_LIST_VALUES[FULL_DIGITAL_MODEL],
      checked: (currentValue & PRODUCTS_LIST_VALUES[FULL_DIGITAL_MODEL]) > 0
    });
    // }
    // if (preferenceValue.indexOf(SEMI_FINISH_DIGITAL_MODEL) !== -1) {

    optionsPVS.push({
      label: PRODUCTS_LIST_LABELS[SEMI_FINISH_DIGITAL_MODEL],
      value: PRODUCTS_LIST_VALUES[SEMI_FINISH_DIGITAL_MODEL],
      checked: (currentValue & PRODUCTS_LIST_VALUES[SEMI_FINISH_DIGITAL_MODEL]) > 0
    });
    // }
    // if (preferenceValue.indexOf(ROUGH_DIGITAL_MODEL) !== -1) {

    optionsPVS.push({
      label: PRODUCTS_LIST_LABELS[ROUGH_DIGITAL_MODEL],
      value: PRODUCTS_LIST_VALUES[ROUGH_DIGITAL_MODEL],
      checked: (currentValue & PRODUCTS_LIST_VALUES[ROUGH_DIGITAL_MODEL]) > 0
    });
    // }
    // if (preferenceValue.indexOf(DIGITAL_STORAGE_ONLY) !== -1) {
    optionsPVS.push({
      label: PRODUCTS_LIST_LABELS[DIGITAL_STORAGE_ONLY],
      value: PRODUCTS_LIST_VALUES[DIGITAL_STORAGE_ONLY],
      checked: (currentValue & PRODUCTS_LIST_VALUES[DIGITAL_STORAGE_ONLY]) > 0
    });
    // }
  }

  if (!currentImpression || isIntraOralScans) {
    // if (preferenceValue.indexOf(CLEANUP) !== -1) {
    optionsOral.push({
      label: PRODUCTS_LIST_LABELS[CLEANUP],
      value: PRODUCTS_LIST_VALUES[CLEANUP],
      checked: (currentValue & PRODUCTS_LIST_VALUES[CLEANUP]) > 0
    });
    //   }
    // if (preferenceValue.indexOf(CLEANUP_BASE) !== -1) {
    optionsOral.push({
      label: PRODUCTS_LIST_LABELS[CLEANUP_BASE],
      value: PRODUCTS_LIST_VALUES[CLEANUP_BASE],
      checked: (currentValue & PRODUCTS_LIST_VALUES[CLEANUP_BASE]) > 0
    });
    //   }
  }

  // if (preferenceValue.indexOf(PRINTING_OPTIONS) !== -1) {
  options3DP.push({
    label: PRODUCTS_LIST_LABELS[PRINTING_OPTIONS],
    value: PRODUCTS_LIST_VALUES[PRINTING_OPTIONS],
    checked: (currentValue & PRODUCTS_LIST_VALUES[PRINTING_OPTIONS]) > 0
  });
  //   }
  // }

  useEffect(() => {
    const isIntraOralScans =
      (currentImpression & IMPRESSION_TYPE_VALUES[IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]) > 0;

    const PVSorStone =
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.FULL_DIGITAL_MODEL] |
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.SEMI_FINISH_DIGITAL_MODEL] |
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.ROUGH_DIGITAL_MODEL] |
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.DIGITAL_STORAGE_ONLY];

    const IntraOral =
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.CLEANUP] |
      PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.CLEANUP_BASE];

    if (isIntraOralScans) {
      if ((currentValue & PVSorStone) > 0) {
        setValue(currentValue & ~PVSorStone);
        console.log('RESET3');
      }
    } else if (currentImpression) {
      if ((currentValue & IntraOral) > 0) {
        setValue(currentValue & ~IntraOral);
        console.log('RESET4');
      }
    }
  }, [currentImpression, currentValue, setValue]);

  const handleChange = (checked, val) => {
    if (checked) setValue(val | (currentValue & PRODUCTS_LIST_VALUES[PRINTING_OPTIONS]));
    else setValue(currentValue & PRODUCTS_LIST_VALUES[PRINTING_OPTIONS]);
  };

  const handleChangeLast = (checked, val) => {
    if (checked) setValue(currentValue | val);
    else setValue(currentValue & ~val);
  };

  return (
    <div className={classes.container}>
      <div className={classes.verticalLine} />
      <FormLabel component="legend">Select products: *</FormLabel>
      <div className={classes.checkboxGroupContainer}>
        <div className={classes.checkboxGroupContainer2}>
          {optionsPVS.length > 0 && (
            <CheckboxGroupField
              options={optionsPVS}
              name={ATTRIBUTES.PRODUCTS_LIST_METHOD}
              {...props}
              fieldProps={{ row: false }}
              onChange={handleChange}
              appendLabel={
                <span className={classes.appendLabel}>
                  From PVS/Allginate Impressions or Stone Model
                </span>
              }
              partial={true}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
        <div className={classes.checkboxGroupContainer2}>
          {optionsOral.length > 0 && (
            <CheckboxGroupField
              options={optionsOral}
              name={ATTRIBUTES.PRODUCTS_LIST_METHOD}
              {...props}
              fieldProps={{ row: false }}
              onChange={handleChange}
              appendLabel={<span className={classes.appendLabel}>From Intra-oral Scans</span>}
              partial={true}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </div>
      <div className={classes.separator} />
      {options3DP.length > 0 && (
        <div className={classes.checkboxGroupContainer}>
          <CheckboxGroupField
            options={options3DP}
            name={ATTRIBUTES.PRODUCTS_LIST_METHOD}
            {...props}
            fieldProps={{ row: false }}
            onChange={handleChangeLast}
            appendLabel={
              <span className={classes.appendLabel}>3D Printed Study Model Options</span>
            }
            partial={true}
            isSubmitting={isSubmitting}
          />
        </div>
      )}
    </div>
  );
};

ProductsListField.defaultProps = {};

ProductsListField.propTypes = {
  preferenceValue: PropTypes.number, // TODO(Marcin): Should be required!!!
  currentValue: PropTypes.number.isRequired,
  currentImpression: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired
};

export default connect(({ formik: { values: encodeValues, setFieldValue }, ...props }) => {
  const values = decodeFormikValues(encodeValues);
  const currentValue = values[ATTRIBUTES.PRODUCTS_LIST_METHOD];
  const currentImpression = values[ATTRIBUTES.IMPRESSION_TYPE_METHOD];

  return (
    <ProductsListField
      setValue={value => setFieldValue(ATTRIBUTES.PRODUCTS_LIST_METHOD, String(value))}
      currentValue={currentValue}
      currentImpression={currentImpression}
      {...props}
    />
  );
});
