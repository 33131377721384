import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { findRolesInOrganization } from '../../helpers';

const CheckOrganization = ({
  organizationRoles,
  initialSetupRequired,
  checkOrganization,
  children,
  organization
}) => {
  const user = useSelector(state => state.auth.user);
  const currentOrganization = useSelector(state => organization || state.auth.currentOrganization);
  const isManagement = useSelector(state => state.auth.isManagement);
  const currentLocation = useLocation();

  const initialSetupRoute = '/initial-setup';
  const mainLocation = get(currentOrganization, 'main_location');

  if (isManagement || !checkOrganization) {
    return children;
  }

  if (!user.organizations.length) {
    return <ErrorMessage message="The user doesn't have organizations." />;
  }

  if (!currentOrganization) {
    return <ErrorMessage message="There is no organization selected." />;
  }

  if (
    organizationRoles.length &&
    !findRolesInOrganization(organizationRoles, currentOrganization)
  ) {
    return <ErrorMessage message="You don't have permission to access this section." />;
  }

  if (initialSetupRequired && !mainLocation) {
    return <Redirect to={initialSetupRoute} />;
  }

  if (currentLocation.pathname === initialSetupRoute && mainLocation) {
    return <Redirect to="/dashboard" />;
  }

  return children;
};

CheckOrganization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  organizationRoles: PropTypes.arrayOf(PropTypes.string)
};

CheckOrganization.defaultProps = {};

export default CheckOrganization;
