import { useSelector } from 'react-redux';

const MakeHidden = ({ profile, children }) => {
  const currentProfile = useSelector(state => (state.auth.isManagement ? 'management' : 'normal'));

  if (currentProfile === profile) {
    return null;
  }

  return children;
};

MakeHidden.defaultProps = {
  yes: () => null,
  no: () => null
};

export default MakeHidden;
