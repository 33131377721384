import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RxFormProvider from '../../components/RxForm/RxFormProvider';
import { useRxForm, useRxFormPreference } from '../../components/RxForm/hooks';
import Form from '../../components/RxForm/Form';
import RxFormsApiService from '../../services/api/rxForms';
import { enqueueNotification } from '../../reducers/notifications';
import LoadAvailableLocations from '../../components/LoadAvailableLocations';
import LoadingButton from '../../components/LoadingButton';
import { overridePreferenceWithModificationRules } from '../../components/RxForm/helpers';
import withOrganizationPricing from '../../hooks/withOrganizationPricing';

const Main = ({
  rxForm,
  preference,
  afterSave,
  onSaveFailure,
  onCancel,
  modificationRules,
  ...props
}) => {
  const patientDetails = `Patient: ${rxForm.last_name}, ${rxForm.first_name} - ${rxForm.organization.name}`;
  const onlyAttributes = Object.keys(modificationRules);
  const [saving, setSaving] = useState(false);

  const modifiedPreferences = overridePreferenceWithModificationRules(
    preference,
    modificationRules
  );

  const save = data => {
    const api = new RxFormsApiService();
    setSaving(true);
    api
      .patch(data.id, data)
      .then(() => {
        setSaving(false);
        afterSave();
      })
      .catch(error => {
        setSaving(false);
        onSaveFailure(error);
      });
  };
  const children = pricing => (
    <Container maxWidth="md">
      <Form
        organization={rxForm.organization}
        title="Modify RxForm"
        description={patientDetails}
        preference={modifiedPreferences}
        onSubmit={save}
        rxForm={rxForm}
        isLoading={false}
        only={onlyAttributes}
        {...props}
        actions={({ dirty, isValid, submitForm, status }) => {
          return (
            <>
              <Button disabled={saving} color="secondary" variant="contained" onClick={onCancel}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={saving}
                disabled={!dirty || !isValid || !!status}
                onClick={submitForm}
              >
                Save
              </LoadingButton>
            </>
          );
        }}
        pricing={pricing}
      />
    </Container>
  );

  return withOrganizationPricing({ organizationId: rxForm.organization.id, children });
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preference: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  modificationRules: PropTypes.object.isRequired,
  afterSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveFailure: PropTypes.func.isRequired
};

const Wrapper = () => {
  const { rxForm, modificationRules } = useRxForm();
  const {
    preference,
    third_party_partner: { name: thirdPartyPartnerName }
  } = useRxFormPreference();
  const dispatch = useDispatch();
  const history = useHistory();

  if (modificationRules.length === 0) {
    history.push('/patients');
    return '';
  }

  const redirect = `/patient-details/${rxForm.patient_id}`;

  return (
    <LoadAvailableLocations organizationId={rxForm.organization_id}>
      {availableLocations => (
        <Main
          rxForm={rxForm}
          preference={preference}
          modificationRules={modificationRules}
          thirdPartyPartnerName={thirdPartyPartnerName}
          availableLocations={availableLocations}
          afterSave={() => {
            dispatch(enqueueNotification('success', 'RxForm successfully updated.'));
            history.push(redirect);
          }}
          onSaveFailure={error => {
            dispatch(enqueueNotification('error', error.message));
          }}
          onCancel={() => (history.length && history.goBack()) || history.push(redirect)}
        />
      )}
    </LoadAvailableLocations>
  );
};

export default ({
  // eslint-disable-next-line react/prop-types
  match: {
    // eslint-disable-next-line react/prop-types
    params: { id }
  }
}) => {
  return (
    <RxFormProvider id={Number(id)} withPreference>
      <Wrapper />
    </RxFormProvider>
  );
};
