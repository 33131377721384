import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Redirect from './components/Redirect';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Notifier from './components/Notifier/Notifier';
import SignUp from './pages/SignUp';
import SignUpSuccess from './pages/SignUp/SignUpSuccess';
import ForgotPassword from './pages/ForgotPassword';
import NotFoundPage from './pages/NotFoundPage';
import UserVerification from './pages/UserVerification';
import ResetPassword from './pages/ResetPassword';
import PrintingFiles from './pages/PrintingFiles';
import Account from './pages/Account';
import Users from './pages/Users';
import Licenses from './pages/Licenses';
import AddLicense from './pages/Licenses/AddLicense';
import PrivateRoute from './components/PrivateRoute';
import Patients from './pages/Patients';
import PatientDetails from './pages/Patients/PatientDetails';
import AccountInitialSetup from './pages/AccountInitialSetup';
import Basic from './components/Layout/Basic';
import GlobalSettings from './components/GlobalSettings';
import RecurringPayments from './pages/RecurringPayments';
import RecurringPaymentDetails from './pages/RecurringPayments/RecurringPaymentDetails';
import UserDetails from './pages/Users/UserDetails';
// import PromotionCodes from './pages/PromotionCodes';
// import AddPromotionCode from './pages/PromotionCodes/AddPromotionCode';
// import UpdatePromotionCode from './pages/PromotionCodes/UpdatePromotionCode';
import AddPeriodicRule from './pages/PeriodicRule/AddPeriodicRule';
import UpdatePeriodicRule from './pages/PeriodicRule/UpdatePeriodicRule';
import AddPatient from './pages/Patients/AddPatient';
import Settings from './pages/Settings';
import AddRxForm from './pages/RxForms/AddRxForm';
// import Requests from './pages/Requests';
import RequestDetails from './pages/Requests/RequestDetails';
import PrintPatientSummary from './pages/PrintPatientSummary';
import PatientsPage from './pages/PatientsPage';
import CaseTraysPage from './pages/CaseTrays';
import PatientWorkflow from './pages/PatientWorkflow';
import ScrollToTop from './components/ScrollToTop';
import Locations from './pages/Locations';
import AddLocation from './pages/Locations/AddLocation';
import UpdateLocation from './pages/Locations/UpdateLocation';
import Organizations from './pages/Organizations';
import AddUser from './pages/Users/AddUser';
import PublicRoute from './components/PublicRoute';
import UpdateOrganization from './pages/Organizations/UpdateOrganization';
import CreateUser from './pages/CreateUser';
import ConfirmInvitation from './pages/ConfirmInvitation';
import RedirectToDesktopApp from './pages/RedirectToDesktop';
import Onboardings, { Manage as ManageOnboarding } from './pages/Onboarding';
import GlobalSearch from './pages/GlobalSearch';
import UpdateRxForm from './pages/RxForms/UpdateRxForm';
// import Reports from './pages/Reports';
import Viewer3D from './pages/3DViewer';
import PrintRxFormSummary from './pages/PrintRxForm';
import CreateOrganization from './pages/Organizations/create';
import AddOldCase from './pages/OldCase/AddOldCase';

function App() {
  return (
    <>
      <Helmet>
        <title>DSM</title>
      </Helmet>
      <Notifier />
      <GlobalSettings>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/management" exact render={props => <Login management {...props} />} />
            <Route path="/dibs" component={RedirectToDesktopApp} />
            <Route path="/verify-user" component={UserVerification} />
            <PublicRoute path="/sign-up" component={SignUp} />
            <PublicRoute path="/sign-up-success" component={SignUpSuccess} />
            <PublicRoute path="/create-user" component={CreateUser} />
            <PublicRoute path="/forgot-password" component={ForgotPassword} />
            <PublicRoute path="/reset-password" component={ResetPassword} />
            <PrivateRoute
              path="/confirm-invitation"
              component={ConfirmInvitation}
              initialSetupRequired={false}
              checkOrganization={false}
              layout={Basic}
            />
            <PrivateRoute name="dashboard" path="/dashboard" component={Dashboard} />

            <PrivateRoute path="/patients" component={Patients} />

            <PrivateRoute exact path="/patient-details/:id" component={PatientDetails} />

            <PrivateRoute
              exact
              path="/3dviewer/:viewerId/organization/:orgID/patient/:patID/:name"
              component={Viewer3D}
              layout={Basic}
            />

            <PrivateRoute
              exact
              path="/patient-details/:id/print"
              component={PrintPatientSummary}
              title="Print Patient Summary"
              layout={({ children }) => children}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />

            <PrivateRoute
              exact
              path="/patient-details/:id/print-rxform"
              component={PrintRxFormSummary}
              title="Print Rx Form"
              layout={({ children }) => children}
            />

            <PrivateRoute
              path="/printing-files"
              component={PrintingFiles}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/onboardings/:id"
              component={ManageOnboarding}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/onboardings"
              component={Onboardings}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute
              exact
              path="/users"
              component={Users}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/users/add"
              component={AddUser}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/organizations/add"
              component={CreateOrganization}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/users/:id"
              component={UserDetails}
              roles={['super_admin', 'admin', 'tech_support', 'lab_partner', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/organizations/:organizationId/patients"
              component={Patients}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/users/:userId/patients/add"
              component={AddPatient}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/licenses"
              component={Licenses}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            {/* <PrivateRoute exact path="/requests" component={Requests} /> */}
            <PrivateRoute exact path="/requests/:id" component={RequestDetails} />
            <PrivateRoute
              exact
              path="/organizations/:organizationId/locations"
              component={Locations}
              detectOrganization
              roles={['super_admin', 'admin', 'lab_partner', 'supervisor']}
            />
            <PrivateRoute
              exact
              detectOrganization
              path="/organizations/:organizationId/locations/add"
              component={AddLocation}
              roles={['super_admin', 'admin', 'lab_partner', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/locations"
              component={Locations}
              roles={['doctor', 'lab_partner']}
              detectOrganization
            />
            <PrivateRoute
              exact
              detectOrganization
              path="/locations/add"
              component={AddLocation}
              roles={['doctor', 'lab_partner']}
            />
            <PrivateRoute
              exact
              path="/locations/:id"
              detectOrganization
              component={UpdateLocation}
              roles={['doctor', 'lab_partner']}
            />
            <PrivateRoute
              exact
              path="/organizations/:organizationId/locations/:id"
              detectOrganization
              component={UpdateLocation}
            />
            <PrivateRoute
              exact
              path="/organizations"
              component={Organizations}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner']}
            />
            <PrivateRoute
              exact
              path="/organizations/:id"
              component={UpdateOrganization}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner']}
            />
            <PrivateRoute exact path="/rx-forms/:id/modify" component={UpdateRxForm} />

            <PrivateRoute
              exact
              path="/organizations/:organizationId/rx-forms/add"
              component={AddRxForm}
            />

            <PrivateRoute
              exact
              path="/organizations/:organizationId/old-cases/add"
              roles={['super_admin', 'admin']}
              component={AddOldCase}
            />

            {/* <PrivateRoute
              exact
              path="/promotion-codes"
              component={PromotionCodes}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/promotion-codes/add"
              component={AddPromotionCode}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            /> */}
            {/* <PrivateRoute
              exact
              path="/promotion-codes/:id"
              component={UpdatePromotionCode}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            /> */}
            <PrivateRoute
              exact
              path="/subscriptions/:contract_id/periodic-rules/add"
              component={AddPeriodicRule}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/subscriptions/:contract_id/periodic-rules/:id"
              component={UpdatePeriodicRule}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/subscriptions"
              component={RecurringPayments}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              exact
              path="/subscriptions/:id"
              component={RecurringPaymentDetails}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/add-license"
              component={AddLicense}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/edit-license/:id"
              component={AddLicense}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/settings"
              component={Settings}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            <PrivateRoute
              path="/initial-setup"
              component={AccountInitialSetup}
              layout={Basic}
              initialSetupRequired={false}
              organizationRoles={['organization_owner']}
              profiles={['normal']}
            />
            <PrivateRoute
              exact
              path="/patients-page"
              component={PatientsPage}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner']}
            />
            <PrivateRoute
              exact
              path="/case-trays"
              component={CaseTraysPage}
              roles={['super_admin', 'admin']}
            />

            <PrivateRoute
              exact
              path="/case-trays/:caseTrayId"
              component={PatientsPage}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner']}
            />

            <PrivateRoute
              exact
              path="/patient-workflow/:id"
              component={PatientWorkflow}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner']}
            />
            <PrivateRoute
              exact
              path="/organizations-users"
              component={GlobalSearch}
              roles={['super_admin', 'admin', 'tech_support', 'supervisor']}
            />
            {/* <PrivateRoute
              exact
              path="/export-reports"
              component={Reports}
              roles={['super_admin']}
            /> */}
            <PrivateRoute exact path="/empty" component={() => null} />
            <Redirect exact from="/" management="patients-page" organization="patients" />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </GlobalSettings>
    </>
  );
}

export default App;
