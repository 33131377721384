import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import createLoadingSelector from '../../../selectors/loading';
import OrganizationForm from '../../../components/OrganizationForm';
import { UI_SETTINGS } from '../../../constants';
import {
  DELETE_ORGANIZATION_PREFIX,
  deleteOrganizationAction,
  UPDATE_ORGANIZATION_PREFIX,
  updateOrganizationAction
} from '../../../reducers/organizations';
import OrganizationMembers from '../../../components/OrganizationMembers';
import Can from '../../../components/Can';
import InviteOrganizationMember from '../../../components/InviteOrganizationMember';
import AssignedLocations from '../../../components/AssignedLocations';
import CurrentOrganizationBillingAddress from './CurrentOrganizationBillingAddress';
import ContactInformation from '../../../components/ContactInformation';

const OrganizationInformation = () => {
  const dispatch = useDispatch();
  const organization = useSelector(state => state.auth.currentOrganization);
  const isSaving = useSelector(state => createLoadingSelector([UPDATE_ORGANIZATION_PREFIX])(state));
  const isDeletingOrganization = useSelector(state =>
    createLoadingSelector([DELETE_ORGANIZATION_PREFIX])(state)
  );
  const isManagement = useSelector(state => state.auth.isManagement);

  const updateProfile = values => {
    dispatch(updateOrganizationAction({ ...values }, organization.id));
    return true;
  };

  const deleteOrganization = id => {
    return dispatch(deleteOrganizationAction(id));
  };

  if (!organization) {
    return null;
  }

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader
            title="Organization Information"
            subheader={
              organization.disabled_at && (
                <Chip size="small" label="Organization Disabled" color="secondary" />
              )
            }
          />
          <Divider />
          <Can
            permissions={['organization.update']}
            yes={() => (
              <OrganizationForm
                isSaving={isSaving}
                onSubmit={updateProfile}
                organization={organization}
                onDelete={!isManagement ? deleteOrganization : null}
                isDeleting={isDeletingOrganization}
                mode={organization.disabled_at ? 'show' : null}
              />
            )}
            no={() => (
              <OrganizationForm
                isSaving={isSaving}
                onSubmit={updateProfile}
                organization={organization}
                mode="show"
              />
            )}
          />
        </Card>
      </Box>
      {!organization.disabled_at && (
        <>
          <Can
            permissions={['organization.update']}
            yes={() => (
              <Box my={2}>
                <InviteOrganizationMember organizationId={organization.id} />
              </Box>
            )}
          />

          <Box my={2}>
            <AssignedLocations organizationId={organization.id} />
          </Box>

          <Can
            permissions={['organization.update']}
            yes={() => (
              <>
                <Box my={2}>
                  <ContactInformation organizationId={organization.id} />
                </Box>

                <Box my={2}>
                  <OrganizationMembers organizationId={organization.id} disabledOwnUser />
                </Box>

                {!organization.disabled_at && (
                  <Box my={2}>
                    <CurrentOrganizationBillingAddress />
                  </Box>
                )}
              </>
            )}
          />
        </>
      )}
    </Box>
  );
};

export default OrganizationInformation;
