import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class LicensesApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({ perPage = 5, page = 1, order, orderBy, searchFullNameQuery = '' }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'full_name_sort';
    return this.request({
      method: 'get',
      url: this.query
        .for('licenses')
        .include('user')
        .where('full_name_like', searchFullNameQuery)
        .where('hide_cancelled', true)
        .sort(orderChar + orderByField, '-id')
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/licenses/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/licenses',
      data
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/licenses/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/licenses/${id}`
    });
  }

  deleteDevice(id) {
    return this.request({
      method: 'delete',
      url: `/devices/${id}`
    });
  }

  updateDevice(id, data) {
    return this.request({
      method: 'put',
      url: `/devices/${id}`,
      data
    });
  }

  getLicenseTypes() {
    return this.request({
      method: 'get',
      url: '/license_types'
    });
  }

  setStatus(id, active) {
    return this.request({
      method: 'post',
      url: `/licenses/${id}/set_status`,
      data: {
        active
      }
    });
  }
}
