import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const MoneyFormat = ({ value, fixedDecimalScale }) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator
      decimalScale="2"
      fixedDecimalScale={fixedDecimalScale}
      prefix="$"
    />
  );
};

MoneyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fixedDecimalScale: PropTypes.bool
};

MoneyFormat.defaultProps = {
  fixedDecimalScale: true
};

export default MoneyFormat;
