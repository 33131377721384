import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { connectHits } from 'react-instantsearch-dom';

const Hits = ({ hits, hitComponent: HitComponent }) => {
  if (_.isEmpty(hits))
    return (
      <Box className="empty-hits">
        <Typography variant="h3">Whoops!</Typography>
        <br />
        <Typography variant="subtitle2">
          We couldn't find any results matching your search! Please, try using different keywords.
        </Typography>
      </Box>
    );

  return (
    <Box>
      {hits.map(hit => (
        <HitComponent key={`${Math.random() * 100}_${hit.model_id}`} hit={hit} />
      ))}
    </Box>
  );
};

Hits.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hits: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  hitComponent: PropTypes.func.isRequired
};

const CustomHits = connectHits(Hits);

export default CustomHits;
