import { Box, Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import OrganizationsFilter from '../../OrganizationsFilter';
import { setIsOpenOrganizationTopBar } from '../../../reducers/auth';

const useStyles = makeStyles(() => ({
  topBar: {
    '& .MuiPaper-root': {
      zIndex: 1500
    }
  }
}));

const OrganizationsTopBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    dispatch(setIsOpenOrganizationTopBar(false));
  }, [location, dispatch]);

  const isOrganizationTopBarOpen = useSelector(state => state.auth.isOrganizationTopBarOpen);

  const close = () => {
    dispatch(setIsOpenOrganizationTopBar(false));
  };

  return (
    <Drawer
      anchor="top"
      variant="persistent"
      open={isOrganizationTopBarOpen}
      className={classes.topBar}
    >
      <Box mt={1}>
        <OrganizationsFilter />
        <Box width="90%" display="flex" justifyContent="flex-end" my={1}>
          <Button onClick={() => close()} color="secondary" startIcon={<CloseIcon />}>
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OrganizationsTopBar;
