import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      backgroundColor: theme.palette.error.dark,
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius * 3,
      padding: theme.spacing(2)
    },
    header: {
      color: 'white'
    },
    link: {
      marginTop: theme.spacing(1),
      color: 'white',
      fontSize: theme.typography.pxToRem(16)
    }
  };
});

const OrthoBrainLink = ({ hide }) => {
  const classes = useStyles();

  if (hide) {
    return '';
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" align="center" className={classes.header}>
        Now Offering Aligners!
      </Typography>
      <a
        href="https://orthobrain.com/orthoselect/"
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Click Here for Details
      </a>
    </div>
  );
};

OrthoBrainLink.propTypes = {
  hide: PropTypes.bool.isRequired
};

export default connect(state => {
  return {
    hide: state.auth.isManagement || !state.auth.showPromotion
  };
})(OrthoBrainLink);
