import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { addDiscountOrChargeToOrderAction } from '../../reducers/orders';
import FormDialog from '../FormDialog';

const AddDiscountToOrder = ({ open, setOpen, order, onAddedDiscount }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ value: '', custom_description: '' });

  const onContinueHandler = () => {
    dispatch(addDiscountOrChargeToOrderAction(order.id, data)).then(response => {
      if (response) {
        onAddedDiscount();
      }
    });
  };

  const handleOnCloseFormDialog = response => {
    if (response === true) {
      onContinueHandler(data);
    }

    setData({
      value: '',
      custom_description: ''
    });

    setOpen(false);
  };

  const handleChangeFormDialogElement = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText="Add or Discount"
      acceptButtonText="Continue"
      handleClose={handleOnCloseFormDialog}
      isAcceptButtonDisabled={data.custom_description === '' || data.value === ''}
    >
      <TextField
        autoFocus
        margin="dense"
        id="custom_description"
        name="custom_description"
        label="Description"
        type="text"
        required
        value={data.custom_description}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
      <TextField
        margin="dense"
        id="name"
        name="value"
        label="Value"
        type="text"
        required
        value={data.value}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
    </FormDialog>
  );
};

AddDiscountToOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
  onAddedDiscount: PropTypes.func.isRequired
};

AddDiscountToOrder.defaultProps = {};

export default AddDiscountToOrder;
