import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Main from '../Layout/Main';
import WSNotificationHandler from '../WSNotificationHandler';
import CheckAuthentication from '../CheckAuthentication';
import CheckAuthorization from '../CheckAuthorization';
import CheckOrganization from '../CheckOrganization';
import withOrganization from '../../hooks/withOrganization';

const DetectOrganization = ({ detect, children, ...props }) => {
  if (detect) {
    const organizationId = props.match.params.organizationId
      ? Number(props.match.params.organizationId)
      : null;
    return withOrganization({ organizationId, children });
  }
  return children({ organization: null });
};

DetectOrganization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
};

const PrivateRoute = ({
  component: Component,
  layout: ContainerLayout,
  roles,
  profiles,
  initialSetupRequired,
  organizationRoles,
  checkOrganization,
  detectOrganization,
  ...rest
}) => {
  const user = useSelector(state => state.auth.user);
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <CheckAuthentication
            path={`${rest.location.pathname}${rest.location.search}`}
            roles={roles}
          >
            <CheckAuthorization profiles={profiles} roles={roles}>
              <ContainerLayout>
                <WSNotificationHandler user={user} />
                <DetectOrganization detect={detectOrganization} {...props}>
                  {({ organization }) => {
                    let componentProps = { ...props };
                    if (organization && organization.data) {
                      componentProps = { organization, ...props };
                    }
                    return (
                      <CheckOrganization
                        organization={organization && organization.data}
                        organizationRoles={organizationRoles}
                        initialSetupRequired={initialSetupRequired}
                        checkOrganization={checkOrganization}
                      >
                        <Component {...componentProps} />
                      </CheckOrganization>
                    );
                  }}
                </DetectOrganization>
              </ContainerLayout>
            </CheckAuthorization>
          </CheckAuthentication>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.func,
  roles: PropTypes.arrayOf(PropTypes.string),
  initialSetupRequired: PropTypes.bool,
  organizationRoles: PropTypes.arrayOf(PropTypes.string),
  profiles: PropTypes.arrayOf(PropTypes.string),
  checkOrganization: PropTypes.bool,
  detectOrganization: PropTypes.bool
};

PrivateRoute.defaultProps = {
  layout: Main,
  roles: [],
  initialSetupRequired: true,
  organizationRoles: [],
  checkOrganization: true,
  profiles: ['normal', 'management'],
  detectOrganization: false
};

export default PrivateRoute;
