import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { QUESTIONS } from '../constants';
import withDependency from './hooks/withDependency';
import InputLabel from '../../Form/InputLabel';

const useStyles = makeStyles(theme => ({
  addressField: {
    marginTop: 10
  },
  addressFieldInput: {
    color: theme.palette.primary.dark
  }
}));

const LocationField = ({ name, setValue, locations }) => {
  const classes = useStyles();
  const {
    values: { [name]: value }
  } = useFormikContext();
  const currentValue = value && Number(value);

  useEffect(() => {
    const exists = locations.find(el => el.id === currentValue);
    if (!exists && locations.length) {
      setValue(locations[0].id);
    }
  }, [setValue, currentValue, locations]);

  const currentLocation = locations.find(el => el.id === currentValue);
  if (!currentLocation) {
    return '';
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>{QUESTIONS[name]}</InputLabel>
      <Field labelId={`${name}-label`} required name={name} component={Select} labelWidth={65}>
        {locations.map(location => {
          return (
            <MenuItem key={location.id} value={location.id}>
              {location.location_name}
            </MenuItem>
          );
        })}
      </Field>
      {currentLocation && (
        <TextField
          className={classes.addressField}
          disabled
          fullWidth
          label="Address"
          multiline
          value={currentLocation.full_address}
          variant="outlined"
          InputProps={{
            classes: { input: classes.addressFieldInput }
          }}
        />
      )}
    </FormControl>
  );
};

LocationField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};

export default withDependency(LocationField);
