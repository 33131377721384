import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrintCompletionDialog from './PrintCompletionDialog';
import { completeAsBundle } from '../../../reducers/requests';
import {
  cancelSelectionForBundlingPatients,
  fetchSpecificPatientsOnly
} from '../../../reducers/patients';
import { COMMON_PATIENT_INCLUDES } from '../../../constants/queries';

const BundleCompletionDialog = ({ patients, submit, close }) => {
  const handleContinue = ({
    tracking_number: trackingNumber,
    custom_shipping_fee: customShippingFee
  }) => submit(patients, trackingNumber, customShippingFee);

  const handleOpen = open => {
    if (!open) {
      close();
    }
  };

  return (
    <PrintCompletionDialog
      open={patients !== null}
      setOpen={handleOpen}
      onContinue={handleContinue}
      title="Bundled Shipping"
    />
  );
};

BundleCompletionDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patients: PropTypes.array,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

BundleCompletionDialog.defaultProps = {
  patients: null
};

export default connect(
  state => ({
    patients: state.patients.selection.bundleShipment
  }),
  dispatch => ({
    submit: (patients, trackingNumber, customShippingFee) => {
      const requestIds = patients.map(patient => patient.customer_request.id);
      dispatch(completeAsBundle(requestIds, trackingNumber, customShippingFee)).then(() => {
        const patientIds = patients.map(item => item.id);
        dispatch(fetchSpecificPatientsOnly(patientIds, COMMON_PATIENT_INCLUDES));
      });
    },
    close: () => {
      dispatch(cancelSelectionForBundlingPatients());
    }
  })
)(BundleCompletionDialog);
