import Card from '@material-ui/core/Card';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Select } from '@material-ui/core';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import { DatePicker } from 'antd';
import moment from 'moment';
import DetailItem from '../../../components/DetailItem';
import AddressCard from '../../../components/Address';
import StatusTag from '../../../components/StatusTag';
import LoadingButton from '../../../components/LoadingButton';
import Can from '../../../components/Can';
import { updateShippingFromRequestAction } from '../../../reducers/requests';
import ShippingMethodsApiService from '../../../services/api/shippingMethods';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PatientWorkflowContext from '../../PatientWorkflow/context/PatientWorkflowContext';

const ShippingDetails = ({ printingRequest, patient, readonly, Wrapper }) => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [mode, setMode] = useState('normal');
  const [shippingMethods, setShippingMethods] = useState([]);
  const [loadingShippingMethods, setLoadingShippingMethods] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const location = get(printingRequest, 'location') || patient.organization.main_location;
  const patientWorkflowContext = useContext(PatientWorkflowContext);

  const request = useSelector(state => {
    return state.requests.items[
      state.requests.items.findIndex(
        requestItem => requestItem.id === printingRequest.customer_request_id
      )
    ];
  });

  const getShippingMethods = async (locationId, assignment) => {
    const shippingMethodsService = new ShippingMethodsApiService();
    setLoadingShippingMethods(true);
    const { shipping_methods: data } = await shippingMethodsService.get({ locationId, assignment });
    setLoadingShippingMethods(false);
    setShippingMethods(
      data.filter(item => item.available || item.id === printingRequest.shipping_method_id)
    );
  };

  const changeMode = (currentMode, isCancelled = false, resetForm) => {
    if (currentMode === 'edit') {
      getShippingMethods(location.id, printingRequest.assigned_to);
    }

    if (isCancelled && typeof resetForm === 'function') {
      resetForm();
    }
    setMode(currentMode);
  };

  const handleOnChangeShippingMethod = (value, setValues, values) => {
    const shippingMethodIndex = shippingMethods.findIndex(item => item.id === parseInt(value, 10));
    // eslint-disable-next-line camelcase
    const { arrival_date, delivery_date_from, delivery_date_to } = shippingMethods[
      shippingMethodIndex
    ];

    setValues({
      ...values,
      shipping_method_id: value,
      expected_arrival_date: arrival_date,
      delivery_date_from,
      delivery_date_to
    });
  };

  const handleOnRangePickerChange = (dates, setValues, values) => {
    setValues({
      ...values,
      delivery_date_from: dates[0].format('YYYY-MM-DD'),
      delivery_date_to: dates[1].format('YYYY-MM-DD'),
      expected_arrival_date: dates[1].format('YYYY-MM-DD')
    });
  };

  const save = values => {
    setIsSaving(true);
    dispatch(
      updateShippingFromRequestAction(printingRequest.customer_request_id, printingRequest.id, {
        patient_id: patient.id,
        ...values
      })
    ).then(response => {
      if (response) {
        setMode('normal');
      }
      setIsSaving(false);
    });
  };

  if (!printingRequest) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        location_id: get(printingRequest, 'location.id'),
        shipping_method_id: printingRequest.shipping_method_id,
        expected_arrival_date: printingRequest.expected_arrival_date || '',
        delivery_date_from: printingRequest.delivery_date_from,
        delivery_date_to: printingRequest.delivery_date_to
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        location_id: Yup.string().required('Required'),
        shipping_method_id: Yup.number().required('Required'),
        expected_arrival_date: Yup.string().required('Required'),
        delivery_date_from: Yup.string().required('Required'),
        delivery_date_to: Yup.string().required('Required')
      })}
      onSubmit={values => {
        return save({
          ...values
        });
      }}
    >
      {({ values, dirty, isValid, errors, setValues, resetForm }) => {
        return (
          <Form noValidate>
            <Wrapper>
              {!readonly && (
                <CardHeader
                  title={
                    <>
                      <Typography variant="h5">Shipping Details</Typography>
                    </>
                  }
                  disableTypography
                />
              )}
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <DetailItem
                        label="Ship To"
                        value={
                          <>
                            <Box my={2}>
                              <AddressCard location={location} />
                            </Box>
                          </>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <DetailItem
                        label="Shipping Method"
                        labelXs={4}
                        value={
                          <>
                            {mode === 'edit' && shippingMethods.length > 0 && (
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel>Shipping Method</InputLabel>
                                <Select
                                  required
                                  name="shipping_method_id"
                                  id="shipping_method_id"
                                  labelWidth={130}
                                  value={values.shipping_method_id}
                                  onChange={e => {
                                    handleOnChangeShippingMethod(e.target.value, setValues, values);
                                  }}
                                  disabled={loadingShippingMethods}
                                >
                                  {shippingMethods.map(shippingMethod => {
                                    return (
                                      <MenuItem key={shippingMethod.id} value={shippingMethod.id}>
                                        {shippingMethod.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )}
                            {mode === 'edit' && loadingShippingMethods && <LoadingSpinner />}
                            {mode !== 'edit' && get(printingRequest, 'shipping_method.name') && (
                              <Box my={1}>
                                <StatusTag
                                  label={printingRequest.shipping_method.name}
                                  color="amber"
                                />
                              </Box>
                            )}
                          </>
                        }
                      />
                      <DetailItem
                        label="Estimated Delivery"
                        labelXs={4}
                        value={
                          <Box my={1}>
                            <Box component="span">
                              {mode === 'edit' ? (
                                <RangePicker
                                  onChange={value =>
                                    handleOnRangePickerChange(value, setValues, values)
                                  }
                                  value={[
                                    values.delivery_date_from
                                      ? moment(values.delivery_date_from, 'YYYY-MM-DD')
                                      : null,
                                    values.delivery_date_to
                                      ? moment(values.delivery_date_to, 'YYYY-MM-DD')
                                      : null
                                  ]}
                                />
                              ) : (
                                get(printingRequest, 'expected_arrival_date') || '-'
                              )}
                            </Box>
                          </Box>
                        }
                      />
                      {printingRequest.tracking_number && (
                        <DetailItem
                          label="Tracking Number"
                          labelXs={4}
                          value={
                            <Box my={1}>
                              <Box component="span">{printingRequest.tracking_number}</Box>
                            </Box>
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              {!readonly && request && request.status_id === 1 && (
                <Can
                  permissions={[
                    'printing-requests.update-shipping-information',
                    'printing-requests.manage',
                    'organization.printing-requests.update-shipping-information'
                  ]}
                  organizationId={get(patientWorkflowContext, 'patient.organization_id')}
                  yes={() => (
                    <>
                      <Divider />
                      <CardActions>
                        {mode === 'edit' && (
                          <Box ml="auto">
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              loading={isSaving}
                              disabled={isSaving || !dirty || !isValid}
                            >
                              Save
                            </LoadingButton>
                          </Box>
                        )}
                        <Box ml="auto">
                          <Button
                            type="button"
                            onClick={() =>
                              changeMode(
                                mode === 'normal' ? 'edit' : 'normal',
                                mode === 'edit',
                                resetForm
                              )
                            }
                            color={mode === 'edit' ? 'secondary' : 'primary'}
                            variant="contained"
                          >
                            {mode === 'edit' ? 'Cancel' : 'Edit'}
                          </Button>
                        </Box>
                      </CardActions>
                    </>
                  )}
                />
              )}
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

ShippingDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  printingRequest: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  Wrapper: PropTypes.elementType
};

ShippingDetails.defaultProps = {
  readonly: false,
  Wrapper: Card
};

export default ShippingDetails;
