import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import red from '@material-ui/core/colors/red';
import { Box } from '@material-ui/core';
import { fetchAnnouncementsAction } from '../../reducers/annoucement';
import { isTechnicianOrAdmin } from '../../helpers';
import announcementVisibilities from '../../constants/announcementVisibilities';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '100%'
  },
  alert_container: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  alert_title: {
    color: red['900']
  }
}));

const AnnouncementContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const announcements = useSelector(state =>
    state.announcement.announcements.filter(a => !!a.body)
  );
  const currentUser = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(fetchAnnouncementsAction());
  }, [dispatch]);

  if (
    announcements.length === 0 ||
    !['/patients', '/patients-page'].includes(_get(location, 'pathname'))
  ) {
    return null;
  }

  const filterAnnouncement = announcement => {
    if (!currentUser) {
      return false;
    }

    if (
      isTechnicianOrAdmin(currentUser) &&
      announcement.visibility === announcementVisibilities.DOCTOR
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.alert_container}>
            {announcements.filter(filterAnnouncement).map(announcement => (
              <Alert severity="error" key={announcement.id} icon={false}>
                <AlertTitle className={classes.alert_title}>Announcement</AlertTitle>
                <Box component="div" whiteSpace="break-spaces">
                  {announcement.body}
                </Box>
              </Alert>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AnnouncementContainer;
