import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PaymentsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  createFattmerchantCustomer(organizationId) {
    return this.request({
      method: 'post',
      url: `f_m_payment_infos`,
      data: {
        organization_id: organizationId
      }
    });
  }

  createFattmerchantPaymentMethod(id, organizationId) {
    return this.request({
      method: 'post',
      url: `f_m_payment_methods`,
      data: {
        payment_method_id: id,
        organization_id: organizationId
      }
    });
  }

  deleteMyPaymentMethod(id) {
    return this.request({
      method: 'delete',
      url: `f_m_payment_methods/${id}`
    });
  }

  updateMyPaymentMethod(id, data) {
    return this.request({
      method: 'put',
      url: `f_m_payment_methods/${id}`,
      data
    });
  }

  initialPurchase(promotionCodeId, organizationId) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/process_initial_purchase`,
      data: {
        promotion_code_id: promotionCodeId
      }
    });
  }
}
