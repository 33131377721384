import React from 'react';
import PropTypes from 'prop-types';

const renderTextLine = (text, newLine = true) => {
  if (!text) {
    return '';
  }
  return (
    <>
      {text}
      {newLine && <br />}
    </>
  );
};

const ShippingLabel = ({ location }) => {
  return (
    <>
      {renderTextLine(`ATTN: Dr. ${location.full_name}`)}
      {renderTextLine(location.street_address)}
      {renderTextLine(location.street_address_line_2)}
      {renderTextLine(
        `${location.city} ${location.state} ${location.zip_code}, ${location.country}`
      )}
    </>
  );
};

ShippingLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
};

export default ShippingLabel;
