import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import ColumnTableHeaderSortable from '../ColumnTableHeaderSortable';
import Can from '../../../Can';

const EnhancedTableHead = props => {
  const { columns, onSelectAllClick, numSelected, rowCount, selectableRows, showActions } = props;

  return (
    <TableHead>
      <TableRow>
        {selectableRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {columns.map(headCell => {
          if (headCell.hide) {
            return null;
          }
          let disableSort = headCell.disabledSorting;
          if (typeof disableSort === 'function') {
            disableSort = disableSort();
          }
          if (disableSort) {
            return (
              <Can
                key={headCell.id}
                permissions={headCell.permissions}
                yes={() => <TableCell width={headCell.width}>{headCell.label}</TableCell>}
              />
            );
          }

          return (
            <Can
              key={headCell.id}
              permissions={headCell.permissions}
              yes={() => <ColumnTableHeaderSortable {...props} headCell={headCell} />}
            />
          );
        })}
        {showActions && <TableCell>Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      customSortId: PropTypes.string
    })
  ).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  selectableRows: PropTypes.bool.isRequired,
  showActions: PropTypes.bool.isRequired
};

export default EnhancedTableHead;
