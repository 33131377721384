import { enqueueNotification } from './notifications';
import RolesApiService from '../services/api/roles';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_ROLES_PREFIX = 'FETCH_ROLES';
export const FETCH_ROLES_REQUEST_ACTION = `${FETCH_ROLES_PREFIX}_REQUEST_ACTION`;
export const FETCH_ROLES_SUCCESS_ACTION = `${FETCH_ROLES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ROLES_FAILURE_ACTION = `${FETCH_ROLES_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchRolesAction() {
  return dispatch => {
    dispatch({ type: FETCH_ROLES_REQUEST_ACTION });
    const RolesService = new RolesApiService();
    return RolesService.get()
      .then(response => {
        dispatch({ type: FETCH_ROLES_SUCCESS_ACTION, payload: response.roles });
      })
      .catch(error => {
        dispatch({ type: FETCH_ROLES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
