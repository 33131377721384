export default {
  general: {
    label: 'Impression Types & Products',
    filter: 'general'
  },
  pvs_or_stone_model: {
    label: 'PVS / Alignate or Stone Model',
    filter: 'pvs_or_stone_model'
  },
  intra_oral_scan: {
    label: 'Intra-Oral Scan',
    filter: 'intra_oral_scan'
  },
  '3d_printed_study': {
    label: '3D Printed Study Model',
    filter: '3d_printed_study'
  }
};
