import React from 'react';
import PropTypes from 'prop-types';
import { assignmentOptions } from '../helpers';
import RadioGroupField from './RadioGroupField';

const AssignmentField = ({ options, thirdPartyPartnerName, ...props }) => {
  const availableAssignments = assignmentOptions({
    assignments: options,
    thirdPartyName: thirdPartyPartnerName
  });

  return <RadioGroupField options={availableAssignments} {...props} />;
};

AssignmentField.defaultProps = {
  thirdPartyPartnerName: null
};

AssignmentField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  thirdPartyPartnerName: PropTypes.string
};

export default AssignmentField;
