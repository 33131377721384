import React from 'react';
import {
  ARCH_LABELS,
  ARCH_MODE_LABELS,
  IMPRESSION_TYPE_LABELS,
  VIEW_LABEL
} from '../../components/RxForm/constants';
import { hasPrintingOption, UTCDateTimeToLocalDateTimeReadable } from '../../helpers';
import _ from 'lodash';

const Table = ({ patient }) => {
  return (
    <div className="rx-form">
      <div className={patient.rx_form.rush_case ? 'rush-case-border' : ''}>
        <div>
          <table className="print-rx-form">
            <tbody>
              <tr className="header">
                <td colSpan={patient.rx_form.rush_case ? 1 : 2}>
                  {IMPRESSION_TYPE_LABELS[patient.rx_form.impression_type] || 'Not Specified'}
                </td>
                {patient.rx_form.rush_case && <td className="text-center">RUSH</td>}
              </tr>
              <tr>
                <td colSpan={2} />
              </tr>
              <tr className="section">
                <td colSpan={2} className="sub-table bb-0">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p className="label text-right">DATE SUBMITTED</p>
                        </td>
                        <td>
                          <p className="value truncate">
                            <strong>
                              {UTCDateTimeToLocalDateTimeReadable(patient.created_at)}
                            </strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label text-right">Organization</p>
                        </td>
                        <td>
                          <p className="value truncate">{patient.organization.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label text-right">Dr. Name</p>
                        </td>
                        <td>
                          <p className="value truncate">
                            {patient.user.first_name} <strong>{patient.user.last_name}</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label text-right">Dr. Number</p>
                        </td>
                        <td>
                          <p className="value truncate">{patient.user.account_number}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label text-right">City, State Zip</p>
                        </td>
                        <td>
                          <p className="value truncate">
                            {_.get(patient, 'organization.main_location.city')}, &nbsp;
                            {_.get(patient, 'organization.main_location.state') +
                              _.get(patient, 'organization.main_location.zip_code')}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label text-right">Account Number</p>
                        </td>
                        <td>
                          <p className="value truncate">{patient.organization.account_number}</p>
                        </td>
                      </tr>
                      { patient.case_tray_number && <tr>
                        <td>
                          <p className="label text-right">Tray Number</p>
                        </td>
                        <td>
                          <p className="value truncate">{patient.case_tray_number}</p>
                        </td>
                      </tr>}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="print-rx-form">
            <tbody>
              <tr className="section">
                <td className="text-right bl-0 bb-0" style={{ width: '40%' }}>
                  <p className="label">Last Name</p>
                  <p className="label">First Name</p>
                  <p className="label">Case Number</p>
                  <p className="label">Impression Date</p>
                </td>
                <td className="br-0 bb-0" style={{ width: '60%' }}>
                  <p className="value truncate">{patient.last_name}</p>
                  <p className="value truncate">{patient.first_name}</p>
                  <p className="value truncate">{patient.case_number_text}</p>
                  <p className="value truncate">{patient.rx_form.impression_date}</p>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="print-rx-form">
            <tbody>
              <tr className="section">
                <td className="bl-0">
                  <p className="heading">SELECTED OPTIONS</p>
                  {patient.rx_form.products_list_names.map((product, i) => (
                    <p key={i} className="value truncate">
                      - {product}
                    </p>
                  ))}
                </td>
                <td className="br-0">
                  <p className="heading">ADD-ONS</p>
                  {patient.rx_form.addon_options_names.map((option, i) => (
                    <p key={i} className="value truncate">
                      - {option}
                    </p>
                  ))}
                </td>
              </tr>
              {hasPrintingOption(patient.rx_form) && (
                <tr className="section">
                  <td colSpan={2}>
                    <p className="heading">PRINTING OPTION:</p>
                    <p className="value">- Arch: {ARCH_LABELS[patient.rx_form.jaws_id]}</p>
                    <p className="value">- Mode: {ARCH_MODE_LABELS[patient.rx_form.arch_mode]}</p>
                  </td>
                </tr>
              )}
              <tr className="section">
                <td colSpan={2}>
                  <p className="heading">SPECIAL INSTRUCTIONS:</p>
                  <p className="value">{patient.rx_form.special_instructions}</p>
                </td>
              </tr>
              <tr className="section">
                <td colSpan={2}>
                  <p className="heading">PLEASE SEND MORE:</p>
                  {Object.keys(patient.rx_form.order_more_supplies).map(key => {
                    const value = patient.rx_form.order_more_supplies[key];
                    return (
                      value && (
                        <p className="value">
                          - {VIEW_LABEL[key]}: {value}
                        </p>
                      )
                    );
                  })}
                </td>
              </tr>
              {/*          <tr>
            <td>
              <p className="heading">Photos</p>
              {patient.patient_files
                .filter(file => file.patient_file_type_id === PATIENT_FILES_IDS.photo)
                .map((file, i) => (
                  <p key={i} className="value truncate">
                    - {file.original_name}
                  </p>
                ))}
            </td>
            <td>
              <p className="heading">IOS</p>
              {patient.patient_files
                .filter(file => file.patient_file_type_id === PATIENT_FILES_IDS.ios)
                .map((file, i) => (
                  <p key={i} className="value truncate">
                    - {file.original_name}
                  </p>
                ))}
            </td>
          </tr>*/}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
