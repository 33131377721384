import React, { useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import {
  createFattmerchantCustomerAction,
  createFattmerchantPaymentMethodAction
} from '../../../reducers/auth';
import LoadingButton from '../../LoadingButton';
import { enqueueNotification } from '../../../reducers/notifications';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '400px',
    maxWidth: '400px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    '& label': {
      position: 'relative',
      color: theme.palette.text.primary,
      fontWeight: '300',
      height: '40px',
      lineHeight: '40px',
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'row',
      '& > span': {
        width: '120px',
        textAlign: 'right',
        marginRight: '30px'
      }
    }
  },
  group: {
    background: 'white',
    boxShadow: '0 7px 14px 0 rgba(49, 49, 93, 0.1) 0 3px 6px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    marginBottom: '20px'
  },
  field: {
    background: 'transparent',
    border: 0,
    outline: 'none',
    flex: 1,
    paddingRight: '10px',
    paddingLeft: '10px',
    cursor: 'text',
    color: '#31325f',
    fontSize: '15px',
    fontWeight: '300'
  },
  error: {
    textAlign: 'right',
    display: 'block',
    color: 'red',
    fontSize: 'small',
    margin: '0'
  }
}));

const ACHForm = ({ onACHSaved }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const fattJs = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const ErrorMessageText = message => {
    return <p className={classes.error}>{message.children}</p>;
  };

  useEffect(() => {
    const { FattJs } = window;

    fattJs.current = new FattJs(process.env.REACT_APP_FATTMERCHANT_WEB_PAYMENTS_TOKEN, {});
  }, []);

  const savePaymentMethod = (
    // eslint-disable-next-line camelcase
    { bank_name, bank_account, bank_type, bank_holder_type, bank_routing },
    paymentInfo
  ) => {
    const extraDetails = {
      customer_id: paymentInfo.customer_id,
      url: 'https://omni.fattmerchant.com/#/bill/',
      method: 'bank',
      bank_name,
      bank_account,
      bank_holder_type,
      bank_type,
      bank_routing,
      person_name: `${currentUser.first_name} ${currentUser.last_name}`,
      validate: true
    };

    return fattJs.current
      .tokenize(extraDetails)
      .then(tokenizedPaymentMethod => {
        return dispatch(
          createFattmerchantPaymentMethodAction(tokenizedPaymentMethod.id, currentOrganization.id)
        ).then(() => {
          onACHSaved(true);
          return true;
        });
      })
      .catch(err => {
        let formattedMessage = 'Something went wrong, please try again.';

        if (err.message) {
          formattedMessage = err.message;
        }

        if (err.errors) {
          formattedMessage = (
            <div>
              {err.errors.map(fieldError => (
                <div key={fieldError}>{`Message: ${fieldError}`}</div>
              ))}
            </div>
          );
        }

        if (err.fieldErrors) {
          formattedMessage = (
            <div>
              {err.fieldErrors.map(fieldError => (
                <div
                  key={fieldError.field}
                >{`Field: ${fieldError.field} Message: ${fieldError.message}`}</div>
              ))}
            </div>
          );
        }

        dispatch(enqueueNotification('error', formattedMessage));
      });
  };

  return (
    <Paper className={classes.paper}>
      <CssBaseline />
      <Formik
        initialValues={{
          bank_name: '',
          bank_account: '',
          bank_routing: '',
          bank_holder_type: 'personal',
          bank_type: 'savings'
        }}
        validationSchema={Yup.object().shape({
          bank_name: Yup.string().required('Required'),
          bank_account: Yup.string().required('Required'),
          bank_routing: Yup.string().required('Required'),
          bank_holder_type: Yup.string().required('Required'),
          bank_type: Yup.string().required('Required')
        })}
        onSubmit={async values => {
          const result = await executeRecaptcha('AddPaymentInformationPage');

          if (!result) {
            return false;
          }

          if (currentOrganization.payment_info) {
            return savePaymentMethod(values, currentOrganization.payment_info);
          }

          await dispatch(createFattmerchantCustomerAction(currentOrganization.id)).then(
            response => {
              return savePaymentMethod(values, response.payment_info);
            }
          );

          return true;
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.group}>
                    <label>
                      <span>Bank Name</span>
                      <Field
                        className={classes.field}
                        name="bank_name"
                        autoComplete="off"
                        required
                        placeholder="E.g. Chase"
                      />
                    </label>
                    <ErrorMessage name="bank_name" component={ErrorMessageText} />
                    <label>
                      <span>Account Number</span>
                      <Field
                        className={classes.field}
                        name="bank_account"
                        autoComplete="off"
                        required
                        placeholder="E.g. 9876543210"
                      />
                    </label>
                    <ErrorMessage name="bank_account" component={ErrorMessageText} />
                    <label>
                      <span>Routing Number</span>
                      <Field
                        className={classes.field}
                        name="bank_routing"
                        autoComplete="off"
                        required
                        placeholder="E.g. 021000021"
                      />
                    </label>
                    <ErrorMessage name="bank_routing" component={ErrorMessageText} />
                    <label>
                      <span>Account Type</span>
                      <Field as="select" className={classes.field} name="bank_type" required>
                        <option value="checking">Checking</option>
                        <option value="savings">Savings</option>
                      </Field>
                    </label>
                    <ErrorMessage name="bank_type" component={ErrorMessageText} />
                    <label>
                      <span>Bank Holder Type</span>
                      <Field as="select" className={classes.field} name="bank_holder_type" required>
                        <option value="personal">Personal</option>
                        <option value="business">Business</option>
                      </Field>
                    </label>
                    <ErrorMessage name="bank_holder_type" component={ErrorMessageText} />
                  </div>
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                className={classes.submit}
              >
                Save
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

ACHForm.propTypes = {
  onACHSaved: PropTypes.func
};

ACHForm.defaultProps = {
  onACHSaved: () => {}
};

export default ACHForm;
