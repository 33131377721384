import React from 'react';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';

const RootIntegrationField = ({ ...props }) => {
  return (
    <RadioGroupField
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]}
      {...props}
    />
  );
};

RootIntegrationField.propTypes = {};

export default withDependency(RootIntegrationField);
