import { PatientFilesApiService } from '../services';
import { enqueueNotification } from './notifications';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PRINTING_FILES_PREFIX = 'FETCH_PRINTING_FILES';
export const FETCH_PRINTING_FILES_REQUEST_ACTION = `${FETCH_PRINTING_FILES_PREFIX}_REQUEST_ACTION`;
export const FETCH_PRINTING_FILES_SUCCESS_ACTION = `${FETCH_PRINTING_FILES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PRINTING_FILES_FAILURE_ACTION = `${FETCH_PRINTING_FILES_PREFIX}_FAILURE_ACTION`;

export const FETCH_DOWNLOAD_URL_PRINTING_FILE_PREFIX = 'FETCH_DOWNLOAD_URL_PRINTING_FILE';
export const FETCH_DOWNLOAD_URL_PRINTING_FILE_REQUEST_ACTION = `${FETCH_DOWNLOAD_URL_PRINTING_FILE_PREFIX}_REQUEST_ACTION`;
export const FETCH_DOWNLOAD_URL_PRINTING_FILE_SUCCESS_ACTION = `${FETCH_DOWNLOAD_URL_PRINTING_FILE_PREFIX}_SUCCESS_ACTION`;
export const FETCH_DOWNLOAD_URL_PRINTING_FILE_FAILURE_ACTION = `${FETCH_DOWNLOAD_URL_PRINTING_FILE_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    order: 'desc',
    orderBy: 'id',
    page: 0,
    perPage: 25,
    searchFullNameQuery: '',
    patientSearchFullNameQuery: ''
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRINTING_FILES_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.patient_files.data,
        total: action.payload.patient_files.total,
        globalParams: {
          ...action.payload.params,
          page: action.payload.params.page - 1
        }
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchPrintingFilesAction(params) {
  return dispatch => {
    dispatch({ type: FETCH_PRINTING_FILES_REQUEST_ACTION });
    const printingFilesService = new PatientFilesApiService();
    return printingFilesService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_PRINTING_FILES_SUCCESS_ACTION,
          payload: { patient_files: response.patient_files, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PRINTING_FILES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchDownloadUrlPrintingFileAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_DOWNLOAD_URL_PRINTING_FILE_REQUEST_ACTION });
    const printingFilesService = new PatientFilesApiService();
    return printingFilesService
      .getDownloadUrl(id)
      .then(response => {
        window.open(response.url);
        dispatch({ type: FETCH_DOWNLOAD_URL_PRINTING_FILE_SUCCESS_ACTION });
      })
      .catch(error => {
        dispatch({ type: FETCH_DOWNLOAD_URL_PRINTING_FILE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
