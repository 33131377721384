import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  error: {
    textAlign: 'left',
    display: 'block',
    color: 'red',
    fontSize: 'small',
    margin: '0'
  }
}));

const InputErrorMessage = message => {
  const classes = useStyles();
  return <p className={classes.error}>{message.children}</p>;
};

InputErrorMessage.prototype = {
  message: PropTypes.string.isRequired
};

export default InputErrorMessage;
