import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { produce } from 'immer';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import Can from '../../components/Can';
import {
  FETCH_LOCATIONS_PREFIX,
  fetchLocationsAction,
  resetParams
} from '../../reducers/locations';
import MakeHidden from '../../components/MakeHidden';
import NotFoundPage from '../NotFoundPage';
import CommonPropTypes from '../../common/PropTypes';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%'
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  }
}));

const Locations = ({ organization: { data: organization, label, redirect } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const globalParams = useSelector(state => state.locations.globalParams);
  const currentOrganization = useSelector(state => organization || state.auth.currentOrganization);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_LOCATIONS_PREFIX])(state));
  const [locationInfo, setLocationInfo] = useState(globalParams.locationInfo);

  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const rows = useSelector(state => state.locations.items);
  const total = useSelector(state => state.locations.total);

  const handleUpdateData = useCallback(
    params => {
      const getParams = {
        ...params,
        organizationId: currentOrganization.id
      };
      dispatch(
        fetchLocationsAction({
          ...getParams
        })
      );
    },
    [dispatch, currentOrganization]
  );

  const handleOnChangeSearchInput = useCallback(
    debounce((value, additionalParameterKey) => {
      setAdditionalParams(
        produce(additionalParams, draft => {
          draft.params[additionalParameterKey] = value;
          draft.resetPage = true;
        })
      );
    }, 500),
    [additionalParams]
  );

  const handleShowItem = id => {
    redirect(`/locations/${id}`);
  };

  const openCreateLocation = () => {
    redirect(`/locations/add`);
  };

  const clearQueryParams = () => {
    dispatch(resetParams()).then(() => {
      setLocationInfo('');

      setAdditionalParams(
        produce(additionalParams, draft => {
          draft.params.locationInfo = '';
          draft.params.searchByOrganization = '';
          draft.resetPage = true;
        })
      );
    });
  };

  const columns = [
    {
      id: 'location_name',
      numeric: false,
      disablePadding: false,
      disabledSorting: true,
      label: 'Name'
    },
    {
      id: 'full_address',
      numeric: false,
      disablePadding: false,
      disabledSorting: true,
      label: 'Address'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      type: 'datetime'
    }
  ];

  if (!currentOrganization) {
    return <NotFoundPage />;
  }

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            {label && (
              <Grid item xs={12}>
                <Typography variant="h6">{label}</Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Location Info"
                className={classes.searchInput}
                value={locationInfo}
                onChange={e => {
                  setLocationInfo(e.target.value);
                  handleOnChangeSearchInput(e.target.value, 'locationInfo');
                }}
              />
            </Grid>
            <MakeHidden profile="normal">
              <Grid item xs={12} sm={2}>
                <Button variant="contained" size="medium" onClick={clearQueryParams}>
                  Clear Filters
                </Button>
              </Grid>
            </MakeHidden>
            <Can
              permissions={[
                'locations.manage',
                'locations.create',
                'organization.locations.create'
              ]}
              organizationId={currentOrganization.id}
              yes={() => (
                <Grid item xs={12} sm={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => openCreateLocation(currentOrganization)}
                  >
                    Create Location
                  </Button>
                </Grid>
              )}
            />
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          total={total}
          updateData={handleUpdateData}
          globalParams={globalParams}
          defaultOrderBy={globalParams.orderBy}
          customActions={[
            {
              name: 'show',
              handleOnAction: handleShowItem,
              icon: <DescriptionIcon />,
              text: 'Show'
            }
          ]}
          additionalParams={additionalParams}
        />
      </div>
    </Container>
  );
};

Locations.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  organization: CommonPropTypes.organizationDataProvider.isRequired
};

Locations.defaultProps = {
  location: {
    search: ''
  }
};

export default Locations;
