import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Divider } from '@material-ui/core';
import createLoadingSelector from '../../selectors/loading';
import LocationForm from '../../components/LocationForm';
import { CREATE_LOCATION_PREFIX, createLocationAction } from '../../reducers/locations';
import OrganizationProviderPropType from '../../common/PropTypes/organizationDataProvider';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const AddLocation = ({ organization: { data: organization, label, redirect } }) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(state => createLoadingSelector([CREATE_LOCATION_PREFIX])(state));
  const classes = useStyles();

  const addLocation = values => {
    return dispatch(
      createLocationAction({
        ...values
      })
    ).then(response => {
      if (response) {
        redirect('/locations');
      }
    });
  };

  return (
    <Card className={classes.form}>
      <CardHeader title="Create Location" subheader={label} />
      <Divider />
      <LocationForm organization={organization} onSubmit={addLocation} isSaving={isSaving} />
    </Card>
  );
};

AddLocation.propTypes = {
  organization: OrganizationProviderPropType.isRequired
};

export default AddLocation;
