import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { fetchCurrentUserAction } from '../../reducers/auth';
import TabPanel from '../../components/TabPanel';
import Pricing from './Pricing';
import AnnouncementManager from "./Announcement/Manager";
import hasPermission from '../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const Settings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const canUpdateGlobalPricing = useSelector(state =>
    hasPermission(state, { permissions: ['pricing.update'] })
  );
  const canUpdateAnnoucement = useSelector(state =>
    hasPermission(state, { permissions: ['announcement.update'] })
  );
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const queryStringParams = parse(location.search);
    if (queryStringParams.tab) {
      setValue(parseInt(queryStringParams.tab, 10));
    }
    dispatch(fetchCurrentUserAction());
  }, [dispatch, location]);

  const getTabContent = (name, index) => {
    switch (name) {
      case 'Customize Fees':
        return (
          <TabPanel value={value} index={index} key={index}>
            <Pricing canUpdateGlobalPricing={canUpdateGlobalPricing} />
          </TabPanel>
        );
      case 'Announcements':
        return (
          <TabPanel value={value} index={index} key={index}>
            <AnnouncementManager />
          </TabPanel>
        );
      default:
        return null;
    }
  };

  const tabs = [];

  if (canUpdateGlobalPricing) {
    tabs.push('Customize Fees');
  }

  if (canUpdateAnnoucement) {
    tabs.push('Announcements');
  }

  if (currentOrganization && !currentOrganization.contract) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          { tabs.map((name, index) => <Tab label={name} value={index} key={index} />) }
        </Tabs>
      </AppBar>
      { tabs.map((name, index) => getTabContent(name, index)) }
    </div>
  );
};

export default Settings;
