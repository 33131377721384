import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import ServiceManager from '../services/api/serviceManager';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_LATEST_PATIENTS_PREFIX = 'FETCH_LATEST_PATIENTS';
export const FETCH_LATEST_PATIENTS_REQUEST_ACTION = `${FETCH_LATEST_PATIENTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_LATEST_PATIENTS_SUCCESS_ACTION = `${FETCH_LATEST_PATIENTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LATEST_PATIENTS_FAILURE_ACTION = `${FETCH_LATEST_PATIENTS_PREFIX}_FAILURE_ACTION`;

export const NEW_PATIENT_EVENT_ACTION = 'NEW_PATIENT_EVENT_ACTION';
export const UPDATED_PATIENT_EVENT_ACTION = 'UPDATED_PATIENT_EVENT_ACTION';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    order: 'desc',
    orderBy: 'updated_at',
    held: 'without'
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LATEST_PATIENTS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.total
      };
    case NEW_PATIENT_EVENT_ACTION:
      return produce(state, draft => {
        draft.items.unshift(action.payload);
      });
    case UPDATED_PATIENT_EVENT_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchLatestPatientsAction(params = {}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_LATEST_PATIENTS_REQUEST_ACTION });
    const patientsService = new ServiceManager(getState).getPatientServiceInstance();
    return patientsService
      .get(params)
      .then(response => {
        dispatch({ type: FETCH_LATEST_PATIENTS_SUCCESS_ACTION, payload: response.patients });
      })
      .catch(error => {
        dispatch({ type: FETCH_LATEST_PATIENTS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function newPatientEventAction(patient) {
  return dispatch => {
    dispatch({ type: NEW_PATIENT_EVENT_ACTION, payload: patient });
  };
}

export function updatedPatientEventAction(patient) {
  return dispatch => {
    dispatch({ type: UPDATED_PATIENT_EVENT_ACTION, payload: patient });
  };
}
