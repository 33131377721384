import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { fetchCurrentOrderAction } from '../../reducers/orders';
import OrderSummary from './index';

const CurrentOrderHandler = ({ organizationId, title, reload, ...rest }) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(state => {
    return state.orders.currentOrder;
  });
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const loadOrder = () => {
    setIsLoadingLocal(true);
    dispatch(fetchCurrentOrderAction(organizationId)).then(() => setIsLoadingLocal(false));
  };

  useEffect(() => {
    loadOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, dispatch, reload]);

  const formatOrder = order => {
    if (order.fm_invoice_status) {
      return order;
    }

    return {
      ...order,
      order_items: order.orderItems,
      applied_promotions: order.appliedPromotions,
      discounts: order.all_discounts,
      charges: order.all_charges
    };
  };

  if (isLoadingLocal) {
    return <LoadingIndicator />;
  }

  if (!currentOrder) {
    return 'There is no current order';
  }

  return (
    <OrderSummary
      order={formatOrder(currentOrder)}
      title={title}
      onReloadOrderAction={loadOrder}
      showRemoveDiscount
      {...rest}
    />
  );
};

CurrentOrderHandler.propTypes = {
  organizationId: PropTypes.number.isRequired,
  title: PropTypes.string,
  reload: PropTypes.bool
};

CurrentOrderHandler.defaultProps = {
  title: '',
  reload: false
};

export default CurrentOrderHandler;
