import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class UsersApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 15,
    page = 1,
    order,
    orderBy,
    searchFullNameEmailQuery = '',
    roles = [],
    organizationId = '',
    includes = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'first_name';
    return this.request({
      method: 'get',
      url: this.query
        .for('users')
        .where('organization_id', organizationId)
        .where('full_name_or_email_like', searchFullNameEmailQuery)
        .whereIn('roles', roles)
        .include(includes)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  getUser(id) {
    return this.request({
      method: 'get',
      url: `/users/${id}`
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/users/${id}`
    });
  }

  archive(id) {
    return this.request({
      method: 'put',
      url: `/users/${id}/archive`
    });
  }

  restore(id) {
    return this.request({
      method: 'put',
      url: `/users/${id}/restore`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: `/users`,
      data
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/users/${id}`,
      data
    });
  }

  getUserOrders(id) {
    return this.request({
      method: 'get',
      url: `/users/${id}/orders`
    });
  }

  addAdditionalEmail(userId, data) {
    return this.request({
      method: 'post',
      url: `/emails`,
      data: {
        ...data,
        user_id: userId
      }
    });
  }

  deleteAdditionalEmail(id) {
    return this.request({
      method: 'delete',
      url: `/emails/${id}`
    });
  }

  toggleCustomPermission(id, permission) {
    return this.request({
      method: 'put',
      url: `/users/${id}/toggle_custom_permission`,
      data: {
        permission
      }
    });
  }
}
