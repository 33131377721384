import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { updateRefinements } from '../../../reducers/globalSearch';

const ClearRefinements = ({ items, attribute, onClick }) => {
  const dispatch = useDispatch();

  const scopedItems = [items.find(group => group.attribute === attribute)];

  const currentRefinement = _.get(scopedItems, [0, 'currentRefinement'], []);

  const isEmpty = _.isEmpty(currentRefinement);

  const customRefine = e => {
    e.preventDefault();
    onClick();
    dispatch(updateRefinements(attribute, []));
  };

  return (
    // eslint-disable-next-line
    <a
      href="#"
      className="ais-ClearRefinements-link"
      onClick={e => customRefine(e)}
      disabled={isEmpty}
    >
      Clear
    </a>
  );
};

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

ClearRefinements.propTypes = {
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

ClearRefinements.defaultProps = {
  onClick: () => {}
};

export default CustomClearRefinements;
