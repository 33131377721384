import { Query } from 'cogent-js';
import BaseApiService from './base';

const isValidBusinessDateCache = {};

export default class MiscApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  getNextBusinessDate({ datetime = null, offset = 0, shippingMethod = null }) {
    const data = {};

    if (datetime) {
      data.datetime = datetime;
    }

    if (offset !== 0) {
      data.offset = offset;
    }

    if (shippingMethod) {
      data.shipping_method = shippingMethod;
    }

    return this.request({
      method: 'get',
      url: this.query
        .for('misc/next-business-date')
        .params(data)
        .url()
    });
  }

  getCalendarDays({ yearMonth }) {
    const data = {};

    data.year_month = yearMonth;

    return this.request({
      method: 'get',
      url: this.query
        .for('misc/calendar-days')
        .params(data)
        .url()
    });
  }

  isValidBusinessDate({ businessDate, datetime = null, offset = 0, shippingMethod = null }) {
    const data = {
      business_date: businessDate
    };

    if (datetime) {
      data.datetime = datetime;
    }

    if (offset !== 0) {
      data.offset = offset;
    }

    if (shippingMethod) {
      data.shipping_method = shippingMethod;
    }

    if (isValidBusinessDateCache[JSON.stringify(data)] !== undefined) {
      return Promise.resolve(isValidBusinessDateCache[JSON.stringify(data)]);
    }

    return this.request({
      method: 'get',
      url: this.query
        .for('misc/is-valid-business-date')
        .params(data)
        .url()
    }).then(response => {
      isValidBusinessDateCache[JSON.stringify(data)] = response.is_valid;
      return response.is_valid;
    });
  }
}
