import React from 'react';
import LatestPatients from './components/LatestPatients';
import AdminDashboard from './components/AdminDashboard';
import MakeHidden from '../../components/MakeHidden';
import Can from '../../components/Can';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function Dashboard() {
  const isManagement = useSelector(state => state.auth.isManagement);
  const history = useHistory();

  if (!isManagement) {
    history.push('/patients');
  }

  return (
    <>
      <MakeHidden profile="normal">
        <Can
          permissions={['users.view-dashboard', 'users.manage', 'users.manage-limited']}
          yes={() => <AdminDashboard />}
        />
      </MakeHidden>
      <MakeHidden profile="management">
        <LatestPatients />
      </MakeHidden>
    </>
  );
}
