import React, { useCallback, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { debounce } from 'lodash';
import { produce } from 'immer';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../components/DataTable';
import createLoadingSelector from '../../selectors/loading';
import SearchInput from '../../components/SearchInput';
import {
  FETCH_PRINTING_FILES_PREFIX,
  fetchDownloadUrlPrintingFileAction,
  fetchPrintingFilesAction
} from '../../reducers/printingFiles';
import { UTCDateTimeToLocalTime } from '../../helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%'
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  }
}));

const PrintingFiles = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalParams = useSelector(state => state.printingFiles.globalParams);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_PRINTING_FILES_PREFIX])(state)
  );
  const [additionalParams, setAdditionalParams] = useState({
    params: {
      searchFullNameQuery: globalParams.searchFullNameQuery,
      patientSearchFullNameQuery: globalParams.patientSearchFullNameQuery
    },
    resetPage: false
  });

  const rows = useSelector(state => state.printingFiles.items);
  const total = useSelector(state => state.printingFiles.total);

  const handleUpdateData = useCallback(
    params => {
      dispatch(fetchPrintingFilesAction(params));
    },
    [dispatch]
  );

  const handleOnDownload = id => {
    dispatch(fetchDownloadUrlPrintingFileAction(id));
  };

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[additionalParameterKey] = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const columns = [
    {
      id: 'patient.user.full_name',
      numeric: false,
      disablePadding: false,
      label: 'Doctor',
      customSortId: 'full_name_sort'
    },
    {
      id: 'patient.full_name',
      numeric: false,
      disablePadding: false,
      label: 'Patient',
      customSortId: 'patient_full_name_sort'
    },
    {
      id: 'patient.case_number_text',
      numeric: false,
      disablePadding: false,
      label: 'Case #',
      disabledSorting: true,
      formatMethod: value => value || '-'
    },
    {
      id: 'patient.numeric_suffix',
      numeric: false,
      disablePadding: false,
      label: 'Suffix',
      disabledSorting: true,
      formatMethod: value => `${value ? `(${value})` : '-'}`
    },
    {
      id: 'patient_file_type.name',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      customSortId: 'patient_file_type_id'
    },
    {
      id: 'original_name',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
      formatMethod: (value, row) => value || row.name
    },
    {
      id: 'file_modification_date',
      numeric: false,
      disablePadding: false,
      label: 'Date',
      formatMethod: (value, row) =>
        value ? UTCDateTimeToLocalTime(value) : UTCDateTimeToLocalTime(row.updated_at)
    }
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by User"
                className={classes.searchInput}
                defaultValue={globalParams.searchFullNameQuery}
                onChange={e => handleOnChangeSearchInput(e.target.value, 'searchFullNameQuery')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Patient"
                className={classes.searchInput}
                defaultValue={globalParams.patientSearchFullNameQuery}
                onChange={e =>
                  handleOnChangeSearchInput(e.target.value, 'patientSearchFullNameQuery')
                }
              />
            </Grid>
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          globalParams={globalParams}
          defaultOrderBy={globalParams.orderBy}
          rows={rows}
          total={total}
          updateData={handleUpdateData}
          actions={{ download: true, handleOnDownload }}
          additionalParams={additionalParams}
        />
      </div>
    </Container>
  );
};

PrintingFiles.propTypes = {};

PrintingFiles.defaultProps = {};

export default PrintingFiles;
