import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { isBoolean } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { OPTIONS, QUESTIONS, STATEMENTS } from '../constants';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    marginLeft: '16px'
  },
  verticalLine: {
    borderLeft: '1px solid #e0e0e0',
    position: 'absolute',
    top: '0',
    height: '86%',
    left: '-16px'
  }
}));

const QuestionLabel = ({ name, appendLabel }) => {
  return (
    <>
      {QUESTIONS[name]} {appendLabel && appendLabel}
    </>
  );
};

const FormControlLabelCheckbox = ({ name, value, label, control, disabled, ...labelProps }) => {
  return (
    <FormControlLabel
      control={control}
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      {...labelProps}
    />
  );
};

const CheckboxGroupField = ({
  name,
  options,
  isSubmitting,
  readOnly,
  appendLabel,
  onChange,
  appendAfterOptions,
  partial,
  required
}) => {
  const classes = useStyles();
  const availableOptions = options || OPTIONS[name];

  const handleChecked = value => event => {
    const checked = event.target.checked;
    if (onChange != null) onChange(checked, value);
  };

  const formControl = (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {options && options.length === 1 ? (
          STATEMENTS[name] || <QuestionLabel name={name} appendLabel={appendLabel} />
        ) : (
          <QuestionLabel name={name} appendLabel={appendLabel} />
        )}
        {required ? ' *' : ''}
      </FormLabel>
      <FormGroup>
        {availableOptions.map(({ value, label, checked, ...labelProps }) => (
          <FormControlLabelCheckbox
            key={label}
            name={name}
            value={String(isBoolean(value) ? Number(value) : value)}
            control={
              <Checkbox
                color="primary"
                disabled={isSubmitting}
                onChange={handleChecked(value)}
                checked={checked}
              />
            }
            label={label}
            disabled={readOnly || isSubmitting}
            {...labelProps}
          />
        ))}
        {appendAfterOptions}
      </FormGroup>
    </FormControl>
  );

  return (
    <>
      {!partial ? (
        <div className={classes.container}>
          <div className={classes.verticalLine} />
          {formControl}
        </div>
      ) : (
        <>{formControl}</>
      )}
    </>
  );
};

QuestionLabel.defaultProps = {
  appendLabel: null
};

QuestionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  appendLabel: PropTypes.any
};

CheckboxGroupField.defaultProps = {
  readOnly: false,
  options: null,
  fieldProps: {},
  appendLabel: null,
  appendAfterOptions: null,
  partial: false,
  required: false
};

CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isSubmitting: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  partial: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fieldProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  appendLabel: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  appendAfterOptions: PropTypes.any,
  required: PropTypes.bool
};

export default CheckboxGroupField;
