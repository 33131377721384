import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import PeriodicRuleApiService from '../services/api/periodicRule';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PERIODIC_RULES_PREFIX = 'FETCH_PERIODIC_RULES';
export const FETCH_PERIODIC_RULES_REQUEST_ACTION = `${FETCH_PERIODIC_RULES_PREFIX}_REQUEST_ACTION`;
export const FETCH_PERIODIC_RULES_SUCCESS_ACTION = `${FETCH_PERIODIC_RULES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PERIODIC_RULES_FAILURE_ACTION = `${FETCH_PERIODIC_RULES_PREFIX}_FAILURE_ACTION`;

export const CREATE_PERIODIC_RULE_PREFIX = 'CREATE_PERIODIC_RULE';
export const CREATE_PERIODIC_RULE_REQUEST_ACTION = `${CREATE_PERIODIC_RULE_PREFIX}_REQUEST_ACTION`;
export const CREATE_PERIODIC_RULE_SUCCESS_ACTION = `${CREATE_PERIODIC_RULE_PREFIX}_SUCCESS_ACTION`;
export const CREATE_PERIODIC_RULE_FAILURE_ACTION = `${CREATE_PERIODIC_RULE_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PERIODIC_RULE_PREFIX = 'UPDATE_PERIODIC_RULE';
export const UPDATE_PERIODIC_RULE_REQUEST_ACTION = `${UPDATE_PERIODIC_RULE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PERIODIC_RULE_SUCCESS_ACTION = `${UPDATE_PERIODIC_RULE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PERIODIC_RULE_FAILURE_ACTION = `${UPDATE_PERIODIC_RULE_PREFIX}_FAILURE_ACTION`;

export const DELETE_PERIODIC_RULE_PREFIX = 'DELETE_PERIODIC_RULE';
export const DELETE_PERIODIC_RULE_REQUEST_ACTION = `${DELETE_PERIODIC_RULE_PREFIX}_REQUEST_ACTION`;
export const DELETE_PERIODIC_RULE_SUCCESS_ACTION = `${DELETE_PERIODIC_RULE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_PERIODIC_RULE_FAILURE_ACTION = `${DELETE_PERIODIC_RULE_PREFIX}_FAILURE_ACTION`;

export const FETCH_PERIODIC_RULE_PREFIX = 'FETCH_PERIODIC_RULE';
export const FETCH_PERIODIC_RULE_REQUEST_ACTION = `${FETCH_PERIODIC_RULE_PREFIX}_REQUEST_ACTION`;
export const FETCH_PERIODIC_RULE_SUCCESS_ACTION = `${FETCH_PERIODIC_RULE_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PERIODIC_RULE_FAILURE_ACTION = `${FETCH_PERIODIC_RULE_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    selectedStatus: '',
    order: 'desc',
    orderBy: 'created_at',
    page: 1,
    perPage: 25
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERIODIC_RULES_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.rules.data,
        total: action.payload.rules.total,
        globalParams: action.payload.params
      };
    case FETCH_PERIODIC_RULE_SUCCESS_ACTION:
      return produce(state, draft => {
        const periodicRuleIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (periodicRuleIndex > -1) {
          draft.items[periodicRuleIndex] = action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_PERIODIC_RULE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_PERIODIC_RULE_SUCCESS_ACTION:
      return produce(state, draft => {
        const periodicRuleIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[periodicRuleIndex] = action.payload;
      });
    case DELETE_PERIODIC_RULE_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchPeriodicRulesAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_PERIODIC_RULES_REQUEST_ACTION });
    const periodicRulesApiService = new PeriodicRuleApiService();
    return periodicRulesApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_PERIODIC_RULES_SUCCESS_ACTION,
          payload: { rules: response.rules, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PERIODIC_RULES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPeriodicRuleAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_PERIODIC_RULE_REQUEST_ACTION });
    const periodicRulesApiService = new PeriodicRuleApiService();
    return periodicRulesApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_PERIODIC_RULE_SUCCESS_ACTION,
          payload: response.rule
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PERIODIC_RULE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createPeriodicRuleAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_PERIODIC_RULE_REQUEST_ACTION });
    const periodicRulesApiService = new PeriodicRuleApiService();
    return periodicRulesApiService
      .create(params)
      .then(response => {
        dispatch({
          type: CREATE_PERIODIC_RULE_SUCCESS_ACTION,
          payload: response.rule
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: CREATE_PERIODIC_RULE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updatePeriodicRuleAction(params, id) {
  return dispatch => {
    dispatch({ type: UPDATE_PERIODIC_RULE_REQUEST_ACTION });
    const periodicRulesApiService = new PeriodicRuleApiService();
    return periodicRulesApiService
      .update(params, id)
      .then(response => {
        dispatch({
          type: UPDATE_PERIODIC_RULE_SUCCESS_ACTION,
          payload: response.rule
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_PERIODIC_RULE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function deletePeriodicRuleAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_PERIODIC_RULE_REQUEST_ACTION });
    const periodicRulesApiService = new PeriodicRuleApiService();
    return periodicRulesApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_PERIODIC_RULE_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_PERIODIC_RULE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
