import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { updateOrganizationAction } from '../reducers/organizations';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'grid',
      margin: '0px 16px 16px 16px',
      gridTemplateColumns: 'fit-content(100%) auto fit-content(100%)',
      gridGap: theme.spacing(1),
      alignItems: 'flex-start'
    },
    label: { marginTop: theme.spacing(1) },
    textarea: {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[100],
      padding: theme.spacing(1)
    },
    button: {
      marginTop: theme.spacing(0.5)
    }
  };
});

const OrganizationNotes = ({ notes: initialNotes, save }) => {
  const [notes, setNotes] = useState(initialNotes);
  const classes = useStyles();
  const initialValueRef = useRef(initialNotes);
  const [disabled, setDisabled] = useState(true);

  const handleChange = e => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const handleSave = e => {
    e.preventDefault();
    initialValueRef.current = notes;
    save(notes);
    setDisabled(true);
  };

  useEffect(() => {
    const shouldDisable = notes === initialValueRef.current;
    if (shouldDisable !== disabled) {
      setDisabled(shouldDisable);
    }
  }, [disabled, setDisabled, notes, initialValueRef]);

  return (
    <div className={classes.container}>
      <span className={classes.label}>
        <strong>Notes:</strong>{' '}
      </span>
      <TextareaAutosize
        value={notes}
        onChange={handleChange}
        minRows={1}
        className={classes.textarea}
      />
      <IconButton
        color="primary"
        disabled={disabled}
        size="small"
        className={classes.button}
        onClick={handleSave}
      >
        <SaveIcon />
      </IconButton>
    </div>
  );
};

OrganizationNotes.propTypes = {
  notes: PropTypes.string,
  save: PropTypes.func.isRequired
};

OrganizationNotes.defaultProps = {
  notes: ''
};

export default connect(
  (state, props) => {
    const org = state.organizations.items.find(item => item.id === props.organizationId);
    // eslint-disable-next-line no-nested-ternary
    const notes = !isEmpty(org) && org.management_notes ? org.management_notes : props.notes;
    return {
      notes
    };
  },
  (dispatch, props) => {
    return {
      save: notes => {
        dispatch(updateOrganizationAction({ management_notes: notes }, props.organizationId));
      }
    };
  }
)(OrganizationNotes);
