import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import createLoadingSelector from '../../../selectors/loading';

import {
  FETCH_ANNOUNCEMENTS_PREFIX,
  fetchAnnouncementsAction
} from '../../../reducers/annoucement';
import LoadingIndicator from '../../../components/LoadingIndicator';
import UpdateAnnouncement from './UpdateAnnouncement';

const Manager = () => {
  const dispatch = useDispatch();

  const isFetching = useSelector(state =>
    createLoadingSelector([FETCH_ANNOUNCEMENTS_PREFIX])(state)
  );
  const announcements = useSelector(state => state.announcement.announcements);

  useEffect(() => {
    dispatch(fetchAnnouncementsAction());
  }, [dispatch]);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container spacing={1}>
      {announcements.map(announcement => (
        <Grid item xs={12} key={announcement.id}>
          <UpdateAnnouncement key={announcement.id} announcement={announcement} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Manager;
