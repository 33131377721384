import BaseApiService from './base';

export default class NotificationsApiService extends BaseApiService {
  get() {
    return this.request({
      method: 'get',
      url: 'notifications'
    });
  }

  markAsRead(id) {
    return this.request({
      method: 'patch',
      url: `notifications/${id}/mark-as-read`
    });
  }
}
