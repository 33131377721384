import _ from 'lodash';
import { enqueueNotification } from './notifications';
import QuestionsApiService from '../services/api/questions';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_QUESTION_PREFIX = 'FETCH_QUESTION';
export const FETCH_QUESTION_REQUEST_ACTION = `${FETCH_QUESTION_PREFIX}_REQUEST_ACTION`;
export const FETCH_QUESTION_SUCCESS_ACTION = `${FETCH_QUESTION_PREFIX}_SUCCESS_ACTION`;
export const FETCH_QUESTION_FAILURE_ACTION = `${FETCH_QUESTION_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  signUpQuestion: null,
  hasQuestion: true
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        signUpQuestion: action.payload.question,
        hasQuestion: action.payload.hasQuestion
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchSignUpQuestionAction() {
  return dispatch => {
    dispatch({ type: FETCH_QUESTION_REQUEST_ACTION });
    const QuestionsService = new QuestionsApiService();
    return QuestionsService.get(1)
      .then(response => {
        const question = _.get(response, ['survey_question'], true);
        const hasQuestion = _.get(response, ['has_questions'], false);

        dispatch({
          type: FETCH_QUESTION_SUCCESS_ACTION,
          payload: {
            question,
            hasQuestion
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_QUESTION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
