import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import LocationsApiService from '../services/api/locations';
import { updateCurrentOrganization } from './auth';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_LOCATIONS_PREFIX = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_REQUEST_ACTION = `${FETCH_LOCATIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_LOCATIONS_SUCCESS_ACTION = `${FETCH_LOCATIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LOCATIONS_FAILURE_ACTION = `${FETCH_LOCATIONS_PREFIX}_FAILURE_ACTION`;

export const CREATE_LOCATION_PREFIX = 'CREATE_LOCATION';
export const CREATE_LOCATION_REQUEST_ACTION = `${CREATE_LOCATION_PREFIX}_REQUEST_ACTION`;
export const CREATE_LOCATION_SUCCESS_ACTION = `${CREATE_LOCATION_PREFIX}_SUCCESS_ACTION`;
export const CREATE_LOCATION_FAILURE_ACTION = `${CREATE_LOCATION_PREFIX}_FAILURE_ACTION`;

export const UPDATE_LOCATION_PREFIX = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_REQUEST_ACTION = `${UPDATE_LOCATION_PREFIX}_REQUEST_ACTION`;
export const UPDATE_LOCATION_SUCCESS_ACTION = `${UPDATE_LOCATION_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_LOCATION_FAILURE_ACTION = `${UPDATE_LOCATION_PREFIX}_FAILURE_ACTION`;

export const DELETE_LOCATION_PREFIX = 'DELETE_LOCATION';
export const DELETE_LOCATION_REQUEST_ACTION = `${DELETE_LOCATION_PREFIX}_REQUEST_ACTION`;
export const DELETE_LOCATION_SUCCESS_ACTION = `${DELETE_LOCATION_PREFIX}_SUCCESS_ACTION`;
export const DELETE_LOCATION_FAILURE_ACTION = `${DELETE_LOCATION_PREFIX}_FAILURE_ACTION`;

export const FETCH_LOCATION_PREFIX = 'FETCH_LOCATION';
export const FETCH_LOCATION_REQUEST_ACTION = `${FETCH_LOCATION_PREFIX}_REQUEST_ACTION`;
export const FETCH_LOCATION_SUCCESS_ACTION = `${FETCH_LOCATION_PREFIX}_SUCCESS_ACTION`;
export const FETCH_LOCATION_FAILURE_ACTION = `${FETCH_LOCATION_PREFIX}_FAILURE_ACTION`;

export const RESET_LOCATIONS_GLOBAL_PARAMS = 'RESET_LOCATIONS_GLOBAL_PARAMS';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    order: 'desc',
    orderBy: 'created_at',
    page: 1,
    perPage: 25,
    locationInfo: '',
    userId: '',
    searchByOrganization: '',
    patientStatusId: ''
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.locations.data,
        total: action.payload.locations.total,
        globalParams: action.payload.params
      };
    case FETCH_LOCATION_SUCCESS_ACTION:
      return produce(state, draft => {
        const locationIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (locationIndex > -1) {
          draft.items[locationIndex] = action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_LOCATION_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_LOCATION_SUCCESS_ACTION:
      return produce(state, draft => {
        const locationIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[locationIndex] = action.payload;
      });
    case DELETE_LOCATION_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    case RESET_LOCATIONS_GLOBAL_PARAMS: {
      return {
        ...state,
        globalParams: {
          ...state.globalParams,
          locationInfo: '',
          searchByOrganization: ''
        }
      };
    }
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchLocationsAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_LOCATIONS_REQUEST_ACTION });
    const locationsApiService = new LocationsApiService();
    return locationsApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_LOCATIONS_SUCCESS_ACTION,
          payload: { locations: response.locations, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_LOCATIONS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchLocationAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_LOCATION_REQUEST_ACTION });
    const locationsApiService = new LocationsApiService();
    return locationsApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_LOCATION_SUCCESS_ACTION,
          payload: response.location
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_LOCATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createLocationAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_LOCATION_REQUEST_ACTION });
    const locationsApiService = new LocationsApiService();
    return locationsApiService
      .create(params)
      .then(response => {
        dispatch({
          type: CREATE_LOCATION_SUCCESS_ACTION,
          payload: response.location
        });

        dispatch(enqueueNotification('success', response.message));
        return dispatch(updateCurrentOrganization()).then(() => response.location);
      })
      .catch(error => {
        dispatch({ type: CREATE_LOCATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateLocationAction(params, id) {
  return dispatch => {
    dispatch({ type: UPDATE_LOCATION_REQUEST_ACTION });
    const locationsApiService = new LocationsApiService();
    return locationsApiService
      .update(params, id)
      .then(response => {
        dispatch({
          type: UPDATE_LOCATION_SUCCESS_ACTION,
          payload: response.location
        });
        dispatch(enqueueNotification('success', response.message));
        return dispatch(updateCurrentOrganization()).then(() => response.location);
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOCATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function deleteLocationAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_LOCATION_REQUEST_ACTION });
    const locationsApiService = new LocationsApiService();
    return locationsApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_LOCATION_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return dispatch(updateCurrentOrganization()).then(() => true);
      })
      .catch(error => {
        dispatch({ type: DELETE_LOCATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetParams() {
  return dispatch => {
    dispatch({ type: RESET_LOCATIONS_GLOBAL_PARAMS });

    return Promise.resolve();
  };
}
