import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchOrganizationUserPermissions } from '../reducers/auth';
import getOrganizationPermissions from '../selectors/getOrganizationPermissions';

const useOrganizationPermissions = ({ organizationId, reload = false }) => {
  const dispatch = useDispatch();
  const isManagement = useSelector(state => state.auth.isManagement);
  const [isLoading, setIsLoading] = useState(true);
  const organizationPermissions = useSelector(state =>
    getOrganizationPermissions(state, organizationId)
  );

  useEffect(() => {
    if (!organizationId || (!reload && organizationPermissions)) {
      setIsLoading(false);
      return;
    }

    if (isManagement) {
      dispatch(fetchOrganizationUserPermissions(organizationId)).then(() => setIsLoading(false));
    }
  }, [dispatch, organizationId, isManagement, organizationPermissions, reload]);

  return { isLoading };
};

export default useOrganizationPermissions;
