import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { closeSnackbar as closeSnackbarAction, removeSnackbar } from '../../reducers/notifications';

const Notifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useSelector(state => state.notifications.items);
  const dispatch = useDispatch();
  const displayed = useRef([]);

  const storeDisplayed = id => {
    displayed.current = [...displayed.current, id];
  };

  const removeDisplayed = id => {
    displayed.current = displayed.current.filter(key => id !== key);
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }
      // Do nothing if snackbar is already displayed
      if (displayed.current.includes(key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        action: snackbarKey => (
          <IconButton onClick={() => dispatch(closeSnackbarAction(snackbarKey))}>
            <CloseIcon />
          </IconButton>
        ),
        onClose: (event, reason, snackbarKey) => {
          if (options.onClose) {
            options.onClose(event, reason, snackbarKey);
          }
        },
        onExited: (event, snackbarKey) => {
          dispatch(removeSnackbar(snackbarKey));
          removeDisplayed(snackbarKey);
        },
        ...options
      });
      // Keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  });

  return null;
};

export default Notifier;
