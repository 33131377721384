import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button, FormControl, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { saveOrganizationCredits } from '../../../../reducers/organizations';

const useStyles = makeStyles(theme => {
  return {
    root: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    buttons: {
      margin: theme.spacing(0.5)
    }
  };
});

const Credits = ({ organization }) => {
  const classNames = useStyles();
  const dispatch = useDispatch();

  const [credits, setCredits] = useState({
    organizationId: null,
    value: 0,
    valid_from: '',
    valid_to: ''
  });

  const handleSave = values => {
    dispatch(
      saveOrganizationCredits(organization.id, values.value, values.valid_from, values.valid_to)
    );
    setCredits({ ...values, organizationId: organization.id });
    return true;
  };

  const handleClear = setFieldValue => {
    setFieldValue('value', 0);
    setFieldValue('valid_from', '');
    setFieldValue('valid_to', '');
  };

  useEffect(() => {
    if (credits.organizationId === organization.id) {
      return;
    }

    const newCredits = organization.credits
      ? {
          organizationId: organization.id,
          value: organization.credits.value || 0,
          valid_from: organization.credits.valid_from
            ? moment(organization.credits.valid_from).format('YYYY-MM-DD')
            : '',
          valid_to: organization.credits.valid_to
            ? moment(organization.credits.valid_to).format('YYYY-MM-DD')
            : ''
        }
      : {
          organizationId: organization.id,
          value: 0,
          valid_from: '',
          valid_to: ''
        };
    setCredits(newCredits);
  }, [organization, credits, setCredits]);

  return (
    <Formik
      initialValues={credits}
      enableReinitialize
      validationSchema={Yup.object().shape({
        value: Yup.number().min(0),
        valid_from: Yup.date().nullable(),
        valid_to: Yup.date().when('valid_from', (from, schema, data) => {
          if (from && data.value && data.value < from) {
            return schema.min(from, 'Expiration date must be later than the starting date.');
          }
          return schema.nullable();
        })
      })}
      onSubmit={handleSave}
    >
      {({ values, dirty, isValid, setFieldValue }) => {
        return (
          <Form>
            <Box className={classNames.root}>
              <Typography variant="h6">Credits</Typography>
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Field
                        name="value"
                        label="Value"
                        type="number"
                        component={TextField}
                        InputProps={{
                          inputProps: {
                            max: 1000000000,
                            min: 0,
                            step: 'any'
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Field
                        name="valid_from"
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        component={TextField}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Field
                        label="Expiration Date"
                        name="valid_to"
                        InputProps={{
                          inputProps: {
                            min: values.valid_from
                              ? moment(values.valid_from)
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD')
                              : null
                          }
                        }}
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        component={TextField}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        className={classNames.buttons}
                        disabled={!values.value && !values.valid_from && !values.valid_to}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClear(setFieldValue)}
                      >
                        Reset
                      </Button>
                      <Button
                        className={classNames.buttons}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!dirty || !isValid}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

Credits.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

export default Credits;
