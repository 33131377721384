import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    container: {
      background: 'none!important',
      border: 'none',
      padding: '0!important',
      color: '#069',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  };
});

const ButtonLink = ({ className, children, ...props }) => {
  const classes = useStyles();
  const classNames = [classes.container];
  if (className) {
    classNames.push(className);
  }

  return (
    <button type="button" className={classNames.join(' ')} {...props}>
      {children}
    </button>
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired
};

ButtonLink.defaultProps = {
  className: ''
};

export default ButtonLink;
