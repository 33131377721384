import { Tooltip as ToolTipOrig } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, disabled, ...props }) => {
  if (!disabled) {
    return <ToolTipOrig {...props}>{children}</ToolTipOrig>;
  }
  return children;
};

Tooltip.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
};

export default Tooltip;
