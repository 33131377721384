import BaseApiService from './base';

export default class AuthApiService extends BaseApiService {
  login(data, management) {
    return this.request({
      method: 'post',
      url: '/login',
      data: {
        ...data,
        management
      }
    });
  }

  signUp(data) {
    return this.request({
      method: 'post',
      url: '/register',
      data
    });
  }

  createOrganizationUser(data) {
    return this.request({
      method: 'post',
      url: '/organizations/create_user',
      data
    });
  }

  updateProfile(data) {
    return this.request({
      method: 'put',
      url: '/profile',
      data
    });
  }

  sendResetPasswordEmail(data) {
    return this.request({
      method: 'post',
      url: '/request_reset',
      data
    });
  }

  resendVerificationEmail(email) {
    return this.request({
      method: 'post',
      url: '/resend_verification',
      data: {
        email
      }
    });
  }

  verifyEmail(token) {
    return this.request({
      method: 'post',
      url: '/verify',
      data: {
        token
      }
    });
  }

  confirmInvitation(token) {
    return this.request({
      method: 'post',
      url: '/organizations/confirm_invitation',
      data: {
        token
      }
    });
  }

  resetPassword(data) {
    return this.request({
      method: 'post',
      url: '/reset',
      data
    });
  }

  logout() {
    return this.request({
      method: 'post',
      url: '/logout'
    });
  }

  getCurrentUser(currentOrganizationId) {
    const params = {};

    if (currentOrganizationId) {
      params.current_organization_id = currentOrganizationId;
    }

    return this.request({
      method: 'get',
      url: '/me',
      params
    });
  }

  getCustomPermissions() {
    return this.request({
      method: 'get',
      url: '/custom_permissions'
    });
  }

  getGlobalSettings() {
    return this.request({
      method: 'get',
      url: '/settings'
    });
  }

  checkEmail(email) {
    return this.request({
      method: 'get',
      url: `email/validate?email=${email}`
    });
  }
}
