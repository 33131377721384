import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { CardContent } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { replaceNullToEmpty } from '../../helpers';
import LoadingButton from '../LoadingButton';
import Can from '../Can';
import CountryList from '../CountryList';
import PhoneNumber from '../PhoneNumber';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const BillingAddress = ({ onSubmit, isSaving, billingAddress, readOnly }) => {
  const classes = useStyles();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    street_address: '',
    street_address_line_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: ''
  };

  return (
    <Formik
      initialValues={billingAddress ? replaceNullToEmpty(billingAddress) : initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
          .email()
          .required('Required'),
        phone_number: Yup.string()
          .required('Required')
          .matches(/^\+[1-9]\d{6,14}$/, 'Invalid phone number.'),
        street_address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string()
          .required('Required')
          .length(2),
        country: Yup.string().required('Required'),
        zip_code: Yup.string().required('Required')
      })}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  autoComplete="name"
                  name="first_name"
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  id="country"
                  label="Country"
                  name="country"
                  component={CountryList}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="phone_number"
                  label="Phone Number"
                  name="phone_number"
                  component={PhoneNumber}
                  selectedCountry={values.country}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="state"
                  label="State / Province"
                  name="state"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="zip_code"
                  label="Postal / Zip Code"
                  name="zip_code"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  name="street_address"
                  label="Street Address"
                  id="street_address"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  fullWidth
                  name="street_address_line_2"
                  label="Street Address Line 2"
                  id="street_address_line_2"
                  component={TextField}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {!readOnly && (
            <CardActions>
              <Can
                permissions={[
                  'organization.update',
                  'organizations.manage',
                  'organizations.update'
                ]}
                yes={() => (
                  <>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isSaving}
                      disabled={!dirty || !isValid || isSaving}
                      className={classes.actionButton}
                    >
                      Save
                    </LoadingButton>
                  </>
                )}
              />
            </CardActions>
          )}
        </Form>
      )}
    </Formik>
  );
};

BillingAddress.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  billingAddress: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

BillingAddress.defaultProps = {
  readOnly: false
};

export default BillingAddress;
