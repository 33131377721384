import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center'
  },
  content: {
    '& > .MuiTextField-root': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

const InfoDialog = ({ open, onClose, title, content, titleClass, contentClass }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => onClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={`${classes.title} ${titleClass}`}>
        {title}
      </DialogTitle>
      <DialogContent className={`${classes.content} ${contentClass}`}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any.isRequired,
  titleClass: PropTypes.string,
  contentClass: PropTypes.string
};

InfoDialog.defaultProps = {
  titleClass: '',
  contentClass: ''
};

export default InfoDialog;
