import { Query } from 'cogent-js/src';
import BaseApiService from './base';

export default class OnboardingApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({ perPage = 25, page = 1, order = 'created_at', orderBy, status, searchOrganizationName }) {
    const orderDirection = orderBy === 'desc' ? '-' : '+';
    return this.request({
      method: 'get',
      url: this.query
        .for('onboardings')
        .where('search_organization_name', searchOrganizationName)
        .whereIn('status', status)
        .sort(orderDirection + order)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      url: `onboardings/${id}`,
      method: 'get'
    });
  }

  proceedNextStep(id) {
    return this.request({
      url: `onboardings/${id}/proceed_next_step`,
      method: 'post'
    });
  }

  updateMetadata(id, metadata) {
    return this.request({
      url: `onboardings/${id}/metadata`,
      method: 'put',
      data: metadata
    });
  }
}
