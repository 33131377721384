import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { TextField, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Help } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import SubSectionWrapper, { SubSectionActionWrapper } from './SubSectionWrapper';
import LoadingButton from '../../../components/LoadingButton';
import OrganizationsApiService from '../../../services/api/organizations';
import { enqueueNotification } from '../../../reducers/notifications';

const NOTIFICATIONS = {
  initial_case_setup_request: {
    label: 'Initial Case Setup Request',
    helper:
      'This notification will be sent to the owner and manager including the additional emails (if specified) when an initial case setup is assigned to the organization.'
  },
  initial_case_setup_completed: {
    label: 'Initial Case Setup Completed',
    helper:
      'This notification will be sent to the doctor who initiated the request including the additional emails (if specified).'
  },
  print_tray_request: {
    label: 'Print Tray Request',
    helper:
      'This notification will be sent to the owner and manager including the additional emails (if specified) when a print tray request is assigned to the organization.'
  },
  flawed_tray_correction_request: {
    label: 'Flawed Tray Correction Request',
    helper:
      'Important Note! This is only intended for future use. No email will be sent from this notification for the time being.'
  },
  print_tray_completed: {
    label: 'Print Tray Completed',
    helper:
      'This notification will be sent to the doctor who requested for printing tray including the additional emails (if specified).'
  },
  flawed_tray_corrected: {
    label: 'Flawed Tray Corrected',
    helper:
      'This notification will be sent to the doctor who requested for flawed tray correction including the additional emails (if specified).'
  },
  monthly_invoice: {
    label: 'Monthly Invoice',
    helper:
      "This notification will be sent to the organization's owners including the specified emails when an invoice becomes available."
  },
  payment_failed: {
    label: 'Payment Failed',
    helper:
      "This notification will be sent to the organization's owners including the specified emails when a payment is failed."
  },
  pending_case_approval_reminder: {
    label: 'Pending Case Approval Reminder',
    helper:
      "This notification will be sent weekly to the organization's owners including the specified emails when there are pending approval cases."
  }
};

const EmailNotificationSection = ({ title, helper, data, onChange, metadata }) => {
  return (
    <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
      <Grid item md={4} sm={12}>
        <p style={{ lineHeight: '1.5em' }}>
          <Checkbox
            checked={data.enabled}
            onChange={event => {
              onChange({ ...data, enabled: event.target.checked });
            }}
          />
          <strong>{title} </strong>
          <Tooltip title={helper}>
            <IconButton tabIndex={-1} style={{ fontSize: '1em', padding: 0 }}>
              <Help style={{ fontSize: '1em' }} />
            </IconButton>
          </Tooltip>
        </p>
      </Grid>
      <Grid item md={8} sm={12}>
        <Autocomplete
          disabled={!data.enabled}
          multiple
          freeSolo
          options={(metadata && metadata.email_suggestions) || []}
          value={data.additional_emails}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip disabled variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          onChange={(event, value) => {
            onChange({ ...data, additional_emails: value });
          }}
          fullWidth
          renderInput={params => (
            <TextField
              size="small"
              variant="outlined"
              multiline
              {...params}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  const currentValue = e.target.value;
                  if (
                    currentValue.match(
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    ) === null
                  ) {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }
              }}
              placeholder="Add Email"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

EmailNotificationSection.propTypes = {
  title: PropTypes.string.isRequired,
  helper: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    additional_emails: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    email_suggestions: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

const EmailNotifications = ({ data, onChange, onSave, metadata }) => {
  return (
    <SubSectionWrapper title="Email Notifications">
      <Grid container>
        <Grid item xs={12}>
          {Object.keys(data).map(name => {
            const { label, helper } = NOTIFICATIONS[name];
            return (
              <EmailNotificationSection
                metadata={metadata}
                key={name}
                title={label}
                helper={helper}
                data={data[name]}
                onChange={newData => {
                  onChange({ ...data, [name]: newData });
                }}
              />
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <SubSectionActionWrapper>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={false}
              onClick={onSave}
            >
              Save
            </LoadingButton>
          </SubSectionActionWrapper>
        </Grid>
      </Grid>
    </SubSectionWrapper>
  );
};

EmailNotifications.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object.isRequired
};

const Wrapper = ({ organizationId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    if (data === null) {
      const OrganizationsService = new OrganizationsApiService();
      OrganizationsService.getNotificationSettings(organizationId).then(response => {
        setData(response.data);
        setMetadata(response.metadata);
      });
    }
  }, [organizationId, data]);

  const handleChange = newData => {
    setData(newData);
  };

  const onSave = () => {
    const OrganizationsService = new OrganizationsApiService();
    OrganizationsService.updateNotificationSettings(organizationId, data)
      .then(() => {
        dispatch(enqueueNotification('success', 'Notification settings successfully updated.'));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
        throw new Error(error.message);
      });
  };

  if (data === null || metadata === null) {
    return '';
  }

  return (
    <EmailNotifications
      data={data || {}}
      onChange={handleChange}
      metadata={metadata}
      onSave={onSave}
    />
  );
};

Wrapper.propTypes = {
  organizationId: PropTypes.number.isRequired
};

export default Wrapper;
