import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import hasPermission from '../../selectors/hasPermission';
import LocationSelector from './index';

const LocationSelectorCurrentOrganizationHandler = ({
  defaultValue,
  multiple,
  onlyAssigned,
  onSetInitialValue,
  label,
  onChange
}) => {
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const { locations } = currentOrganization;
  const canViewAllLocations = useSelector(state =>
    hasPermission(state, { permissions: ['organization.locations.view-any'] })
  );
  const assignedLocations = currentOrganization.current_user_assigned_locations;
  const locationOptions = canViewAllLocations && !onlyAssigned ? locations : assignedLocations;
  let localDefaultValue = defaultValue;
  if (!defaultValue || isEmpty(defaultValue)) {
    if (multiple) {
      localDefaultValue = map(locationOptions, 'id');
    } else {
      localDefaultValue = locationOptions.length ? locationOptions[0].id : null;
    }
  }

  useEffect(() => {
    if (localDefaultValue) {
      onSetInitialValue(localDefaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationSelector
      assignedLocations={assignedLocations}
      locationOptions={locationOptions}
      multiple={multiple}
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
    />
  );
};

LocationSelectorCurrentOrganizationHandler.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.array,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  onlyAssigned: PropTypes.bool,
  onSetInitialValue: PropTypes.func
};

LocationSelectorCurrentOrganizationHandler.defaultProps = {
  defaultValue: null,
  multiple: true,
  label: 'Locations',
  onlyAssigned: false,
  onSetInitialValue: () => {}
};

export default LocationSelectorCurrentOrganizationHandler;
