import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import PrintingRequestsApiService from '../services/api/printingRequests';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PRINTING_REQUESTS_PREFIX = 'FETCH_PRINTING_REQUESTS';
export const FETCH_PRINTING_REQUESTS_REQUEST_ACTION = `${FETCH_PRINTING_REQUESTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_PRINTING_REQUESTS_SUCCESS_ACTION = `${FETCH_PRINTING_REQUESTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PRINTING_REQUESTS_FAILURE_ACTION = `${FETCH_PRINTING_REQUESTS_PREFIX}_FAILURE_ACTION`;

export const FETCH_PRINTING_REQUEST_PREFIX = 'FETCH_PRINTING_REQUEST';
export const FETCH_PRINTING_REQUEST_REQUEST_ACTION = `${FETCH_PRINTING_REQUEST_PREFIX}_REQUEST_ACTION`;
export const FETCH_PRINTING_REQUEST_SUCCESS_ACTION = `${FETCH_PRINTING_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PRINTING_REQUEST_FAILURE_ACTION = `${FETCH_PRINTING_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PRINTING_REQUEST_PREFIX = 'UPDATE_PRINTING_REQUEST';
export const UPDATE_PRINTING_REQUEST_SUCCESS_ACTION = `${UPDATE_PRINTING_REQUEST_PREFIX}_SUCCESS_ACTION`;

export const CANCEL_PRINTING_REQUEST_PREFIX = 'CANCEL_PRINTING_REQUEST';
export const CANCEL_PRINTING_REQUEST_SUCCESS_ACTION = `${CANCEL_PRINTING_REQUEST_PREFIX}_SUCCESS_ACTION`;

export const COMPLETE_PRINTING_REQUEST_PREFIX = 'COMPLETE_PRINTING_REQUEST';
export const COMPLETE_PRINTING_REQUEST_SUCCESS_ACTION = `${COMPLETE_PRINTING_REQUEST_PREFIX}_SUCCESS_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRINTING_REQUESTS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.total
      };
    case FETCH_PRINTING_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const printingRequestIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (printingRequestIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case UPDATE_PRINTING_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case CANCEL_PRINTING_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case COMPLETE_PRINTING_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchPrintingRequestsAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_PRINTING_REQUESTS_REQUEST_ACTION });
    const printingRequestsApiService = new PrintingRequestsApiService();
    return printingRequestsApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_PRINTING_REQUESTS_SUCCESS_ACTION,
          payload: response.printing_requests
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PRINTING_REQUESTS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPrintingRequestAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_PRINTING_REQUEST_REQUEST_ACTION });
    const printingRequestsApiService = new PrintingRequestsApiService();
    return printingRequestsApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_PRINTING_REQUEST_SUCCESS_ACTION,
          payload: response.printing_request
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PRINTING_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
