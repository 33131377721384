import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connectRefinementList } from 'react-instantsearch-dom';
import { updateRefinements } from '../../../reducers/globalSearch';

const RefinementList = ({ items, currentRefinement, options, attribute, onClick }) => {
  const dispatch = useDispatch();

  const toggleRefine = (e, option) => {
    e.preventDefault();

    const removeOption = currentRefinement.find(refinement => refinement === option.filter);

    const updatedRefinements = removeOption
      ? currentRefinement.filter(refinement => refinement !== option.filter)
      : [...currentRefinement, option.filter];

    onClick(updatedRefinements);
    dispatch(updateRefinements(attribute, updatedRefinements));
  };

  return (
    <ul className="ais-RefinementList-list">
      {_.map(options, (option, key) => {
        const item = _.find(items, i => i.label === key);

        /* The item isn't fetched from the search facets */
        if (!item)
          return (
            <li
              className="ais-RefinementList-item"
              key={option.filter}
              onClick={e => toggleRefine(e, option)}
            >
              <label className="ais-RefinementList-label">
                <input className="ais-RefinementList-checkbox" type="checkbox" />
                <span className="ais-RefinementList-labelText">{option.label}</span>
              </label>
            </li>
          );

        return (
          <li
            className={
              item.isRefined ? 'ais-RefinementList-item--selected' : 'ais-RefinementList-item'
            }
            key={item.label}
            onClick={e => toggleRefine(e, option)}
          >
            <label className="ais-RefinementList-label">
              <input className="ais-RefinementList-checkbox" type="checkbox" />
              <span className="ais-RefinementList-labelText">{option.label}</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

const CustomRefinementList = connectRefinementList(RefinementList);

RefinementList.propTypes = {
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  options: PropTypes.objectOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  currentRefinement: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

RefinementList.defaultProps = {
  options: {},
  onClick: () => {}
};

export default CustomRefinementList;
