import clsx from 'clsx';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { IconButton, Input, Paper } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '../../../components/LoadingButton';
import OrganizationsApiService from '../../../services/api/organizations';
import { enqueueNotification } from '../../../reducers/notifications';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  offscreen: {
    position: 'absolute',
    left: '-999em'
  },
  iconLink: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: 0
  }
}));

const InviteToCreateOrganization = ({ className, style, ...rest }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const currentUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);
  const textArea = useRef(null);
  const url = `${window.location.origin}/sign-up?lab_partner=${currentUser.id}`;

  const onChange = event => {
    setEmail(event.target.value);
  };

  const handleSentInvitation = async () => {
    setIsSending(true);
    const organizationApiService = new OrganizationsApiService();
    try {
      const { message } = await organizationApiService.sendInviteToCreateOrganization({ email });
      dispatch(enqueueNotification('success', message));
    } catch (error) {
      dispatch(enqueueNotification('error', error.message));
    }
    setIsSending(false);
  };

  const copyInvitationLink = () => {
    navigator.clipboard.writeText(textArea.current.textContent).then(() => {
      dispatch(enqueueNotification('info', 'Link copied!'));
    });
  };

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <AlternateEmailIcon className={classes.icon} />
      <Input
        value={email}
        placeholder="Write an email to send an invitation to create an organization"
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
      <LoadingButton
        type="button"
        size="small"
        variant="contained"
        color="primary"
        loading={isSending}
        disabled={isSending}
        onClick={handleSentInvitation}
      >
        Invite
      </LoadingButton>
      <IconButton className={classes.iconLink} color="primary" onClick={copyInvitationLink}>
        <LinkIcon />
      </IconButton>
      <div className={classes.offscreen}>
        <textarea ref={textArea} value={url} />
      </div>
    </Paper>
  );
};

InviteToCreateOrganization.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

InviteToCreateOrganization.defaultProps = {
  className: '',
  onChange: () => {},
  style: {}
};

export default InviteToCreateOrganization;
