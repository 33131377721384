import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../LoadingIndicator';
import OrdersList from '../OrdersList/OrdersList';
import {
  FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX,
  fetchCurrentOrganizationsOrdersAction
} from '../../reducers/auth';

const COOrdersListHandler = () => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX])(state)
  );

  useEffect(() => {
    dispatch(fetchCurrentOrganizationsOrdersAction(currentOrganization.id));
  }, [currentOrganization.id, dispatch]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OrdersList
      orders={get(currentOrganization, 'orders', []).filter(order => order.fm_invoice_status)}
    />
  );
};

COOrdersListHandler.propTypes = {};

COOrdersListHandler.defaultProps = {};

export default COOrdersListHandler;
