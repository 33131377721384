import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { StepConnector, SvgIcon, withStyles } from '@material-ui/core';
import { CheckCircleRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getActualStepFromIndex, getStepIndex, getTotalSteps, STEPS } from '../steps';
import SubChecklist from './SubChecklist';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  stepContent: {
    borderLeft: 0,
    paddingLeft: 24
  },
  stepLabel: {
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 14
    },
    '& .MuiStepLabel-labelContainer span': {
      fontSize: 22,
      fontWeight: 700
    }
  }
}));

const MainConnector = withStyles({
  line: {
    border: 0
  }
})(StepConnector);

const useMainStepIconStyles = makeStyles({
  root: {
    fontSize: 38,
    '& text': { fontSize: 20, fontWeight: 700 }
  }
});

const StepIcon = ({ icon, active, completed }) => {
  const classes = useMainStepIconStyles();
  const props = { color: 'primary' };
  if (!active) {
    props.color = 'disabled';
  }
  if (completed) {
    return <CheckCircleRounded color="primary" viewBox="2 2 20 20" className={classes.root} />;
  }
  return (
    <SvgIcon viewBox="0 0 34 34" className={classes.root} {...props}>
      <circle cx="17" cy="17" r="17" />
      <text fill="white" className="MuiStepIcon-text" x="17" y="23" textAnchor="middle">
        {icon}
      </text>
    </SvgIcon>
  );
};

StepIcon.propTypes = {
  icon: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired
};

export default function Checklist({ onboarding, setCurrentStep }) {
  const classes = useStyles();
  const currentStep = onboarding.current_step;
  const stepIndex = getStepIndex(currentStep);
  const totalSteps = getTotalSteps();
  const [expandedSteps, setExpandedSteps] = useState({});

  const isParentStepCompleted = parentIndex => {
    return currentStep === totalSteps || parentIndex < stepIndex.active.parent;
  };

  const isChildStepCompleted = (parentIndex, childIndex) => {
    return (
      parentIndex < stepIndex.active.parent ||
      (parentIndex === stepIndex.current.parent && childIndex <= stepIndex.current.child)
    );
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={stepIndex.active.parent}
        orientation="vertical"
        connector={<MainConnector />}
      >
        {STEPS.map((parentStep, parentStepIndex) => (
          <Step
            key={parentStep.title}
            expanded={!isParentStepCompleted(parentStepIndex) || expandedSteps[parentStepIndex]}
            completed={isParentStepCompleted(parentStepIndex)}
          >
            <StepLabel
              StepIconComponent={StepIcon}
              className={classes.stepLabel}
              onClick={() => {
                if (!isParentStepCompleted(parentStepIndex)) {
                  return;
                }
                setExpandedSteps({
                  ...expandedSteps,
                  [parentStepIndex]: !expandedSteps[parentStepIndex]
                });
              }}
            >
              {parentStep.title}
            </StepLabel>
            <StepContent className={classes.stepContent}>
              <SubChecklist
                onboarding={onboarding}
                activeStep={
                  stepIndex.active.parent === parentStepIndex ? stepIndex.active.child : -1
                }
                isSubStepCompletedFunc={childStepIndex =>
                  isChildStepCompleted(parentStepIndex, childStepIndex)
                }
                setActiveStep={childStepIndex =>
                  setCurrentStep(getActualStepFromIndex(parentStepIndex, childStepIndex))
                }
                steps={parentStep.subSteps}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

Checklist.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onboarding: PropTypes.object.isRequired
};
