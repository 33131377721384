import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { Button } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import Box from '@material-ui/core/Box';
import { isEmpty } from 'lodash';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from 'formik-material-ui';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import StatusTag from '../../../components/StatusTag';
import UploadFilesDialog from '../../../components/UploadFilesDialog';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import PatientFilesApiService from '../../../services/api/patientFiles';
import { enqueueNotification } from '../../../reducers/notifications';
import LoadingButton from '../../../components/LoadingButton';
import { JAWS_TYPES, PATIENT_FILES_IDS } from '../../../constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../components/Can';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  }
}));

const FlawedTray = ({
  item,
  patientId,
  correctFlawedTray,
  updateFlawedTray,
  isUpdating,
  remove,
  isDeleting
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [mode, setMode] = useState('normal');
  const [isSaving, setIsSaving] = useState(false);
  const request = useSelector(state => {
    return state.requests.items[
      state.requests.items.findIndex(requestItem => requestItem.id === item.customer_request_id)
    ];
  });

  const handleUploadDialogClose = uploadedFiles => {
    if (!isEmpty(uploadedFiles)) {
      const correctedFileId = uploadedFiles[Object.keys(uploadedFiles)[0]].id;
      correctFlawedTray(item.id, correctedFileId);
    }

    setUploadDialogOpen(false);
  };

  const removeCorrectedFile = () => {
    const patientFilesService = new PatientFilesApiService();
    patientFilesService.deleteFile(item.corrected_file_id).catch(error => {
      dispatch(enqueueNotification('error', error.message));
    });

    updateFlawedTray(item.id, {
      corrected_file_id: null,
      status_id: 1
    });
  };

  const handleDownloadFile = fileId => {
    dispatch(fetchDownloadUrlPrintingFileAction(fileId));
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={item}
        validationSchema={Yup.object().shape({
          print: Yup.bool().required('Required')
        })}
        onSubmit={values => {
          setIsSaving(true);
          return updateFlawedTray(item.id, values).then(response => {
            if (response) {
              setMode('normal');
            }
            setIsSaving(false);
          });
        }}
      >
        {({ submitForm, isValid, dirty }) => (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>
                  <BuildIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Box component="span" display="inline-block" width={100}>
                      {JAWS_TYPES[item.arch.jaw_type_id].name}
                    </Box>
                    {mode === 'edit' ? (
                      <FormControlLabel
                        control={<Field name="print" color="primary" component={Checkbox} />}
                        label="Print"
                      />
                    ) : (
                      item.print && <StatusTag label="Print" color="orange" />
                    )}
                  </>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      IDB Tray
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction>
                {request && request.status_id === 1 && (
                  <Can
                    permissions={[
                      'flawed-trays.manage',
                      'flawed-trays.update',
                      'flawed-trays.correct'
                    ]}
                    yes={() => (
                      <>
                        {item.status_id === 1 && (
                          <Button
                            variant="contained"
                            onClick={() => setUploadDialogOpen(true)}
                            size="small"
                            startIcon={<CloudUploadIcon />}
                            className={classes.button}
                          >
                            Upload File
                          </Button>
                        )}
                        {item.status_id === 2 && item.corrected_file_id && (
                          <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            onClick={removeCorrectedFile}
                            startIcon={<DeleteIcon />}
                            loading={isUpdating}
                          >
                            Remove File
                          </LoadingButton>
                        )}
                      </>
                    )}
                  />
                )}
                {item.corrected_file_id && (
                  <Button
                    variant="contained"
                    onClick={() => handleDownloadFile(item.corrected_file_id)}
                    className={classes.button}
                    size="small"
                    startIcon={<CloudDownloadIcon />}
                  >
                    Corrected
                  </Button>
                )}
                {item.stl_file_id && (
                  <Button
                    variant="contained"
                    onClick={() => handleDownloadFile(item.stl_file_id)}
                    size="small"
                    startIcon={<CloudDownloadIcon />}
                  >
                    Original STL
                  </Button>
                )}
                {request && request.status_id === 1 && (
                  <Can
                    permissions={[
                      'flawed-trays.manage',
                      'flawed-trays.update',
                      'flawed-trays.correct'
                    ]}
                    yes={() => (
                      <Box component="span" ml={3}>
                        {mode === 'edit' && (
                          <>
                            <IconButton
                              disabled={isSaving || isDeleting || !isValid || !dirty}
                              color="primary"
                              onClick={submitForm}
                            >
                              <CheckCircleIcon />
                              {isSaving && <LoadingSpinner />}
                            </IconButton>
                          </>
                        )}
                        <IconButton
                          disabled={isDeleting || isSaving}
                          onClick={() => setMode(mode === 'edit' ? 'normal' : 'edit')}
                        >
                          {mode === 'edit' ? (
                            <CancelIcon color="secondary" />
                          ) : (
                            <EditIcon color="primary" />
                          )}
                        </IconButton>
                        <ConfirmationDialogOnClickWrapper
                          confirmationBody="Are you sure you want to delete this item?"
                          confirmationTitle="Delete Confirmation"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => remove(item.id)}
                            disabled={isDeleting || isUpdating}
                          >
                            <DeleteIcon />
                            {isDeleting && <LoadingSpinner />}
                          </IconButton>
                        </ConfirmationDialogOnClickWrapper>
                      </Box>
                    )}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <UploadFilesDialog
              title="Corrected File"
              patientFileTypeId={PATIENT_FILES_IDS.stl}
              patientId={patientId}
              maxFiles={1}
              handleClose={handleUploadDialogClose}
              open={uploadDialogOpen}
            />
          </>
        )}
      </Formik>
    </>
  );
};

FlawedTray.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
  correctFlawedTray: PropTypes.func,
  updateFlawedTray: PropTypes.func,
  isUpdating: PropTypes.bool,
  remove: PropTypes.func,
  isDeleting: PropTypes.bool
};

FlawedTray.defaultProps = {
  correctFlawedTray: () => {},
  updateFlawedTray: () => {},
  isUpdating: false,
  remove: false,
  isDeleting: false
};

export default FlawedTray;
