import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

const PublicRoute = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [history, isAuthenticated]);

  if (isAuthenticated) {
    return null;
  }

  return <Route {...props} />;
};

PublicRoute.propTypes = {};

PublicRoute.defaultProps = {};

export default PublicRoute;
