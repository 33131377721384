import ListItemText from '@material-ui/core/ListItemText';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ARCH_MODE_LABELS,
  ASSIGNMENT_LABELS,
  ASSIGNMENTS,
  ATTRIBUTE_VALUE_LABELS,
  ATTRIBUTES,
  IMPRESSION_TYPE_LABELS,
  QUESTIONS,
  SCAN_SUBMISSION_LABELS,
  SCAN_SUBMISSION_METHODS,
  SCANNER_LABELS,
  VIEW_LABEL
} from './constants';
import _ from 'lodash';
import { hasPrintingOption } from '../../helpers';

const useStyles = makeStyles(theme => ({
  bracketsList: {
    paddingLeft: theme.spacing(2.5)
  },
  ul: {
    paddingLeft: theme.spacing(2.5)
  }
}));

const View = ({ rxForm }) => {
  const styles = useStyles();

  const questions = [
    {
      attribute: ATTRIBUTES.IMPRESSION_TYPE_METHOD,
      generateLabel: (value, data) => {
        return IMPRESSION_TYPE_LABELS[value];
      },
      hide: !rxForm.impression_type
    },
    {
      attribute: ATTRIBUTES.RUSH_CASE,
      generateLabel: (value, data) => {
        if (!value) {
          return 'No';
        }
        const authorizedName = data[ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME];
        return `Yes, authorized by "${authorizedName}"`;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.PRODUCTS_LIST_METHOD,
      generateLabel: (value, data) => {
        return (
          <ul className={styles.ul}>
            {data.products_list_names.map((name, i) => (
              <li key={i}>{name}</li>
            ))}
          </ul>
        );
      },
      hide: _.isEmpty(rxForm.products_list_names)
    },
    {
      attribute: ATTRIBUTES.ADDON_OPTIONS_METHOD,
      generateLabel: (value, data) => {
        return (
          <ul className={styles.ul}>
            {data.addon_options_names.map((name, i) => (
              <li key={i}>{name}</li>
            ))}
          </ul>
        );
      },
      hide: _.isEmpty(rxForm.addon_options_names)
    },
    {
      attribute: ATTRIBUTES.ARCH_TYPE,
      hide: !hasPrintingOption(rxForm)
    },
    {
      attribute: ATTRIBUTES.ARCH_MODE,
      generateLabel: (value, data) => {
        return ARCH_MODE_LABELS[value];
      },
      hide: !hasPrintingOption(rxForm)
    },
    {
      attribute: ATTRIBUTES.SCAN_SUBMISSION_METHOD,
      generateLabel: (value, data) => {
        let label = SCAN_SUBMISSION_LABELS[value];
        if (value === SCAN_SUBMISSION_METHODS.UPLOAD) {
          label = SCAN_SUBMISSION_LABELS[value];
        } else {
          const scannerName =
            data[ATTRIBUTES.CUSTOM_SCANNER_NAME] || SCANNER_LABELS[data[ATTRIBUTES.SCANNER]];
          label = `${label} (${scannerName})`;
        }
        return label.replace(
          '{ASSIGNMENT}',
          ASSIGNMENT_LABELS[data[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]]
        );
      }
    },
    {
      attribute: ATTRIBUTES.TREATMENT_LOCATION,
      generateLabel: (value, data) => {
        return data.location.location_name;
      }
    },
    {
      attribute: ATTRIBUTES.SHIPPING_LOCATION,
      generateLabel: (value, data) => {
        return data.shipping_location && data.shipping_location.location_name;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.SHIPPING_METHOD,
      hide: !rxForm.version || rxForm.print_assignment === ASSIGNMENTS.IN_OFFICE,
      generateLabel: (value, data) => {
        return data.shipping_method_label;
      }
    },
    {
      attribute: 'created_by_user_id',
      hide: rxForm.user_id === rxForm.created_by_user_id,
      generateLabel: (value, data) => {
        return data.created_by_user.full_name;
      }
    },
    {
      attribute: ATTRIBUTES.ORDER_MORE_SUPPLIES_METHOD,
      hide: !(rxForm.boxes || rxForm.impression_baggies),
      generateLabel: (value, data) => {
        return (
          <ul className={styles.ul}>
            {Object.keys(value).map(key => {
              return (
                value[key] && (
                  <li key={key}>
                    {VIEW_LABEL[key]}: {value[key]}
                  </li>
                )
              );
            })}
          </ul>
        );
      }
    }
  ];

  return (
    <Grid container spacing={1}>
      {questions.map(
        ({ attribute, generateLabel = null, gridSize = 6, hide = false, labelProps = {} }) => {
          let label = rxForm[attribute];

          if (label === null || label === undefined || hide) {
            return '';
          }
          if (generateLabel !== null) {
            label = generateLabel(label, rxForm);
          } else if (ATTRIBUTE_VALUE_LABELS[attribute]) {
            label = ATTRIBUTE_VALUE_LABELS[attribute][label];
          } else if (typeof label === 'boolean') {
            label = label ? 'Yes' : 'No';
          }

          return (
            <Grid key={attribute} item xs={gridSize}>
              <ListItemText
                primary={VIEW_LABEL[attribute] || QUESTIONS[attribute]}
                secondary={label}
                secondaryTypographyProps={labelProps}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

View.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired
};

export default View;
