import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { makeStyles } from '@material-ui/core';
import {
  ASSIGNMENTS,
  ASSIGNMENT_LABELS,
  ATTRIBUTES,
  SCAN_SUBMISSION_METHODS,
  SCAN_SUBMISSION_LABELS
} from '../constants';
import RadioGroupField from './RadioGroupField';
import { decodeFormikValues } from '../helpers';
import InfoDialog from '../../InfoDialog';

const useStyles = makeStyles(() => ({
  title: {
    '& > h2': {
      fontSize: '1rem',
      color: 'red'
    }
  }
}));

const { THIRD_PARTY, ORTHOSELECT, IN_OFFICE } = ASSIGNMENTS;
const { UPLOAD, SCANNER } = SCAN_SUBMISSION_METHODS;

const ScanSubmissionMethodField = ({
  currentAssignment,
  currentValue,
  setValue,
  preferenceValue,
  thirdPartyPartnerName,
  ...props
}) => {
  const classes = useStyles();
  const options = [];
  if (Array.isArray(preferenceValue)) {
    if (preferenceValue.indexOf(UPLOAD) !== -1) {
      options.push({
        label: SCAN_SUBMISSION_LABELS[UPLOAD],
        value: UPLOAD
      });
    }
    if (preferenceValue.indexOf(SCANNER) !== -1) {
      const labEntity =
        currentAssignment === THIRD_PARTY ? thirdPartyPartnerName : ASSIGNMENT_LABELS[ORTHOSELECT];
      options.push({
        label: SCAN_SUBMISSION_LABELS[SCANNER].replace('{ASSIGNMENT}', labEntity),
        value: SCANNER,
        disabled: currentAssignment === IN_OFFICE
      });
    }
  }

  const [submissionInfoShown, setSubmissionInfoShown] = useState(false);
  const [fieldLoaded, setFieldLoaded] = useState(false);

  useEffect(() => {
    if (currentAssignment === IN_OFFICE && currentValue === SCANNER) {
      setValue(UPLOAD);
    }
  }, [currentAssignment, currentValue, setValue]);

  useEffect(() => {
    if (fieldLoaded && currentValue === SCANNER) {
      setSubmissionInfoShown(true);
    }
    // eslint-disable-next-line
  }, [currentValue]);

  useEffect(() => {
    setFieldLoaded(true);
  }, []);

  return (
    <>
      <RadioGroupField
        options={options}
        name={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
        {...props}
        fieldProps={{ row: false }}
      />
      <InfoDialog
        open={submissionInfoShown}
        onClose={() => setSubmissionInfoShown(false)}
        title="IMPORTANT INFORMATION TO FACILITATE CASE COMPLETION"
        content="Patient name MUST match intra-oral scan name, otherwise scans cannot be retrieved. Please double check before submission of this form."
        titleClass={classes.title}
      />
    </>
  );
};

ScanSubmissionMethodField.defaultProps = {
  thirdPartyPartnerName: null
};

ScanSubmissionMethodField.propTypes = {
  preferenceValue: PropTypes.array.isRequired,
  currentAssignment: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  thirdPartyPartnerName: PropTypes.string
};

export default connect(({ formik: { values: encodeValues, setFieldValue }, ...props }) => {
  const values = decodeFormikValues(encodeValues);
  const currentAssignment = values[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT];
  const currentValue = values[ATTRIBUTES.SCAN_SUBMISSION_METHOD];

  return (
    <ScanSubmissionMethodField
      setValue={value => setFieldValue(ATTRIBUTES.SCAN_SUBMISSION_METHOD, String(value))}
      currentValue={currentValue}
      currentAssignment={currentAssignment}
      {...props}
    />
  );
});
