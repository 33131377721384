import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import createLoadingSelector from '../../selectors/loading';
import PatientForm from './components/PatientForm';
import { CREATE_PATIENT_PREFIX, createPatientAction } from '../../reducers/patients';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const AddPatient = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCreatingPatient = useSelector(state =>
    createLoadingSelector([CREATE_PATIENT_PREFIX])(state)
  );
  const location = useLocation();
  const { firstName, lastName, caseNumber, rxFormId } = parse(location.search);

  const createPatient = values => {
    dispatch(createPatientAction({ ...values, user_id: params.userId, rx_form_id: rxFormId })).then(
      patient => {
        if (patient) {
          history.push(`/patient-details/${patient.id}`, {
            route: `/users/${params.userId}/patients`
          });
        }
      }
    );
    return true;
  };

  return (
    <Card className={classes.form}>
      <CardHeader title="Create Patient" />
      <Divider />
      <PatientForm
        onSubmit={createPatient}
        initialValues={
          rxFormId ? { first_name: firstName, last_name: lastName, case_number_text: caseNumber } : {}
        }
        isLoading={isCreatingPatient}
      />
    </Card>
  );
};

AddPatient.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

AddPatient.defaultProps = {
  match: { params: {} }
};

export default AddPatient;
