import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import AnnouncementApiService from '../services/api/announcement';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const FETCH_ANNOUNCEMENTS_PREFIX = 'FETCH_ANNOUNCEMENTS';
export const FETCH_ANNOUNCEMENTS_REQUEST_ACTION = `${FETCH_ANNOUNCEMENTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_ANNOUNCEMENTS_SUCCESS_ACTION = `${FETCH_ANNOUNCEMENTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ANNOUNCEMENTS_FAILURE_ACTION = `${FETCH_ANNOUNCEMENTS_PREFIX}_FAILURE_ACTION`;

export const UPDATE_ANNOUNCEMENT_PREFIX = 'UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_REQUEST_ACTION = `${UPDATE_ANNOUNCEMENT_PREFIX}_REQUEST_ACTION`;
export const UPDATE_ANNOUNCEMENT_SUCCESS_ACTION = `${UPDATE_ANNOUNCEMENT_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_ANNOUNCEMENT_FAILURE_ACTION = `${UPDATE_ANNOUNCEMENT_PREFIX}_FAILURE_ACTION`;

export const DELETE_ANNOUNCEMENT_PREFIX = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_REQUEST_ACTION = `${DELETE_ANNOUNCEMENT_PREFIX}_REQUEST_ACTION`;
export const DELETE_ANNOUNCEMENT_SUCCESS_ACTION = `${DELETE_ANNOUNCEMENT_PREFIX}_SUCCESS_ACTION`;
export const DELETE_ANNOUNCEMENT_FAILURE_ACTION = `${DELETE_ANNOUNCEMENT_PREFIX}_FAILURE_ACTION`;

export const UPDATE_ANNOUNCEMENT_LOCALLY_ACTION = 'UPDATE_ANNOUNCEMENT_LOCALLY_ACTION';

// INITIAL STATE ///////////////////////////////////////////////////////////////
const initialState = {
  announcements: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.announcements = action.payload;
      });
    case UPDATE_ANNOUNCEMENT_SUCCESS_ACTION:
    case UPDATE_ANNOUNCEMENT_LOCALLY_ACTION:
    case DELETE_ANNOUNCEMENT_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.announcements[state.announcements.findIndex(item => item.id === action.payload.id)] =
          action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////
export function fetchAnnouncementsAction() {
  return dispatch => {
    dispatch({ type: FETCH_ANNOUNCEMENTS_REQUEST_ACTION });

    const service = new AnnouncementApiService();

    return service
      .get()
      .then(response => {
        dispatch({ type: FETCH_ANNOUNCEMENTS_SUCCESS_ACTION, payload: response.announcements });
      })
      .catch(error => {
        dispatch({
          type: FETCH_ANNOUNCEMENTS_FAILURE_ACTION,
          payload: { message: error.message }
        });

        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateAnnouncementAction(id, body) {
  return dispatch => {
    dispatch({ type: UPDATE_ANNOUNCEMENT_REQUEST_ACTION });

    const service = new AnnouncementApiService();

    return service
      .update(id, body)
      .then(response => {
        dispatch({
          type: UPDATE_ANNOUNCEMENT_SUCCESS_ACTION,
          payload: response.announcement
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_ANNOUNCEMENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteAnnouncementAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_ANNOUNCEMENT_REQUEST_ACTION });

    const service = new AnnouncementApiService();

    return service
      .update(id, '')
      .then(response => {
        dispatch({
          type: DELETE_ANNOUNCEMENT_SUCCESS_ACTION,
          payload: response.announcement
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_ANNOUNCEMENT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateAnnouncementLocally(announcement) {
  return dispatch => {
    dispatch({
      type: UPDATE_ANNOUNCEMENT_LOCALLY_ACTION,
      payload: announcement
    });
  };
}
