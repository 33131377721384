import moment from 'moment';
import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class DashboardApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({ dateFilterFrom, dateFilterTo, withLiveData = true }) {
    const from = moment.isMoment(dateFilterFrom) ? encodeURIComponent(dateFilterFrom.format()) : '';
    const to = moment.isMoment(dateFilterTo) ? encodeURIComponent(dateFilterTo.format()) : '';
    // eslint-disable-next-line camelcase
    const with_live_data = withLiveData ? 1 : 0;
    return this.request({
      method: 'get',
      url: this.query
        .for('dashboard')
        .params({ dateFilterFrom: from, dateFilterTo: to, with_live_data })
        .get()
    });
  }

  /**
   * @deprecated
   */
  syncWorkflowData() {
    return this.request({
      method: 'get',
      url: '/sync/workflow-data'
    });
  }
}
