import PropTypes from 'prop-types';
import React from 'react';
import { produce } from 'immer';
import { DatePicker } from 'antd';
import moment from 'moment';
import { dateTimeToUTC } from '../../helpers';

const DibsRangePickerFilter = props => {
  const {
    setAdditionalParams,
    additionalParams,
    defaultValue,
    fromParamKey,
    toParamKey,
    convertToUtc
  } = props;
  const { RangePicker } = DatePicker;

  const rangePickerProps = { ...props };
  if (!defaultValue || !defaultValue.length) {
    rangePickerProps.value = [
      additionalParams.params[fromParamKey] ? moment(additionalParams.params[fromParamKey]) : '',
      additionalParams.params[toParamKey] ? moment(additionalParams.params[toParamKey]) : ''
    ];
  } else {
    rangePickerProps.defaultValue = defaultValue;
  }

  const handleOnRangePickerChange = dates => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        if (dates) {
          const from = dates[0].startOf('day');
          const to = dates[1].endOf('day');
          draft.params[fromParamKey] = convertToUtc ? dateTimeToUTC(from) : from;
          draft.params[toParamKey] = convertToUtc ? dateTimeToUTC(to) : to;
        } else {
          draft.params[fromParamKey] = '';
          draft.params[toParamKey] = '';
        }
        draft.resetPage = true;
      })
    );
  };

  return <RangePicker onChange={handleOnRangePickerChange} {...rangePickerProps} />;
};

DibsRangePickerFilter.propTypes = {
  setAdditionalParams: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalParams: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.array,
  fromParamKey: PropTypes.string,
  toParamKey: PropTypes.string,
  convertToUtc: PropTypes.bool
};

DibsRangePickerFilter.defaultProps = {
  defaultValue: [],
  fromParamKey: 'from',
  toParamKey: 'to',
  convertToUtc: true
};

export default DibsRangePickerFilter;
