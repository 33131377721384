import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { ASSIGNMENTS, ATTRIBUTES } from '../constants';
import { assignmentOptions, decodeFormikValues } from '../helpers';
import RadioGroupField from './RadioGroupField';

const { IN_OFFICE } = ASSIGNMENTS;

const Field = ({
  currentPrintAssignment,
  preferenceValue,
  thirdPartyPartnerName,
  // eslint-disable-next-line react/prop-types
  formik: { values, setFieldValue },
  ...props
}) => {
  const name = ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT;
  const [assignments, setAssignments] = useState([]);
  const decodedValues = decodeFormikValues(values);
  const currentValue = decodedValues[name];

  // update assignments on print assignment change
  useEffect(() => {
    const newAssignments = [IN_OFFICE];
    if (currentPrintAssignment !== IN_OFFICE) {
      newAssignments.unshift(currentPrintAssignment);
    }
    if (assignments.toString() !== newAssignments.toString()) {
      setAssignments(newAssignments);
    }
  }, [assignments, currentPrintAssignment]);

  // fallback value if current value is not available on the options
  useEffect(() => {
    if (!assignments.length) {
      return;
    }

    if (assignments.indexOf(currentValue) === -1) {
      if (assignments.indexOf(preferenceValue) !== -1) {
        setFieldValue(name, String(preferenceValue));
        return;
      }
      setFieldValue(name, String(assignments[0]));
    }
  }, [assignments, name, setFieldValue, currentValue, preferenceValue]);

  const options = assignmentOptions({ assignments, thirdPartyName: thirdPartyPartnerName });
  return (
    <RadioGroupField name={ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT} options={options} {...props} />
  );
};

Field.defaultProps = {
  thirdPartyPartnerName: null
};

Field.propTypes = {
  currentPrintAssignment: PropTypes.number.isRequired,
  preferenceValue: PropTypes.number.isRequired,
  thirdPartyPartnerName: PropTypes.string
};

export default connect(Field);
