import { Query } from 'cogent-js';
import BaseApiService from './base';
import { encodeURIComponentArray, serializeDate } from '../../helpers';

export default class PatientApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
    this.resourcePath = '/patients';
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    organizationId = '',
    searchFullNameQuery = '',
    includes = [],
    searchDoctorFullNameOrOrganizationQuery = '',
    patientStatusId = '',
    onlyDeleted = false,
    onlyOnhold = 'without',
    requestTypeStatus = [],
    requestStep = null,
    organizations = [],
    shippingTypeFilter = [],
    shipDateFrom,
    shipDateTo,
    customerServiceManager = '',
    requestAssignments = [],
    activeRequestOnly = true,
    archivedOnly = false,
    rushCaseOnly = false,
    shipping = null,
    createdDateFrom,
    createdDateTo,
    completedCaseDateFrom,
    completedCaseDateTo,
    cancelled = null,
    stats = null,
    shippedDateFrom,
    shippedDateTo,
    ids = [],
    trackingNumber = null,
    impressionType = null,
    productsList = null,
    resource_type = 'patients',
    case_tray_id = null,
    null_tray_only = false
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    const from = serializeDate(shipDateFrom);
    const to = serializeDate(shipDateTo);

    const createdFrom = serializeDate(createdDateFrom);
    const createdTo = serializeDate(createdDateTo);

    const completedCaseFrom = serializeDate(completedCaseDateFrom);
    const completedCaseTo = serializeDate(completedCaseDateTo);

    const shippedFrom = serializeDate(shippedDateFrom);
    const shippedTo = serializeDate(shippedDateTo);

    let url = this.query
      .for('patients')
      .include(...includes)
      .where('user_id', userId)
      .where('organization_id', organizationId)
      .where('patient_status_id', patientStatusId)
      .where('patient_full_name_like', searchFullNameQuery)
      .where('doctor_full_name_or_organization_like', searchDoctorFullNameOrOrganizationQuery)
      .where('trashed', onlyDeleted ? 'only' : '')
      .where('held', onlyOnhold)
      .where('cancelled', cancelled)
      .where('stats', stats)
      .where('active_request_only', activeRequestOnly)
      .where('archived_only', archivedOnly)
      .where('rush_case_only', rushCaseOnly)
      .where('shipping', shipping)
      .where('organization_type', customerServiceManager)
      .whereIn('ids', encodeURIComponentArray(ids))
      .whereIn('filter_assignments', encodeURIComponentArray(requestAssignments))
      .whereIn('chosen_wi_organization_id', organizations)
      .whereIn('filter_request_type_status', encodeURIComponentArray(requestTypeStatus))
      .where('request_step', requestStep)
      .where('tracking_number', trackingNumber)
      .whereIn('filter_shipping_type', shippingTypeFilter)
      .where('filter_ship_date_range', from && to ? [from, to].join(',') : null)
      .where(
        'created_at_between',
        createdFrom && createdTo ? [createdFrom, createdTo].join(',') : null
      )
      .where(
        'completed_case_between',
        completedCaseFrom && completedCaseTo ? [completedCaseFrom, completedCaseTo].join(',') : null
      )
      .where(
        'shipped_between',
        shippedFrom && shippedTo ? [shippedFrom, shippedTo].join(',') : null
      )
      .where('impression_type', impressionType)
      .where('products_list', productsList)
      .where('case_tray_id', case_tray_id)
      .where('null_tray_only', null_tray_only)
      .sort(orderChar + orderByField)
      .params({ perPage, page, resource_type });

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  getSpecific(ids, includes) {
    let url = this.query
      .whereIn('ids', encodeURIComponentArray(ids))
      .for('patients')
      .include(...includes);

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  show(id, includes = []) {
    let url = this.query.for(`patients/${id}`).include(includes);

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: this.resourcePath,
      data: {
        ...data,
        auto_increment: true
      }
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `${this.resourcePath}/${id}`
    });
  }

  restore(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/restore`
    });
  }

  complete(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/complete_setup`
    });
  }

  hold(id, data = null) {
    let request = {
      method: 'put',
      url: `/patients/${id}/toggle_hold`
    };
    if (data) {
      request = { ...request, data };
    }
    return this.request(request);
  }

  getFilesDownloadUrls(patientId, fileTypeId) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/${patientId}/images`,
      params: {
        thumbnails: true,
        patient_file_type_id: fileTypeId
      }
    });
  }

  archive(id) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/${id}/archive`
    });
  }

  archiveCaseTray(id) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/case-trays/${id}/archive`
    });
  }

  generateShippingId(id) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/case-trays/${id}/shipping-id`
    });
  }

  addCharge(patientId, data) {
    return this.request({
      method: 'post',
      url: `/patients/${patientId}/charge`,
      data
    });
  }

  removeCharge(patientId, chargeId) {
    return this.request({
      method: 'delete',
      url: `/patients/${patientId}/charge`,
      data: {
        charge_id: chargeId
      }
    });
  }

  storePatientsTray(data) {
    return this.request({
      method: 'post',
      url: '/patients/case-trays',
      data
    });
  }

  findCaseTray(id) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/case-trays/${id}`
    });
  }
}
