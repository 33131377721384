import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(10)
    }
  }
}));

const WelcomeMessage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.paper}>
      <Typography variant="subtitle2">
        Congratulations! You have successfully logged in to your ORTHOSELECT DIGITAL STUDY MODELS (DSM) account. Let's finish the
        process now.
      </Typography>
      <br />
      <Typography variant="subtitle2">What is next?</Typography>
      <Box mt={1}>
        <ol>
          <li>Fill in the rest of your information.</li>
          <li>Go through the onboarding process (optional).</li>
          <li>Hooray! You are ready to submit your first case.</li>
        </ol>
      </Box>
    </Box>
  );
};

export default WelcomeMessage;
