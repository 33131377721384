import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import createLoadingSelector from '../../../selectors/loading';
import { UI_SETTINGS } from '../../../constants';
import OrgForm from './OrgForm';
import { SIGN_UP_PREFIX, signUpAction } from '../../../reducers/auth';
import { setNoStartupFee } from '../../../reducers/organizations';

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCreating = useSelector(state => createLoadingSelector([SIGN_UP_PREFIX])(state));

  const create = values => {
    const data = {
      ...values,
      password: Math.random().toString(16).slice(2)
    };

    return dispatch(signUpAction(data))
    .then(identifier => {
      if (identifier) {
        if (data.no_startup_fee) {
          dispatch(setNoStartupFee(identifier));
        }

        history.push(`/organizations/${identifier}`);
      }
    });
  };

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader title="Create Organization" />
          <Divider />
          <OrgForm onSubmit={create} isSaving={isCreating} />
        </Card>
      </Box>
    </Box>
  );
};

CreateOrganization.propTypes = {};

CreateOrganization.defaultProps = {};

export default CreateOrganization;
