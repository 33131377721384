/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import LoadingButton from '../../components/LoadingButton';

const MAX_TRAY_NUMBER_LENGTH = 10;

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center'
  },
  content: {
    '& > .MuiTextField-root': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

const CreateTrayDialog = ({ onSubmit, open, setOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [trayNumber, setTrayNumber] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    setTrayNumber('');
    setErrorMsg('');
  };

  const onInputChange = ({ target }) => {
    if (target.value.length > MAX_TRAY_NUMBER_LENGTH) {
      setErrorMsg(`Max ${MAX_TRAY_NUMBER_LENGTH} characters allowed`);
    } else if (/[^a-zA-Z0-9]/.test(target.value)) {
      setErrorMsg('Only alphanumeric characters allowed');
    } else {
      setErrorMsg('');
      setTrayNumber(target.value.toUpperCase());
    }
  };

  const onDone = () => {
    setLoading(true);
    onSubmit(trayNumber, { handleClose, setErrorMsg, setLoading });
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Tray Information
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            autoFocus
            id="tray_number"
            name="tray_number"
            label="Tray Number"
            placeholder="e.g AXX1234"
            type="text"
            required
            value={trayNumber}
            onChange={onInputChange}
            fullWidth
            error={!!errorMsg}
            helperText={errorMsg || 'Max 10 alpha-numeric characters allowed'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            color="primary"
            loading={loading}
            onClick={onDone}
            disabled={trayNumber.length === 0 || !!errorMsg}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CreateTrayDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CreateTrayDialog;
