import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { Button, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { green, orange } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import BuildIcon from '@material-ui/icons/Build';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { get, find, every } from 'lodash';
import AddressCard from '../Address';
import { CONTENT_TYPES, JAWS_TYPES, REQUEST_STATUSES, RX_FORMS } from '../../constants';
import StatusTag from '../StatusTag';
import { updatePatientFieldAction } from '../../reducers/patients';
import LoadingButton from '../LoadingButton';
import { formatAccountNumber, getColorByStatus, isTechnicianOrAdmin } from '../../helpers';
import BracketsSection from './BracketsSection';

const useStyles = makeStyles(theme => ({
  column: {
    border: `1px solid ${theme.palette.divider}`
  },
  hideOnPrint: {
    '@media print': {
      display: 'none'
    }
  },
  root: {
    width: '70%',
    '@media print': {
      width: '100%',
      '& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.grey[800]
      }
    }
  },
  actionButton: {
    marginLeft: 'auto'
  },
  tag: {
    margin: '4px 0'
  },
  redColorFont: {
    color: 'red'
  },
  bigFont: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  flawedTrayButton: {
    backgroundColor: orange[500],
    marginBottom: '10px'
  },
  printingRequestButton: {
    backgroundColor: green[500],
    marginBottom: '10px'
  },
  initialSetupButton: {
    marginBottom: '10px'
  },
  printingRequestGridItem: {
    height: '70px'
  }
}));

const PatientSummary = ({ patient, printingPreview, request }) => {
  const classes = useStyles();
  const currentUser = useSelector(state => state.auth.user);
  const [notes, setNotes] = useState({
    doctor_notes: patient.doctor_notes,
    management_notes: patient.management_notes
  });
  const history = useHistory();
  const [loading, setLoading] = useState({
    doctor_notes: false,
    management_notes: false
  });
  const dispatch = useDispatch();

  const updateNotes = key => {
    setLoading({ ...loading, [key]: true });
    dispatch(updatePatientFieldAction(patient.id, notes[key], key)).then(() => {
      setLoading({ ...loading, [key]: false });
    });
  };

  const renderFlawedTray = flawedTray => {
    if (!flawedTray) {
      return (
        <Grid container>
          <Grid item>
            <Box height={60} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Grid item>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <BuildIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<>{JAWS_TYPES[flawedTray.arch.jaw_type_id].name}</>}
              disableTypography
              secondary={
                <div>
                  <Typography component="span" variant="body2" color="textPrimary">
                    IDB Tray
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </Grid>
        <Grid item>
          {!!flawedTray.print && (
            <div className={classes.tag}>
              <StatusTag label="Print" color="orange" />
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderPrintingRequestItem = printingRequestItem => {
    if (!printingRequestItem) {
      return <Grid item xs={12} className={classes.printingRequestGridItem} />;
    }

    return (
      <Grid item xs={12} className={classes.printingRequestGridItem}>
        <Grid container>
          <Grid>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>
                  <BuildIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<div>{JAWS_TYPES[printingRequestItem.arch.jaw_type_id].name}</div>}
                disableTypography
                secondary={
                  <>
                    <div>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {printingRequestItem.content_type_id &&
                          CONTENT_TYPES[printingRequestItem.content_type_id].name}
                      </Typography>
                    </div>
                  </>
                }
              />
            </ListItem>
          </Grid>
          <Grid>
            {!!printingRequestItem.flawed_tray_id && (
              <div className={classes.tag}>
                <StatusTag label="Fix" color="purple" />
              </div>
            )}
            {printingRequestItem.insert_brackets && (
              <div className={classes.tag}>
                <StatusTag label="Brackets" color="green" />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderPrintingRequest = printingRequest => {
    return (
      <Grid item xs={12} className={classes.column}>
        <Box p={1}>
          <Grid container>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12} className={classes.printingRequestGridItem}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.printingRequestButton}
                    disabled={!printingRequest}
                    onClick={() => history.push(`/requests/${get(request, 'id')}`)}
                  >
                    Print Request
                  </Button>
                  {printingRequest && (
                    <StatusTag
                      label={REQUEST_STATUSES[request.status_id]}
                      color={getColorByStatus(REQUEST_STATUSES[request.status_id])}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.printingRequestGridItem}>
                  {printingRequest && (
                    <ListItemText primary="Submitted Date" secondary={request.created_at} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                {printingRequest &&
                  renderPrintingRequestItem(
                    find(printingRequest.printing_request_items, { arch: { jaw_type_id: 1 } })
                  )}
                {printingRequest &&
                  renderPrintingRequestItem(
                    find(printingRequest.printing_request_items, { arch: { jaw_type_id: 2 } })
                  )}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container>
                <Grid item xs={12} className={classes.printingRequestGridItem}>
                  {printingRequest && (
                    <ListItemText
                      primary="Insert Brackets"
                      secondary={
                        get(printingRequest, 'printing_request_items', []).findIndex(
                          item => item.insert_brackets
                        ) > -1 ? (
                          <span className={clsx(classes.redColorFont, classes.bigFont)}>YES</span>
                        ) : (
                          <span className={classes.bigFont}>NO</span>
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.printingRequestGridItem} />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12} className={classes.printingRequestGridItem}>
                  {printingRequest && (
                    <ListItemText
                      primary="Shipping Type"
                      secondary={
                        <>
                          <span className={clsx(classes.redColorFont, classes.bigFont)}>
                            {`${get(printingRequest, 'shipping_method.name')}`}
                          </span>
                          <br />
                          <span className={clsx(classes.redColorFont)}>
                            {`${get(printingRequest, 'expected_arrival_date', '') || ''}`}
                          </span>
                        </>
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.printingRequestGridItem}>
                  {printingRequest && (
                    <ListItemText
                      primary="Tracking Number"
                      secondary={get(printingRequest, 'tracking_number', '-')}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <Box mx="auto" id="patientSummary" className={classes.root}>
      <Box my={2}>
        <Card>
          <CardHeader
            title={`Patient Summary: ${patient.formatted_name}`}
            action={
              <>
                {!printingPreview && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.hideOnPrint}
                    onClick={() => history.push(`/patient-details/${patient.id}/print`)}
                  >
                    Print
                  </Button>
                )}
              </>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  <ListItemText
                    primary="User Information"
                    secondaryTypographyProps={{ component: 'span' }}
                    secondary={
                      <>
                        <span>{patient.user.full_name}</span>
                        <br />
                        <span>{patient.user.organization || '-'}</span>
                        <br />
                        <span>{formatAccountNumber(patient.user.account_number) || '-'}</span>
                      </>
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  {get(request, 'printing_request') && (
                    <ListItemText
                      primary="Ship To"
                      secondaryTypographyProps={{ component: 'span' }}
                      secondary={
                        <AddressCard
                          location={
                            request.printing_request.location || patient.organization.main_location
                          }
                        />
                      }
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!patient.rx_form}
                        className={classes.initialSetupButton}
                        onClick={() => history.push(`/patient-details/${patient.id}`)}
                      >
                        Initial Setup
                      </Button>
                      {patient.rx_form && (
                        <>
                          {patient.rx_form.cancelled_at && (
                            <StatusTag label="CANCELLED" color="red" />
                          )}
                          {patient.rx_form.ready_at && !patient.rx_form.completed_at && (
                            <StatusTag label="PENDING" color="blue" />
                          )}
                          {patient.rx_form.completed_at && (
                            <StatusTag label="COMPLETED" color="green" />
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      {patient.rx_form && (
                        <ListItemText
                          primary="Intraoral Scan Date"
                          secondary={patient.rx_form.intraoral_scan_date}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {patient.rx_form && (
                        <ListItemText
                          primary="Arches"
                          secondaryTypographyProps={{ component: 'div' }}
                          secondary={
                            <span
                              className={clsx(
                                patient.rx_form.jaws_id !== 3 && classes.redColorFont,
                                classes.bigFont
                              )}
                            >
                              {RX_FORMS.jaws_id[patient.rx_form.jaws_id].name}
                            </span>
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.flawedTrayButton}
                            disabled={!request || request.flawed_trays.length === 0}
                            onClick={() => history.push(`/requests/${get(request, 'id')}`)}
                          >
                            Flawed Trays
                          </Button>
                          {get(request, 'flawed_trays', []).length > 0 && (
                            <>
                              {every(request.flawed_trays, { status_id: 2 }) ? (
                                <StatusTag label="COMPLETED" color="green" />
                              ) : (
                                <StatusTag label="PENDING" color="blue" />
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {request && request.flawed_trays.length > 0 && (
                            <ListItemText primary="Submitted Date" secondary={request.created_at} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      {renderFlawedTray(
                        find(get(request, 'flawed_trays', []), {
                          arch: { jaw_type_id: 1 }
                        })
                      )}
                      {renderFlawedTray(
                        find(get(request, 'flawed_trays', []), {
                          arch: { jaw_type_id: 2 }
                        })
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {renderPrintingRequest(get(request, 'printing_request', null))}
              {!printingPreview && (
                <BracketsSection
                  brackets={get(request, 'brackets', [])}
                  columnClass={classes.column}
                />
              )}
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Doctor Notes"
                    fullWidth
                    multiline
                    minRows={4}
                    value={notes.doctor_notes || ''}
                    variant="outlined"
                    disabled={!isTechnicianOrAdmin(currentUser) || printingPreview}
                    onChange={event => setNotes({ ...notes, doctor_notes: event.target.value })}
                  />
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      className={classes.hideOnPrint}
                      onClick={() => updateNotes('doctor_notes')}
                      loading={loading.doctor_notes}
                      disabled={
                        notes.doctor_notes === patient.doctor_notes ||
                        loading.doctor_notes ||
                        printingPreview
                      }
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.column}>
                <Box p={1}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Tech Notes"
                    fullWidth
                    multiline
                    minRows={4}
                    value={notes.management_notes || ''}
                    disabled={!isTechnicianOrAdmin(currentUser) || printingPreview}
                    variant="outlined"
                    onChange={event => setNotes({ ...notes, management_notes: event.target.value })}
                  />
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      className={classes.hideOnPrint}
                      onClick={() => updateNotes('management_notes')}
                      loading={loading.management_notes}
                      disabled={
                        notes.management_notes === patient.management_notes ||
                        loading.management_notes ||
                        printingPreview
                      }
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          {printingPreview && (
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.goBack()}
                className={clsx(classes.actionButton, classes.hideOnPrint)}
              >
                Back
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>
    </Box>
  );
};

PatientSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  request: PropTypes.object,
  printingPreview: PropTypes.bool
};

PatientSummary.defaultProps = {
  printingPreview: false,
  request: null
};

export default PatientSummary;
