import React from 'react';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

const ErrorMessage = ({ message, showHomeLink = false }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <WarningIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {message}
        </Typography>
        {showHomeLink && <Link to="/">Go to Home </Link>}
      </div>
    </Container>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  showHomeLink: PropTypes.bool.isRequired
};

export default ErrorMessage;
