import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormDialog from '../../FormDialog';

const initialValue = {
  tracking_number: '',
  custom_shipping_fee: null
};

const PrintCompletionDialog = ({ onContinue, open, ...props }) => {
  const [data, setData] = useState(initialValue);

  const onChangeHandler = ({ target }) => {
    setData({
      ...data,
      [target.name]: target.value === '' ? null : target.value
    });
  };

  const closeHandler = response => {
    if (response && data.tracking_number) {
      onContinue(data);
    }
    setData(initialValue);
  };

  return (
    <FormDialog
      titleText="Shipping Information"
      {...props}
      open={open}
      handleClose={closeHandler}
      acceptButtonText="Continue"
    >
      <TextField
        autoFocus
        id="name"
        name="tracking_number"
        label="Tracking Number"
        type="text"
        required
        onChange={onChangeHandler}
        fullWidth
      />
      <TextField
        id="name"
        name="custom_shipping_fee"
        label="Override Shipping Fee (Optional)"
        type="number"
        inputProps={{
          min: 0
        }}
        onChange={onChangeHandler}
        fullWidth
      />
    </FormDialog>
  );
};

PrintCompletionDialog.propTypes = {
  onContinue: PropTypes.func.isRequired,
  open: PropTypes.bool
};

PrintCompletionDialog.defaultProps = {
  open: false
};

export default PrintCompletionDialog;
