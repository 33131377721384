import React from 'react';
import { Stats } from 'react-instantsearch-dom';

const CustomStats = () => (
  <Stats
    translations={{
      stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
        return areHitsSorted && nbHits !== nbSortedHits
          ? `${nbSortedHits.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found`
          : `${nbHits.toLocaleString()} results found`;
      }
    }}
  />
);

export default CustomStats;
