import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FETCH_CURRENT_USER_PREFIX, fetchCurrentUserAction } from '../../reducers/auth';
import createErrorMessageSelector from '../../selectors/error';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import { MANAGEMENT_ROLES } from '../../constants';

const CheckAuthentication = ({ path, roles, children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const error = useSelector(state =>
    createErrorMessageSelector([FETCH_CURRENT_USER_PREFIX])(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && isAuthenticated) {
      setIsLoadingLocal(true);
      dispatch(fetchCurrentUserAction()).then(() => {
        setIsLoadingLocal(false);
      });
    }
  }, [user, dispatch, isAuthenticated]);

  if (!isAuthenticated) {
    const redirectToManagement = _.intersection(roles, MANAGEMENT_ROLES).length > 0;

    return redirectToManagement ? (
      <Redirect to={`/management?path=${path}`} />
    ) : (
      <Redirect to={`/login?path=${path}`} />
    );
  }

  if (isLoadingLocal) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!user) {
    return null;
  }

  return children;
};

CheckAuthentication.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired
};

CheckAuthentication.defaultProps = {
  roles: []
};

export default CheckAuthentication;
