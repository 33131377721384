import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormDialog from '../FormDialog';
import { addChargeToPatientAction } from '../../reducers/patients';

const AddChargeToPatient = ({ open, setOpen, patientId, onChargeAdded }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ value: '', description: '' });

  const handleOnCloseFormDialog = response => {
    if (response === true) {
      dispatch(addChargeToPatientAction(patientId, data)).then(response => {
        if (response) {
          onChargeAdded();
        }
      });
    }

    setData({
      value: '',
      description: ''
    });

    setOpen(false);
  };

  const handleChangeFormDialogElement = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText="Add or Discount Patient"
      acceptButtonText="Continue"
      handleClose={handleOnCloseFormDialog}
      isAcceptButtonDisabled={data.custom_description === '' || data.value === ''}
    >
      <TextField
        autoFocus
        margin="dense"
        id="description"
        name="description"
        label="Description"
        type="text"
        required
        value={data.description}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
      <TextField
        margin="dense"
        id="name"
        name="value"
        label="Value"
        type="text"
        required
        value={data.value}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
    </FormDialog>
  );
};

AddChargeToPatient.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  onChargeAdded: PropTypes.func.isRequired
};

AddChargeToPatient.defaultProps = {};

export default AddChargeToPatient;
