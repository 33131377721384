import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import List from '@material-ui/core/List';
import { JAWS_TYPES_IDS } from '../../../constants';

const PrintingRequestItemList = ({ items, ItemComponent, patientId, requestId }) => {
  const maxillary = find(items, { arch: { jaw_type_id: JAWS_TYPES_IDS.maxillary } });
  const mandibular = find(items, { arch: { jaw_type_id: JAWS_TYPES_IDS.mandibular } });

  return (
    <List>
      {maxillary && <ItemComponent patientId={patientId} item={maxillary} requestId={requestId} />}
      {mandibular && (
        <ItemComponent patientId={patientId} item={mandibular} requestId={requestId} />
      )}
    </List>
  );
};

PrintingRequestItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ItemComponent: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  requestId: PropTypes.number
};

PrintingRequestItemList.defaultProps = {
  requestId: null
};

export default PrintingRequestItemList;
