import React from 'react';
import PropTypes from 'prop-types';
import DataProvider from '../components/Organization/DataProvider';

const hook = ({ organizationId, ...props }) => {
  return <DataProvider organizationId={organizationId} {...props} />;
};

hook.propTypes = {
  organizationId: PropTypes.number
};

hook.defaultProps = {
  organizationId: null
};

export default hook;
