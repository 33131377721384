import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { JAWS_TYPES_IDS } from '../../../constants';

const PatientLabel = ({ patient }) => {
  let archLabel = '';

  switch (patient.rx_form.jaws_id) {
    case JAWS_TYPES_IDS.mandibular:
      archLabel = 'Lower';
      break;
    case JAWS_TYPES_IDS.maxillary:
      archLabel = 'Upper';
      break;
    default: {
      archLabel = 'Upper & Lower';
    }
  }
  // handle missing location (which is a bug)
  const attn = patient.customer_request.printing_request.location
    ? patient.customer_request.printing_request.location.full_name
    : patient.user.name;

  return (
    <>
      {patient.last_name}, {patient.first_name}
      <br />
      Case #: {isEmpty(patient.case_number_text) ? 'N/A' : patient.case_number_text}
      <br />
      ATTN: {attn}
      <br />
      {archLabel}
    </>
  );
};

PatientLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

export default PatientLabel;
