import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { markNotificationAsRead, removeNotificationAlertAction } from '../../reducers/auth';

const RushCaseAlert = ({ id, patientName, patientId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAlertClose = () => {
    dispatch(removeNotificationAlertAction(id));
    dispatch(markNotificationAsRead(id));
  };

  const handleLinkClick = e => {
    e.preventDefault();
    history.push(`/patient-workflow/${patientId}`);
  };

  return (
    <Alert severity="info" key={id} onClose={handleAlertClose}>
      <AlertTitle>Rush Case</AlertTitle>
      <Link href="#" onClick={handleLinkClick}>
        Patient: {patientName}
      </Link>
    </Alert>
  );
};

RushCaseAlert.propTypes = {
  id: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired
};

export default RushCaseAlert;
