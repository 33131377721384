import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';
import { setOrganizationsToGlobalFilterAction } from '../../reducers/auth';
import { UI_SETTINGS } from '../../constants';
import OrganizationsApiService from '../../services/api/organizations';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1500
  }
}));

const OrganizationsFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const isManagement = useSelector(state => state.auth.isManagement);

  const loadOptions = debounce(async value => {
    setLoading(true);
    const organizationsService = new OrganizationsApiService();
    const {
      organizations: { data }
    } = await organizationsService.get({
      searchString: value,
      customerServiceManager: null,
      orderBy: 'name'
    });
    setOptions(data);
    setLoading(false);
  }, 500);

  if (!isManagement) {
    return null;
  }

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto" my={1}>
      <Autocomplete
        open={open}
        classes={{ popper: classes.popper }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        getOptionDisabled={option => {
          return selectedOrganizations.findIndex(so => so.id === option.id) > -1;
        }}
        getOptionLabel={option => {
          return option && `${option.name} | ${option.account_number}`;
        }}
        options={options}
        value={selectedOrganizations}
        loading={loading}
        multiple
        onChange={(_, value) => {
          dispatch(setOrganizationsToGlobalFilterAction(value));
        }}
        onInputChange={(event, value, reason) => {
          if (reason === 'input') {
            loadOptions(value);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Organizations"
            variant="outlined"
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </Box>
  );
};

OrganizationsFilter.propTypes = {};

OrganizationsFilter.defaultProps = {};

export default OrganizationsFilter;
