import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import clsx from 'clsx';
import { AssignmentInd, LocalShippingOutlined as ShippingIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import Tooltip from './Tooltip';
import { PRINTING_REQUESTS_STATUSES_ID } from '../../../constants';
import ButtonLink from '../../../components/ButtonLink';

const useStyles = makeStyles(theme => {
  return {
    shipping: {
      fontSize: theme.typography.pxToRem(12),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    redText: {
      color: 'darkred'
    },
    greenText: {
      color: 'green'
    },
    boldText: {
      fontWeight: 'bold'
    }
  };
});

const ShippingColumn = ({ printingRequest, collapsed, onClickTrackingNumber }) => {
  const classes = useStyles();

  if (!printingRequest) {
    return '';
  }

  const shippingMethod = !!printingRequest.shipping_method && printingRequest.shipping_method.name;
  const shipDate = printingRequest.ship_date;
  const shipDateMoment = moment(shipDate).endOf('day');
  const isPrintCompleted =
    printingRequest.printing_request_status_id === PRINTING_REQUESTS_STATUSES_ID.completed;
  let shipDateClassName = null;

  if (!isPrintCompleted) {
    if (shipDateMoment.isBefore(moment.now())) {
      shipDateClassName = clsx([classes.redText, classes.boldText]);
    } else if (shipDateMoment.isSame(moment.now(), 'date')) {
      shipDateClassName = clsx([classes.greenText, classes.boldText]);
    }
  }

  return (
    <div className={classes.shipping}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {shipDate && (
          <Tooltip disabled={collapsed} title={shippingMethod}>
            <label className={shipDateClassName}>{shipDate}</label>
          </Tooltip>
        )}

        {printingRequest.patient_label_printed && (
          <Tooltip disabled={false} title="Patient Label Printed">
            <AssignmentInd fontSize="small" color="primary" />
          </Tooltip>
        )}
        {printingRequest.shipping_label_printed && (
          <Tooltip disabled={false} title="Shipping Label Printed">
            <ShippingIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </div>
      {collapsed && (
        <>
          <label>{printingRequest.expected_arrival_date}</label>
          <label>{shippingMethod}</label>
          {!isEmpty(printingRequest.tracking_number) && (
            <ButtonLink onClick={() => onClickTrackingNumber(printingRequest.tracking_number)}>
              {printingRequest.tracking_number}
            </ButtonLink>
          )}
        </>
      )}
    </div>
  );
};

ShippingColumn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  printingRequest: PropTypes.object,
  collapsed: PropTypes.bool.isRequired,
  onClickTrackingNumber: PropTypes.func.isRequired
};

ShippingColumn.defaultProps = {
  printingRequest: null
};

export default ShippingColumn;
