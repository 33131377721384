import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from 'formik-material-ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CREATE_ORGANIZATION_USER_PREFIX, createOrganizationUserAction } from '../../reducers/auth';
import createLoadingSelector from '../../selectors/loading';
import LoadingButton from '../../components/LoadingButton';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(5)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const CreateUser = ({ location = {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const urlQueryParams = new URLSearchParams(location.search);
  const verificationCode = urlQueryParams.get('token');
  const isLoading = useSelector(state =>
    createLoadingSelector([CREATE_ORGANIZATION_USER_PREFIX])(state)
  );
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: ''
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        password: Yup.string()
          .required('Required')
          .max(32, 'Must contain max 32 characters.')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            'Must contain at least 8 characters, one uppercase, one lowercase and one number'
          ),
        password_confirmation: Yup.string()
          .required('Required')
          .when('password', {
            is: val => !!(val && val.length > 0),
            then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
          })
      })}
      onSubmit={async values => {
        const result = await executeRecaptcha('createUserPage');
        if (result) {
          return dispatch(
            createOrganizationUserAction({
              ...values,
              token: verificationCode
            })
          ).then(response => {
            if (response) {
              history.push('/login');
            }
          });
        }

        return false;
      }}
    >
      {({ isValid, dirty }) => (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    autoComplete="name"
                    name="first_name"
                    variant="outlined"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    name="password_confirmation"
                    label="Confirm Password"
                    type="password"
                    id="password_confirmation"
                    component={TextField}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
                disabled={!dirty || !isValid || isLoading}
                className={classes.submit}
              >
                Sign Up
              </LoadingButton>
            </Form>
          </div>
        </Container>
      )}
    </Formik>
  );
};

CreateUser.propTypes = {
  location: PropTypes.shape({})
};

CreateUser.defaultProps = {
  location: {}
};

export default CreateUser;
