import React from 'react';
import { CardContent, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    backgroundColor: theme.palette.grey[200]
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    },
    padding: theme.spacing(1)
  },
  text: {
    fontSize: 14,
    lineHeight: 1
  }
}));

const AddressCard = ({ location, showLocationName, withCard }) => {
  const classes = useStyles();

  const renderTextLine = text => {
    if (!text) {
      return null;
    }

    return (
      <Typography className={classes.text} color="textSecondary" gutterBottom>
        {text}
      </Typography>
    );
  };

  const content = (
    <>
      {showLocationName && renderTextLine(location.location_name)}
      {renderTextLine(location.organization)}
      {renderTextLine(`ATTN: Dr. ${location.full_name}`)}
      {renderTextLine(location.street_address)}
      {renderTextLine(location.street_address_line_2)}
      {renderTextLine(
        `${location.city} ${location.state} ${location.zip_code}, ${location.country}`
      )}
    </>
  );

  if (!withCard) {
    return content;
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>{content}</CardContent>
    </Card>
  );
};

AddressCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  showLocationName: PropTypes.bool
};

AddressCard.defaultProps = {
  showLocationName: true,
  withCard: true
};

export default AddressCard;
