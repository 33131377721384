import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { CREATE_USER_PREFIX, createUserAction } from '../../reducers/users';
import createLoadingSelector from '../../selectors/loading';
import { UI_SETTINGS } from '../../constants';
import UserForm from '../../components/UserForm';

const AddUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCreating = useSelector(state => createLoadingSelector([CREATE_USER_PREFIX])(state));

  const create = values => {
    return dispatch(createUserAction(values)).then(response => {
      if (response) {
        history.push('/organizations-users');
      }
    });
  };

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader title="Invite User" />
          <Divider />
          <UserForm onSubmit={create} isSaving={isCreating} saveButtonLabel="Invite" />
        </Card>
      </Box>
    </Box>
  );
};

AddUser.propTypes = {};

AddUser.defaultProps = {};

export default AddUser;
