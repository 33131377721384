import React from 'react';
import PropTypes from 'prop-types';
import HitCard from './HitCard';

const OrganizationHit = ({ hit }) => {
  return (
    <div key={hit.model_id}>
      <HitCard hit={hit} />
    </div>
  );
};

OrganizationHit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

OrganizationHit.defaultProps = {};

export default OrganizationHit;
