import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { RotateLeft } from '@material-ui/icons';
import {
  SEND_RESET_PASSWORD_EMAIL_PREFIX,
  sendResetPasswordEmailAction
} from '../../reducers/auth';
import LoadingButton from '../../components/LoadingButton';
import createLoadingSelector from '../../selectors/loading';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(state =>
    createLoadingSelector([SEND_RESET_PASSWORD_EMAIL_PREFIX])(state)
  );

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Required')
      })}
      onSubmit={values => {
        dispatch(sendResetPasswordEmailAction(values));
        return true;
      }}
    >
      {({ dirty, isValid }) => (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <RotateLeft />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Your Password?
            </Typography>
            <Typography variant="subtitle2">
              Enter your email and we will send you a reset request.
            </Typography>
            <Form className={classes.form} noValidate>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                component={TextField}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
                className={classes.submit}
                disabled={!dirty || !isValid || isLoading}
              >
                Send Request
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  {'Back to '}
                  <Link component={RouterLink} to="/login" variant="body2">
                    Login Page
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </div>
        </Container>
      )}
    </Formik>
  );
};

export default ForgotPassword;
