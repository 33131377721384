/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, each } from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ArchiveOutlined from '@material-ui/icons/ArchiveOutlined';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import DataTable from '../../components/DataTable';
import createLoadingSelector from '../../selectors/loading';
import SearchInput from '../../components/SearchInput';
import PatientsFilter from '../../components/PatientsFilter';

import {
  archiveCaseTray,
  FETCH_PATIENTS_PREFIX,
  fetchPatientsAction,
  generateShippingId,
  initialState,
  setInitialParameters,
  storePatientsTray
} from '../../reducers/patients';
import { findRolesInUser } from '../../helpers';
import Content from '../../components/Layout/Content';
import { ROLES } from '../../constants';
import extractUrlQueryParameters from '../PatientsPage/helpers/extractUrlQueryParameters';
import PatientColumn from '../PatientsPage/columns/PatientColumn';
import DateColumn from '../PatientsPage/columns/DateColumn';
import checkLikOrButton from '../PatientsPage/helpers/checkLikOrButton';
import withIdleDetector from '../../hooks/withIdleDetector';
import { COMMON_PATIENT_INCLUDES } from '../../constants/queries';
import { autoEnableShipmentBundling } from '../PatientsPage/components/ShipmentBundling/functions';
import useStyles from './useStyles';
import CreateTrayDialog from './CreateTrayDialog';
import { enqueueNotification } from '../../reducers/notifications';
import NotifyArchiveTrayDialog from './NotifyArchiveTrayDialog';

const REFRESH_INTERVAL = 60 * 2; // seconds
const MAX_CASES_IN_TRAY = 8;
const rowsPerPageOptions = [10, 25, 50, 100];
const defaultRowsPerPage = 50;

const CaseTraysPage = ({ idle, location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalParams = useSelector(state => state.patients.globalParams);
  const customGlobalParamsInitialized = useSelector(
    state => state.patients.customGlobalParams !== null
  );
  const is3PP = useSelector(state => findRolesInUser([ROLES.labPartner], state.auth.user));
  const isLoading = useSelector(state => createLoadingSelector([FETCH_PATIENTS_PREFIX])(state));
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);
  const urlQueryParams = new URLSearchParams(location.search);
  const paramsFromUrl = extractUrlQueryParameters(location.search);
  const refreshTimes = useRef(0);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const initialParamsFor3PP = {
    requestAssignments: []
  };

  const [additionalParams, setAdditionalParams] = useState({
    params: {
      ...globalParams,
      ...paramsFromUrl
    },
    resetPage: false
  });

  const [collapsed, setCollapsed] = useState([]);
  const searchRefs = {
    patient: useRef(null),
    organization: useRef(null),
    orgType: useRef(null)
  };

  const [selectedCases, setSelectedCases] = useState({});
  const [enableTrayBtn, setEnableTrayBtn] = useState(false);
  const [archiveableTray, setArchiveableTray] = useState(false);
  const [showCreateTrayDialog, setShowCreateDialog] = useState(false);

  const items = useSelector(state => state.patients.items);
  const total = useSelector(state => state.patients.total);

  const tabs = ['Cases', 'Trays'];
  const [activeTab, setActiveTab] = useState(parseInt(urlQueryParams.get('idx') || 0, 10));

  const removeQueryParam = useCallback(
    (...paramsToRemove) => {
      let paramsRemoved = false;

      each(paramsToRemove, param => {
        if (urlQueryParams.has(param)) {
          urlQueryParams.delete(param);
          paramsRemoved = true;
        }
      });

      if (paramsRemoved) {
        history.replace({
          search: urlQueryParams.toString()
        });
      }
    },
    [urlQueryParams, history]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedParams = extractUrlQueryParameters(queryParams);
    if (Object.keys(updatedParams).length) {
      setAdditionalParams({
        params: {
          ...initialState.globalParams,
          ...initialState.customGlobalParams,
          ...updatedParams
        },
        resetPage: true
      });
    }
  }, [urlQueryParams, location]);

  const getSortValue = name => {
    return additionalParams.params.orderBy === name ? additionalParams.params.order : null;
  };

  const handleSort = ({ property, direction }) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        if (!direction) {
          draft.params.order = initialState.globalParams.order;
          draft.params.orderBy = initialState.globalParams.orderBy;
        } else {
          draft.params.order = direction;
          draft.params.orderBy = property;
        }
        draft.resetPage = true;
      })
    );
  };

  const createSortHandler = column => (event, value) => {
    handleSort({ property: column, direction: value });
  };

  const handleUpdateData = useCallback(
    parameters => {
      if (!customGlobalParamsInitialized) {
        return;
      }

      setCollapsed([]);

      if (!is3PP) {
        autoEnableShipmentBundling(parameters);
      }

      const includes = COMMON_PATIENT_INCLUDES;

      dispatch(
        fetchPatientsAction(
          {
            ...parameters,
            includes,
            organizations: selectedOrganizations.map(org => org.id)
          },
          {
            resource_type: activeTab === 0 ? 'patients' : 'trays',
            null_tray_only: activeTab === 0
          }
        )
      );
    },
    [dispatch, selectedOrganizations, is3PP, customGlobalParamsInitialized, activeTab]
  );

  // eslint-disable-next-line no-shadow
  const handleReset = (params = {}) => {
    removeQueryParam(...Array.from(urlQueryParams.keys()));
    Object.keys(searchRefs).forEach(key => {
      searchRefs[key].current.value = '';
    });
    setAdditionalParams({
      params: {
        ...initialState.globalParams,
        ...initialState.customGlobalParams,
        ...params
      },
      resetPage: true
    });
  };

  const handleFilterChange = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = {
          ...additionalParams.params,
          [additionalParameterKey]: value
        };
        draft.resetPage = true;
      })
    );
  }, 250);

  const createToggleHandler = ({ only = false, nullable = false } = {}) => event => {
    let value;
    if (only) {
      value = event.target.checked ? 'only' : 'without';
    } else {
      value = event.target.checked;
    }
    if (nullable && !value) {
      value = null;
    }
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[event.target.name] = value;
        draft.resetPage = true;
      })
    );
  };

  const refresh = useCallback(() => {
    refreshTimes.current += 1;
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = { ...additionalParams.params };
      })
    );
  }, [setAdditionalParams, additionalParams]);

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  const handleCreateTray = (trayNumber, callbacks) => {
    const data = {
      tray_number: trayNumber,
      case_ids: Object.keys(selectedCases)
    };

    storePatientsTray(data)
      .then(() => {
        callbacks.handleClose();
        setSelectedCases({});
        refresh();
      })
      .catch(error => {
        callbacks.setErrorMsg(error.message);
        callbacks.setLoading(false);
        dispatch(enqueueNotification('error', error.message));
      });
  };

  useEffect(() => {
    if (!idle || !autoRefresh) {
      refreshTimes.current = 0;
      return () => {};
    }
    if (refreshTimes.current === 0) {
      refresh();
      return () => {};
    }

    const timeout = setTimeout(refresh, REFRESH_INTERVAL * 1000);

    return () => clearTimeout(timeout);
  }, [idle, refresh, autoRefresh]);

  // initialize custom parameters
  useEffect(() => {
    if (customGlobalParamsInitialized) {
      return;
    }
    const initialParams = is3PP ? initialParamsFor3PP : {};
    dispatch(setInitialParameters(initialParams));
    setAdditionalParams({
      params: {
        ...globalParams,
        ...initialParams,
        ...paramsFromUrl
      },
      resetPage: false
    });
  }, [
    is3PP,
    globalParams,
    paramsFromUrl,
    initialParamsFor3PP,
    dispatch,
    customGlobalParamsInitialized
  ]);

  useEffect(() => {
    const count = Object.keys(selectedCases).length;
    setEnableTrayBtn(() => activeTab === 0 && count >= 1 && count <= MAX_CASES_IN_TRAY);
  }, [selectedCases, activeTab]);

  useEffect(() => {
    const idx = parseInt(urlQueryParams.get('idx') || 0, 10);

    if (idx === activeTab) {
      return;
    }

    urlQueryParams.set('idx', activeTab);

    history.replace({
      search: urlQueryParams.toString()
    });
  }, [activeTab, history, urlQueryParams]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderFilters = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={6} md={4}>
                <SearchInput
                  placeholder={`Search by ${activeTab === 0 ? 'Patient' : 'Tray No.'}`}
                  inputRef={searchRefs.patient}
                  ignore={[',']}
                  value={additionalParams.params.searchFullNameQuery}
                  onChange={e => handleFilterChange(e.target.value, 'searchFullNameQuery')}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SearchInput
                  disabled={
                    additionalParams.params.userId !== null ||
                    additionalParams.params.organizationId !== null
                  }
                  inputRef={searchRefs.organization}
                  placeholder="Search by Doctor or Organization"
                  value={additionalParams.params.searchDoctorFullNameOrOrganizationQuery}
                  ignore={[',']}
                  delay={250}
                  onChange={e =>
                    handleFilterChange(e.target.value, 'searchDoctorFullNameOrOrganizationQuery')
                  }
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SearchInput
                  inputRef={searchRefs.orgType}
                  placeholder="Search by Organization Type"
                  value={globalParams.customerServiceManager}
                  ignore={[',']}
                  onChange={e => handleFilterChange(e.target.value, 'customerServiceManager')}
                />
              </Grid>

              <Grid item xs={12}>
                <PatientsFilter
                  handleChange={handleFilterChange}
                  filterValues={globalParams}
                  initialValues={additionalParams.params}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end" className={classes.switch}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={additionalParams.params.onlyOnhold === 'only'}
                      onChange={createToggleHandler({ only: true })}
                      name="onlyOnhold"
                    />
                  }
                  label="Only On-hold"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!additionalParams.params.activeRequestOnly}
                      onChange={createToggleHandler({ nullable: true })}
                      name="activeRequestOnly"
                    />
                  }
                  label="Active Only"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!additionalParams.params.rushCaseOnly}
                      onChange={createToggleHandler()}
                      name="rushCaseOnly"
                    />
                  }
                  label="Rush Case Only"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!additionalParams.params.shipping}
                      onChange={createToggleHandler({ nullable: true })}
                      name="shipping"
                    />
                  }
                  label="Shipping"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!additionalParams.params.archivedOnly}
                      onChange={createToggleHandler()}
                      name="archivedOnly"
                    />
                  }
                  label="Archived Only"
                />
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  value={getSortValue('created_at')}
                  exclusive
                  onChange={createSortHandler('created_at')}
                >
                  <ToggleButton value="desc">Newest</ToggleButton>
                  <ToggleButton value="asc">Oldest</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} className={classes.spaceBetween}>
                <Button
                  color="primary"
                  title="Create Tray"
                  onClick={() => setShowCreateDialog(true)}
                  disabled={!enableTrayBtn}
                  variant="contained"
                >
                  Create Tray
                </Button>

                <div className={classes.miscButtons}>
                  <FormControlLabel
                    checked={autoRefresh}
                    onChange={toggleAutoRefresh}
                    control={<Switch name="auto-refresh" color="primary" />}
                    label="Auto-Refresh"
                  />
                  <Button color="primary" title="Refresh" onClick={refresh} variant="contained">
                    Refresh
                  </Button>
                  <Button
                    color="secondary"
                    title="Reset Filters"
                    onClick={handleReset}
                    variant="contained"
                  >
                    Reset Filters
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderTabs = () => {
    return (
      <div className={classes.tabContainer}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((name, index) => (
            <Tab label={name} value={index} key={name} />
          ))}
        </Tabs>
      </div>
    );
  };

  const isCollapsed = patientId => {
    return collapsed.find(item => patientId === item) !== undefined;
  };

  /**
   * Toggle collapse for a given patient row
   *
   * @param {Number} patientId
   */
  const toggleCollapse = patientId => {
    if (activeTab === 1) return;

    if (isCollapsed(patientId)) {
      setCollapsed(collapsed.filter(item => item !== patientId));
    } else {
      setCollapsed([...collapsed, patientId]);
    }
  };

  /**
   * Archives a given tray
   *
   * @param {String} tray
   */
  const handleTrayArchive = tray => {
    archiveCaseTray(tray)
      .then(() => {
        refresh();
      })
      .catch(() => null);
  };

  const handleGenerateShippingId = tray => {
    generateShippingId(tray)
      .then(() => {
        refresh();
      })
      .catch(() => null);
  };

  const casesColumns = [
    {
      id: 'patient',
      label: 'Cases with no Tray Number',
      customSortId: 'last_name',
      formatMethod: (value, row) => {
        return (
          <PatientColumn
            collapsed={isCollapsed(row.id)}
            patient={row}
            doctor={row.user}
            organization={row.organization}
            selectable={{
              checked: selectedCases[row.id],
              toggle: () => {
                const patientId = row.id;
                setSelectedCases(e => {
                  const curr = e;
                  if (curr[patientId]) {
                    delete curr[patientId];
                  } else {
                    curr[patientId] = true;
                  }

                  return { ...curr };
                });
              }
            }}
          />
        );
      }
    },
    {
      id: 'date',
      label: 'Date',
      customSortId: 'created_at',
      formatMethod: (value, row) => {
        return (
          <DateColumn
            started={row.created_at}
            modified={row.updated_at}
            completed={row.setup_completed_at}
            collapsed={isCollapsed(row.id)}
          />
        );
      }
    }
  ];

  const traysColumns = [
    {
      id: 'tray_number',
      label: 'Tray',
      customSortId: 'tray_number',
      formatMethod: (value, row) => {
        return (
          <div>
            <Link to={`/case-trays/${row.id}`}>{row.tray_number}</Link>
          </div>
        );
      }
    }
  ];

  const trayActionColumns = [
    {
      name: 'archive',
      handleOnAction: trayId => {
        const tray = items.find(item => item.id === trayId);
        setArchiveableTray(tray);
      },
      title: 'Archive Tray',
      isIconButton: true,
      className: classes.compactButton,
      icon: <ArchiveOutlined />
    },

    {
      name: 'generate_shipping_id',
      handleOnAction: handleGenerateShippingId,
      title: 'Generate Tray ID',
      isIconButton: true,
      className: classes.compactButton,
      icon: <LocalShippingOutlined />,
      replaceWith: items
        .filter(item => item.shipping_id_text)
        .reduce((acc, item) => {
          acc[item.id] = (
            <Tooltip title="Tray ID">
              <Typography display="inline" variant="body2" color="textSecondary">
                {item.shipping_id_text}
              </Typography>
            </Tooltip>
          );
          return acc;
        }, {})
    }
  ];

  const rowsPropsProvider = row => {
    const props = {};
    if (isCollapsed(row.id)) {
      props.className = classes.collapsedRow;
    }

    props.onMouseUp = e => {
      if (e.target.type === 'checkbox') return;

      if (!checkLikOrButton(e.target)) {
        toggleCollapse(row.id);
      }
    };
    return props;
  };

  const renderCreateTrayDialog = () => {
    return (
      <CreateTrayDialog
        onSubmit={handleCreateTray}
        open={showCreateTrayDialog}
        setOpen={setShowCreateDialog}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>DSM | Trays of 8</title>
      </Helmet>

      {renderTabs()}

      <Content filters={renderFilters()} className={classes.compact}>
        <div className={classes.fullWidth}>
          {activeTab === 0 ? (
            <>
              <DataTable
                isLoading={isLoading}
                columns={casesColumns}
                rows={items}
                total={total}
                updateData={handleUpdateData}
                defaultOrderBy={additionalParams.params.orderBy}
                additionalParams={additionalParams}
                showVerticalLines
                rowsPerPageOptions={rowsPerPageOptions}
                defaultRowsPerPage={defaultRowsPerPage}
                globalParams={globalParams}
                onSortChange={handleSort}
                rowPropsProvider={rowsPropsProvider}
                showEmptyRows
              />
            </>
          ) : (
            <>
              <DataTable
                isLoading={isLoading}
                columns={traysColumns}
                rows={items}
                total={total}
                updateData={handleUpdateData}
                defaultOrderBy={additionalParams.params.orderBy}
                additionalParams={additionalParams}
                showVerticalLines
                rowsPerPageOptions={rowsPerPageOptions}
                defaultRowsPerPage={defaultRowsPerPage}
                customActions={trayActionColumns}
                globalParams={globalParams}
                onSortChange={handleSort}
                rowPropsProvider={rowsPropsProvider}
                showEmptyRows
              />

              <NotifyArchiveTrayDialog
                open={!!archiveableTray}
                tray={archiveableTray}
                onAccept={handleTrayArchive}
                setOpen={setArchiveableTray}
              />
            </>
          )}
        </div>
      </Content>

      {renderCreateTrayDialog()}
    </>
  );
};

CaseTraysPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  idle: PropTypes.bool.isRequired
};

export default withIdleDetector(CaseTraysPage, REFRESH_INTERVAL);
