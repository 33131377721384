import React from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  labelGrid: {
    marginTop: theme.spacing(1.5)
  }
}));

const FieldWrapper = ({ label, children, columns, labelWidth, ...props }) => {
  const classes = useStyles();
  const config = {
    wrapperWidth: {
      md: 12 / (columns.md || columns.sm),
      sm: 12 / columns.sm
    },
    labelWidth: {
      xs: 12,
      sm: 6,
      md: 7,
      ...labelWidth
    },
    childrenWidth: {}
  };

  const calculateColumnSize = firstColumn => {
    return firstColumn === 12 ? 12 : 12 - firstColumn;
  };

  config.childrenWidth = {
    xs: 12,
    sm: calculateColumnSize(config.labelWidth.sm),
    md: calculateColumnSize(config.labelWidth.md)
  };

  return (
    <Grid item xs={12} {...config.wrapperWidth} {...props}>
      <Grid container>
        <Grid item {...config.labelWidth} className={classes.labelGrid}>
          <FormLabel style={{textTransform: 'capitalize'}}>{label}</FormLabel>
        </Grid>
        <Grid item {...config.childrenWidth}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

FieldWrapper.defaultProps = {
  columns: { sm: 1, md: 2 },
  labelWidth: {}
};

FieldWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  labelWidth: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default FieldWrapper;
