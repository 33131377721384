import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Select, TextField } from 'formik-material-ui';
import CardContent from '@material-ui/core/CardContent';
import { Box, Card, CardActions, Divider } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { replaceNullToEmpty } from '../../helpers';
import InputErrorMessage from '../InputErrorMessage';
import DatePicker from '../DatePicker';
import UploadRxFormFile from '../../pages/RxForms/components/UploadRxFormFile';
import {
  ASSIGNMENTS,
  ATTRIBUTES,
  IMPRESSION_TYPE_METHODS,
  IMPRESSION_TYPE_VALUES,
  PRODUCTS_LIST_VALUES,
  PRODUCTS_LIST_METHODS,
  QUESTIONS,
  SCANNERS,
  VIEW_LABEL,
  BOXES_OPTIONS,
  IMPRESSION_BAGGIE_OPTIONS
} from './constants';
import { decodeFormikValues, encodeFormikValues } from './helpers';
import AssignmentField from './Fields/AssignmentField';
import RadioGroupField from './Fields/RadioGroupField';
import ImpressionTypeField from './Fields/ImpressionTypeField';
import ScanSubmissionMethodField from './Fields/ScanSubmissionMethodField';
import InsertBracketsAssignmentField from './Fields/InsertBracketsAssignmentField';
import LocationField from './Fields/LocationField';
import ShippingMethodField from './Fields/ShippingMethodField';
import RootIntegrationField from './Fields/RootIntegrationField';
import ScannerField from './Fields/ScannerField';
import ProductsListField from './Fields/ProductsListField';
import ExportTypeField from './Fields/ExportTypeField';
import RushCaseField from './Fields/RushCaseField';
import BracketLibrariesField from './Fields/BracketLibrariesField';
import DoctorSelectionField from './Fields/DoctorSelectionField';
import BondingDateField from './Fields/BondingDateField';
import OrganizationsApiService from '../../services/api/organizations';
import AddonOptionsField from './Fields/AddonOptionsField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ArchModeField from './Fields/ArchModeField';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 0
  },
  hidden: {
    display: 'none'
  }
}));

const Context = createContext({ only: [] });

const GridField = ({ show, name, children, ...props }) => {
  const classes = useStyles();
  const { only } = useContext(Context);

  if (only.length > 0 && only.indexOf(name) === -1) {
    return null;
  }

  let overrideProps = { ...props };
  if (only.length > 0) {
    overrideProps = { ...overrideProps, xs: 12, md: 12, lg: 12 };
  }
  return (
    <Grid item {...overrideProps} className={!show ? classes.hidden : ''}>
      {children}
    </Grid>
  );
};

GridField.defaultProps = {
  show: true
};

GridField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  show: PropTypes.bool
};

const RxForm = ({
  organization,
  title,
  description,
  preference,
  thirdPartyPartnerName,
  onSubmit,
  isLoading,
  rxForm,
  availableLocations,
  only,
  actions,
  doctors,
  pricing,
  ...props
}) => {
  const classes = useStyles();

  const [bracketLibraries, setBracketLibraries] = useState([]);
  const [doctorId, setDoctorId] = useState(null);

  const currentUser = useSelector(state => state.auth.user);

  const refreshBrackets = useCallback(
    userId => {
      const organizationApiService = new OrganizationsApiService();
      organizationApiService.getBracketLibrariesForUser(organization.id, userId).then(response => {
        setBracketLibraries(response.data);
      });
    },
    [organization.id]
  );

  useEffect(() => {
    if (!Array.isArray(doctors) && currentUser) {
      refreshBrackets(currentUser.id);
    }
  }, [doctors, currentUser, refreshBrackets]);

  useEffect(() => {
    if (Array.isArray(doctors) && doctorId) {
      refreshBrackets(doctorId);
    }
  }, [doctors, doctorId, refreshBrackets]);

  const hasBracketLibraries = Array.isArray(bracketLibraries) && bracketLibraries.length > 0;
  const withDoctorSelection = Array.isArray(doctors) && doctors.length > 1;

  const encodedFormikValues = encodeFormikValues(rxForm);
  const initialValues = replaceNullToEmpty(encodedFormikValues, ['bonding_date']);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={data => {
        const decodedValues = decodeFormikValues(data);
        return onSubmit(decodedValues);
      }}
    >
      {({
        submitForm,
        isValid,
        status,
        dirty,
        values,
        setFieldValue,
        isSubmitting,
        setFieldTouched
      }) => {
        if (values.user_id !== doctorId) {
          setDoctorId(values.user_id);
        }
        const decodedValues = decodeFormikValues(values);
        const assignment = decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT];
        const enableShippingMethods = assignment !== ASSIGNMENTS.IN_OFFICE;
        return (
          <Card {...props}>
            <CardHeader title={title} subheader={description} />
            <CardContent>
              <Context.Provider value={{ only }}>
                <Form className={classes.form} noValidate>
                  <Grid container spacing={4}>
                    <GridField xs={12} sm={6} name={ATTRIBUTES.FIRST_NAME}>
                      <Field
                        variant="outlined"
                        fullWidth
                        required
                        id="first_name"
                        label="Patient First Name"
                        name="first_name"
                        component={TextField}
                      />
                    </GridField>
                    <GridField name={ATTRIBUTES.LAST_NAME} xs={12} sm={6}>
                      <Field
                        name="last_name"
                        variant="outlined"
                        required
                        fullWidth
                        id="last_name"
                        label="Patient Last Name"
                        component={TextField}
                      />
                    </GridField>
                    <GridField name={ATTRIBUTES.CASE_NUMBER_TEXT} xs={12} sm={6}>
                      <Field
                        name="case_number_text"
                        variant="outlined"
                        fullWidth
                        id="case_number_text"
                        label="Patient Case Number"
                        component={TextField}
                      />
                    </GridField>
                    <GridField name={ATTRIBUTES.IMPRESSION_DATE} xs={12} sm={6}>
                      <DatePicker label="Impression Date" name="impression_date" required />
                    </GridField>
                    {withDoctorSelection && (
                      <GridField name={ATTRIBUTES.DOCTOR} xs={12} md={6}>
                        <DoctorSelectionField
                          name={ATTRIBUTES.DOCTOR}
                          doctors={doctors}
                          preferenceValue={preference[ATTRIBUTES.DOCTOR]}
                          isSubmitting={isSubmitting}
                        />
                      </GridField>
                    )}
                    {false && (
                      <>
                        <GridField
                          name={ATTRIBUTES.TREATMENT_LOCATION}
                          xs={12}
                          sm={withDoctorSelection ? 6 : 12}
                        >
                          <LocationField
                            dependency
                            name={ATTRIBUTES.TREATMENT_LOCATION}
                            locations={availableLocations}
                            isSubmitting={isSubmitting}
                          />
                        </GridField>
                      </>
                    )}
                    {false && (
                      <>
                        <GridField name={ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT} md={12}>
                          <>
                            <AssignmentField
                              name={ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT}
                              options={preference.initial_case_setup_assignment_options}
                              thirdPartyPartnerName={thirdPartyPartnerName}
                              isSubmitting={isSubmitting}
                            />
                            <Divider />
                          </>
                        </GridField>
                      </>
                    )}
                    <GridField name={ATTRIBUTES.IMPRESSION_TYPE_METHOD} xs={12}>
                      <>
                        <FormControl component="fieldset">
                          <ImpressionTypeField
                            preferenceValue={preference.impression_type_options}
                            isSubmitting={isSubmitting}
                            required
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>

                    <GridField
                      name={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
                      xs={12}
                      show={
                        decodedValues.scan_submission_type === ASSIGNMENTS.ORTHOSELECT &&
                        (decodedValues[ATTRIBUTES.IMPRESSION_TYPE_METHOD] &
                          IMPRESSION_TYPE_VALUES[IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]) >
                          0
                      }
                    >
                      <>
                        <FormControl component="fieldset">
                          <ScanSubmissionMethodField
                            preferenceValue={preference.scan_submission_options}
                            thirdPartyPartnerName={thirdPartyPartnerName}
                            isSubmitting={isSubmitting}
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>
                    <GridField
                      name={ATTRIBUTES.SCAN_SUBMISSION_METHOD}
                      xs={12}
                      show={
                        decodedValues.scan_submission_type === ASSIGNMENTS.ORTHOSELECT &&
                        (decodedValues[ATTRIBUTES.IMPRESSION_TYPE_METHOD] &
                          IMPRESSION_TYPE_VALUES[IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]) >
                          0
                      }
                    >
                      <>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend">Upload intraoral STL files *</FormLabel>
                          <UploadRxFormFile
                            accept="application/sla, model/stl, model/x.stl-ascii, model/x.stl-binary, .stl"
                            dependency={
                              decodedValues.scan_submission_type === ASSIGNMENTS.ORTHOSELECT
                            }
                            name={ATTRIBUTES.STL_FILES}
                            preferenceValue={[]}
                            title="Upload STL Files"
                            onChange={files => {
                              setTimeout(() => setFieldValue('stl_files', files));
                              setFieldTouched('stl_files', true, false);
                            }}
                            values={values.stl_files}
                            label="Add Files"
                            maxFiles={10}
                            organization={organization}
                          />
                          <ErrorMessage
                            name="stl_files"
                            render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>
                    <GridField
                      name={ATTRIBUTES.SCANNER}
                      xs={12}
                      show={
                        decodedValues[ATTRIBUTES.SCAN_SUBMISSION_METHOD] === 2 &&
                        (decodedValues[ATTRIBUTES.IMPRESSION_TYPE_METHOD] &
                          IMPRESSION_TYPE_VALUES[IMPRESSION_TYPE_METHODS.INTRA_ORAL_SCANS]) >
                          0
                      }
                    >
                      <>
                        <ScannerField
                          name={ATTRIBUTES.SCANNER}
                          dependency={decodedValues[ATTRIBUTES.SCAN_SUBMISSION_METHOD] === 2}
                          preferenceValue={SCANNERS.ITERO}
                          isSubmitting={isSubmitting}
                        />
                        <Divider />
                      </>
                    </GridField>

                    <GridField name={ATTRIBUTES.INTRAORAL_PHOTOS} xs={12}>
                      <>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend">Upload intraoral photos here</FormLabel>
                          <UploadRxFormFile
                            accept={'image/*'}
                            name={ATTRIBUTES.INTRAORAL_PHOTOS}
                            title="Upload Intraoral Photos"
                            onChange={files => {
                              setTimeout(() => setFieldValue('rx_intraoral_photos', files));
                              setFieldTouched('rx_intraoral_photos', true, false);
                            }}
                            values={values.rx_intraoral_photos}
                            label="Add Files"
                            maxFiles={10}
                            organization={organization}
                            preferenceValue={[]}
                          />
                          <ErrorMessage
                            name="rx_intraoral_photos"
                            render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>
                    {false && (
                      <GridField name={ATTRIBUTES.IOS_FILES} xs={12}>
                        <>
                          <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Upload IOS Files here</FormLabel>
                            <UploadRxFormFile
                              accept="application/sla, model/ios, .ios"
                              name={ATTRIBUTES.IOS_FILES}
                              title="Upload IOS Files"
                              onChange={files => {
                                setTimeout(() => setFieldValue('ios_files', files));
                                setFieldTouched('ios_files', true, false);
                              }}
                              values={values.ios_files}
                              label="Add Files"
                              maxFiles={10}
                              organization={organization}
                              preferenceValue={[]}
                            />
                            <ErrorMessage
                              name="ios_files"
                              render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                            />
                          </FormControl>
                          <Divider />
                        </>
                      </GridField>
                    )}

                    <GridField name={ATTRIBUTES.PRODUCTS_LIST_METHOD} xs={12}>
                      <>
                        <FormControl component="fieldset">
                          <ProductsListField
                            preferenceValue={preference.product_list_options}
                            isSubmitting={isSubmitting}
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>

                    <GridField
                      name={ATTRIBUTES.ARCH_TYPE}
                      xs={12}
                      show={(decodedValues[ATTRIBUTES.PRODUCTS_LIST_METHOD] & 64) > 0}
                    >
                      <>
                        <RadioGroupField isSubmitting={isSubmitting} name={ATTRIBUTES.ARCH_TYPE} />
                        <Divider />
                      </>
                    </GridField>

                    <GridField
                      name={ATTRIBUTES.ARCH_MODE}
                      xs={12}
                      show={(decodedValues[ATTRIBUTES.PRODUCTS_LIST_METHOD] & 64) > 0}
                    >
                      <>
                        <ArchModeField isSubmitting={isSubmitting} name={ATTRIBUTES.ARCH_MODE} />
                        <Divider />
                      </>
                    </GridField>

                    {false && (
                      <>
                        <GridField name={ATTRIBUTES.EXPORT_TYPE} xs={12}>
                          <>
                            <ExportTypeField isSubmitting={isSubmitting} />
                            <Divider />
                          </>
                        </GridField>
                        <GridField name={ATTRIBUTES.PRINT_ASSIGNMENT} xs={12}>
                          <>
                            <AssignmentField
                              name={ATTRIBUTES.PRINT_ASSIGNMENT}
                              options={preference.print_assignment_options}
                              thirdPartyPartnerName={thirdPartyPartnerName}
                              isSubmitting={isSubmitting}
                            />
                            <Divider />
                          </>
                        </GridField>
                        <GridField name={ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT} xs={12}>
                          <>
                            <InsertBracketsAssignmentField
                              currentPrintAssignment={decodedValues.print_assignment}
                              isSubmitting={isSubmitting}
                              preferenceValue={preference[ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]}
                              setDefault={value => {
                                setFieldValue(ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT, String(value));
                              }}
                              thirdPartyPartnerName={thirdPartyPartnerName}
                            />
                            <Divider />
                          </>
                        </GridField>

                        <GridField name={ATTRIBUTES.BRACKET_LIBRARIES} xs={12}>
                          <>
                            <BracketLibrariesField
                              name={ATTRIBUTES.BRACKET_LIBRARIES}
                              disabled={!hasBracketLibraries}
                              items={bracketLibraries}
                            />
                            <Divider />
                          </>
                        </GridField>
                      </>
                    )}
                    {false && (
                      <>
                        <GridField
                          name={ATTRIBUTES.SHIPPING_LOCATION}
                          xs={12}
                          md={6}
                          show={
                            decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] !== ASSIGNMENTS.IN_OFFICE
                          }
                        >
                          <LocationField
                            name={ATTRIBUTES.SHIPPING_LOCATION}
                            locations={availableLocations}
                            dependency={
                              decodedValues[ATTRIBUTES.PRINT_ASSIGNMENT] !== ASSIGNMENTS.IN_OFFICE
                            }
                            preferenceValue={preference[ATTRIBUTES.SHIPPING_LOCATION]}
                            isSubmitting={isSubmitting}
                          />
                        </GridField>
                        <GridField
                          name={ATTRIBUTES.SHIPPING_METHOD}
                          xs={12}
                          md={6}
                          show={enableShippingMethods}
                        >
                          <ShippingMethodField
                            name={ATTRIBUTES.SHIPPING_METHOD}
                            dependency={enableShippingMethods}
                            preferenceValue={preference[ATTRIBUTES.SHIPPING_METHOD]}
                            isSubmitting={isSubmitting}
                            resetValue={() => {
                              if (!enableShippingMethods) {
                                setFieldValue(ATTRIBUTES.SHIPPING_METHOD, null);
                                setFieldTouched(ATTRIBUTES.SHIPPING_METHOD);
                              }
                            }}
                          />
                        </GridField>
                      </>
                    )}
                    {false && (
                      <>
                        <GridField
                          name={ATTRIBUTES.ROOT_INTEGRATION}
                          xs={12}
                          show={preference[ATTRIBUTES.ROOT_INTEGRATION]}
                        >
                          <>
                            <RootIntegrationField
                              name={ATTRIBUTES.ROOT_INTEGRATION}
                              dependency={preference[ATTRIBUTES.ROOT_INTEGRATION]}
                              preferenceValue={preference[ATTRIBUTES.ROOT_INTEGRATION]}
                              isSubmitting={isSubmitting}
                            />
                            <Divider />
                          </>
                        </GridField>
                        <GridField
                          name={ATTRIBUTES.BONDING_DATE}
                          xs={12}
                          sm={6}
                          show={
                            preference[ATTRIBUTES.BONDING_DATE] &&
                            decodedValues[ATTRIBUTES.SHIPPING_METHOD] !== 'international_shipping'
                          }
                        >
                          <>
                            <BondingDateField
                              label={`(Optional) ${VIEW_LABEL[ATTRIBUTES.BONDING_DATE]}`}
                              name={ATTRIBUTES.BONDING_DATE}
                              createdAt={rxForm.created_at_mt || ''}
                            />
                            <Divider />
                          </>
                        </GridField>
                      </>
                    )}

                    <GridField
                      name={ATTRIBUTES.ADDON_OPTIONS_METHOD}
                      xs={12}
                      show={
                        (decodedValues[ATTRIBUTES.PRODUCTS_LIST_METHOD] &
                          ~PRODUCTS_LIST_VALUES[PRODUCTS_LIST_METHODS.PRINTING_OPTIONS]) >
                        0
                      }
                    >
                      <>
                        <FormControl component="fieldset">
                          <AddonOptionsField
                            preferenceValue={preference.addon_options_method}
                            isSubmitting={isSubmitting}
                          />
                        </FormControl>
                        <Divider />
                      </>
                    </GridField>

                    <>
                      <GridField name={ATTRIBUTES.NOTES} xs={12}>
                        <Field
                          variant="outlined"
                          fullWidth
                          id="special_instructions"
                          label={`(Optional) ${QUESTIONS[ATTRIBUTES.NOTES]}`}
                          name="special_instructions"
                          multiline
                          component={TextField}
                        />
                      </GridField>

                      <GridField
                        name={ATTRIBUTES.RUSH_CASE}
                        xs={12}
                        show={
                          preference[ATTRIBUTES.RUSH_CASE_ENABLED] &&
                          decodedValues[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT] !==
                            ASSIGNMENTS.IN_OFFICE
                        }
                      >
                        <RushCaseField
                          name={ATTRIBUTES.RUSH_CASE}
                          preferenceValue={preference[ATTRIBUTES.RUSH_CASE]}
                          dependency={
                            decodedValues[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT] !==
                            ASSIGNMENTS.IN_OFFICE
                          }
                          resetValue={setField => {
                            setField(ATTRIBUTES.RUSH_CASE, '0');
                            setField(ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME, '');
                          }}
                          isSubmitting={isSubmitting}
                          rushCaseFee={get(pricing, 'rx_forms.misc.rx_form.rush_case', 0)}
                        />
                      </GridField>

                      <Grid item xs={12}>
                        <GridField name={ATTRIBUTES.ORDER_MORE_SUPPLIES_METHOD}>
                          <Box mb={2}>
                            <FormLabel component="legend">Order More Supplies:</FormLabel>
                          </Box>
                        </GridField>
                        <Grid container spacing={4}>
                          <GridField name={ATTRIBUTES.BOXES} xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Boxes</InputLabel>
                              <Field name="boxes" component={Select} fullWidth label="Boxes">
                                {BOXES_OPTIONS.map(key => (
                                  <MenuItem key={key} value={key}>
                                    {key}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </GridField>
                          <GridField name={ATTRIBUTES.IMPRESSION_BAGGIES} xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>Impression Baggies</InputLabel>
                              <Field
                                name="impression_baggies"
                                component={Select}
                                fullWidth
                                label="Impression Baggies"
                              >
                                {IMPRESSION_BAGGIE_OPTIONS.map(key => (
                                  <MenuItem key={key} value={key}>
                                    {key}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </GridField>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Form>
              </Context.Provider>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: 'flex-end' }}>
              {actions({ dirty, status, isValid, submitForm })}
            </CardActions>
          </Card>
        );
      }}
    </Formik>
  );
};

RxForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  availableLocations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  preference: PropTypes.object.isRequired,
  thirdPartyPartnerName: PropTypes.string,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.any.isRequired,
  only: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  doctors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  pricing: PropTypes.object
};

RxForm.defaultProps = {
  rxForm: null,
  availableLocations: [],
  thirdPartyPartnerName: null,
  only: [],
  doctors: null,
  pricing: null
};
/**
 * No longer used
 * - isDeleting
 * - onDelete
 *
 */
export default RxForm;
