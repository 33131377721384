import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Select } from 'antd';
import { PRODUCTS_LIST_LABELS, PRODUCTS_LIST_VALUES } from '../../RxForm/constants';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonLink from '../../ButtonLink';

const useStyles = makeStyles(() => {
  return {
    filterTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    clearButton: {
      float: 'right',
      alignSelf: 'center',
      fontSize: 12,
      color: 'gray'
    }
  };
});

const ProductListFilter = ({ value, onChange }) => {
  const { Option } = Select;
  const classes = useStyles();

  return (
    <Grid item xs={4}>
      <Typography component="span" className={classes.filterTitle} variant="subtitle1">
        Filter Request
        {!!value && (
          <ButtonLink className={classes.clearButton} onClick={() => onChange(null)}>
            Clear
          </ButtonLink>
        )}
      </Typography>
      <Select
        placeholder="Please select"
        value={value}
        onChange={value => onChange(value)}
        style={{ width: '100%' }}
      >
        {Object.keys(PRODUCTS_LIST_VALUES).map(key => {
          const label = PRODUCTS_LIST_LABELS[key];
          const value = PRODUCTS_LIST_VALUES[key];
          return (
            <Option key={label} value={value}>
              {label}
            </Option>
          );
        })}
      </Select>
    </Grid>
  );
};

ProductListFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

ProductListFilter.defaultProps = {
  value: null
};

export default ProductListFilter;
