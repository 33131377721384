import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Checkbox, TextField } from 'formik-material-ui';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import { CardActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { replaceNullToEmpty } from '../../../helpers';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../components/Can';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    marginLeft: 'auto'
  }
}));

const PeriodicRuleForm = ({
  contractId,
  onSubmit,
  isLoading,
  periodicRule,
  isDeleting,
  onDelete
}) => {
  const classes = useStyles();
  const emptyPeriodicRule = {
    value: '',
    custom_description: '',
    is_active: true,
    applied_from: null,
    applied_to: null
  };

  const parseValues = rule => {
    if (!rule) return false;

    const parsedRule = { ...rule };

    parsedRule.value = rule.type === 'Discount' ? -1 * rule.value : rule.value;

    return parsedRule;
  };

  return (
    <Formik
      initialValues={
        periodicRule
          ? replaceNullToEmpty(parseValues(periodicRule), ['applied_from', 'applied_to'])
          : emptyPeriodicRule
      }
      enableReinitialize
      validationSchema={Yup.object().shape({
        value: Yup.number().required('Required'),
        custom_description: Yup.string().required('Required'),
        is_active: Yup.bool().required('Required'),
        applied_from: Yup.date().nullable(),
        applied_to: Yup.date().nullable()
      })}
      onSubmit={values => {
        const data = {
          ...values,
          contract_id: contractId
        };
        return onSubmit(data);
      }}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={10} sm={10}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="value"
                  label="Value"
                  name="value"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <Box ml={2}>
                  <FormControlLabel
                    control={
                      <Field
                        name="is_active"
                        color="primary"
                        variant="outlined"
                        id="is_active"
                        component={Checkbox}
                      />
                    }
                    label="Active"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="custom_description"
                  variant="outlined"
                  required
                  fullWidth
                  id="custom_description"
                  label="Description"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          fullWidth
                          id="applied_from"
                          disableToolbar
                          label="Applied From"
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          value={values.applied_from}
                          onChange={value =>
                            setFieldValue(
                              'applied_from',
                              moment.isMoment(value) ? value.format('YYYY-MM-DD') : value
                            )
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          fullWidth
                          id="applied_to"
                          disableToolbar
                          label="Applied To"
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          value={values.applied_to}
                          onChange={value =>
                            setFieldValue(
                              'applied_to',
                              moment.isMoment(value) ? value.format('YYYY-MM-DD') : value
                            )
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={!dirty || !isValid || isLoading}
              className={classes.submit}
            >
              {periodicRule ? 'Update' : 'Create'}
            </LoadingButton>
            {periodicRule && (
              <Can
                roles={['admin']}
                yes={() => (
                  <ConfirmationDialogOnClickWrapper
                    confirmationBody="Are you sure you want to delete the current periodic rule?"
                    confirmationTitle="Delete Periodic Rule"
                  >
                    <LoadingButton
                      loading={isDeleting}
                      color="secondary"
                      variant="contained"
                      disabled={isLoading || isDeleting}
                      onClick={onDelete}
                    >
                      Delete
                    </LoadingButton>
                  </ConfirmationDialogOnClickWrapper>
                )}
              />
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

PeriodicRuleForm.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  periodicRule: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool
};

PeriodicRuleForm.defaultProps = {
  periodicRule: null,
  onDelete: () => {},
  isDeleting: false
};

export default PeriodicRuleForm;
