import { Query } from 'cogent-js';
import BaseApiService from './base';
import { serializeDate } from '../../helpers';

export default class ContractsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    searchFullNameQuery = '',
    paymentStatus = '',
    from,
    to,
    organizations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'full_name_sort';
    const paymentFrom = serializeDate(from);
    const paymentTo = serializeDate(to);
    return this.request({
      method: 'get',
      url: this.query
        .for('contracts')
        .where('full_name_like', searchFullNameQuery)
        .include('organization', 'current_monthly_order')
        .where('payment_status', paymentStatus)
        .where(
          'payment_date_range',
          paymentFrom && paymentTo ? [paymentFrom, paymentTo].join(',') : null
        )
        .whereIn('chosen_wi_organization_id', organizations)
        .sort(orderChar + orderByField, '-id')
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/contracts/${id}`
    });
  }

  attemptPaymentAgain(contractId) {
    return this.request({
      method: 'post',
      url: `/contracts/${contractId}/attempt_payment`
    });
  }

  suspendContract(contractId) {
    return this.request({
      method: 'post',
      url: `/contracts/${contractId}/suspend`
    });
  }

  resumeContract(contractId) {
    return this.request({
      method: 'post',
      url: `/contracts/${contractId}/resume`
    });
  }
}
