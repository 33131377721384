import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormDialog from '../FormDialog';

const HoldingExtraInfo = ({ open, setOpen, onAddedExtraInfo }) => {
  const currentUser = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);
  const actionOwnerName =
    isManagement && currentUser.first_name && currentUser.last_name
      ? `${currentUser.first_name} ${currentUser.last_name}`
      : '';

  const [data, setData] = useState({
    action_owner_name: actionOwnerName,
    reason: '',
    should_notify_doctor: false
  });

  const handleOnCloseFormDialog = response => {
    if (response === true) {
      onAddedExtraInfo(data);
    }
    setData(oldData => ({
      ...oldData,
      reason: '',
      should_notify_doctor: false
    }));
    setOpen(false);
  };

  const handleChangeFormDialogElement = ({ target }) => {
    setData({ ...data, [target.name]: target.type === 'checkbox' ? target.checked : target.value });
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText="Put on Hold"
      acceptButtonText="Continue"
      handleClose={handleOnCloseFormDialog}
      isAcceptButtonDisabled={data.action_owner_name === '' || data.reason === ''}
    >
      <TextField
        autoFocus
        margin="dense"
        id="action_owner_name"
        name="action_owner_name"
        label="Your Name"
        type="text"
        required
        value={data.action_owner_name}
        disabled={!!actionOwnerName}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
      <TextField
        margin="dense"
        id="reason"
        name="reason"
        label="Reason"
        type="text"
        required
        value={data.reason}
        onChange={handleChangeFormDialogElement}
        fullWidth
        multiline
      />
      {isManagement && (
        <FormControlLabel
          control={
            <Checkbox
              id="should_notify_doctor"
              name="should_notify_doctor"
              onChange={handleChangeFormDialogElement}
              checked={data.should_notify_doctor}
            />
          }
          label="Notify Doctor"
        />
      )}
    </FormDialog>
  );
};

HoldingExtraInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAddedExtraInfo: PropTypes.func.isRequired
};

export default HoldingExtraInfo;
