import React, { useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PeriodicRuleForm from './components/PeriodicRuleForm';
import createLoadingSelector from '../../selectors/loading';
import {
  DELETE_PERIODIC_RULE_PREFIX,
  deletePeriodicRuleAction,
  FETCH_PERIODIC_RULE_PREFIX,
  fetchPeriodicRuleAction,
  UPDATE_PERIODIC_RULE_PREFIX,
  updatePeriodicRuleAction
} from '../../reducers/periodicRule';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const UpdatePeriodicRule = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const periodicRuleId = parseInt(params.id, 10);
  const contractId = parseInt(params.contract_id, 10);
  const periodicRule = useSelector(state => {
    return state.periodicRule.items[
      state.periodicRule.items.findIndex(item => item.id === periodicRuleId)
    ];
  });
  const isUpdatingPeriodicRule = useSelector(state =>
    createLoadingSelector([UPDATE_PERIODIC_RULE_PREFIX])(state)
  );

  const isDeletingPeriodicRule = useSelector(state =>
    createLoadingSelector([DELETE_PERIODIC_RULE_PREFIX])(state)
  );

  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_PERIODIC_RULE_PREFIX])(state)
  );

  useEffect(() => {
    dispatch(fetchPeriodicRuleAction(periodicRuleId));
  }, [dispatch, periodicRuleId]);

  const updatePeriodicRule = values => {
    dispatch(updatePeriodicRuleAction(values, periodicRuleId)).then(response => {
      if (response) {
        history.goBack();
      }
    });
    return true;
  };

  const deletePeriodicRule = () => {
    dispatch(deletePeriodicRuleAction(periodicRuleId)).then(response => {
      if (response) {
        history.goBack();
      }
    });
    return true;
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Card className={classes.form}>
      <CardHeader title="Update Periodic Rule" />
      <Divider />
      <PeriodicRuleForm
        contractId={contractId}
        onSubmit={updatePeriodicRule}
        isLoading={isUpdatingPeriodicRule}
        periodicRule={periodicRule}
        isDeleting={isDeletingPeriodicRule}
        onDelete={deletePeriodicRule}
      />
    </Card>
  );
};

UpdatePeriodicRule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

UpdatePeriodicRule.defaultProps = {
  match: { params: {} }
};

export default UpdatePeriodicRule;
