// ACTION TYPES
import { produce } from 'immer';
import OnboardingApiService from '../services/api/onboarding';
import { enqueueNotification } from './notifications';

export const FETCHING_ONBOARDINGS = 'FETCHING_ONBOARDINGS';
export const FETCHED_ONBOARDINGS = 'FETCHED_ONBOARDINGS';
export const UPDATING_ONBOARDING = 'UPDATING_ONBOARDING';
export const UPDATED_ONBOARDING = 'UPDATED_ONBOARDING';
export const SET_CURRENT_ONBOARDING = 'SET_CURRENT_ONBOARDING';
export const OPEN_ONBOARDING_MODAL = 'OPEN_ONBOARDING_MODAL';
export const CLOSE_ONBOARDING_MODAL = 'CLOSE_ONBOARDING_MODAL';
export const SET_ONBOARDING_META = 'SET_ONBOARDING_META';

const initialState = {
  modalOpened: false,
  items: [],
  total: 0,
  current: null,
  updating: false,
  loading: false,
  meta: {
    doctor_allowed_steps: [],
    links: {}
  },
  links: {},
  queryParams: {
    order: 'desc',
    orderBy: 'created_at',
    page: 1,
    perPage: 10,
    status: [1, 5],
    searchOrganizationName: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ONBOARDINGS:
      return {
        ...state,
        loading: true,
        queryParams: action.payload.params
      };
    case FETCHED_ONBOARDINGS:
      return {
        ...state,
        loading: false,
        items: action.payload.onboardings,
        total: action.payload.total
      };
    case UPDATING_ONBOARDING:
      return {
        ...state,
        updating: true
      };
    case UPDATED_ONBOARDING:
      return produce(state, draft => {
        const { onboarding } = action.payload;
        if (draft.items.length) {
          const itemIndex = draft.items.findIndex(item => {
            return item.id === onboarding.id;
          });
          if (itemIndex !== 1) {
            draft.items[itemIndex] = onboarding;
          }
        }
        if (draft.current && draft.current.id === onboarding.id) {
          draft.current = onboarding;
          draft.updating = false;
        }
      });
    case SET_CURRENT_ONBOARDING:
      return {
        ...state,
        current: action.payload.onboarding
      };
    case OPEN_ONBOARDING_MODAL:
      return {
        ...state,
        modalOpened: true
      };
    case CLOSE_ONBOARDING_MODAL:
      return {
        ...state,
        modalOpened: false
      };
    case SET_ONBOARDING_META:
      return {
        ...state,
        meta: action.payload.meta
      };
    default:
      return state;
  }
};

export const fetchOnboardings = params => {
  return dispatch => {
    dispatch({ type: FETCHING_ONBOARDINGS, payload: { params } });
    const onboardingsAPI = new OnboardingApiService();
    return onboardingsAPI
      .get(params)
      .then(response => {
        dispatch({
          type: FETCHED_ONBOARDINGS,
          payload: { onboardings: response.onboardings, total: response.meta.total }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
};

export const proceedNextStep = (onboarding, callback = null) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.onboardings.updating) {
      return;
    }
    dispatch({ type: UPDATING_ONBOARDING });
    const onboardingsAPI = new OnboardingApiService();
    onboardingsAPI
      .proceedNextStep(onboarding.id)
      .then(response => {
        dispatch({
          type: UPDATED_ONBOARDING,
          payload: { onboarding: response.onboarding }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
};

export const showOnboarding = id => {
  return (dispatch, getState) => {
    const state = getState();
    const onboarding = state.onboardings.items.find(item => item.id === id);
    if (onboarding) {
      dispatch({ type: SET_CURRENT_ONBOARDING, payload: { onboarding } });
      return;
    }

    const onboardingsAPI = new OnboardingApiService();
    onboardingsAPI
      .show(id)
      .then(response => {
        dispatch({
          type: SET_ONBOARDING_META,
          payload: { meta: response.meta }
        });
        dispatch({
          type: SET_CURRENT_ONBOARDING,
          payload: { onboarding: response.onboarding }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
};

export const updateMetadata = (onboarding, key, value, callback) => {
  return dispatch => {
    dispatch({ type: UPDATING_ONBOARDING });
    const metadata = onboarding.metadata
      ? { ...onboarding.metadata, [key]: value }
      : { [key]: value };
    const onboardingsAPI = new OnboardingApiService();
    onboardingsAPI
      .updateMetadata(onboarding.id, metadata)
      .then(response => {
        dispatch({
          type: UPDATED_ONBOARDING,
          payload: { onboarding: response.onboarding }
        });
        callback();
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
};

export const openModal = () => {
  return dispatch => {
    dispatch({ type: OPEN_ONBOARDING_MODAL });
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch({ type: CLOSE_ONBOARDING_MODAL });
  };
};

export const selectCurrentOrganizationOnboarding = state => {
  const { currentOrganization } = state.auth;
  const onboarding = state.onboardings.current;
  if (
    !currentOrganization ||
    !currentOrganization.onboarding ||
    !onboarding ||
    onboarding.id !== currentOrganization.onboarding.id
  ) {
    return null;
  }
  return onboarding;
};
