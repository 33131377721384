import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from '../../../selectors/loading';
import FlawedTray from './FlawedTray';
import {
  correctFlawedTrayFromRequestAction,
  REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX,
  removeFlawedTrayFromRequestAction,
  UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX,
  updateFlawedTrayFromRequestAction
} from '../../../reducers/requests';

const FlawedTrayRequestHandler = ({ item, patientId, requestId }) => {
  const dispatch = useDispatch();
  const isUpdating = useSelector(state =>
    createLoadingSelector([UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX])(state)
  );

  const isDeleting = useSelector(state =>
    createLoadingSelector([REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX])(state)
  );

  return (
    <FlawedTray
      correctFlawedTray={(id, correctedFileId) =>
        dispatch(correctFlawedTrayFromRequestAction(id, correctedFileId, requestId))
      }
      updateFlawedTray={(id, params) =>
        dispatch(updateFlawedTrayFromRequestAction(id, params, requestId))
      }
      remove={id => dispatch(removeFlawedTrayFromRequestAction(requestId, id))}
      isDeleting={isDeleting}
      isUpdating={isUpdating}
      patientId={patientId}
      item={item}
    />
  );
};

FlawedTrayRequestHandler.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
  requestId: PropTypes.number.isRequired
};

FlawedTrayRequestHandler.defaultProps = {};

export default FlawedTrayRequestHandler;
