import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  spinner: {
    marginLeft: 5
  }
}));

const LoadingSpinner = ({ show, ...rest }) => {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return <CircularProgress className={classes.spinner} size={20} {...rest} />;
};

LoadingSpinner.propTypes = {
  show: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  show: false
};

export default LoadingSpinner;
