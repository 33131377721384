import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { fetchGlobalSettingsAction } from '../../reducers/settings';

const GlobalSettings = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);

  useEffect(() => {
    dispatch(fetchGlobalSettingsAction()).then(() => setIsLoadingSettings(false));
  }, [dispatch]);

  if (isLoadingSettings) {
    return <LoadingIndicator />;
  }

  return children;
};

GlobalSettings.prototype = {};

export default GlobalSettings;
