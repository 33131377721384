import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import LoadingButton from '../../../components/LoadingButton';
import { checkEmail } from '../../../reducers/auth';
import { objectHasProperty } from '../../../helpers';
import { stepsMap } from '../../../constants/signup';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

stepsMap[1].beforeProceed = (dispatch, values) => {
  return new Promise((resolve, reject) => {
    dispatch(checkEmail(values.email))
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

function FormActions({ isValid, dirty, isLoading, show, currentStep, setCurrentStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { values, errors } = useFormikContext();

  const emptyValues = useMemo(() => {
    const inputsToValidate = _.pick(values, stepsMap[currentStep].inputs);

    return _.reduce(inputsToValidate, (result, value) => _.isEmpty(value) && result, true);
  }, [currentStep, values]);

  const canProceed = useMemo(
    () =>
      !emptyValues &&
      _.intersection(stepsMap[currentStep].inputs, Object.keys(errors)).length === 0,
    [currentStep, errors, emptyValues]
  );

  const onProceed = async () => {
    if (objectHasProperty(stepsMap[currentStep], 'beforeProceed')) {
      const res = await stepsMap[currentStep].beforeProceed(dispatch, values);

      if (res) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  if (!show) {
    return (
      <LoadingButton
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        loading={isLoading}
        disabled={!canProceed}
        className={classes.submit}
        onClick={onProceed}
      >
        Next
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      size="large"
      loading={isLoading}
      disabled={!dirty || !isValid || isLoading}
      className={classes.submit}
    >
      Signup
    </LoadingButton>
  );
}

FormActions.propTypes = {
  show: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

FormActions.defaultProps = {
  show: false
};

export default FormActions;
