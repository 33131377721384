import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Can from '../Can';
import LoadingButton from '../LoadingButton';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import { HOLD_PATIENT_PREFIX, holdPatientAction } from '../../reducers/patients';
import createLoadingSelector from '../../selectors/loading';
import HoldingExtraInfo from './HoldingExtraInfo';

const PatientHoldingAction = ({ patient, isLoading }) => {
  const dispatch = useDispatch();
  const isHolding = useSelector(state => createLoadingSelector([HOLD_PATIENT_PREFIX])(state));
  const [extraHoldingDataRequesting, setExtraHoldingDataRequesting] = useState(false);

  const holdPatient = extraInfo => {
    dispatch(holdPatientAction(patient.id, extraInfo));
    return true;
  };

  const confirmHold = () => {
    if (patient.is_on_hold) {
      holdPatient();
    } else {
      setExtraHoldingDataRequesting(true);
    }
  };

  if (patient && patient.deleted_at) {
    return null;
  }

  return (
    <>
      <Can
        organizationId={patient.organization_id}
        permissions={[
          'patients.manage',
          'patients.supervise',
          'patients.toggle-hold',
          'organization.patients.toggle-hold'
        ]}
        yes={() => (
          <>
            <ConfirmationDialogOnClickWrapper
              confirmationBody={`Are you sure you want to ${
                patient.is_on_hold ? 'unhold' : 'hold'
              } the current patient?`}
              confirmationTitle={patient.is_on_hold ? 'Unhold The Patient' : 'Put on Hold'}
            >
              <LoadingButton
                loading={isHolding}
                color="secondary"
                variant="outlined"
                disabled={isLoading || isHolding}
                onClick={confirmHold}
              >
                {patient.is_on_hold ? 'Unhold' : 'Hold'}
              </LoadingButton>
            </ConfirmationDialogOnClickWrapper>
            <HoldingExtraInfo
              open={extraHoldingDataRequesting}
              setOpen={open => setExtraHoldingDataRequesting(open)}
              onAddedExtraInfo={extraInfo => holdPatient(extraInfo)}
            />
          </>
        )}
      />
    </>
  );
};

PatientHoldingAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PatientHoldingAction;
