import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => {
  return {
    main: {
      textAlign: 'center',
      display: 'flex',
      cursor: 'pointer',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRadius: 16,
      backgroundColor: 'gray',
      '&.indicator-okay': {
        backgroundColor: theme.palette.success.main
      },
      '&.indicator-warning': {
        backgroundColor: theme.palette.warning.main
      },
      '&.indicator-danger': {
        backgroundColor: theme.palette.error.main
      },
      '&.indicator-neutral': {
        backgroundColor: theme.palette.text.secondary
      }
    },
    selected: {
      display: 'flex',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(-0.5),
      justifyContent: 'center',
      flexDirection: 'column'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      float: 'left'
    },
    containerWithAge: {
      height: 36
    },
    counter: {},
    label: {
      fontWeight: 500,
      marginBottom: theme.spacing(0.25)
    },
    labelCounterDivider: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      borderLeft: '1px solid'
    },
    age: {
      fontSize: 12,
      fontWeight: 'bolder'
    }
  };
});

const AGE_LABELS = {
  year: 'Y',
  month: 'M',
  day: 'D',
  hour: 'h',
  minute: 'm',
  second: 's'
};

const calculateAge = timestamp => {
  const breakdown = {
    year: null,
    month: null,
    day: null,
    hour: null,
    minute: null,
    second: null
  };

  let datetimeObject = moment.utc(timestamp);
  const now = moment();
  Object.keys(breakdown).forEach(period => {
    const diff = now.diff(datetimeObject, period);

    if (diff) {
      breakdown[period] = diff;
      datetimeObject = datetimeObject.add(diff, period);
    }
  });

  const ageBreakdown = Object.keys(breakdown)
    .map(period => {
      if (breakdown[period]) {
        const label = AGE_LABELS[period];
        return `${breakdown[period]}${label}`;
      }
      return null;
    })
    .filter(age => age !== null);

  return ageBreakdown.join(' ');
};

const INDICATOR = {
  OKAY: 'okay',
  WARNING: 'warning',
  DANGER: 'danger',
  NEUTRAL: 'neutral'
};

const indicator = timestamp => {
  if (timestamp === null) {
    return INDICATOR.NEUTRAL;
  }
  const diff = timestamp ? moment().diff(moment(timestamp), 'hour') : 0;
  if (diff <= 6) {
    return INDICATOR.OKAY;
  }
  if (diff <= 12) {
    return INDICATOR.WARNING;
  }
  return INDICATOR.DANGER;
};

const Step = ({ label, count, timestamp, showAge, selected, onClick, ...props }) => {
  const classes = useStyles();

  const containerClassNames = [classes.container];
  if (showAge) {
    containerClassNames.push(classes.containerWithAge);
  }
  const mainClassNames = [classes.main];
  mainClassNames.push(`indicator-${indicator(timestamp)}`);

  const age = !timestamp ? null : calculateAge(timestamp);

  const handleSelect = e => {
    e.preventDefault();
    onClick();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div {...props} className={mainClassNames.join(' ')} onClick={handleSelect}>
      <div className={containerClassNames.join(' ')}>
        <div className={classes.label}>
          {count == null && <CircularProgress size={10} color="inherit" />}
          {count !== null && <span className={classes.counter}>{count}</span>}
          <span className={classes.labelCounterDivider} />
          {label}
        </div>
        {showAge && age !== null && <div className={classes.age}>{age}</div>}
      </div>
      {selected && (
        <div className={classes.selected}>
          <CheckCircleIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};

Step.defaultProps = {
  count: null,
  selected: false,
  showAge: true,
  timestamp: null
};

Step.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  timestamp: PropTypes.string,
  selected: PropTypes.bool,
  showAge: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default Step;
