import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Steps from './Steps';
import { REQUEST_STEPS } from '../../../constants';

const RequestsStepsFilter = ({ requestTypeStatusValue, loading, value, data, onChange }) => {
  const request =
    requestTypeStatusValue.length > 0 ? requestTypeStatusValue[0].split('.')[0] : null;
  const [, selectedStep] = value !== null ? value.split('.') : [null, null];

  if (request === null) {
    return '';
  }

  const steps = REQUEST_STEPS[request];

  const handleSelect = step => {
    if (step === selectedStep) {
      onChange(null);
    } else {
      onChange(`${request}.${step}`);
    }
  };

  const findStepData = step => data.find(({ label }) => label === step);

  const filledData = steps.map(step => {
    const stepData = findStepData(step);
    return (
      stepData || {
        label: step,
        timestamp: loading && data.length === 0 ? null : '',
        total: loading && data.length === 0 ? null : 0
      }
    );
  });

  return (
    <Steps steps={steps} currentStep={selectedStep} onSelect={handleSelect} data={filledData} />
  );
};

RequestsStepsFilter.defaultProps = {
  value: null,
  data: [],
  loading: false
};

RequestsStepsFilter.propTypes = {
  requestTypeStatusValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      timestamp: PropTypes.string,
      total: PropTypes.number
    })
  ),
  loading: PropTypes.bool
};

export default connect(state => {
  return {
    data: state.patients.requestSteps.data,
    loading: state.patients.loadingItems
  };
}, null)(RequestsStepsFilter);
