import React, { useEffect, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import createLoadingSelector from '../../selectors/loading';
import createErrorMessageSelector from '../../selectors/error';
import { CREATE_RX_FORM_PREFIX, createRxFormAction } from '../../reducers/rxForms';
import OrganizationInfo from '../../components/OrganizationInfo';
import LoadAvailableLocations from '../../components/LoadAvailableLocations';
import LoadingIndicator from '../../components/LoadingIndicator';
import useOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import OrganizationsApiService from '../../services/api/organizations';
import Form from '../../components/RxForm/Form';
import { getDefaultAssignment } from '../../components/RxForm/helpers';
import LoadingButton from '../../components/LoadingButton';
import createGetItemSelector from '../../selectors/getItem';
import { enqueueNotification } from '../../reducers/notifications';
import { ATTRIBUTES, SCAN_SUBMISSION_METHODS } from '../../components/RxForm/constants';
import hasPermission from '../../selectors/hasPermission';
import { fetchOrganizationUsers } from '../../reducers/organizationUsers';
import withOrganizationPricing from '../../hooks/withOrganizationPricing';
import InfoDialog from '../../components/InfoDialog';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiCardHeader-action': {
      flex: '0.5 0 auto'
    }
  }
}));

const getDoctors = members => {
  return members.map(user => {
    return {
      id: user.id,
      full_name: user.full_name,
      role: user.pivot.role.name
    };
  });
};

const AddRxForm = ({ organizationMembers, match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hasMount = useRef(false);
  const organizationId = parseInt(params.organizationId, 10);
  const isManagement = useSelector(state => state.auth.isManagement);
  const { isLoading: isLoadingOrganizationPermissions } = useOrganizationPermissions({
    organizationId
  });
  const organizationFromState = useSelector(state =>
    createGetItemSelector(state.organizations, organizationId)
  );
  const currentUserOrganization = useSelector(state => state.auth.currentOrganization);
  const currentUser = useSelector(state => state.auth.user);

  const [preference, setPreference] = useState();
  const [thirdPartyPartnerName, setThirdPartyPartnerName] = useState();
  const [doctors, setDoctors] = useState(null);
  const canSelectDoctor = useSelector(state =>
    hasPermission(state, {
      permissions: [
        'rx-forms.manage',
        'rx-forms.select-doctor',
        'organization.rx-forms.select-doctor'
      ],
      organizationId
    })
  );
  const [bondingDateInfoShown, setBondingDateInfoShown] = useState(null);

  const isCreatingRxForm = useSelector(state =>
    createLoadingSelector([CREATE_RX_FORM_PREFIX])(state)
  );
  const errorOnCreating = useSelector(state =>
    createErrorMessageSelector([CREATE_RX_FORM_PREFIX])(state)
  );
  const [organization, setOrganization] = useState(null);

  const rxToPrint = useSelector(state =>
    state.rxForms.items.length > 0 ? state.rxForms.items[0] : null
  );

  useEffect(() => {
    if (hasMount.current && !isCreatingRxForm && !errorOnCreating) {
      window.open(`/patient-details/${rxToPrint.patient_id}/print-rxform`, '_blank');

      if (isManagement) {
        history.push('/patients-page');
      } else {
        history.push('/patients');
      }
    }

    hasMount.current = true;
  }, [dispatch, isCreatingRxForm, errorOnCreating, history, isManagement, rxToPrint]);

  useEffect(() => {
    const organizationApiService = new OrganizationsApiService();
    organizationApiService.getRxFormPreference(organizationId).then(({ data, metadata }) => {
      setPreference(data);
      setThirdPartyPartnerName(metadata.third_party_partner.name);
    });
  }, [organizationId]);

  useEffect(() => {
    if (organization && organization.id === organizationId) {
      return () => {};
    }
    if (isManagement && organizationFromState) {
      setOrganization(organizationFromState);
      return () => {};
    }
    if (currentUserOrganization) {
      setOrganization(currentUserOrganization);
      return () => {};
    }

    let setFetchOrganization = data => {
      setOrganization(data);
    };
    const api = new OrganizationsApiService();
    api
      .show(organizationId)
      .then(data => {
        setFetchOrganization(data.organization);
      })
      .catch(() => {
        setOrganization(null);
        dispatch(enqueueNotification('error', 'Failed to fetch organization.'));
      });

    return () => {
      setFetchOrganization = () => {};
    };
  }, [
    organization,
    setOrganization,
    organizationId,
    isManagement,
    organizationFromState,
    currentUserOrganization,
    dispatch
  ]);

  useEffect(() => {
    if (!canSelectDoctor) {
      return;
    }
    if (organization) {
      if (organization.users) {
        setDoctors(getDoctors(organization.users));
        return;
      }

      if (organizationMembers[organization.id] && organizationMembers[organization.id].users) {
        setDoctors(organizationMembers[organization.id].users);
      } else if (
        !organizationMembers[organization.id] ||
        !organizationMembers[organization.id].fetching
      ) {
        dispatch(fetchOrganizationUsers(organization.id));
      }
    }
  }, [organizationMembers, organization, setDoctors, dispatch, canSelectDoctor]);

  const createRxForm = values => {
    console.log('createRxForm clicked');
    dispatch(
      createRxFormAction({
        ...values,
        organization_id: organization.id
      })
    );
    return true;
  };

  if (!organization || !preference || isLoadingOrganizationPermissions) {
    return <LoadingIndicator />;
  }

  if (organization && !organization.paid_startup_fee) {
    return <Alert severity="warning">Start-up Fee for Organization not paid.</Alert>;
  }

  const rxForm = {
    first_name: '',
    last_name: '',
    case_number_text: '',
    impression_date: moment().format('YYYY-MM-DD'),
    submission_completed_by: getDefaultAssignment(preference.initial_case_setup_assignment_options),
    rush_case: preference.rush_case,
    rush_case_authorized_by: '',
    impression_type: '',
    scan_submission_type: SCAN_SUBMISSION_METHODS.UPLOAD,
    products_list: null,
    addon_options: '',
    scanner_type: '1',
    custom_scanner_type_name: '',
    stl_files: [],
    ios_files: [],
    rx_intraoral_photos: [],
    special_instructions: '',
    jaws_id: preference.arch_type,
    arch_mode: '1',
    export_type: preference.export_type,
    print_assignment: getDefaultAssignment(preference.print_assignment_options),
    insert_brackets_assignment: preference.insert_brackets_assignment,
    location_id: preference.treatment_location_id,
    shipping_location_id: preference.shipping_location_id,
    shipping_method: null, // let the ShippingMethodField take care setting the correct method
    root_integration: preference.root_integration,
    [ATTRIBUTES.BRACKET_LIBRARIES]: [],
    bonding_date: null,
    [ATTRIBUTES.BOXES]: null,
    [ATTRIBUTES.IMPRESSION_BAGGIES]: null
  };

  if (canSelectDoctor) {
    rxForm[ATTRIBUTES.DOCTOR] = preference.default_doctor_id;
  } else if (currentUser) {
    rxForm[ATTRIBUTES.DOCTOR] = currentUser.id;
  }

  const children = pricing => (
    <Card className={classes.form}>
      <LoadAvailableLocations organizationId={organization.id}>
        {availableLocations => (
          <Form
            organization={organization}
            title="Start A Case"
            description={<OrganizationInfo organization={organization} />}
            rxForm={rxForm}
            onSubmit={createRxForm}
            thirdPartyPartnerName={thirdPartyPartnerName}
            preference={preference}
            isLoading={isCreatingRxForm}
            availableLocations={availableLocations}
            doctors={doctors}
            actions={({ dirty, isValid, submitForm, status }) => {
              return (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isCreatingRxForm}
                  onClick={submitForm}
                >
                  Submit
                </LoadingButton>
              );
            }}
            pricing={pricing}
          />
        )}
      </LoadAvailableLocations>
      <InfoDialog
        open={!!bondingDateInfoShown}
        onClose={() => setBondingDateInfoShown(false)}
        title="Note"
        content="For maximum bracket accuracy, cases should be bonded no later than 30 days from this submission date."
      />
    </Card>
  );

  return withOrganizationPricing({ organizationId: organization.id, children });
};

AddRxForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  organizationMembers: PropTypes.object.isRequired
};

AddRxForm.defaultProps = {
  match: { params: {} }
};

// export default AddRxForm;

export default connect(state => {
  const organizationMembers = state.organizationUsers.organizations;
  return {
    organizationMembers
  };
})(AddRxForm);
