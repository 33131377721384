import React, { useEffect } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const StateResult = ({ searchResults, children, searching, onSearch }) => {
  useEffect(() => {
    onSearch(searchResults && searchResults.nbHits ? searchResults.nbHits : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searching]);

  if (!children) {
    return <div />;
  }

  return children;
};

StateResult.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  searchResults: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  searching: PropTypes.bool.isRequired,
  onSearch: PropTypes.func
};

StateResult.defaultProps = {
  onSearch: () => {},
  children: <div />,
  searchResults: null
};

export default connectStateResults(StateResult);
