import { map } from 'lodash';
import moment from 'moment';

window._moment = moment;
export const dateFormatString = 'YYYY/MM/DD';
export const timeFormatString = 'HH:mm';

const parseDate = (date) => {
  if (date && moment(date).isValid()) {
    return moment.utc(date).local().format(`${dateFormatString} ${timeFormatString}`);
  }

  return null;
};

export const productionTime = (startedAt, finishedAt) => {
  let prodTime = '-';
  const parser = (t, u) => t ? `${t}${u}` : '';

  if (startedAt && finishedAt) {
    let minutes = moment(finishedAt).diff(moment(startedAt), 'minutes');
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let days = Math.floor(hours / 24);
    hours = hours % 24;

    if (days) {
      prodTime = `${days}d ${ parser(hours, 'h') } ${ parser(minutes, 'm') }`;
    } else if (hours) {
      prodTime = `${hours}h ${ parser(minutes, 'm') }`;
    } else {
      prodTime = `${minutes}m`;
    }
  }

  return prodTime;
};

// eslint-disable-next-line import/prefer-default-export
export const formatProcesses = processes => {
  return map(processes, item => {
    let {
      id,
      step,
      process,
      started_at,
      finished_at,
      user_id,
      tech_name,
      production_time
    } = item;

    production_time = productionTime(started_at, finished_at);
    started_at = parseDate(started_at);
    finished_at = parseDate(finished_at);

    const o = {
      id,
      step,
      process,
      started_at,
      finished_at,
      user_id,
      tech_name,
      production_time
    };

    // console.log({item, o});

    return o;
  });
};

export const formatProcessesRequest = processes => {
  return map(processes, item => {
    let { started_at, finished_at } = item;
    const { id, user_id } = item;

    const fstring = 'YYYY-MM-DD HH:mm:ss';
    started_at = started_at ? moment.utc(started_at).format(fstring) : null;
    finished_at = finished_at ? moment.utc(finished_at).format(fstring) : null;

    return {
      id,
      user_id,
      started_at,
      finished_at
    };
  });
};
