import { ONBOARDING_STATUS } from '../../../constants';

export default {
  1: {
    label: ONBOARDING_STATUS.PENDING.label,
    filter: ONBOARDING_STATUS.PENDING.key
  },
  5: {
    label: ONBOARDING_STATUS.IN_PROGRESS.label,
    filter: ONBOARDING_STATUS.IN_PROGRESS.key
  },
  10: {
    label: ONBOARDING_STATUS.COMPLETED.label,
    filter: ONBOARDING_STATUS.COMPLETED.key
  }
};
