import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import OrdersList from '../OrdersList/OrdersList';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../LoadingIndicator';
import {
  FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX,
  fetchContractOrganizationOrdersAction
} from '../../reducers/contracts';

const OrdersListHandler = ({ organization, contractId }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX])(state)
  );

  useEffect(() => {
    dispatch(fetchContractOrganizationOrdersAction(contractId, organization.id));
  }, [contractId, organization.id, dispatch]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OrdersList orders={get(organization, 'orders', []).filter(order => order.fm_invoice_status)} />
  );
};

OrdersListHandler.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  contractId: PropTypes.number.isRequired
};

OrdersListHandler.defaultProps = {};

export default OrdersListHandler;
