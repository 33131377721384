import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUserAction, setIsCurrentOrganizationUpdating } from '../../reducers/auth';
import LoadingSpinner from '../LoadingSpinner';
import { resetPatients } from '../../reducers/patients';
import { resetRequests } from '../../reducers/requests';
import { resetRxForms } from '../../reducers/rxForms';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    paddingLeft: '10px'
  },
  icon: {
    color: 'white'
  },
  select: {
    marginRight: theme.spacing(2)
  },
  spinner: {
    marginLeft: 5,
    color: 'white'
  }
}));

const OrganizationSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const currentUser = useSelector(state => state.auth.user);
  const isLoading = useSelector(state => state.auth.isCurrentOrganizationUpdating);

  const onChange = event => {
    dispatch(resetPatients());
    dispatch(resetRequests());
    dispatch(resetRxForms());
    dispatch(setIsCurrentOrganizationUpdating(true));
    dispatch(fetchCurrentUserAction(event.target.value)).then(() => {
      dispatch(setIsCurrentOrganizationUpdating(false));
      history.push('/');
    });
  };

  if (!currentOrganization) {
    return null;
  }

  if (currentUser.organizations.length === 1) {
    return null;
  }

  return (
    <div className={classes.select}>
      <Select
        disableUnderline
        value={currentOrganization.id}
        onChange={onChange}
        classes={{ root: classes.root, icon: classes.icon }}
      >
        {currentUser.organizations.map(organization => (
          <MenuItem value={organization.id} key={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
      <LoadingSpinner show={isLoading} className={classes.spinner} />
    </div>
  );
};

OrganizationSelector.propTypes = {};

OrganizationSelector.defaultProps = {};

export default OrganizationSelector;
