import React, { useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import { CircularProgress, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { get, isNull } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import EnhancedTableHead from './components/EnhancedTableHead';
import { UTCDateTimeToLocalTime } from '../../helpers';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import Can from '../Can';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'relative',
    marginTop: theme.spacing(5)
  },
  table: {
    minWidth: 750
  },
  verticalLines: {
    '& .MuiTableCell-root:not(.vertical-border-none)': {
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    '& .MuiTableCell-root.MuiTableCell-empty': {
      border: 'none'
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translateY(-100%)',
    borderRadius: '25px 0 0 25px',
    fontWeight: 500,
    color: theme.palette.grey[600],
    '& .MuiAvatar-root': {
      width: 'auto',
      padding: '5px',
      marginLeft: '5px'
    }
  }
}));

const ActionButton = ({
  rowId,
  rowData,
  isIconButton,
  handleOnAction,
  text,
  icon,
  title,
  disabled,
  condition,
  Wrapper,
  replaceWith,
  ...props
}) => {
  if (condition && !condition(rowData)) {
    return '';
  }

  const replaceAction = replaceWith ? replaceWith[rowId] : null;

  if (replaceAction) {
    return replaceAction;
  }

  const buttonProps = {
    disabled: typeof disabled === 'function' ? disabled(rowData) : !!disabled,
    onClick: () => handleOnAction(rowId, rowData),
    ...props
  };

  let component;

  if (isIconButton) {
    component = disabled ? (
      <IconButton {...buttonProps}>{icon}</IconButton>
    ) : (
      <Tooltip title={title}>
        <IconButton {...buttonProps}>{icon}</IconButton>
      </Tooltip>
    );
  } else {
    component = (
      <Button title={title} startIcon={icon} {...buttonProps}>
        {text || title}
      </Button>
    );
  }

  if (Wrapper) {
    return <Wrapper row={rowData}>{component}</Wrapper>;
  }
  return component;
};

ActionButton.defaultProps = {
  variant: 'contained',
  color: 'default',
  size: 'small',
  title: '',
  isIconButton: false,
  disabled: false,
  text: null,
  icon: null,
  condition: null,
  Wrapper: null
};
ActionButton.propTypes = {
  rowId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rowData: PropTypes.object.isRequired,
  icon: PropTypes.element,
  title: PropTypes.string,
  handleOnAction: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  isIconButton: PropTypes.bool,
  text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  disabled: PropTypes.any,
  condition: PropTypes.func,
  Wrapper: PropTypes.elementType
};

const RenderActions = (actions, id, customActions, row, classes) => {
  const showDelete = () => {
    if (!actions.showDeleteValidationMethod) {
      return true;
    }

    return actions.showDeleteValidationMethod(id);
  };

  return (
    <TableCell>
      {customActions.map(action => {
        if (action.condition && !action.condition(row)) {
          return null;
        }

        return (
          <Can
            permissions={action.permissions}
            key={action.name}
            yes={() => (
              <ActionButton rowId={id} rowData={row} className={classes.button} {...action} />
            )}
          />
        );
      })}
      {actions.edit && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          key={`edit_${id}`}
          title="Edit"
          className={classes.button}
          onClick={() => actions.handleOnEdit(id)}
        >
          Edit
        </Button>
      )}
      {actions.delete && showDelete() && (
        <Can
          permissions={actions.deletePermissions}
          yes={() => (
            <ConfirmationDialogOnClickWrapper
              confirmationBody={
                actions.deleteConfirmationMessage || 'Are you sure you want to delete this item?'
              }
              confirmationTitle={actions.deleteConfirmationTitle || 'Delete Confirmation'}
              onCancelConfirmation={actions.handleOnCancelDeleteConfirmation}
            >
              <Button
                variant="contained"
                color="secondary"
                size="small"
                key={`delete_${id}`}
                title="Delete"
                className={classes.button}
                onClick={() => actions.handleOnDelete(id)}
              >
                Delete
              </Button>
            </ConfirmationDialogOnClickWrapper>
          )}
        />
      )}
      {actions.download && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          key={`download_${id}`}
          title="Download"
          className={classes.button}
          onClick={() => actions.handleOnDownload(id)}
        >
          Download
        </Button>
      )}
    </TableCell>
  );
};

const DefaultRowsIterator = ({ rows, rowRenderer }) => {
  return <TableBody>{rows.map((row, index) => rowRenderer(row, index))}</TableBody>;
};

const DataTable = ({
  rows,
  columns,
  updateData,
  total,
  isLoading,
  defaultOrderBy,
  selectableRows,
  actions,
  customActions,
  showDensePaddingOption,
  additionalParams,
  globalParams,
  showVerticalLines,
  showEmptyRows,
  onSortChange,
  rowsPerPageOptions,
  defaultRowsPerPage,
  rowPropsProvider,
  RowsIterator,
  rowsIteratorProps
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState(globalParams.order);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(globalParams.page);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || globalParams.perPage);
  const [additionalParameters, setAdditionalParameters] = useState({
    ...additionalParams.params
  });
  const hasMount = useRef(false);
  const hasSortListener = useMemo(() => {
    return onSortChange !== null;
  }, [onSortChange]);

  const showActions = Boolean(
    actions.edit || actions.delete || actions.download || (customActions && customActions.length)
  );

  useEffect(() => {
    let orderParams = {};
    if (!hasSortListener) {
      orderParams = { order, orderBy };
    } else if (additionalParameters.order !== order || additionalParameters.orderBy !== orderBy) {
      setOrder(additionalParameters.order);
      setOrderBy(additionalParameters.orderBy);
      return;
    }

    const changes = {
      ...additionalParameters,
      ...orderParams,
      page,
      perPage: rowsPerPage
    };

    updateData(changes);
  }, [orderBy, order, page, rowsPerPage, updateData, additionalParameters, hasSortListener]);

  useEffect(() => {
    if (hasMount.current) {
      if (additionalParams.resetPage) {
        setPage(1);
      }
      setAdditionalParameters(additionalParams.params);
    } else {
      hasMount.current = true;
    }
  }, [additionalParams]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (onSortChange) {
      onSortChange({
        property,
        direction: isAsc ? 'desc' : 'asc'
      });
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, total - (page - 1) * rowsPerPage);
  const emptyRows = 5;

  const filterAllowedColumns = tableColumns => {
    return tableColumns.filter(column => {
      return !column.hide;
    });
  };

  const isNullSetDefault = (value, defaultValue) => {
    return isNull(value) ? defaultValue || '' : value;
  };

  const tableClasses = `${classes.table} ${showVerticalLines ? classes.verticalLines : ''}`;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Chip
          avatar={<Avatar variant="rounded">{total}</Avatar>}
          label="results found"
          color="default"
          className={classes.badge}
        />
        <TableContainer>
          <Table
            className={tableClasses}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              selectableRows={selectableRows}
              showActions={showActions}
            />
            <RowsIterator
              rows={rows}
              DefaultRowsIteratorComponent={DefaultRowsIterator}
              rowRenderer={(row, index = {}) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const props = rowPropsProvider ? rowPropsProvider(row) : {};
                return (
                  <TableRow
                    index={index}
                    hover
                    onClick={event => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected && selectableRows}
                    {...props}
                  >
                    {selectableRows && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                    )}
                    {filterAllowedColumns(columns).map((column, columnIndex) => {
                      if (!columnIndex && selectableRows) {
                        return (
                          <TableCell
                            key={column.id}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {get(row, column.id, '')}
                          </TableCell>
                        );
                      }

                      if (column.type && column.type === 'datetime') {
                        return (
                          <TableCell key={column.id} align="left">
                            {UTCDateTimeToLocalTime(get(row, column.id, ''))}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id} align="left" className={column.className}>
                          {column.formatMethod
                            ? column.formatMethod(get(row, column.id, ''), row)
                            : isNullSetDefault(get(row, column.id, ''), column.default)}
                        </TableCell>
                      );
                    })}
                    {showActions && RenderActions(actions, row.id, customActions, row, classes)}
                  </TableRow>
                );
              }}
              {...rowsIteratorProps}
            />
            {emptyRows > 0 && showEmptyRows && (
              <TableBody>
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} className="MuiTableCell-empty" />
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {total > 0 && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {showDensePaddingOption && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
    </div>
  );
};

DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string
    })
  ).isRequired,
  total: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultOrderBy: PropTypes.string.isRequired,
  selectableRows: PropTypes.bool,
  actions: PropTypes.shape({
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    download: PropTypes.bool,
    handleOnEdit: PropTypes.func,
    handleOnDelete: PropTypes.func
  }),
  // eslint-disable-next-line react/forbid-prop-types
  customActions: PropTypes.array,
  showDensePaddingOption: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  additionalParams: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  globalParams: PropTypes.object,
  showVerticalLines: PropTypes.bool,
  showEmptyRows: PropTypes.bool,
  onSortChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  defaultRowsPerPage: PropTypes.number,
  rowPropsProvider: PropTypes.func,
  RowsIterator: PropTypes.elementType
};

DataTable.defaultProps = {
  selectableRows: false,
  actions: { edit: false, delete: false, download: false },
  showDensePaddingOption: false,
  additionalParams: {},
  customActions: [],
  globalParams: {},
  showVerticalLines: false,
  showEmptyRows: true,
  onSortChange: null,
  rowsPerPageOptions: [3, 5, 10, 25],
  defaultRowsPerPage: null,
  rowPropsProvider: null,
  RowsIterator: DefaultRowsIterator
};

export default DataTable;
