import React from 'react';
import PropTypes from 'prop-types';
import HitCardDetails from '../Common/HitCardDetails';
import HitCardHeader from '../Common/HitCardHeader';

const visibleAttributes = [
  'email',
  'phone_number',
  'street_address',
  'street_address_line_2',
  'city',
  'state',
  'country',
  'zip_code',
  'full_address',
  'number_of_users'
];

const HitCard = ({ hit }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <HitCardHeader hit={hit} expanded={expanded} setExpanded={setExpanded} />

      <HitCardDetails hit={hit} visibleAttributes={visibleAttributes} expanded={expanded} />
    </>
  );
};

HitCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

HitCard.defaultProps = {};

export default HitCard;
