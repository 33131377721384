import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import createLoadingSelector from '../../../selectors/loading';
import BillingAddress from '../../../components/BillingAddress';
import {
  UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX,
  updateOrganizationBillingAddress
} from '../../../reducers/organizations';
import SubSectionWrapper from '../../Account/components/SubSectionWrapper';

const OrganizationBillingAddress = ({ organizationId, readOnly }) => {
  const dispatch = useDispatch();
  const organization = useSelector(state => {
    return state.organizations.items[
      state.organizations.items.findIndex(item => item.id === organizationId)
    ];
  });
  const isSaving = useSelector(state =>
    createLoadingSelector([UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX])(state)
  );

  const updateBillingAddress = values => {
    dispatch(updateOrganizationBillingAddress(organizationId, { ...values }));
    return true;
  };

  if (!organization || !organization.payment_info) {
    return null;
  }

  return (
    <SubSectionWrapper title="Billing Address">
      <BillingAddress
        onSubmit={updateBillingAddress}
        billingAddress={organization.payment_info.billing_address}
        isSaving={isSaving}
        readOnly={readOnly}
      />
    </SubSectionWrapper>
  );
};

OrganizationBillingAddress.propTypes = {
  organizationId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool
};

OrganizationBillingAddress.defaultProps = {
  readOnly: false
};

export default OrganizationBillingAddress;
