import { createSelector } from 'reselect';
import { get } from 'lodash';

const getOrganizationPermissions = createSelector(
  state => state.auth.isManagement,
  state => state.auth.currentOrganization,
  state => state.auth.management.organizationsPermissions,
  (_, organizationId) => organizationId,
  (isManagement, currentOrganization, organizationsPermissions, organizationId) => {
    if (isManagement) {
      return organizationsPermissions[organizationId];
    }

    if (currentOrganization && organizationId === currentOrganization.id) {
      return get(currentOrganization, 'pivot.all_permissions', []);
    }

    return null;
  }
);

export default getOrganizationPermissions;
