import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import UserHit from '../UserHit';
import LocationHit from '../LocationHit';
import OrganizationHit from '../OrganizationHit';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const Hit = ({ hit }) => {
  const classes = useStyles();
  const { model } = hit;

  const renderComponent = () => {
    switch (model) {
      case 'user':
        return <UserHit hit={hit} />;
      case 'organization':
        return <OrganizationHit hit={hit} />;
      case 'location':
        return <LocationHit hit={hit} />;

      default:
        break;
    }

    return null;
  };

  return <Card className={classes.root}>{renderComponent()}</Card>;
};

Hit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

Hit.defaultProps = {};

export default Hit;
