import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Container, Paper } from '@material-ui/core';
import { fetchGlobalPricing, storeGlobalPricing } from '../../reducers/pricing';
import CustomizeFees from '../../components/CustomizeFees';

const Index = () => {
  const dispatch = useDispatch();
  const original = useSelector(state => state.pricing.global);
  const [data, setData] = useState(null);

  const handleOnSave = () => {
    dispatch(storeGlobalPricing(data));
  };

  useEffect(() => {
    if (!original) {
      dispatch(fetchGlobalPricing());
    } else {
      setData(cloneDeep(original));
    }
  }, [original, dispatch]);

  if (!data) {
    return '';
  }

  return (
    <Container maxWidth="md">
      <Paper>
        <CustomizeFees
          fees={data}
          originalFees={original}
          defaultFees={{}}
          onSave={handleOnSave}
          onChange={setData}
        />
      </Paper>
    </Container>
  );
};

export default Index;
