import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Checkbox } from 'formik-material-ui';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import PrintIcon from '@material-ui/icons/Print';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import { CONTENT_TYPES, JAWS_TYPES } from '../../../constants';
import StatusTag from '../../../components/StatusTag';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  removePrintingRequestItemFromRequestAction,
  updatePrintingRequestItemFromRequestAction
} from '../../../reducers/requests';
import Can from '../../../components/Can';
import PatientWorkflowContext from '../../PatientWorkflow/context/PatientWorkflowContext';

const PrintingRequestItem = ({ item, requestId, patientId, allowEditMode }) => {
  const dispatch = useDispatch();
  const isFlawedTray = !!item.flawed_tray_id;
  const [mode, setMode] = useState('normal');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const request = useSelector(state => {
    return state.requests.items[
      state.requests.items.findIndex(requestItem => requestItem.id === requestId)
    ];
  });
  const patientWorkflowContext = useContext(PatientWorkflowContext);

  const handleDownloadFile = fileId => {
    dispatch(fetchDownloadUrlPrintingFileAction(fileId));
  };

  const remove = () => {
    setIsDeleting(true);
    dispatch(
      removePrintingRequestItemFromRequestAction(requestId, item.id, item.flawed_tray_id)
    ).then(response => {
      if (!response) {
        setIsDeleting(false);
      }
    });
  };

  if (!item) {
    return null;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={item}
        validationSchema={Yup.object().shape({
          insert_brackets: Yup.bool().required('Required')
        })}
        onSubmit={values => {
          setIsSaving(true);
          return dispatch(
            updatePrintingRequestItemFromRequestAction(requestId, item.id, {
              patient_id: patientId,
              ...values
            })
          ).then(response => {
            if (response) {
              setMode('normal');
            }
            setIsSaving(false);
          });
        }}
      >
        {({ submitForm, isValid, dirty }) => (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <PrintIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Box component="span" display="inline-block" width={100}>
                    {JAWS_TYPES[item.arch.jaw_type_id].name}
                  </Box>
                  {mode === 'edit' ? (
                    <FormControlLabel
                      control={
                        <Field name="insert_brackets" color="primary" component={Checkbox} />
                      }
                      label="Insert Brackets"
                    />
                  ) : (
                    item.insert_brackets && <StatusTag label="Insert Brackets" color="green" />
                  )}
                  {isFlawedTray && (
                    <>
                      <StatusTag label="Fix" color="purple" />
                    </>
                  )}
                </>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textPrimary">
                    {item.content_type_id && CONTENT_TYPES[item.content_type_id].name}
                  </Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              {item.file_id && (
                <Button
                  variant="contained"
                  onClick={() => handleDownloadFile(item.file_id)}
                  size="small"
                  startIcon={<CloudDownloadIcon />}
                >
                  Download
                </Button>
              )}
              {request.status_id === 1 && (
                <Can
                  permissions={[
                    'printing-request-items.manage',
                    'printing-request-items.update',
                    'printing-request-items.delete',
                    'organization.printing-request-items.manage',
                    'organization.printing-request-items.update',
                    'organization.printing-request-items.delete'
                  ]}
                  organizationId={get(patientWorkflowContext, 'patient.organization_id')}
                  yes={() => (
                    <Box component="span" ml={3}>
                      {mode === 'edit' && (
                        <>
                          <IconButton
                            disabled={isSaving || isDeleting || !isValid || !dirty}
                            color="primary"
                            onClick={submitForm}
                          >
                            <CheckCircleIcon />
                            {isSaving && <LoadingSpinner />}
                          </IconButton>
                        </>
                      )}
                      {allowEditMode && (
                        <IconButton
                          disabled={isDeleting || isSaving}
                          onClick={() => setMode(mode === 'edit' ? 'normal' : 'edit')}
                        >
                          {mode === 'edit' ? (
                            <CancelIcon color="secondary" />
                          ) : (
                            <EditIcon color="primary" />
                          )}
                        </IconButton>
                      )}
                      <ConfirmationDialogOnClickWrapper
                        confirmationBody="Are you sure you want to delete this item?"
                        confirmationTitle="Delete Confirmation"
                      >
                        <IconButton
                          color="secondary"
                          onClick={remove}
                          disabled={isDeleting || isSaving}
                        >
                          <DeleteIcon />
                          {isDeleting && <LoadingSpinner />}
                        </IconButton>
                      </ConfirmationDialogOnClickWrapper>
                    </Box>
                  )}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </Formik>
    </>
  );
};

PrintingRequestItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  requestId: PropTypes.number.isRequired,
  patientId: PropTypes.number.isRequired,
  allowEditMode: PropTypes.bool
};

PrintingRequestItem.defaultProps = {
  item: null,
  allowEditMode: true
};

export default PrintingRequestItem;
