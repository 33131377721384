import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { UTCDateTimeToLocalTime } from '../../../helpers';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { deleteDeviceLicenseAction } from '../../../reducers/licenses';

const LicenseDevices = ({ devices, licenseId }) => {
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  const handleOnDelete = id => {
    setSelectedDeviceId(id);
    setOpenDeleteConfirmation(true);
  };

  const handleOnCloseDeleteConfirmation = confirmed => {
    if (confirmed) {
      dispatch(deleteDeviceLicenseAction(selectedDeviceId, licenseId));
    }

    setOpenDeleteConfirmation(false);
    setSelectedDeviceId(null);
  };

  if (!devices) {
    return null;
  }

  return (
    <Card>
      <CardHeader subheader="List of the associated devices with this license" title="Devices" />
      <Divider />
      <CardContent>
        {devices.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Additional Information</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map(device => (
                <TableRow hover key={device.id}>
                  <TableCell>{device.name}</TableCell>
                  <TableCell>
                    {device.details && typeof device.details === 'object'
                      ? JSON.stringify(device.details)
                      : '-'}
                  </TableCell>
                  <TableCell>{UTCDateTimeToLocalTime(device.created_at)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOnDelete(device.id)}>
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography component="h5" variant="subtitle1">
            No Devices
          </Typography>
        )}
      </CardContent>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleOnCloseDeleteConfirmation}
        title="Delete Device"
        bodyText="Are you sure that you want to remove the device?"
      />
    </Card>
  );
};

LicenseDevices.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  devices: PropTypes.array.isRequired,
  licenseId: PropTypes.number.isRequired
};

export default LicenseDevices;
