import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import BuildIcon from '@material-ui/icons/Build';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CardActions } from '@material-ui/core';
import { updateTechWorkflowProcessesAction } from '../../../reducers/patients';
import WorkflowHandler from '../../../components/Workflows/WorkflowHandler';
import { getColorByStatus, UTCDateTimeToLocalDateTimeReadable } from '../../../helpers';
import StatusTag from '../../../components/StatusTag';
import {
  FLAWED_TRAYS_STATUSES,
  JAWS_TYPES,
  PATIENT_FILES_IDS,
  PRINT_REQUEST_TABLE_CONFIG
} from '../../../constants';
import PatientFilesApiService from '../../../services/api/patientFiles';
import { enqueueNotification } from '../../../reducers/notifications';
import {
  CANCEL_REQUEST_PREFIX,
  COMPLETE_REQUEST_PREFIX,
  correctFlawedTrayFromRequestAction,
  updateFlawedTrayFromRequestAction
} from '../../../reducers/requests';
import UploadFilesDialog from '../../../components/UploadFilesDialog';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import hasPermission from '../../../selectors/hasPermission';
import techWorkflowTypes from '../../../constants/techWorkflowTypes';
import { formatProcessesRequest } from '../../../helpers/techWorkflow';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import createLoadingSelector from '../../../selectors/loading';
import { createDesktopURI } from '../../../helpers/urls';

const useStyles = makeStyles(theme => ({
  requestHeader: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    alignItems: 'baseline'
  },
  requestActionButton: {
    width: '100%',
    maxHeight: theme.spacing(5),
    marginTop: theme.spacing(2)
  },
  tag: {
    margin: '4px 0'
  },
  requestActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const FlawedTraysWorkflow = ({
  techWorkflowId,
  flawedTrays,
  statusId,
  requestId,
  patientId,
  iosFileId,
  flawedRequestWorkflow,
  disableActionButtons,
  showActionButtons,
  handleCancel,
  handleComplete
}) => {
  const dispatch = useDispatch();
  const [targetFlawedId, setTargetFlawedId] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const classes = useStyles();
  const isInactive = !showActionButtons;

  const canManageCustomRequest = useSelector(state =>
    hasPermission(state, {
      permissions: [
        'customer-requests.manage',
        'customer-requests.complete',
        'customer-requests.update-management-data'
      ]
    })
  );

  const handleFlayedTraysUploadDialogClose = uploadedFiles => {
    if (!isEmpty(uploadedFiles) && targetFlawedId) {
      const correctedFileId = uploadedFiles[Object.keys(uploadedFiles)[0]].id;
      dispatch(correctFlawedTrayFromRequestAction(targetFlawedId, correctedFileId, requestId));
    }

    setTargetFlawedId(null);
    setUploadDialogOpen(false);
  };

  const handleDownloadFile = fileId => {
    dispatch(fetchDownloadUrlPrintingFileAction(fileId));
  };

  const removeCorrectedFile = item => {
    const patientFilesService = new PatientFilesApiService();
    patientFilesService.deleteFile(item.corrected_file_id).catch(error => {
      dispatch(enqueueNotification('error', error.message));
    });

    dispatch(
      updateFlawedTrayFromRequestAction(
        item.id,
        {
          corrected_file_id: null,
          status_id: 1
        },
        requestId
      )
    );
  };

  const renderFlawedDownloadButton = (name, correctedFileId) => {
    return (
      <Button
        key={`flawed_download_${name}`}
        variant="contained"
        onClick={() => handleDownloadFile(correctedFileId)}
        size="small"
        startIcon={<CloudDownloadIcon />}
        className={classes.requestActionButton}
      >
        DOWNLOAD {name.toUpperCase()}
      </Button>
    );
  };

  const renderFlawedRequestLabel = (primary, flawedTray) => (
    <Grid container alignItems="center" justifyContent="space-between" key={`flawed_${primary}`}>
      <Grid item>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <BuildIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={primary}
            disableTypography
            secondary={
              <div>
                <Typography component="span" variant="body2" color="textPrimary">
                  IDB Tray
                </Typography>
              </div>
            }
          />
        </ListItem>
      </Grid>
      {flawedTray.print && (
        <Grid item>
          <div className={classes.tag}>
            <StatusTag label="Print" color="orange" />
          </div>
        </Grid>
      )}
    </Grid>
  );

  const handleUpload = id => {
    setTargetFlawedId(id);
    setUploadDialogOpen(true);
  };

  const renderFlawedUploadButton = (name, flawedTrayId) => {
    return (
      <Button
        key={`flawed_upload_${name}`}
        variant="contained"
        onClick={() => handleUpload(flawedTrayId)}
        size="small"
        startIcon={<CloudUploadIcon />}
        className={classes.requestActionButton}
      >
        Upload {name.toUpperCase().substr(0, 3)}
      </Button>
    );
  };

  const renderFlawedRemoveButton = (name, item) => {
    return (
      <Button
        key={`flawed_remove_${name}`}
        variant="contained"
        color="secondary"
        onClick={() => removeCorrectedFile(item)}
        size="small"
        startIcon={<DeleteIcon />}
        className={classes.requestActionButton}
      >
        Remove {name.toUpperCase().substr(0, 3)}
      </Button>
    );
  };

  const renderFlawedActionButton = flawedTray => {
    const { name } = JAWS_TYPES[flawedTray.arch.jaw_type_id];
    const correctedFileId = flawedTray.corrected_file_id;

    if (correctedFileId) {
      return (
        <>
          {renderFlawedDownloadButton(name, correctedFileId)}
          {renderFlawedRemoveButton(name, flawedTray)}
        </>
      );
    }
    return renderFlawedUploadButton(name, flawedTray.id);
  };

  const handleOpenFile = fileId => {
    window.location.href = createDesktopURI(`open_file/${fileId}`);
  };

  return (
    <Box width="100%">
      <Grid item xs={12}>
        <Card>
          <div className={classes.requestHeader}>
            <Box>
              <Typography component="h1" variant="h5">
                Flawed Trays
              </Typography>
              <Typography component="div" variant="caption">
                {`Requested at ${UTCDateTimeToLocalDateTimeReadable(flawedTrays[0].created_at)}`}
              </Typography>
              <Typography component="div" variant="caption">
                {`Last Update ${UTCDateTimeToLocalDateTimeReadable(flawedTrays[0].updated_at)}`}
              </Typography>
            </Box>
            <Box mx={10}>
              <StatusTag
                label={FLAWED_TRAYS_STATUSES[statusId]}
                color={getColorByStatus(FLAWED_TRAYS_STATUSES[statusId])}
              />
            </Box>
          </div>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                {flawedTrays.map(flawedTray =>
                  renderFlawedRequestLabel(JAWS_TYPES[flawedTray.arch.jaw_type_id].name, flawedTray)
                )}
              </Grid>
              <Grid item xs={7}>
                {canManageCustomRequest && (
                  <WorkflowHandler
                    update={data =>
                      dispatch(
                        updateTechWorkflowProcessesAction(
                          patientId,
                          techWorkflowId,
                          techWorkflowTypes.FLAWED_TRAY,
                          formatProcessesRequest(data)
                        )
                      )
                    }
                    field="tech_workflow"
                    title="Tech Workflow Table"
                    workflowData={flawedRequestWorkflow}
                    columnsData={PRINT_REQUEST_TABLE_CONFIG}
                    readOnly={isInactive}
                  />
                )}
              </Grid>
              <Grid item xs={2} className={classes.requestActions}>
                {iosFileId && (
                  <Button
                    key="flawed_tray_download_ios"
                    variant="contained"
                    onClick={() => handleOpenFile(iosFileId)}
                    size="small"
                    startIcon={<LaunchIcon />}
                    className={classes.requestActionButton}
                  >
                    Open
                  </Button>
                )}
                {!isInactive &&
                  canManageCustomRequest &&
                  flawedTrays.map(flawedTray => renderFlawedActionButton(flawedTray))}
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            {showActionButtons && (
              <>
                <ConfirmationDialogOnClickWrapper
                  confirmationTitle="Cancel Request"
                  confirmationBody="Are you sure you want to cancel this request?"
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    disabled={disableActionButtons}
                    className={classes.actionButton}
                    onClick={() => {
                      handleCancel({ flawed_tray_only: true });
                    }}
                  >
                    Cancel
                  </Button>
                </ConfirmationDialogOnClickWrapper>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={disableActionButtons}
                  className={classes.actionButton}
                  onClick={() => {
                    handleComplete({ flawed_tray_only: true });
                  }}
                >
                  Complete
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
      <UploadFilesDialog
        title="Corrected File"
        patientFileTypeId={PATIENT_FILES_IDS.stl}
        patientId={patientId}
        maxFiles={1}
        handleClose={handleFlayedTraysUploadDialogClose}
        open={uploadDialogOpen}
      />
    </Box>
  );
};

FlawedTraysWorkflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  flawedTrays: PropTypes.array.isRequired,
  statusId: PropTypes.number.isRequired,
  requestId: PropTypes.number.isRequired,
  patientId: PropTypes.number.isRequired,
  iosFileId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  flawedRequestWorkflow: PropTypes.array.isRequired,
  disableActionButtons: PropTypes.bool.isRequired,
  showActionButtons: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleComplete: PropTypes.func.isRequired,
  techWorkflowId: PropTypes.number.isRequired
};

FlawedTraysWorkflow.defaultProps = {
  iosFileId: null
};

export default connect((state, { showActionButtons, disableActionButtons }) => {
  const isCompleting = createLoadingSelector([COMPLETE_REQUEST_PREFIX])(state);
  const isCancelling = createLoadingSelector([CANCEL_REQUEST_PREFIX])(state);
  const canManageCustomRequest = hasPermission(state, {
    permissions: [
      'customer-requests.manage',
      'customer-requests.complete',
      'customer-requests.update-management-data'
    ]
  });
  return {
    disableActionButtons: disableActionButtons || isCompleting || isCancelling,
    showActionButtons: showActionButtons && canManageCustomRequest,
    showWorkflowTable: canManageCustomRequest
  };
})(FlawedTraysWorkflow);
