import React from 'react';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { ErrorMessage, Field } from 'formik';
import InputErrorMessage from '../InputErrorMessage';

const DatePicker = ({ name, label, required, pickerProps }) => {
  return (
    <Field name={name} id={name}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              {...pickerProps}
              variant="inline"
              autoOk
              fullWidth
              required={required}
              disableToolbar
              label={label}
              inputVariant="outlined"
              format="YYYY-MM-DD"
              value={value}
              onBlur={() => setFieldTouched(name, true, true)}
              onChange={newValue => {
                setFieldValue(
                  name,
                  moment.isMoment(newValue) ? newValue.format('YYYY-MM-DD') : newValue
                );
                setFieldTouched(name, true, false);
              }}
            />
            <ErrorMessage
              name={name}
              render={message => <InputErrorMessage>{message}</InputErrorMessage>}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      )}
    </Field>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickerProps: PropTypes.object
};

DatePicker.defaultProps = {
  required: false,
  pickerProps: {}
};

export default DatePicker;
