import { produce } from 'immer';
import OrganizationsApiService from '../services/api/organizations';

const FETCHING_RXFORM_PREFERENCE = 'FETCHING_RXFORM_PREFERENCE';
const FETCHED_RXFORM_PREFERENCE = 'FETCHED_RXFORM_PREFERENCE';

const ACTIONS = [FETCHING_RXFORM_PREFERENCE, FETCHED_RXFORM_PREFERENCE];

const initialState = {
  organizations: {}
};

export default (state = initialState, { type, payload }) => {
  if (ACTIONS.indexOf(type) === -1) {
    return state;
  }

  const organizationId = payload.organization_id;
  const organizationState = state.organizations[organizationId] || {
    fetching: false,
    preference: null,
    third_party_partner: null,
    shipping_methods: []
  };

  switch (type) {
    case FETCHING_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: true,
          preference: null,
          third_party_partner: null,
          shipping_methods: []
        };
      });
    case FETCHED_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: false,
          ...payload
        };
      });
    default:
      return state;
  }
};

export function fetchRxFormPreference(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_RXFORM_PREFERENCE, payload: { organization_id: organizationId } });
    const api = new OrganizationsApiService();
    api
      .getRxFormPreference(organizationId)
      .then(
        ({
          data: preference,
          metadata: { third_party_partner: thirdPartyPartner, shipping_methods: shippingMethods }
        }) => {
          dispatch({
            type: FETCHED_RXFORM_PREFERENCE,
            payload: {
              organization_id: organizationId,
              preference,
              third_party_partner: thirdPartyPartner,
              shipping_methods: shippingMethods
            }
          });
        }
      );
  };
}
