import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PeriodicRuleForm from './components/PeriodicRuleForm';
import createLoadingSelector from '../../selectors/loading';
import { CREATE_PERIODIC_RULE_PREFIX, createPeriodicRuleAction } from '../../reducers/periodicRule';
import createErrorMessageSelector from '../../selectors/error';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const AddPeriodicRule = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const contractId = parseInt(params.contract_id, 10);
  const hasMount = useRef(false);
  const isCreatingPeriodicRule = useSelector(state =>
    createLoadingSelector([CREATE_PERIODIC_RULE_PREFIX])(state)
  );
  const errorOnCreating = useSelector(state =>
    createErrorMessageSelector([CREATE_PERIODIC_RULE_PREFIX])(state)
  );

  useEffect(() => {
    if (hasMount.current && !isCreatingPeriodicRule && !errorOnCreating) {
      history.goBack();
    } else {
      hasMount.current = true;
    }
  }, [isCreatingPeriodicRule, errorOnCreating, history, contractId]);

  const createPeriodicRule = values => {
    dispatch(createPeriodicRuleAction(values));
    return true;
  };

  return (
    <Card className={classes.form}>
      <CardHeader title="Create Periodic Rule" />
      <Divider />
      <PeriodicRuleForm
        onSubmit={createPeriodicRule}
        isLoading={isCreatingPeriodicRule}
        contractId={contractId}
      />
    </Card>
  );
};

AddPeriodicRule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

AddPeriodicRule.defaultProps = {
  match: { params: {} }
};
export default AddPeriodicRule;
