import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import PromotionCodesApiService from '../services/api/promotionCodes';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_PROMOTION_CODES_PREFIX = 'FETCH_PROMOTION_CODES';
export const FETCH_PROMOTION_CODES_REQUEST_ACTION = `${FETCH_PROMOTION_CODES_PREFIX}_REQUEST_ACTION`;
export const FETCH_PROMOTION_CODES_SUCCESS_ACTION = `${FETCH_PROMOTION_CODES_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PROMOTION_CODES_FAILURE_ACTION = `${FETCH_PROMOTION_CODES_PREFIX}_FAILURE_ACTION`;

export const CREATE_PROMOTION_CODE_PREFIX = 'CREATE_PROMOTION_CODE';
export const CREATE_PROMOTION_CODE_REQUEST_ACTION = `${CREATE_PROMOTION_CODE_PREFIX}_REQUEST_ACTION`;
export const CREATE_PROMOTION_CODE_SUCCESS_ACTION = `${CREATE_PROMOTION_CODE_PREFIX}_SUCCESS_ACTION`;
export const CREATE_PROMOTION_CODE_FAILURE_ACTION = `${CREATE_PROMOTION_CODE_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PROMOTION_CODE_PREFIX = 'UPDATE_PROMOTION_CODE';
export const UPDATE_PROMOTION_CODE_REQUEST_ACTION = `${UPDATE_PROMOTION_CODE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PROMOTION_CODE_SUCCESS_ACTION = `${UPDATE_PROMOTION_CODE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PROMOTION_CODE_FAILURE_ACTION = `${UPDATE_PROMOTION_CODE_PREFIX}_FAILURE_ACTION`;

export const DELETE_PROMOTION_CODE_PREFIX = 'DELETE_PROMOTION_CODE';
export const DELETE_PROMOTION_CODE_REQUEST_ACTION = `${DELETE_PROMOTION_CODE_PREFIX}_REQUEST_ACTION`;
export const DELETE_PROMOTION_CODE_SUCCESS_ACTION = `${DELETE_PROMOTION_CODE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_PROMOTION_CODE_FAILURE_ACTION = `${DELETE_PROMOTION_CODE_PREFIX}_FAILURE_ACTION`;

export const FETCH_PROMOTION_CODE_PREFIX = 'FETCH_PROMOTION_CODE';
export const FETCH_PROMOTION_CODE_REQUEST_ACTION = `${FETCH_PROMOTION_CODE_PREFIX}_REQUEST_ACTION`;
export const FETCH_PROMOTION_CODE_SUCCESS_ACTION = `${FETCH_PROMOTION_CODE_PREFIX}_SUCCESS_ACTION`;
export const FETCH_PROMOTION_CODE_FAILURE_ACTION = `${FETCH_PROMOTION_CODE_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    selectedStatus: '',
    order: 'desc',
    orderBy: 'expires_at',
    page: 1,
    perPage: 25,
    code: ''
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOTION_CODES_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.promotion_codes.data,
        total: action.payload.promotion_codes.total,
        globalParams: action.payload.params
      };
    case FETCH_PROMOTION_CODE_SUCCESS_ACTION:
      return produce(state, draft => {
        const promotionCodeIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (promotionCodeIndex > -1) {
          draft.items[promotionCodeIndex] = action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_PROMOTION_CODE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_PROMOTION_CODE_SUCCESS_ACTION:
      return produce(state, draft => {
        const promotionCodeIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[promotionCodeIndex] = action.payload;
      });
    case DELETE_PROMOTION_CODE_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchPromotionCodesAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_PROMOTION_CODES_REQUEST_ACTION });
    const promotionCodesApiService = new PromotionCodesApiService();
    return promotionCodesApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_PROMOTION_CODES_SUCCESS_ACTION,
          payload: { promotion_codes: response.promotion_codes, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PROMOTION_CODES_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchPromotionCodeAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_PROMOTION_CODE_REQUEST_ACTION });
    const promotionCodesApiService = new PromotionCodesApiService();
    return promotionCodesApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_PROMOTION_CODE_SUCCESS_ACTION,
          payload: response.promotion_code
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_PROMOTION_CODE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createPromotionCodeAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_PROMOTION_CODE_REQUEST_ACTION });
    const promotionCodesApiService = new PromotionCodesApiService();
    return promotionCodesApiService
      .create(params)
      .then(response => {
        dispatch({
          type: CREATE_PROMOTION_CODE_SUCCESS_ACTION,
          payload: response.promotion_code
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: CREATE_PROMOTION_CODE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updatePromotionCodeAction(params, id) {
  return dispatch => {
    dispatch({ type: UPDATE_PROMOTION_CODE_REQUEST_ACTION });
    const promotionCodesApiService = new PromotionCodesApiService();
    return promotionCodesApiService
      .update(params, id)
      .then(response => {
        dispatch({
          type: UPDATE_PROMOTION_CODE_SUCCESS_ACTION,
          payload: response.promotion_code
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_PROMOTION_CODE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function deletePromotionCodeAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_PROMOTION_CODE_REQUEST_ACTION });
    const promotionCodesApiService = new PromotionCodesApiService();
    return promotionCodesApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_PROMOTION_CODE_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_PROMOTION_CODE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function validatePromotionCodeAction(code, promotionCodeType) {
  return dispatch => {
    const PromotionCodesService = new PromotionCodesApiService();
    return PromotionCodesService.validatePromotionCode(code, promotionCodeType)
      .then(response => {
        return response.promotion_code;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
