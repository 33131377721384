import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paper, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { UTCDateTimeToLocalTime } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  noFoundChip: {
    marginLeft: theme.spacing(2)
  }
}));

const TreatmentNotes = ({ treatmentNotes }) => {
  const classes = useStyles();

  if (!treatmentNotes.length) {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="subtitle1">Treatment Notes</Typography>
          <Chip size="small" className={classes.noFoundChip} label="No treatment notes found" />
        </Toolbar>
      </Paper>
    );
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Treatment Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {treatmentNotes.map(treatmentNote => {
            return (
              <Fragment key={treatmentNote.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={UTCDateTimeToLocalTime(treatmentNote.updated_at)}
                    secondary={treatmentNote.note}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Fragment>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

TreatmentNotes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treatmentNotes: PropTypes.array.isRequired
};

export default TreatmentNotes;
