import { produce } from 'immer';
import PricingApiService from '../services/api/pricing';

export const FETCHING_ORGANIZATION_PRICING = 'FETCHING_ORGANIZATION_PRICING';
export const FETCHED_ORGANIZATION_PRICING = 'FETCHED_ORGANIZATION_PRICING';

const initialState = {
  organizations: {}
};

export default (state = initialState, { type, payload = null }) => {
  const organizationId = payload ? payload.organization_id : -1;
  const organizationState = state.organizations[organizationId] || {
    fetching: false
  };

  switch (type) {
    case FETCHING_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: true
        };
      });

    case FETCHED_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: false,
          ...payload
        };
      });
    default:
      return state;
  }
};

export function fetchOrganizationPricing(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_ORGANIZATION_PRICING, payload: { organization_id: organizationId } });

    const pricingService = new PricingApiService();
    pricingService.getOrganization(organizationId).then(({ data: pricing }) => {
      dispatch({
        type: FETCHED_ORGANIZATION_PRICING,
        payload: {
          organization_id: organizationId,
          ...pricing
        }
      });
    });
  };
}
