import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import SaveIcon from '@material-ui/icons/Save';
import hasPermission from '../../../selectors/hasPermission';
import { updateMetadata } from '../../../reducers/onboardings';
import { enqueueNotification } from '../../../reducers/notifications';

const useSubChecklistStyles = makeStyles(() => ({
  childStep: {
    padding: 10,
    '& .MuiStepConnector-lineVertical': {
      minHeight: 12
    }
  },
  subStepLabel: {
    '& .MuiStepLabel-labelContainer span': {
      fontSize: 16,
      fontWeight: 600
    }
  }
}));

const SubStepIcon = ({ active, tipProps, completed, stepIndex, setActiveStep }) => {
  const color = active ? 'primary' : 'disabled';

  const handleClick = () => {
    if (!active) {
      return;
    }
    setActiveStep(stepIndex);
  };

  if (completed) {
    return <CheckCircleRounded color="primary" />;
  }
  return (
    <CheckItemToolTip {...tipProps}>
      <RadioButtonUncheckedRounded onClick={handleClick} color={color} />
    </CheckItemToolTip>
  );
};

SubStepIcon.defaultProps = {
  tip: null,
  beforeClick: null
};

SubStepIcon.propTypes = {
  tip: PropTypes.string,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  stepIndex: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  beforeClick: PropTypes.func
};

const SubStep = ({
  stepIndex,
  title,
  content,
  editable,
  tip,
  setActiveStep,
  onboarding,
  input,
  className,
  completed,
  meta,
  ...props
}) => {
  const isManagement = useSelector(state => state.auth.isManagement);
  const initialToolTipProps = !isManagement ? { title: tip } : {};
  const [toolTip, setToolTip] = useState(initialToolTipProps);
  const hasRequiredInput = editable && input && input.required;

  useEffect(() => {
    if (!hasRequiredInput) {
      return;
    }
    const value = (onboarding.metadata && onboarding.metadata[input.metadataKey]) || '';
    // revert old tooltip
    if (value && toolTip.title !== initialToolTipProps.title) {
      setToolTip({ ...initialToolTipProps });
    }
  }, [onboarding, toolTip, setToolTip, hasRequiredInput, tip, input, initialToolTipProps]);

  const handleSetActiveStep = value => {
    if (hasRequiredInput) {
      const value = (onboarding.metadata && onboarding.metadata[input.metadataKey]) || '';
      if (!value) {
        setToolTip({ title: `${input.label} is required.`, open: true });
        return;
      }
    }
    setActiveStep(value);
  };
  return (
    <Step key={title} expanded completed={completed} {...props}>
      <StepLabel
        className={className}
        StepIconComponent={SubStepIcon}
        StepIconProps={{
          stepIndex,
          setActiveStep: handleSetActiveStep,
          tipProps: toolTip
        }}
      >
        {title}
      </StepLabel>
      <StepContent>
        <Box>
          {() => {
            return typeof content === 'function' ? content(meta) : content;
          }}
        </Box>
        {input && (
          <Box marginTop={2}>
            <StepInput onboarding={onboarding} readOnly={!editable} {...input} />
          </Box>
        )}
      </StepContent>
    </Step>
  );
};

const SubChecklist = ({ onboarding, steps, activeStep, setActiveStep, isSubStepCompletedFunc }) => {
  const meta = useSelector(state => state.onboardings.meta);
  const classes = useSubChecklistStyles();
  const editable = useSelector(state =>
    hasPermission(state, { permissions: ['onboardings.update-metadata', 'onboardings.manage'] })
  );

  return (
    <Stepper activeStep={activeStep} orientation="vertical" className={classes.childStep}>
      {steps.map((childStep, childStepIndex) => (
        <SubStep
          key={childStep.title}
          className={classes.subStepLabel}
          meta={meta}
          editable={editable}
          stepIndex={childStepIndex}
          setActiveStep={setActiveStep}
          completed={isSubStepCompletedFunc(childStepIndex)}
          onboarding={onboarding}
          {...childStep}
        />
      ))}
    </Stepper>
  );
};

SubChecklist.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  isSubStepCompletedFunc: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onboarding: PropTypes.object.isRequired
};

const StepInput = ({
  onboarding,
  label,
  type,
  metadataKey,
  required,
  validation,
  readOnly,
  helperText,
  style
}) => {
  const value = (onboarding.metadata && onboarding.metadata[metadataKey]) || '';
  const dispatch = useDispatch();

  const handleSave = values => {
    dispatch(
      updateMetadata(onboarding, metadataKey, values[metadataKey], () => {
        dispatch(enqueueNotification('success', `Onboarding details successfully updated.`));
      })
    );
    return true;
  };

  const fieldProps =
    type === 'date'
      ? {
          type: 'date',
          InputLabelProps: {
            shrink: true
          }
        }
      : {
          type: 'text'
        };
  const formProps = {};
  if (!readOnly && validation) {
    formProps.validationSchema = Yup.object().shape({
      [metadataKey]: validation
    });
  }
  return (
    <>
      <Formik
        initialValues={{ [metadataKey]: value }}
        enableReinitialize
        onSubmit={handleSave}
        {...formProps}
      >
        {isValid => (
          <Form noValidate>
            <Field
              label={label}
              type={type}
              name={metadataKey}
              placeholder={label}
              size="small"
              variant="outlined"
              component={TextField}
              helperText={helperText}
              InputProps={{
                readOnly
              }}
              style={style}
              {...fieldProps}
            />
            {!readOnly && (
              <IconButton
                disabled={!isValid}
                type="submit"
                aria-label="delete"
                size="small"
                style={{ marginTop: '.3em' }}
              >
                <SaveIcon />
              </IconButton>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

StepInput.defaultProps = {
  validation: null,
  helperText: null,
  style: {}
};

StepInput.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  metadataKey: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onboarding: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  readOnly: PropTypes.bool.isRequired
};

const CheckItemToolTip = ({ view, children, ...props }) => {
  if (!props.title) {
    return children;
  }
  return (
    <Tooltip enterDelay={200} leaveDelay={200} {...props}>
      {children}
    </Tooltip>
  );
};

CheckItemToolTip.propTypes = {
  children: PropTypes.element.isRequired
};

export default SubChecklist;
