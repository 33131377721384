import { useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getTokenHeader } from '../../helpers/connection';
import {
  addUserNotificationAction,
  fetchNotificationsAction,
  updateUserNotificationAction
} from '../../reducers/auth';
import { NOTIFICATION_TYPES } from './constants';
import { updateAnnouncementLocally } from '../../reducers/annoucement';
import { isTechnicianOrAdmin } from '../../helpers';

const WSNotificationHandler = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotificationsAction());
  }, [dispatch]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      Pusher.logToConsole = true;
    }

    const options = {
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_KEY,
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      forceTLS: true,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          ...getTokenHeader(),
          accept: 'application/json'
        }
      }
    };

    const echo = new Echo(options);

    if (isTechnicianOrAdmin(user)) {
      echo.private('internal').listen('.announcement.updated', e => {
        dispatch(updateAnnouncementLocally(e.announcement));
      });
    }

    echo.private('general').listen('.announcement.updated', e => {
      dispatch(updateAnnouncementLocally(e.announcement));
    });

    echo.private(`App.User.${user.id}`).notification(data => {
      switch (data.type) {
        case NOTIFICATION_TYPES.PATIENT_SETUP_COMPLETED:
          dispatch(addUserNotificationAction(data, '/dashboard'));
          break;
        case NOTIFICATION_TYPES.RUSH_CASE:
          dispatch(addUserNotificationAction(data, `/patient-details/${data.patient_id}`));
          break;
        case NOTIFICATION_TYPES.UPDATED_NOTIFICATION:
          dispatch(updateUserNotificationAction(data.data, `/patient-details/${data.patient_id}`));
          break;
        default:
          break;
      }
    });

    return () => {
      echo.disconnect();
    };
  }, [user, user.id, dispatch]);

  return null;
};
WSNotificationHandler.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any
};

WSNotificationHandler.defaultProps = {
  user: {}
};

export default WSNotificationHandler;
