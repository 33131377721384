// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const MODAL_PREFIX = 'MODAL';
export const MODAL_PATIENT_MODIFICATION_WARNING_OPEN_ACTION = `${MODAL_PREFIX}_PATIENT_MODIFICATION_WARNING_OPEN_ACTION`;
export const MODAL_PATIENT_MODIFICATION_WARNING_CLOSE_ACTION = `${MODAL_PREFIX}_PATIENT_MODIFICATION_WARNING_CLOSE_ACTION`;
export const MODAL_IOS_3D_VIEWER_WARNING_OPEN_ACTION = `${MODAL_PREFIX}_IOS_3D_VIEWER_WARNING_OPEN_ACTION`;
export const MODAL_IOS_3D_VIEWER_WARNING_CLOSE_ACTION = `${MODAL_PREFIX}_IOS_3D_VIEWER_WARNING_CLOSE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  IS_OPEN_PATIENT_MODIFICATION_WARNING: false,
  IS_IOS_3D_VIEWER_WARNING: false,
  PATIENT_MODIFICATION_WARNING_ID: ''
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_PATIENT_MODIFICATION_WARNING_OPEN_ACTION:
    case MODAL_PATIENT_MODIFICATION_WARNING_CLOSE_ACTION:
      return {
        ...state,
        IS_OPEN_PATIENT_MODIFICATION_WARNING: action.payload.open,
        PATIENT_MODIFICATION_WARNING_ID: action.payload.id
      };
    case MODAL_IOS_3D_VIEWER_WARNING_OPEN_ACTION:
    case MODAL_IOS_3D_VIEWER_WARNING_CLOSE_ACTION:
      return {
        ...state,
        IS_IOS_3D_VIEWER_WARNING: action.payload.open
      };

    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function openPatientModificationWarning(id) {
  return dispatch => {
    dispatch({
      type: MODAL_PATIENT_MODIFICATION_WARNING_OPEN_ACTION,
      payload: { open: true, id }
    });
  };
}

export function closePatientModificationWarning(id) {
  return dispatch => {
    dispatch({
      type: MODAL_PATIENT_MODIFICATION_WARNING_CLOSE_ACTION,
      payload: { open: false, id }
    });
  };
}

export function openIOS3DViewerWarning() {
  return dispatch => {
    dispatch({
      type: MODAL_IOS_3D_VIEWER_WARNING_OPEN_ACTION,
      payload: { open: true }
    });
  };
}

export function closeIOS3DViewerWarning() {
  return dispatch => {
    dispatch({
      type: MODAL_IOS_3D_VIEWER_WARNING_CLOSE_ACTION,
      payload: { open: false }
    });
  };
}
