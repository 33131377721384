import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import OrdersApiService from '../services/api/orders';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const FETCH_ORDER_PREFIX = 'FETCH_ORDER';
export const FETCH_ORDER_REQUEST_ACTION = `${FETCH_ORDER_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORDER_SUCCESS_ACTION = `${FETCH_ORDER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORDER_FAILURE_ACTION = `${FETCH_ORDER_PREFIX}_FAILURE_ACTION`;

export const FETCH_CURRENT_ORDER_PREFIX = 'FETCH_CURRENT_ORDER';
export const FETCH_CURRENT_ORDER_REQUEST_ACTION = `${FETCH_CURRENT_ORDER_PREFIX}_REQUEST_ACTION`;
export const FETCH_CURRENT_ORDER_SUCCESS_ACTION = `${FETCH_CURRENT_ORDER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CURRENT_ORDER_FAILURE_ACTION = `${FETCH_CURRENT_ORDER_PREFIX}_FAILURE_ACTION`;

export const ADD_DISCOUNT_OR_TO_ORDER_PREFIX = 'ADD_DISCOUNT_OR_TO_ORDER_PREFIX';
export const ADD_DISCOUNT_OR_TO_ORDER_REQUEST_ACTION = `${ADD_DISCOUNT_OR_TO_ORDER_PREFIX}_REQUEST_ACTION`;
export const ADD_DISCOUNT_OR_TO_ORDER_SUCCESS_ACTION = `${ADD_DISCOUNT_OR_TO_ORDER_PREFIX}_SUCCESS_ACTION`;
export const ADD_DISCOUNT_OR_TO_ORDER_FAILURE_ACTION = `${ADD_DISCOUNT_OR_TO_ORDER_PREFIX}_FAILURE_ACTION`;

export const REMOVE_DISCOUNT_TO_ORDER_PREFIX = 'REMOVE_DISCOUNT_TO_ORDER';
export const REMOVE_DISCOUNT_TO_ORDER_REQUEST_ACTION = `${REMOVE_DISCOUNT_TO_ORDER_PREFIX}_REQUEST_ACTION`;
export const REMOVE_DISCOUNT_TO_ORDER_SUCCESS_ACTION = `${REMOVE_DISCOUNT_TO_ORDER_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_DISCOUNT_TO_ORDER_FAILURE_ACTION = `${REMOVE_DISCOUNT_TO_ORDER_PREFIX}_FAILURE_ACTION`;

export const REMOVE_CHARGE_TO_ORDER_PREFIX = 'REMOVE_CHARGE_TO_ORDER';
export const REMOVE_CHARGE_TO_ORDER_REQUEST_ACTION = `${REMOVE_CHARGE_TO_ORDER_PREFIX}_REQUEST_ACTION`;
export const REMOVE_CHARGE_TO_ORDER_SUCCESS_ACTION = `${REMOVE_CHARGE_TO_ORDER_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_CHARGE_TO_ORDER_FAILURE_ACTION = `${REMOVE_CHARGE_TO_ORDER_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  currentOrder: null
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_SUCCESS_ACTION:
      return produce(state, draft => {
        const orderIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (orderIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case FETCH_CURRENT_ORDER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrder = action.payload;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchOrderAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORDER_REQUEST_ACTION });
    const ordersApiService = new OrdersApiService();
    return ordersApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_ORDER_SUCCESS_ACTION,
          payload: {
            ...response.order,
            template_data: response.metadata ? response.metadata.template_data : null
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchCurrentOrderAction(organizationId) {
  return dispatch => {
    dispatch({ type: FETCH_CURRENT_ORDER_REQUEST_ACTION });
    const ordersApiService = new OrdersApiService();
    return ordersApiService
      .getCurrentOrder(organizationId)
      .then(response => {
        dispatch({
          type: FETCH_CURRENT_ORDER_SUCCESS_ACTION,
          payload: {
            ...response.order,
            template_data: response.metadata ? response.metadata.template_data : null
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CURRENT_ORDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function addDiscountOrChargeToOrderAction(orderId, data) {
  return dispatch => {
    dispatch({ type: ADD_DISCOUNT_OR_TO_ORDER_REQUEST_ACTION });
    const ordersApiService = new OrdersApiService();
    return ordersApiService
      .addDiscountOrChargeToOrder(orderId, data)
      .then(response => {
        dispatch({
          type: ADD_DISCOUNT_OR_TO_ORDER_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ADD_DISCOUNT_OR_TO_ORDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeDiscountFromOrderAction(orderId, discountId) {
  return dispatch => {
    dispatch({ type: REMOVE_DISCOUNT_TO_ORDER_REQUEST_ACTION });
    const ordersApiService = new OrdersApiService();
    return ordersApiService
      .removeDiscountToOrder(orderId, discountId)
      .then(response => {
        dispatch({
          type: REMOVE_DISCOUNT_TO_ORDER_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_DISCOUNT_TO_ORDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeChargeFromOrderAction(orderId, chargeId) {
  return dispatch => {
    dispatch({ type: REMOVE_CHARGE_TO_ORDER_REQUEST_ACTION });
    const ordersApiService = new OrdersApiService();
    return ordersApiService
      .removeChargeToOrder(orderId, chargeId)
      .then(response => {
        dispatch({
          type: REMOVE_CHARGE_TO_ORDER_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_CHARGE_TO_ORDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
