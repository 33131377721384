import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class OrganizationsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    searchString = '',
    customerServiceManager = '',
    orderBy,
    includes = []
  }) {
    const orderChar = order === 'desc' ? '+' : '-';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .where('search', searchString)
        .where('organization_type', customerServiceManager)
        .for('organizations')
        .include(...includes)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id, includes = []) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}`,
      params: {
        include: includes.join(',')
      }
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/organizations',
      data
    });
  }

  update(data, id) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/organizations/${id}`
    });
  }

  inviteMember(id, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/invite`,
      data
    });
  }

  addMember(id, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/add_member`,
      data
    });
  }

  removeMember(id, userId) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/remove_member`,
      data: {
        user_id: userId
      }
    });
  }

  changeMemberRole(id, userId, role) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/change_role`,
      data: {
        user_id: userId,
        role
      }
    });
  }

  getOrganizationMembers(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/list_members`
    });
  }

  getOrganizationOrders(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/list_orders`
    });
  }

  getOrganizationUserPermissions(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/current_user_permissions`
    });
  }

  getOrganizationAssignedLocations(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/list_assigned_locations`
    });
  }

  updateBillingAddress(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/billing_address`,
      data
    });
  }

  sendInviteToCreateOrganization(data) {
    return this.request({
      method: 'post',
      url: `/organizations/send_invite_to_create`,
      data
    });
  }

  getOrganizationInitialSetupConfiguration(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/initial_setup_configuration`
    });
  }

  updateOrganizationInitialSetupConfiguration(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/initial_setup_configuration`,
      data
    });
  }

  getOrganizationPrintRequestConfiguration(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/print_request_configuration`
    });
  }

  updateOrganizationPrintRequestConfiguration(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/print_request_configuration`,
      data
    });
  }

  updateAccountInfo(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/account-details`,
      data
    });
  }

  getRxFormPreference(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/rx_form_preference`
    });
  }

  updateRxFormPreference(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/rx_form_preference`,
      data
    });
  }

  getNotificationSettings(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/notification-settings`
    });
  }

  updateNotificationSettings(id, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/notification-settings`,
      data
    });
  }

  getBracketLibraries(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/bracket-libraries`
    });
  }

  getBracketLibrariesForUser(organizationId, userId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries`,
      params: {
        member_id: userId
      }
    });
  }

  getBracketLibraryAssignableMembers(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/bracket-libraries/assignable-members`
    });
  }

  getUploadUrlForBracketLibraries(id, filename) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/bracket-libraries/request-upload-url`,
      data: { file_name: filename }
    });
  }

  storeBracketLibrary(id, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/bracket-libraries`,
      data
    });
  }

  updateBracketLibrary(oraganizationId, bracketLibraryId, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${oraganizationId}/bracket-libraries/${bracketLibraryId}`,
      data
    });
  }

  deleteBracketLibrary(id, itemId) {
    return this.request({
      method: 'delete',
      url: `/organizations/${id}/bracket-libraries/${itemId}`
    });
  }

  getBracketLibraryDownloadUrl(id, itemId) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/bracket-libraries/${itemId}/request-download-url`
    });
  }

  saveCredits(id, value, validFrom = null, validTo = null) {
    return this.request({
      method: 'post',
      url: `/organizations/${id}/credits`,
      data: {
        value,
        valid_from: validFrom,
        valid_to: validTo
      }
    });
  }

  getContacts(id) {
    return this.request({
      method: 'get',
      url: `/organizations/${id}/contacts`
    }).then(response => {
      // eslint-disable-next-line no-param-reassign
      response.contacts = OrganizationsApiService.mappingContacts(response.contacts);
      return response;
    });
  }

  updateContact(id, contactsParam) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/contacts`,
      data: { contacts: contactsParam }
    }).then(response => {
      // eslint-disable-next-line no-param-reassign
      response.contacts = OrganizationsApiService.mappingContacts(response.contacts);
      return response;
    });
  }

  static mappingContacts(contacts) {
    // eslint-disable-next-line no-restricted-syntax
    for (const contact of contacts) {
      contact.is_create_account = !!contact.user_id;
      contact.email = contact.email || '';
      contact.phone_number = contact.phone_number || '';
      contact.notes = contact.notes || '';
    }
    return contacts;
  }

  archive(id) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/archive`
    });
  }

  unArchive(id) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/un-archive`
    });
  }

  setNoStartupFee(id) {
    return this.request({
      method: 'put',
      url: `/organizations/${id}/no-startup-fee`
    });
  }
}
