import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Workflow from '../Workflow';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from '../../reducers/notifications';

const WorkflowHandler = ({ update, ...rest }) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const save = data => {
    setIsSaving(true);
    update(data).then(() => {
      setIsSaving(false);
    }).catch(error => {
      setIsSaving(false);
      dispatch(enqueueNotification('error', error.message));
    });
  };

  return <Workflow isSaving={isSaving} save={save} {...rest} />;
};

WorkflowHandler.propTypes = {
  update: PropTypes.func
};

WorkflowHandler.defaultProps = {
  update: null
};

export default WorkflowHandler;
