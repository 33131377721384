import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { fetchDashboardDataAction } from '../../../reducers/dashboard';
import Filters from './Filters';
import Content from './Content';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    background: '#f5f5f5'
  }
}));

export default function AdminDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalParams = useSelector(state => state.dashboard.globalParams);
  const resetAfterSync = useSelector(state => state.dashboard.globalParams.resetPage);
  const isLoading = useSelector(state => state.loading.FETCH_DASHBOARD_DATA);
  const data = useSelector(state => state.dashboard.items);
  const [additionalParams, setAdditionalParams] = useState({
    params: {
      dateFilterFrom: globalParams.dateFilterFrom,
      dateFilterTo: globalParams.dateFilterTo,
      timeFilter: globalParams.timeFilter
    },
    resetPage: false
  });

  useEffect(() => {
    const { params } = additionalParams;
    dispatch(fetchDashboardDataAction(params));
  }, [dispatch, additionalParams, resetAfterSync]);

  if (!data || !data.statistics) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <Filters additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} />
      <Content data={data} isLoading={isLoading} />
    </Grid>
  );
}
