import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { produce } from 'immer';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import InputLabel from '@material-ui/core/InputLabel';
import { Button } from '@material-ui/core';
import { Select } from 'antd';
import createLoadingSelector from '../../selectors/loading';
import DataTable from '../../components/DataTable';
import { FETCH_USERS_PREFIX, fetchUsersAction } from '../../reducers/users';
import SearchInput from '../../components/SearchInput';
import { ROLE_OPTIONS } from '../../constants';
import Can from '../../components/Can';
import Content from '../../components/Layout/Content';

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginBottom: theme.spacing(2)
  },
  role: {
    textTransform: 'capitalize'
  }
}));

const Users = ({ location, match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(state => createLoadingSelector([FETCH_USERS_PREFIX])(state));
  const { Option } = Select;
  const globalParams = useSelector(state => state.users.globalParams);
  const rows = useSelector(state => state.users.items);
  const totalUsers = useSelector(state => state.users.total);
  const urlQueryParams = new URLSearchParams(location.search);
  const queryOrganization = urlQueryParams.get('queryOrganization');
  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const handleUpdateData = useCallback(
    parameters => {
      const filterParams = {
        ...parameters,
        organizationId: queryOrganization
      };

      dispatch(fetchUsersAction(filterParams));
    },
    [dispatch, queryOrganization]
  );

  const handleOnChangeSearchInput = debounce(value => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.searchFullNameEmailQuery = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleUserDetails = id => {
    history.push(`/users/${id}`);
  };

  const openCreate = () => {
    history.push('/users/add');
  };

  const columns = [
    { id: 'full_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'created_at', numeric: false, type: 'datetime', disablePadding: false, label: 'Sign-up' },
    {
      id: 'roles[0].name',
      numeric: false,
      disablePadding: false,
      label: 'Role',
      className: classes.role,
      formatMethod: value => {
        return ROLE_OPTIONS[value].label;
      },
      disabledSorting: true
    }
  ];

  const handleChangeRole = debounce(value => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.roles = value;
        draft.resetPage = true;
      })
    );
  }, 250);

  const renderRolesSelector = () => {
    const children = Object.values(ROLE_OPTIONS).map(({ key, label }) => {
      return (
        <Option key={key} value={key}>
          {label}
        </Option>
      );
    });

    return (
      <Select
        mode="multiple"
        placeholder="Please select"
        defaultValue={additionalParams.params.roles}
        onChange={handleChangeRole}
        style={{ width: '100%' }}
      >
        {children}
      </Select>
    );
  };

  const renderFilters = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            placeholder="User's Name or Email"
            className={classes.searchInput}
            defaultValue={globalParams.searchFullNameEmailQuery}
            onChange={e => handleOnChangeSearchInput(e.target.value.replace(/,/gi, '|'))}
          />
        </Grid>
        <Can
          permissions={['users.create', 'users.manage', 'users.manage-limited']}
          yes={() => (
            <Grid item xs={12} sm={3}>
              <Button color="primary" variant="contained" onClick={openCreate}>
                Create User
              </Button>
            </Grid>
          )}
        />
        <Grid item xs={12} sm={5}>
          <InputLabel>Roles</InputLabel>
          {renderRolesSelector()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Content filters={renderFilters()}>
      <DataTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        total={totalUsers}
        updateData={handleUpdateData}
        defaultOrderBy={globalParams.orderBy}
        globalParams={globalParams}
        additionalParams={additionalParams}
        customActions={[
          {
            name: 'userDetails',
            handleOnAction: handleUserDetails,
            title: 'Details',
            icon: <DescriptionIcon />
          }
        ]}
      />
    </Content>
  );
};

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
