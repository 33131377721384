import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Step from './Step';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      gap: theme.spacing(0.75),
      color: 'white'
    }
  };
});

const shouldShowAge = data => {
  return data.filter(({ timestamp }) => timestamp !== null && timestamp !== '').length > 0;
};

const findStepData = (step, data) => {
  const stepData = data.find(({ label }) => label === step);
  return stepData !== undefined
    ? stepData
    : {
        title: step,
        timestamp: null,
        total: 0
      };
};

const Steps = ({ steps, data, currentStep, onSelect }) => {
  const classes = useStyles();

  const showAge = shouldShowAge(data);
  return (
    <div className={classes.container}>
      {steps.map(step => {
        const { total, timestamp } = findStepData(step, data);
        return (
          <Step
            key={step}
            label={step}
            selected={currentStep === step}
            count={total}
            timestamp={timestamp}
            onClick={() => onSelect(step)}
            showAge={showAge}
          />
        );
      })}
    </div>
  );
};

Steps.defaultProps = {
  currentStep: null,
  data: []
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      timestamp: PropTypes.string,
      total: PropTypes.number
    })
  )
};

export default Steps;
