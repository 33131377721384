import BaseApiService from './base';

export default class ShippingMethodsApiService extends BaseApiService {
  // eslint-disable-next-line camelcase
  get({ locationId, assignment = null, availableOnly = false }) {
    return this.request({
      method: 'get',
      url: `/shipping_methods`,
      params: {
        location_id: locationId,
        assignment,
        available_only: Number(availableOnly)
      }
    });
  }

  updateShippingMethodFromPrintRequest(printingRequestId, data) {
    return this.request({
      method: 'put',
      url: `/printing_requests/${printingRequestId}/shipping`,
      data
    });
  }
}
