import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PricingApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get() {
    return this.request({
      method: 'get',
      url: 'settings/pricing'
    });
  }

  update(data) {
    return this.request({
      method: 'put',
      data,
      url: 'settings/pricing'
    });
  }

  getOrganization(id) {
    return this.request({
      method: 'get',
      url: `organizations/${id}/pricing`
    });
  }

  updateOrganization(id, data) {
    return this.request({
      method: 'put',
      url: `organizations/${id}/pricing`,
      data
    });
  }
}
