import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, Paper } from '@material-ui/core';
import CurrentOrderHandler from '../OrderSummary/CurrentOrderHandler';
import COOrdersListHandler from '../COOrdersListHandler';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(5)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  currentOrder: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  oldOrders: {
    width: '70%'
  }
}));

const Orders = () => {
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <ReceiptIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Current Invoice
      </Typography>
      <Paper className={classes.currentOrder}>
        <CurrentOrderHandler organizationId={currentOrganization.id} title="Current Invoice" />
      </Paper>
      <div className={classes.oldOrders}>
        <Accordion TransitionProps={{ mountOnEnter: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
            <Typography variant="h6">Old Invoices</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <COOrdersListHandler />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

Orders.propTypes = {};

Orders.defaultProps = {};

export default Orders;
