import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import InstallersApiService from '../services/api/installers';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_INSTALLERS_PREFIX = 'FETCH_INSTALLERS';
export const FETCH_INSTALLERS_REQUEST_ACTION = `${FETCH_INSTALLERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_INSTALLERS_SUCCESS_ACTION = `${FETCH_INSTALLERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_INSTALLERS_FAILURE_ACTION = `${FETCH_INSTALLERS_PREFIX}_FAILURE_ACTION`;

export const CREATE_INSTALLER_PREFIX = 'CREATE_INSTALLER';
export const CREATE_INSTALLER_REQUEST_ACTION = `${CREATE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const CREATE_INSTALLER_SUCCESS_ACTION = `${CREATE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_INSTALLER_FAILURE_ACTION = `${CREATE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_INSTALLER_PREFIX = 'UPDATE_INSTALLER';
export const UPDATE_INSTALLER_REQUEST_ACTION = `${UPDATE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const UPDATE_INSTALLER_SUCCESS_ACTION = `${UPDATE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_INSTALLER_FAILURE_ACTION = `${UPDATE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const DELETE_INSTALLER_PREFIX = 'DELETE_INSTALLER';
export const DELETE_INSTALLER_REQUEST_ACTION = `${DELETE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const DELETE_INSTALLER_SUCCESS_ACTION = `${DELETE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const DELETE_INSTALLER_FAILURE_ACTION = `${DELETE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const FETCH_INSTALLER_PREFIX = 'FETCH_INSTALLER';
export const FETCH_INSTALLER_REQUEST_ACTION = `${FETCH_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const FETCH_INSTALLER_SUCCESS_ACTION = `${FETCH_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_INSTALLER_FAILURE_ACTION = `${FETCH_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_INSTALLERS_PROGRESS_ACTION = 'UPDATE_INSTALLERS_PROGRESS_ACTION';

export const SET_AUTO_UPDATE_SETTING = 'SET_AUTO_UPDATE';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  autoUpdate: true,
  items: [],
  uploadProgress: 0,
  total: 0
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INSTALLERS_PROGRESS_ACTION:
      return {
        ...state,
        uploadProgress: action.payload.uploadProgress
      };
    case SET_AUTO_UPDATE_SETTING: {
      return {
        ...state,
        autoUpdate: action.payload
      };
    }
    case FETCH_INSTALLERS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.total
      };
    case FETCH_INSTALLER_SUCCESS_ACTION:
      return produce(state, draft => {
        const installerIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (installerIndex > -1) {
          draft.items[installerIndex] = action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_INSTALLER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_INSTALLER_SUCCESS_ACTION:
      return produce(state, draft => {
        const installerIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[installerIndex] = action.payload;
      });
    case DELETE_INSTALLER_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchAutoUpdateSetting() {
  return dispatch => {
    const installersApiService = new InstallersApiService();
    return installersApiService
      .fetchAutoUpdate()
      .then(response => {
        dispatch({
          type: SET_AUTO_UPDATE_SETTING,
          payload: response.data.value
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setAutoUpdateSetting(value) {
  return dispatch => {
    const installersApiService = new InstallersApiService();
    return installersApiService
      .setAutoUpdate(value)
      .then(response => {
        dispatch({
          type: SET_AUTO_UPDATE_SETTING,
          payload: value
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchInstallersAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_INSTALLERS_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_INSTALLERS_SUCCESS_ACTION,
          payload: response.installers
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_INSTALLERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchInstallerAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_INSTALLER_SUCCESS_ACTION,
          payload: response.installer
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createInstallerAction(params, onUploadProgress) {
  return dispatch => {
    dispatch({ type: CREATE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .create(params, onUploadProgress)
      .then(response => {
        dispatch({
          type: CREATE_INSTALLER_SUCCESS_ACTION,
          payload: response.installer
        });
        dispatch(enqueueNotification('success', response.message));
        return response.installer;
      })
      .catch(error => {
        dispatch({ type: CREATE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      })
      .finally(() => {
        dispatch({
          type: UPDATE_INSTALLERS_PROGRESS_ACTION,
          payload: {
            uploadProgress: 0
          }
        });
      });
  };
}

export function updateInstallerAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .updateReleaseNotes(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_INSTALLER_SUCCESS_ACTION,
          payload: response.installer
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function deleteInstallerAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_INSTALLER_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateInstallerProgressAction(uploadProgress) {
  return dispatch => {
    dispatch({
      type: UPDATE_INSTALLERS_PROGRESS_ACTION,
      payload: {
        uploadProgress
      }
    });
  };
}
