import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Input } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserAutoComplete from './UserAutoComplete';
import { findRolesInUser } from '../../helpers';
import DeleteCellButton from './DeleteCellButton';
import { ROLES } from '../../constants';
import { dateFormatString, timeFormatString } from '../../helpers/techWorkflow';

const useStyles = makeStyles(() => ({
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '2px 45px 2px 0 !important'
    },
    '& .MuiAutocomplete-input': {
      padding: '3px !important',
      lineHeight: 1.15,
      fontSize: 12
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '0 !important',
      '& .MuiAutocomplete-clearIndicator': {
        padding: '2px !important'
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: '2px !important'
      }
    }
  }
}));

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  deletable,
  ...rest
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();

  const classes = useStyles();

  const currentUser = useSelector(state => state.auth.user);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = value => {
    if (type === 'user' && value) {
      handleSave({
        ...record,
        [dataIndex]: value.full_name,
        user_id: value.id,
        user_full_name: value.full_name,
        user_email: value.email
      });
    } else {
      handleSave({ ...record, [dataIndex]: value });
    }
  };

  const handleDelete = e => {
    save(
      type !== 'user'
        ? null
        : {
            id: null,
            full_name: null,
            email: null
          }
    );
    e.stopPropagation();
  };

  const getOptions = () => {
    const options = [];

    if (currentUser && findRolesInUser(['tech_support', 'supervisor'], currentUser)) {
      options.push({
        id: currentUser.id,
        full_name: currentUser.full_name,
        email: currentUser.email
      });
    }

    if (record[dataIndex] && record.user_id !== (currentUser.id || 0)) {
      options.push({
        id: record.user_id,
        full_name: record[dataIndex],
        email: record.user_email
      });
    }
    return options;
  };

  const renderInput = () => {
    let input = (
      <Input
        value={record[dataIndex]}
        ref={inputRef}
        onPressEnter={toggleEdit}
        onBlur={toggleEdit}
        onChange={e => save(e.target.value)}
      />
    );
    switch (type) {
      case 'datetime':
        input = (
          <DatePicker
            autoFocus
            showTime
            value={record[dataIndex] ? moment(record[dataIndex]) : null}
            onPressEnter={toggleEdit}
            onBlur={toggleEdit}
            onChange={(_, value) => save(value)}
            format={dateFormatString + ' ' + timeFormatString}
            placeholder='Select date & time'
          />
        );
        break;
      case 'user':
        input = (
          <UserAutoComplete
            currentUserOnly={findRolesInUser(['tech_support'], currentUser)}
            className={classes.autoComplete}
            size="small"
            onBlur={toggleEdit}
            onChange={(e, value) => {
              save(value);
            }}
            value={
              record[dataIndex]
                ? {
                    id: record.user_id,
                    full_name: record[dataIndex],
                    email: record.user_email
                  }
                : undefined
            }
            options={getOptions()}
            roles={[ROLES.techSupport, ROLES.supervisor, ROLES.superAdmin, ROLES.admin]}
          />
        );
        break;
      default:
        break;
    }

    return input;
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <td {...rest}>{renderInput()}</td>
    ) : (
      <td onClick={toggleEdit} {...rest}>
        {deletable && record[dataIndex] ? (
          <DeleteCellButton onClick={handleDelete}>{children}</DeleteCellButton>
        ) : (
          <span>{children}</span>
        )}
      </td>
    );

    return childNode;
  }

  return <td {...rest}>{children}</td>;
};

EditableCell.propTypes = {
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  dataIndex: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  type: PropTypes.string,
  deletable: PropTypes.bool
};

EditableCell.defaultProps = {
  type: '',
  editable: false,
  deletable: false
};

export default EditableCell;
