import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const getSortId = column => {
  return column.customSortId || column.id;
};

const ColumnTableHeaderSortable = ({ headCell, orderBy, order, onRequestSort }) => {
  const classes = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableCell
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      padding={headCell.disablePadding ? 'none' : 'normal'}
      sortDirection={orderBy === getSortId(headCell) ? order : false}
      width={headCell.width}
    >
      <TableSortLabel
        active={orderBy === getSortId(headCell)}
        direction={orderBy === getSortId(headCell) ? order : 'asc'}
        onClick={createSortHandler(getSortId(headCell))}
      >
        {headCell.label}
        {orderBy === getSortId(headCell) ? (
          <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

ColumnTableHeaderSortable.propTypes = {
  headCell: PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    number: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
    customSortId: PropTypes.string,
    width: PropTypes.string
  }).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default ColumnTableHeaderSortable;
