import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import LoadingIndicator from '../LoadingIndicator';
import OrganizationsApiService from '../../services/api/organizations';
import { UTCDateTimeToLocalTime } from '../../helpers';
import SubSectionWrapper from '../../pages/Account/components/SubSectionWrapper';

const AssignedLocations = ({ organizationId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [assignedLocations, setAssignedLocations] = useState([]);

  useEffect(() => {
    const fetchOrganizationAssignedLocations = async () => {
      const organizationApiService = new OrganizationsApiService();
      setIsLoading(true);
      const { locations } = await organizationApiService.getOrganizationAssignedLocations(
        organizationId
      );
      setIsLoading(false);
      setAssignedLocations(locations);
    };
    fetchOrganizationAssignedLocations();
  }, [organizationId]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <SubSectionWrapper title="Assigned Locations">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedLocations.map(location => (
              <TableRow key={location.id}>
                <TableCell component="th" scope="row">
                  {location.location_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {location.full_address}
                </TableCell>
                <TableCell>{UTCDateTimeToLocalTime(location.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubSectionWrapper>
  );
};

AssignedLocations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizationId: PropTypes.number.isRequired
};

AssignedLocations.defaultProps = {};

export default AssignedLocations;
