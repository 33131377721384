import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { UTCDateTimeToLocalTime } from '../../../../helpers';
import MoneyFormat from '../../../MoneyFormat';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialogOnClickWrapper from '../../../ConfirmationDialogOnClickWrapper';
import {
  REMOVE_CHARGE_FROM_PATIENT_PREFIX,
  removeChargeFromPatientAction
} from '../../../../reducers/patients';
import { useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from '../../../../selectors/loading';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreOptions from '../../../MoreOptions';
import AddChargeToPatient from '../../AddChargeToPatient';
import Can from '../../../Can';

const useStyles = makeStyles(theme => {
  return {
    listItem: {
      padding: theme.spacing(1, 0)
    },
    total: {
      fontWeight: 700
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& > th, & > td': {
        textAlign: 'center'
      },
      '& td': {
        paddingTop: 3,
        paddingBottom: 3,
        '&:first-child': {
          textAlign: 'left'
        },
        '&:last-child': {
          textAlign: 'right',
          position: 'relative'
        }
      }
    },
    patientDetails: {
      '&:not(.compact) > td': {
        fontWeight: 500
      },
      '&:not(:first-child) td': {
        paddingTop: 15
      },
      '&.compact td': {
        paddingTop: 5
      }
    },
    patientSubDetails: {
      '& td': {
        paddingLeft: 5
      }
    },
    patientSubItem: {
      color: '#999999'
    },
    patientTotal: {
      borderBottom: '#001028 1px solid',
      '& > td': {
        paddingTop: 5,
        paddingBottom: 10
      },
      '& > td:not(:first-child)': {
        color: 'black'
      }
    },
    buttonProgress: {
      color: theme.palette.primary.dark,
      position: 'absolute',
      top: '50%',
      right: 1,
      marginTop: -15,
      marginLeft: -15
    }
  };
});

const V2 = ({ order, detailed, onReloadOrderAction, showRemoveCharge, showMoreOptions }) => {
  const isDeletingPatientCharge = useSelector(state =>
    createLoadingSelector([REMOVE_CHARGE_FROM_PATIENT_PREFIX])(state)
  );
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const { patients = [] } = order.template_data;
  const classes = useStyles();

  const dispatch = useDispatch();

  const OPTIONS = [{ id: 1, label: 'Charge or Discount Patient' }];

  const removePatientCharge = (patientId, patientChargeId) => {
    dispatch(removeChargeFromPatientAction(patientId, patientChargeId)).then(response => {
      if (response) {
        onReloadOrderAction();
      }
    });
  };

  if (!detailed) {
    if (patients.length === 0) {
      return null;
    }
    const total = patients.reduce((partialSum, patient) => partialSum + patient.total, 0);
    return (
      <>
        <ListItem className={classes.listItem}>
          <ListItemText primary={`${patients.length}x Patients`} />
          <Typography variant="subtitle1" className={classes.total}>
            <MoneyFormat value={total} />
          </Typography>
        </ListItem>
        <Divider />
      </>
    );
  }

  return (
    <div>
      <table className={classes.table}>
        <tbody>
          {patients.map(patient => {
            const hasSubItems =
              patient.deductions.length !== 0 ||
              patient.add_ons.length !== 0 ||
              patient.charges.length > 0;
            return (
              <React.Fragment key={patient.patient_name + patient.case_number_text}>
                <tr className={[classes.patientDetails, 'compact'].join(' ')}>
                  <td>
                    {patient.patient_name} | Dr. {patient.doctor_name}
                  </td>
                  <td>
                    {showMoreOptions && (
                      <MoreOptions
                        onChooseItem={() => {
                          setSelectedItem(patient);
                          setOpen(true);
                        }}
                        options={OPTIONS}
                        size="small"
                      />
                    )}
                  </td>
                </tr>
                <tr
                  className={[
                    classes.patientSubItem,
                    classes.patientSubDetails,
                    !hasSubItems ? classes.patientTotal : ''
                  ].join(' ')}
                >
                  <td>
                    {patient.impression_type && `${patient.impression_type} | `}
                    {UTCDateTimeToLocalTime(patient.creation_date)}{' '}
                    {patient.case_number && ` | Case #${patient.case_number}`}
                  </td>
                  <td>
                    <MoneyFormat value={patient.base_fee} />
                  </td>
                </tr>
                {patient.deductions.map(({ label, value }) => {
                  return (
                    <tr key={label} className={classes.patientSubItem}>
                      <td>{label}</td>
                      <td>
                        <MoneyFormat value={value * -1} />
                      </td>
                    </tr>
                  );
                })}
                {patient.add_ons.map(({ label, value, details }) => {
                  return (
                    <tr key={label} className={classes.patientSubItem}>
                      <td>
                        {label} {details && `(${details})`}
                      </td>
                      <td>
                        <MoneyFormat value={value} />
                      </td>
                    </tr>
                  );
                })}
                {patient.charges.map(({ description, value, is_discount, patient_charge_id }) => {
                  return (
                    <tr key={description} className={classes.patientSubItem}>
                      <td>{description}</td>
                      <td>
                        <MoneyFormat value={is_discount ? value * -1 : value} />

                        {showRemoveCharge && patient_charge_id && (
                          <Can
                            permissions={['orders.manage']}
                            yes={() => (
                              <>
                                <ConfirmationDialogOnClickWrapper
                                  confirmationBody="Are you sure you want to delete this item?"
                                  confirmationTitle="Delete Confirmation"
                                >
                                  <IconButton
                                    onClick={() =>
                                      removePatientCharge(patient.patient_id, patient_charge_id)
                                    }
                                    size="small"
                                    disabled={isDeletingPatientCharge}
                                  >
                                    <DeleteIcon color="secondary" />
                                  </IconButton>
                                </ConfirmationDialogOnClickWrapper>

                                {isDeletingPatientCharge && (
                                  <CircularProgress size={30} className={classes.buttonProgress} />
                                )}
                              </>
                            )}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
                {hasSubItems && (
                  <tr className={classes.patientTotal}>
                    <td>Total:</td>
                    <td>
                      <MoneyFormat value={patient.total} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {selectedItem && (
        <AddChargeToPatient
          open={open}
          setOpen={setOpen}
          patientId={selectedItem.patient_id}
          onChargeAdded={onReloadOrderAction}
        />
      )}
    </div>
  );
};

V2.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
  detailed: PropTypes.bool.isRequired,
  showRemoveCharge: PropTypes.bool,
  showMoreOptions: PropTypes.bool,
  onReloadOrderAction: PropTypes.func
};

V2.defaultProps = {
  showRemoveCharge: false,
  showMoreOptions: false,
  onReloadOrderAction: () => {}
};

export default V2;
