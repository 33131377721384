import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchCurrentUserAction,
  UPDATE_PROFILE_PREFIX,
  updateProfileAction
} from '../../../reducers/auth';
import createLoadingSelector from '../../../selectors/loading';
import UserForm from '../../../components/UserForm';
import AdditionalEmails from './AdditionalEmails';
import { UI_SETTINGS } from '../../../constants';
import MakeHidden from '../../../components/MakeHidden';

const useStyles = makeStyles(theme => ({
  additionalLocations: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

const AccountInformation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isLoading = useSelector(state => createLoadingSelector([UPDATE_PROFILE_PREFIX])(state));

  useEffect(() => {
    if (!user) {
      dispatch(fetchCurrentUserAction());
    }
  }, [dispatch, user]);

  const updateProfile = values => {
    dispatch(updateProfileAction({ ...values }));
    return true;
  };

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader title="User Information" />
          <Divider />
          <UserForm
            isUpdating={isLoading}
            onSubmit={updateProfile}
            user={user}
            isSaving={isLoading}
            isOwn
          />
        </Card>
      </Box>
      <MakeHidden profile="management">
        <Box className={classes.additionalLocations}>
          <AdditionalEmails userId={user.id} emails={user.additional_emails} />
        </Box>
      </MakeHidden>
    </Box>
  );
};

export default AccountInformation;
