import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchInput from '../../components/SearchInput';
import { fetchOnboardings } from '../../reducers/onboardings';
import DataTable from '../../components/DataTable';
import Content from '../../components/Layout/Content';
import { getStepLabel } from '../../components/Onboarding/steps';
import { STATUSES } from '../../components/Onboarding/constants';

const useStyles = makeStyles(() => ({
  status: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 5
    }
  }
}));

const Filters = ({
  searchOrganizationName,
  onSearchOrganizationNameChange,
  onStatusValueChange,
  statusValue
}) => {
  const classes = useStyles();
  let searchValueChangeTimeout;

  const handleStatusChange = (event, value) => {
    onStatusValueChange(value.map(status => status.value));
  };

  const handleSearchOrganizationValueChange = event => {
    const { value } = event.target;
    if (searchValueChangeTimeout) {
      clearTimeout(searchValueChangeTimeout);
    }
    searchValueChangeTimeout = setTimeout(() => {
      onSearchOrganizationNameChange(value);
    }, 1000);
  };

  const selectedStatuses = statusValue.map(value => {
    return STATUSES.find(statusItem => statusItem.value === value);
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={5}>
        <SearchInput
          placeholder="Search Organization"
          defaultValue={searchOrganizationName}
          onChange={handleSearchOrganizationValueChange}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Autocomplete
          className={classes.status}
          multiple
          id="tags-standard"
          options={STATUSES}
          getOptionLabel={option => option.status}
          value={selectedStatuses}
          onChange={handleStatusChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Status"
              placeholder="Filter By Status"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

Filters.defaultProps = {
  searchOrganizationName: null,
  statusValue: []
};

Filters.propTypes = {
  searchOrganizationName: PropTypes.string,
  onSearchOrganizationNameChange: PropTypes.func.isRequired,
  onStatusValueChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  statusValue: PropTypes.array
};

const Onboardings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    { id: 'organization.name', label: 'Organization' },
    { id: 'status', label: 'Status' },
    { id: 'current_step', label: 'Current Step', formatMethod: step => getStepLabel(step) },
    { id: 'created_at', label: 'Signup Date' }
  ];

  const rows = useSelector(state => state.onboardings.items);
  const total = useSelector(state => state.onboardings.total);
  const loading = useSelector(state => state.onboardings.loading);
  const queryParams = useSelector(state => state.onboardings.queryParams);

  const updateData = useCallback(
    params => {
      dispatch(fetchOnboardings({ ...queryParams, ...params }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const filters = (
    <Filters
      searchOrganizationName={queryParams.searchOrganizationName}
      onSearchOrganizationNameChange={searchOrganizationName =>
        updateData({ searchOrganizationName })
      }
      statusValue={queryParams.status}
      onStatusValueChange={status => updateData({ status })}
    />
  );

  return (
    <Content filters={filters}>
      <DataTable
        isLoading={loading}
        defaultOrderBy={queryParams.orderBy}
        rows={rows}
        total={total}
        columns={columns}
        updateData={updateData}
        globalParams={queryParams}
        customActions={[
          {
            name: 'show',
            handleOnAction: id => history.push(`/onboardings/${id}`),
            text: 'Show',
            title: 'Show'
          }
        ]}
      />
    </Content>
  );
};

export default Onboardings;
