import React, { useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  DELETE_LOCATION_PREFIX,
  deleteLocationAction,
  FETCH_LOCATION_PREFIX,
  fetchLocationAction,
  UPDATE_LOCATION_PREFIX,
  updateLocationAction
} from '../../reducers/locations';
import LocationForm from '../../components/LocationForm';
import { UI_SETTINGS } from '../../constants';
import hasPermission from '../../selectors/hasPermission';

const UpdateLocation = ({
  organization: { data: organization, label, redirect },
  match: { params }
}) => {
  const dispatch = useDispatch();
  const locationId = parseInt(params.id, 10);
  const location = useSelector(state => {
    return state.locations.items[state.locations.items.findIndex(item => item.id === locationId)];
  });
  const isUpdating = useSelector(state => createLoadingSelector([UPDATE_LOCATION_PREFIX])(state));

  const isDeleting = useSelector(state => createLoadingSelector([DELETE_LOCATION_PREFIX])(state));

  const isLoading = useSelector(state => createLoadingSelector([FETCH_LOCATION_PREFIX])(state));

  const canUpdateAllLocations = useSelector(state =>
    hasPermission(state, { permissions: ['locations.update', 'locations.manage'] })
  );
  const cannotUpdateAddress = !canUpdateAllLocations && get(location, 'has_incomplete_requests');

  useEffect(() => {
    dispatch(fetchLocationAction(locationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationId]);

  const onUpdate = values => {
    dispatch(updateLocationAction(values, locationId)).then(response => {
      if (response) {
        redirect('/locations');
      }
    });
    return true;
  };

  const onDelete = () => {
    dispatch(deleteLocationAction(locationId)).then(response => {
      if (response) {
        redirect('/locations');
      }
    });
    return true;
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!location || !location.users || location.organization_id !== organization.id) {
    return null;
  }

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader
            title="Update Location"
            subheader={
              (cannotUpdateAddress && (
                <>
                  <WarningIcon /> The address modification has been disabled while all your requests
                  are completed. If you want to update the destination address for an open request,
                  please contact the administrator.
                </>
              )) ||
              label
            }
          />

          <Divider />
          <LocationForm
            organization={organization}
            onSubmit={onUpdate}
            isSaving={isUpdating}
            location={location}
            isDeleting={isDeleting}
            onDelete={onDelete}
            disabledAddressFields={cannotUpdateAddress}
          />
        </Card>
      </Box>
    </Box>
  );
};

UpdateLocation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

UpdateLocation.defaultProps = {
  match: { params: {} }
};

export default UpdateLocation;
