import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SubSectionWrapper, {
  SubSectionActionWrapper
} from '../../Account/components/SubSectionWrapper';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import LoadingButton from '../../../components/LoadingButton';
import createLoadingSelector from '../../../selectors/loading';
import {
  ARCHIVE_ORGANIZATION_PREFIX,
  archiveOrganizationAction
} from '../../../reducers/organizations';
import { resetDashboardDataAction } from '../../../reducers/dashboard';

const ArchiveOrganization = ({ organization }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isArchivingOrganization = useSelector(state =>
    createLoadingSelector([ARCHIVE_ORGANIZATION_PREFIX])(state)
  );

  const handleArchivingOrganization = () => {
    return dispatch(archiveOrganizationAction(organization.id)).then(response => {
      if (!response) return;
      dispatch(resetDashboardDataAction());
      history.push('/organizations-users');
    });
  };

  return (
    <SubSectionWrapper title="Archive Organization">
      <SubSectionActionWrapper>
        <ConfirmationDialogOnClickWrapper
          confirmationBody="Are you sure you want to archive this organization?"
          confirmationTitle="Archive Confirmation"
        >
          <LoadingButton
            type="button"
            variant="contained"
            color="secondary"
            loading={isArchivingOrganization}
            disabled={isArchivingOrganization}
            onClick={handleArchivingOrganization}
          >
            Archive
          </LoadingButton>
        </ConfirmationDialogOnClickWrapper>
      </SubSectionActionWrapper>
    </SubSectionWrapper>
  );
};

ArchiveOrganization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

ArchiveOrganization.defaultProps = {};

export default ArchiveOrganization;
