import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingButton from '../LoadingButton';
import { updatePatientFieldAction } from '../../reducers/patients';

const PatientNote = ({
  noteIdentifier,
  relatedIdentifiers,
  patientId,
  note,
  label,
  rows,
  disabled,
  emptyOnSave
}) => {
  const [localNote, setLocalNote] = useState(note);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setLocalNote(note);
  }, [note]);

  const updateNote = () => {
    setIsSaving(true);
    dispatch(
      updatePatientFieldAction(patientId, localNote, noteIdentifier, relatedIdentifiers)
    ).then(() => {
      setIsSaving(false);
      if (emptyOnSave) {
        setLocalNote('');
      }
    });
  };
  return (
    <Box flexDirection="column" width={1}>
      <TextField
        id="outlined-multiline-static"
        label={label}
        fullWidth
        multiline
        minRows={rows}
        disabled={disabled}
        value={localNote}
        variant="outlined"
        onChange={event => setLocalNote(event.target.value)}
      />
      {!disabled && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() => updateNote()}
            loading={isSaving}
            disabled={isSaving}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

PatientNote.propTypes = {
  noteIdentifier: PropTypes.string.isRequired,
  relatedIdentifiers: PropTypes.arrayOf(PropTypes.string),
  patientId: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  emptyOnSave: PropTypes.bool
};

PatientNote.defaultProps = {
  relatedIdentifiers: [],
  rows: 4,
  disabled: false,
  emptyOnSave: false
};

export default PatientNote;
