import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Checkbox, ListItemText } from '@material-ui/core';
import { QUESTIONS } from '../constants';
import InputLabel from '../../Form/InputLabel';

const BracketLibrariesField = ({ name, items, disabled }) => {
  const {
    values: { [name]: value },
    setFieldValue
  } = useFormikContext();

  useEffect(() => {
    if (disabled && Array.isArray(value) && value.length > 0) {
      setFieldValue(name, []);
    }
  }, [value, disabled, name, setFieldValue]);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>{QUESTIONS[name]}</InputLabel>
      <Field
        disabled={disabled}
        labelId={`${name}-label`}
        required
        name={name}
        component={Select}
        multiple
        renderValue={selected => {
          const selectedItems = items
            .filter(item => selected.indexOf(item.id) !== -1)
            .map(item => item.file_name);
          return selectedItems.join(', ');
        }}
        labelWidth={65}
      >
        {items.map(item => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={value.indexOf(item.id) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

BracketLibrariesField.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  disabled: PropTypes.bool.isRequired
};

BracketLibrariesField.defaultProps = {
  items: []
};

export default BracketLibrariesField;
