import React, { useCallback, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { produce } from 'immer';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import createLoadingSelector from '../../selectors/loading';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import { UTCDateTimeToLocalTime } from '../../helpers';
import { FETCH_CONTRACTS_PREFIX, fetchContractsAction } from '../../reducers/contracts';
import PaymentStatusTag from '../../components/PaymentStatusTag';
import DibsRangePickerFilter from '../../components/DibsRangePickerFilter';

const dateFormat = 'YYYY-MM-DD';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%'
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  },
  role: {
    textTransform: 'capitalize'
  },
  paymentStatusFormControl: {
    width: '100%'
  }
}));

const RecurringPayments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_CONTRACTS_PREFIX])(state));
  const rows = useSelector(state => state.contracts.items);
  const globalParams = useSelector(state => state.contracts.globalParams);
  const totalUsers = useSelector(state => state.contracts.total);
  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(globalParams.paymentStatus);

  const handlePaymentDetails = id => {
    history.push(`/subscriptions/${id}`);
  };

  const handleUpdateData = useCallback(
    params => {
      dispatch(
        fetchContractsAction({ ...params, organizations: selectedOrganizations.map(org => org.id) })
      );
    },
    [dispatch, selectedOrganizations]
  );

  const handleOnChangeSearchInput = debounce(value => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.searchFullNameQuery = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleChangePaymentStatus = event => {
    const paymentStatus = event.target.value;
    setSelectedPaymentStatus(paymentStatus);
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.paymentStatus = paymentStatus === 'ALL' ? '' : paymentStatus;
        draft.resetPage = true;
      })
    );
  };

  const columns = [
    {
      id: 'organization.name',
      numeric: false,
      disablePadding: false,
      label: 'Organization',
      customSortId: 'full_name_sort'
    },
    {
      id: 'next_payment_date',
      numeric: false,
      disablePadding: false,
      label: 'Next Payment Date',
      formatMethod: (value, row) =>
        row.suspension_type === 1 ? '' : UTCDateTimeToLocalTime(value),
      customSortId: 'custom_next_payment_date'
    },
    {
      id: 'last_payment_date',
      numeric: false,
      disablePadding: false,
      label: 'Last Payment Date',
      formatMethod: (value, row) =>
        row.suspension_type === 1
          ? UTCDateTimeToLocalTime(row.suspended_at)
          : UTCDateTimeToLocalTime(value),
      customSortId: 'custom_next_payment_date'
    },
    {
      id: 'payment_status',
      numeric: false,
      disablePadding: false,
      label: 'Payment Status',
      className: classes.role,
      formatMethod: value => <PaymentStatusTag paymentStatus={value} />,
      disabledSorting: true
    }
  ];

  let defaultValue = [];

  if (globalParams.from && globalParams.to) {
    defaultValue = [moment(globalParams.from, dateFormat), moment(globalParams.to, dateFormat)];
  }

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Organization Name or Account Number"
                className={classes.searchInput}
                defaultValue={globalParams.searchFullNameQuery}
                onChange={e => handleOnChangeSearchInput(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.paymentStatusFormControl}>
                <InputLabel>Payment Status</InputLabel>
                <Select value={selectedPaymentStatus || 'ALL'} onChange={handleChangePaymentStatus}>
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="PENDING">Pending</MenuItem>
                  <MenuItem value="NOT_PAID">Not Paid</MenuItem>
                  <MenuItem value="PAID">Paid</MenuItem>
                  <MenuItem value="BLOCKED">Blocked</MenuItem>
                  <MenuItem value="SUSPENDED">Suspended</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={3} justifyContent="flex-end" direction="row">
                <Grid item>
                  <DibsRangePickerFilter
                    setAdditionalParams={setAdditionalParams}
                    additionalParams={additionalParams}
                    defaultValue={defaultValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          total={totalUsers}
          updateData={handleUpdateData}
          defaultOrderBy={globalParams.orderBy}
          additionalParams={additionalParams}
          globalParams={globalParams}
          customActions={[
            {
              name: 'paymentDetails',
              handleOnAction: handlePaymentDetails,
              text: 'Details'
            }
          ]}
        />
      </div>
    </Container>
  );
};

RecurringPayments.propTypes = {};

RecurringPayments.defaultProps = {};

export default RecurringPayments;
