import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { FormControl, FormLabel } from '@material-ui/core';
import { includes, map } from 'lodash';

const LocationSelector = ({
  onChange,
  defaultValue,
  multiple,
  label,
  assignedLocations,
  locationOptions,
  assignedLocationsPrefix
}) => {
  const { Option } = Select;

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <Select
        mode={multiple ? 'multiple' : null}
        placeholder="Please select"
        defaultValue={defaultValue}
        onChange={onChange}
        maxTagCount={2}
        style={{ width: '100%' }}
      >
        {locationOptions.map(item => (
          <Option value={item.id} key={item.id}>
            {assignedLocationsPrefix && includes(map(assignedLocations, 'id'), item.id)
              ? assignedLocationsPrefix
              : ''}
            {item.location_name}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};

LocationSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  multiple: PropTypes.bool,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  assignedLocations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  locationOptions: PropTypes.array,
  assignedLocationsPrefix: PropTypes.string
};

LocationSelector.defaultProps = {
  defaultValue: null,
  multiple: true,
  label: 'Locations',
  locationOptions: [],
  assignedLocations: [],
  assignedLocationsPrefix: '(A) '
};

export default LocationSelector;
