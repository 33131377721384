import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { fetchOrderAction } from '../../reducers/orders';
import OrderSummary from './index';

const OrderSummaryHandler = ({ orderId, title }) => {
  const dispatch = useDispatch();
  const order = useSelector(state => {
    return state.orders.items[state.orders.items.findIndex(item => item.id === orderId)];
  });
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  useEffect(() => {
    setIsLoadingLocal(true);
    dispatch(fetchOrderAction(orderId)).then(() => setIsLoadingLocal(false));
  }, [orderId, dispatch]);

  if (isLoadingLocal) {
    return <LoadingIndicator />;
  }

  if (!order) {
    return null;
  }

  return <OrderSummary order={order} title={title} />;
};

OrderSummaryHandler.propTypes = {
  orderId: PropTypes.number.isRequired,
  title: PropTypes.string
};

OrderSummaryHandler.defaultProps = {
  title: ''
};

export default OrderSummaryHandler;
