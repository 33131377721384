import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  PRINT_ASSIGNED_TO,
  PRINT_ASSIGNED_TO_ID,
  PRINTING_REQUESTS_STATUSES_ID,
  REQUEST_ASSIGNMENT_IN_OFFICE
} from '../../../constants';
import Can from '../../../components/Can';
import SimpleMenu from '../../../components/SimpleMenu';
import {
  ASSIGN_PRINTING_REQUEST_PREFIX,
  assignPrintingRequestAction
} from '../../../reducers/requests';
import createLoadingSelector from '../../../selectors/loading';
import PatientWorkflowContext from '../context/PatientWorkflowContext';
import { isTechnicianOrAdmin } from '../../../helpers';
import RxFormPreferenceProvider from '../../../components/RxForm/RxFormPreferenceProvider';
import { assignmentOptions } from '../../../components/RxForm/helpers';

const PrintRequestAssignment = ({ printingRequest, defaultAssignmentOptions, requestId }) => {
  const dispatch = useDispatch();
  const onChange = selectedValue => {
    dispatch(assignPrintingRequestAction(printingRequest.id, selectedValue, requestId));
  };

  const currentUser = useSelector(state => state.auth.user);
  const isAssigningPrintingRequest = useSelector(state =>
    createLoadingSelector([ASSIGN_PRINTING_REQUEST_PREFIX])(state)
  );

  const availableAssignmentOptions = defaultAssignmentOptions.filter(
    option => option.value !== printingRequest.assigned_to
  );

  const patientWorkflowContext = useContext(PatientWorkflowContext);

  const organizationManager = get(
    patientWorkflowContext,
    'patient.organization.organization_manager'
  );

  const organizationId = get(patientWorkflowContext, 'patient.organization_id');

  const isCurrentUserTheOrganizationManager = get(organizationManager, 'id') === currentUser.id;

  const isAssignedToCurrentUser =
    (isCurrentUserTheOrganizationManager &&
      printingRequest.assigned_to === PRINT_ASSIGNED_TO_ID.thirdParty) ||
    (isTechnicianOrAdmin(currentUser) &&
      printingRequest.assigned_to === PRINT_ASSIGNED_TO_ID.orthoselect);

  const isNewPrintRequest =
    printingRequest.printing_request_status_id === PRINTING_REQUESTS_STATUSES_ID.new;

  const hideReassignButton = !isNewPrintRequest || !organizationManager || !isAssignedToCurrentUser;

  return (
    <>
      {`Assigned To: ${PRINT_ASSIGNED_TO[printingRequest.assigned_to || 1].name}`}
      {!hideReassignButton && availableAssignmentOptions.length > 0 && (
        <Can
          permissions={[
            'printing-requests.assign',
            'printing-requests.manage',
            'organization.printing-requests.assign'
          ]}
          organizationId={organizationId}
          yes={() => {
            return (
              <Box mx={2} component="span">
                <SimpleMenu
                  buttonTitle="Change"
                  onOptionSelected={({ value }) => onChange(value)}
                  isLoading={isAssigningPrintingRequest}
                  options={availableAssignmentOptions}
                />
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

PrintRequestAssignment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  printingRequest: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultAssignmentOptions: PropTypes.array.isRequired,
  requestId: PropTypes.number.isRequired
};

PrintRequestAssignment.defaultProps = {};

const Wrapper = ({ patient, ...props }) => {
  return (
    <RxFormPreferenceProvider
      organizationId={patient.organization_id}
      loaderProps={{ size: 20, withSpacing: false }}
    >
      {({ preference, third_party_partner: thirdPartyPartner }) => {
        const options = assignmentOptions({
          assignments: preference.print_assignment_options.filter(
            value => value !== REQUEST_ASSIGNMENT_IN_OFFICE
          ),
          thirdPartyName: thirdPartyPartner ? thirdPartyPartner.name : null
        });
        return <PrintRequestAssignment defaultAssignmentOptions={options} {...props} />;
      }}
    </RxFormPreferenceProvider>
  );
};

Wrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

export default Wrapper;
