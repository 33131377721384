import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';
import { SCANNERS } from '../constants';

const ScannerField = ({ currentValue, setValue, ...props }) => {
  useEffect(() => {
    if (!currentValue) {
      setValue(SCANNERS.ITERO);
    }
  }, [currentValue, setValue]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroupField {...props} />
      </Grid>
      {currentValue === 7 && (
        <Grid item xs={12}>
          <Field
            name="custom_scanner_type_name"
            variant="outlined"
            fullWidth
            id="custom_scanner_type_name"
            label="Scanner Name"
            component={TextField}
          />
        </Grid>
      )}
    </Grid>
  );
};

ScannerField.defaultProps = {
  currentValue: null
};

ScannerField.propTypes = {
  currentValue: PropTypes.number,
  setValue: PropTypes.func.isRequired
};

export default withDependency(ScannerField);
