import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LabelDialog from './LabelDialog';
import PatientLabel from '../Labels/PatientLabel';
import { markPatientLabelAsPrinted } from '../../../reducers/requests';
import { closePatientLabel, fetchPatientAction } from '../../../reducers/patients';
import { COMMON_PATIENT_INCLUDES } from '../../../constants/queries';

const PatientLabelDialog = ({ patient, markPrinted, close }) => {
  return (
    <LabelDialog
      open={patient !== null}
      onClose={close}
      title="Patient Label"
      printed={patient && !!patient.customer_request.printing_request.patient_label_printed}
      onPrinted={() => markPrinted(patient)}
    >
      {patient !== null && <PatientLabel patient={patient} />}
    </LabelDialog>
  );
};

PatientLabelDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  close: PropTypes.func.isRequired,
  markPrinted: PropTypes.func.isRequired
};

PatientLabelDialog.defaultProps = {
  patient: null
};

export default connect(
  state => {
    return {
      patient: state.patients.selection.patientLabel
    };
  },
  dispatch => {
    return {
      markPrinted: patient => {
        dispatch(markPatientLabelAsPrinted(patient.customer_request.id)).then(() => {
          dispatch(fetchPatientAction(patient.id, [COMMON_PATIENT_INCLUDES]));
          dispatch(closePatientLabel());
        });
      },
      close: () => dispatch(closePatientLabel())
    };
  }
)(PatientLabelDialog);
