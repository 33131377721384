import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import createLoadingSelector from '../../selectors/loading';
import moment from 'moment';
import OldCaseForm from './components/OldCaseForm';
import { Container } from '@material-ui/core';
import { FETCH_ORGANIZATION_PREFIX, fetchOrganizationAction } from '../../reducers/organizations';
import { CREATE_OLD_CASE_PREFIX, createOldCaseAction } from '../../reducers/rxForms';
import { useHistory } from 'react-router-dom';

const AddOldCase = ({ match: { params } }) => {
  const organizationId = parseInt(params.organizationId, 10);
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoadingOrganization = useSelector(state =>
    createLoadingSelector([FETCH_ORGANIZATION_PREFIX])(state)
  );
  const isSubmitting = useSelector(state => createLoadingSelector([CREATE_OLD_CASE_PREFIX])(state));
  const organization = useSelector(state => {
    return state.organizations.items[
      state.organizations.items.findIndex(item => item.id === organizationId)
    ];
  });

  useEffect(() => {
    dispatch(fetchOrganizationAction(organizationId));
  }, [dispatch, organizationId]);

  const handleSubmit = async data => {
    dispatch(
      createOldCaseAction({
        ...data,
        organization_id: organizationId
      })
    ).then(rxForm => {
      if (rxForm) {
        window.open(`/patient-details/${rxForm.patient_id}`, '_self');
      }
    });

    return true;
  };

  const getDoctors = users => {
    return users.map(user => {
      return {
        id: user.id,
        full_name: user.full_name,
        role: user.pivot.role.name
      };
    });
  };

  if (isLoadingOrganization) {
    return <LoadingIndicator />;
  }

  if (!organization) {
    return null;
  }

  const rxForm = {
    first_name: '',
    last_name: '',
    case_number_text: '',
    impression_date: moment().format('YYYY-MM-DD'),
    ios_files: [],
    user_id: null
  };

  return (
    <div>
      <Container maxWidth="md">
        <OldCaseForm
          organization={organization}
          onSubmit={handleSubmit}
          onCancel={() => {
            history.goBack();
          }}
          isSaving={isSubmitting}
          rxForm={rxForm}
          doctors={getDoctors(organization.users)}
        />
      </Container>
    </div>
  );
};

AddOldCase.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

AddOldCase.defaultProps = {
  match: { params: {} }
};

export default AddOldCase;
