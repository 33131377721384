import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { find } from 'lodash';
import BracketImage from './BracketImage';

const BracketsSection = ({ brackets, columnClass }) => {
  const maxillary = find(brackets, {
    arch: { jaw_type_id: 1 }
  });
  const mandibular = find(brackets, {
    arch: { jaw_type_id: 2 }
  });

  if (!brackets.length) {
    return null;
  }

  return (
    <>
      <Grid item xs={6} className={columnClass}>
        <Box p={1}>
          {maxillary && <BracketImage bracket={maxillary} title="Maxillary Brackets" />}
        </Box>
      </Grid>
      <Grid item xs={6} className={columnClass}>
        <Box p={1}>
          {mandibular && <BracketImage bracket={mandibular} title="Mandibular Brackets" />}
        </Box>
      </Grid>
    </>
  );
};

BracketsSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  brackets: PropTypes.array.isRequired,
  columnClass: PropTypes.string
};

BracketsSection.defaultProps = {
  columnClass: ''
};

export default BracketsSection;
