import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  patient_status_id: PropTypes.number.isRequired,
  organization_id: PropTypes.number.isRequired,
  patient_files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ),
  has_pending_flawed_tray_request: PropTypes.bool.isRequired,
  organization_type: PropTypes.string
});
