import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import createLoadingSelector from '../../selectors/loading';
import StatusTag from '../../components/StatusTag';
import { FETCH_PERIODIC_RULES_PREFIX, fetchPeriodicRulesAction } from '../../reducers/periodicRule';

export const STATUSES = {
  0: { name: 'ALL', color: 'grey', value: '' },
  1: { name: 'ACTIVE', color: 'green', value: true },
  2: { name: 'INACTIVE', color: 'red', value: false }
};

const PeriodicRules = ({ contract }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const globalParams = useSelector(state => state.periodicRule.globalParams);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_PERIODIC_RULES_PREFIX])(state)
  );
  const [additionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const rows = useSelector(state => state.periodicRule.items);
  const total = useSelector(state => state.periodicRule.total);

  const handleUpdateData = useCallback(
    params => {
      dispatch(fetchPeriodicRulesAction({ ...params, contractId: contract.id }));
    },
    [dispatch, contract]
  );

  const handleShowItem = id => {
    history.push(`/subscriptions/${contract.id}/periodic-rules/${id}`);
  };

  const columns = [
    {
      id: 'value',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      formatMethod: (value, row) => `${row.type === 'Discount' ? '-' : ''}$${value}`
    },
    {
      id: 'custom_description',
      numeric: false,
      disablePadding: false,
      label: 'Description'
    },

    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      formatMethod: value => {
        const color = value === 'Charge' ? 'blue' : 'amber';
        return <StatusTag label={value} color={color} />;
      }
    },
    {
      id: 'applied_from',
      numeric: false,
      disablePadding: false,
      customSortId: 'applied_from',
      label: 'Applied From',
      default: '-'
    },
    {
      id: 'applied_to',
      numeric: false,
      disablePadding: false,
      customSortId: 'applied_to',
      label: 'Applied To',
      default: '-'
    },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      formatMethod: value => {
        const statusId = value ? 1 : 2;
        return <StatusTag label={STATUSES[statusId].name} color={STATUSES[statusId].color} />;
      }
    }
  ];

  return (
    <DataTable
      isLoading={isLoading}
      columns={columns}
      rows={rows}
      total={total}
      updateData={handleUpdateData}
      globalParams={globalParams}
      defaultOrderBy={globalParams.orderBy}
      showEmptyRows={false}
      customActions={[
        {
          name: 'edit',
          handleOnAction: handleShowItem,
          text: 'Edit'
        }
      ]}
      additionalParams={additionalParams}
    />
  );
};

PeriodicRules.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contract: PropTypes.object.isRequired
};

PeriodicRules.defaultProps = {};

export default PeriodicRules;
