import { Query } from 'cogent-js';
import RxFormsApiService from './rxForms';

export default class OrganizationRxFormsApiService extends RxFormsApiService {
  constructor({ organization, ...rest }) {
    super(rest);
    this.query = new Query();
    this.organization = organization;
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    includes = [],
    searchDoctorFullNameQuery = '',
    searchFullNameQuery = '',
    status = -1,
    organizationId = '',
    organizations = [],
    locations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for(`organizations/${this.organization.id}/rx_forms`)
        .include(...includes)
        .where('user_id', userId)
        .where('user_full_name_like', searchDoctorFullNameQuery)
        .where('patient_full_name_like', searchFullNameQuery)
        .where('status', status || -1)
        .where('organization_id', organizationId)
        .whereIn('chosen_wi_organization_id', organizations)
        .whereIn('chosen_wi_location_id', locations)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }
}
