import { produce } from 'immer';
import OrganizationsApiService from '../services/api/organizations';
import { enqueueNotification } from './notifications';

export const FETCHING_BRACKET_LIBRARIES = 'FETCHING_BRACKET_LIBRARIES';
export const FETCHED_BRACKET_LIBRARIES = 'FETCHED_BRACKET_LIBRARIES';
export const FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS =
  'FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS';
export const FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS =
  'FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS';
export const UPLOADING_BRACKET_LIBRARY = 'UPLOADING_BRACKET_LIBRARY';
export const UPLOADED_BRACKET_LIBRARY = 'UPLOADED_BRACKET_LIBRARY';
export const UPDATING_BRACKET_LIBRARY = 'UPDATING_BRACKET_LIBRARY';
export const UPDATED_BRACKET_LIBRARY = 'UPDATED_BRACKET_LIBRARY';
export const REMOVING_BRACKET_LIBRARY = 'REMOVING_BRACKET_LIBRARY';
export const REMOVED_BRACKET_LIBRARY = 'REMOVED_BRACKET_LIBRARY';

const initialState = {
  organizations: {}
};

const sortByFileName = items => {
  items.sort((item1, item2) => (item1.file_name < item2.file_name ? -1 : 1));
};

export default (state = initialState, { type, payload = null }) => {
  switch (type) {
    case FETCHING_BRACKET_LIBRARIES:
    case FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        if (!draft.organizations[payload.organization_id]) {
          draft.organizations[payload.organization_id] = {
            fetched: false,
            items: [],
            assignableMembers: [],
            uploading: [],
            fetching: false,
            removing: [],
            updating: false
          };
        }
        draft.organizations[payload.organization_id].fetching = true;
      });

    case FETCHED_BRACKET_LIBRARIES:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetching = false;
        draft.organizations[payload.organization_id].fetched = false;
        draft.organizations[payload.organization_id].items = payload.items;
        sortByFileName(draft.organizations[payload.organization_id].items);
      });

    case FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetching = false;
        draft.organizations[payload.organization_id].fetched = false;
        draft.organizations[payload.organization_id].assignableMembers = payload.items;
      });

    case UPLOADING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].uploading.push(payload.file);
      });

    case UPLOADED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items.push(payload.item);
        sortByFileName(draft.organizations[payload.organization_id].items);
        const { uploading } = state.organizations[payload.organization_id];
        draft.organizations[payload.organization_id].uploading = uploading.filter(
          item => item !== payload.file
        );
      });

    case UPDATING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = true;
      });

    case UPDATED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = false;
        draft.organizations[payload.organization_id].items = draft.organizations[
          payload.organization_id
        ].items.map(item => {
          if (item.id === payload.item.id) {
            return payload.item;
          }
          return item;
        });
      });

    case REMOVING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].removing.push(payload.item_id);
      });

    case REMOVED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items = state.organizations[
          payload.organization_id
        ].items.filter(item => item.id !== payload.item_id);
        draft.organizations[payload.organization_id].removing = state.organizations[
          payload.organization_id
        ].removing.filter(item => item.id !== payload.item_id);
      });

    default:
      return state;
  }
};

export function fetchBracketLibraries(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_BRACKET_LIBRARIES, payload: { organization_id: organizationId } });
    const api = new OrganizationsApiService();
    return api
      .getBracketLibraries(organizationId)
      .then(({ data }) => {
        dispatch({
          type: FETCHED_BRACKET_LIBRARIES,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchBracketLibraryAssignableMembers(organizationId) {
  return dispatch => {
    dispatch({
      type: FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS,
      payload: { organization_id: organizationId }
    });
    const api = new OrganizationsApiService();
    return api
      .getBracketLibraryAssignableMembers(organizationId)
      .then(data => {
        dispatch({
          type: FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteBracketLibrary(organizationId, id) {
  return dispatch => {
    dispatch({
      type: REMOVING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId, item_id: id }
    });
    const api = new OrganizationsApiService();
    return api
      .deleteBracketLibrary(organizationId, id)
      .then(() => {
        dispatch({
          type: REMOVED_BRACKET_LIBRARY,
          payload: {
            organization_id: organizationId,
            item_id: id
          }
        });
        dispatch(enqueueNotification('success', `Bracket library has been successfully removed.`));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function uploadBracketLibrary(organizationId, file) {
  return dispatch => {
    dispatch({
      type: UPLOADING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId, file }
    });
    const api = new OrganizationsApiService();
    return api
      .storeBracketLibrary(organizationId, file)
      .then(({ data }) => {
        dispatch({
          type: UPLOADED_BRACKET_LIBRARY,
          payload: {
            organization_id: organizationId,
            item: data,
            file
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateBracketLibrary(organizationId, id, userIds) {
  return dispatch => {
    dispatch({
      type: UPDATING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId }
    });
    const api = new OrganizationsApiService();
    return api
      .updateBracketLibrary(organizationId, id, { userIds })
      .then(({ data }) => {
        dispatch({
          type: UPDATED_BRACKET_LIBRARY,
          payload: {
            organization_id: organizationId,
            item: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
