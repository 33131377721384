/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center'
  },
  content: {
    '& > .MuiTextField-root': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));

const NotifyArchiveTrayDialog = ({ open, setOpen, tray, onAccept }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onAccept(tray.id);
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title}>Archive Tray {tray.tray_number}</DialogTitle>

        <DialogContent className={classes.content}>
          <Typography>
            Confirming this action will archive this tray together with all its cases.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

NotifyArchiveTrayDialog.propTypes = {
  onAccept: PropTypes.func.isRequired
};

export default NotifyArchiveTrayDialog;
