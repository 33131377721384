import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { REQUEST_STATUSES_ID } from '../../../constants';
import RequestWorkflow from './RequestWorkFlow';
import { FETCH_REQUEST_PREFIX, fetchRequestAction } from '../../../reducers/requests';
import LoadingIndicator from '../../../components/LoadingIndicator';
import createLoadingSelector from '../../../selectors/loading';

const RequestsWorkflowSection = ({ requests, hasDuplicatedRequest, iosFileId }) => {
  const dispatch = useDispatch();
  const noCanceledRequests = requests.filter(
    item => item.status_id !== REQUEST_STATUSES_ID.canceled
  );
  const isLoadingRequest = useSelector(state =>
    createLoadingSelector([FETCH_REQUEST_PREFIX])(state)
  );
  const [localRequests, setLocalRequests] = React.useState(noCanceledRequests);
  const [paginationPage, setPaginationPage] = useState(1);
  const [showCanceled, setShowCanceled] = React.useState(false);
  const [selectedRequestId, setSelectedRequestId] = React.useState(
    (noCanceledRequests.length > 0 && noCanceledRequests[0].id) || requests[0].id
  );

  const request = useSelector(state => {
    return state.requests.items[
      state.requests.items.findIndex(requestItem => requestItem.id === selectedRequestId)
    ];
  });

  useEffect(() => {
    if (!selectedRequestId) {
      return;
    }
    dispatch(fetchRequestAction(selectedRequestId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequestId]);

  const handlePaginationChange = (e, page) => {
    const { id: paginationRequestId } = localRequests[page - 1];
    setPaginationPage(page);
    setSelectedRequestId(paginationRequestId);
  };

  const handleShowCanceled = event => {
    const showAll = event.target.checked;
    setShowCanceled(showAll);
    const filteredRequests = showAll ? requests : noCanceledRequests;
    setLocalRequests(filteredRequests);
    setSelectedRequestId(filteredRequests[0].id);
    setPaginationPage(1);
  };

  const renderPagination = () => {
    if (requests.length === 1) {
      return null;
    }

    return (
      <>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              onChange={handlePaginationChange}
              page={paginationPage}
              count={localRequests.length}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Box ml={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showCanceled}
                    onChange={handleShowCanceled}
                    name="showCanceled"
                  />
                }
                label="All"
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <Box mt={4} width="100%">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {hasDuplicatedRequest && (
              <Box ml={2}>
                <Alert severity="error">
                  There is more than one active request. Cancel one first to continue.
                </Alert>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            {renderPagination()}
          </Grid>
        </Grid>
        {isLoadingRequest ? (
          <LoadingIndicator />
        ) : (
          request && (
            <RequestWorkflow
              iosFileId={iosFileId}
              request={request}
              hasDuplicatedRequest={hasDuplicatedRequest}
            />
          )
        )}
      </Box>
    </>
  );
};

RequestsWorkflowSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requests: PropTypes.array.isRequired,
  hasDuplicatedRequest: PropTypes.bool.isRequired,
  iosFileId: PropTypes.number.isRequired
};

RequestsWorkflowSection.defaultProps = {};

export default RequestsWorkflowSection;
