import React from 'react';
import PropTypes from 'prop-types';
import { connectRange } from 'react-instantsearch-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import { updateMultipleParameters } from '../../../reducers/globalSearch';

const DateRange = ({ currentRefinement, refine, fromParamKey, toParamKey, style, disableFutureDates }) => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();

  const defaultValue = [
    currentRefinement.min ? moment.unix(currentRefinement.min).utc() : '',
    currentRefinement.max ? moment.unix(currentRefinement.max).utc() : ''
  ];

  const handleOnChange = dates => {
    let min;
    let max;

    if (dates && dates.length > 1) {
      min = dates[0].unix();
      max = dates[1].unix();
    }

    refine({
      ...currentRefinement,
      min,
      max
    });

    dispatch(
      updateMultipleParameters({
        [fromParamKey]: min,
        [toParamKey]: max
      })
    );
  };
  const disabledDate = current => {
    if (!disableFutureDates) {
      return false;
    }

    return moment.isMoment(current) && current > moment().endOf('day');
  };

  return (
    <RangePicker
      onChange={handleOnChange}
      defaultValue={defaultValue}
      style={style}
      disabledDate={disabledDate}
    />
  );
};

DateRange.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentRefinement: PropTypes.object.isRequired,
  refine: PropTypes.func.isRequired,
  fromParamKey: PropTypes.string.isRequired,
  toParamKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  disableFutureDates: PropTypes.bool
};

DateRange.defaultProps = {
  style: {},
  disableFutureDates: false
};

export default connectRange(DateRange);
