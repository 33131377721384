import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { RESET_PASSWORD_PREFIX, resetPasswordAction } from '../../reducers/auth';
import LoadingButton from '../../components/LoadingButton';
import createLoadingSelector from '../../selectors/loading';
import { MANAGEMENT_ROLES } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const ResetPassword = ({ location = {} }) => {
  const urlQueryParams = new URLSearchParams(location.search);
  const token = urlQueryParams.get('token');
  const userRole = urlQueryParams.get('userRole');
  const mode = urlQueryParams.get('mode');
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => createLoadingSelector([RESET_PASSWORD_PREFIX])(state));
  const history = useHistory();

  if (!token) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
        token
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Required')
          .max(32, 'Must contain max 32 characters.')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            'Must contain at least 8 characters, one uppercase, one lowercase and one number'
          ),
        password_confirmation: Yup.string().required('Required')
      })}
      onSubmit={values => {
        return dispatch(resetPasswordAction(values)).then(response => {
          if (response) {
            if (MANAGEMENT_ROLES.indexOf(userRole) !== -1) {
              history.push('/management');
            } else {
              history.push('/login');
            }
          }
        });
      }}
    >
      {({ dirty, isValid }) => (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <Lock />
            </Avatar>
            <Typography component="h1" variant="h5">
              {mode === 'initial' ? 'Set Your Password' : 'Reset Your Password'}
            </Typography>
            <Typography variant="subtitle2">Enter the password and the confirmation.</Typography>
            <Form className={classes.form} noValidate>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                name="password"
                autoComplete="password"
                type="password"
                component={TextField}
              />
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password_confirmation"
                label="Confirm Password"
                name="password_confirmation"
                autoComplete="password_confirmation"
                type="password"
                component={TextField}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
                className={classes.submit}
                disabled={!dirty || !isValid || isLoading}
              >
                {mode === 'initial' ? 'Set Password' : 'Reset Password'}
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  {'Go to '}
                  <Link component={RouterLink} to="/login" variant="body2">
                    Login Page
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </div>
        </Container>
      )}
    </Formik>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.shape({})
};

ResetPassword.defaultProps = {
  location: {}
};

export default ResetPassword;
