import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatisticsCard from './StatisticsCard';
import LoadingSnackbar from '../../../components/LoadingSnackbar';
import Board from './Board';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    margin: theme.spacing(-0.5),
  },
  card: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.contrastText
  }
}));

function Content({ data, isLoading }) {
  const classes = useStyles();

  return (
    <>
      <LoadingSnackbar show={isLoading} />
      <Grid container>
        <Grid container justifyContent="flex-start" className={classes.wrapper}>
          {_.map(data.filtered_statistics, (value, key) => (
            <StatisticsCard value={value} title={value.title} key={key} icon={key} />
          ))}
        </Grid>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid container item direction="column" xs={12} md={6} className={classes.card}>
            <Board statistics={data.statistics} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

Content.defaultProps = {
  isLoading: false
};

export default Content;
