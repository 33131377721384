import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchOrganizationAction } from '../../reducers/organizations';
import LoadingIndicator from '../LoadingIndicator';
import { fetchOrganizationUserPermissions } from '../../reducers/auth';
import CommonPropTypes from '../../common/PropTypes';

const Main = ({
  organization,
  children,
  isManagement,
  hasUserPermissions,
  fetchOrganization,
  fetchUserPermissions
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!organization) {
      fetchOrganization();
    }
  }, [organization, isManagement, fetchOrganization]);

  useEffect(() => {
    if (!isManagement || !organization || hasUserPermissions) {
      return;
    }
    fetchUserPermissions(organization.id);
  }, [hasUserPermissions, organization, isManagement, fetchUserPermissions]);
  if (!organization) {
    return <LoadingIndicator />;
  }

  const props = {
    data: organization,
    redirect: location => {
      const path = isManagement ? `/organizations/${organization.id}${location}` : location;
      history.push(path);
    },
    label: isManagement ? `Organization: ${organization.name}` : null
  };

  return children({ organization: props });
};

Main.propTypes = {
  organization: CommonPropTypes.organization,
  children: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  isManagement: PropTypes.bool.isRequired,
  hasUserPermissions: PropTypes.bool.isRequired
};

Main.defaultProps = {
  organization: null
};

const mapProps = (state, ownProps) => {
  const { isManagement } = state.auth;
  const hasUserPermissions = !!state.auth.management.organizationsPermissions[
    ownProps.organizationId
  ];
  let organization;
  if (
    (state.auth.currentOrganization &&
      ownProps.organizationId === state.auth.currentOrganization.id) ||
    (!isManagement && !ownProps.organizationId)
  ) {
    organization = state.auth.currentOrganization;
  } else {
    organization = state.organizations.items.find(item => item.id === ownProps.organizationId);
  }
  return {
    organization,
    isManagement,
    hasUserPermissions
  };
};

const mapDispatch = (dispatch, ownProps) => {
  return {
    fetchOrganization: () => {
      if (ownProps.organizationId) {
        dispatch(fetchOrganizationAction(ownProps.organizationId));
      }
    },
    fetchUserPermissions: organizationId =>
      dispatch(fetchOrganizationUserPermissions(organizationId))
  };
};

export default connect(mapProps, mapDispatch)(Main);
