import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { set, isEqual, cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { validate } from './fields';
// import InputGroup from './InputGroup';
import RxForms from './RxForms';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .customize-fees-section-label': {
      fontSize: '1rem',
      color: theme.palette.primary.main,
    }
  },
  actionButtons: {
    margin: theme.spacing(0.5)
  }
}));

const Container = ({ fees, originalFees, defaultFees, onChange, onSave, discountProps }) => {
  const classes = useStyles();
  const allowReset = defaultFees && Object.keys(defaultFees).length > 0;
  const [dirty, setDirty] = useState(false);
  const [customized, setCustomized] = useState(false);
  const [errors, setErrors] = useState(null);

  const exclude = [];

  const handleFieldChange = (name, value) => {
    set(fees, name, value);
    onChange({ ...fees });
  };

  const handleReset = () => {
    onChange(cloneDeep(defaultFees));
  };

  const handleCancel = () => {
    onChange(cloneDeep(originalFees));
  };

  useEffect(() => {
    const hasChanged = !isEqual(fees, originalFees);
    setDirty(hasChanged);
  }, [fees, originalFees]);

  useEffect(() => {
    const isCustomized = defaultFees && !isEqual(fees, defaultFees);
    setCustomized(isCustomized);
  }, [fees, defaultFees]);

  useEffect(() => {
    if (!fees) {
      return;
    }

    validate(fees, exclude)
      .then(() => {
        if (fees) {
          setErrors(null);
        }
      })
      .catch(err => {
        if (isEqual(err, errors)) {
          return;
        }
        setErrors(err);
      });
  }, [errors, fees, exclude]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <RxForms
        givenFees={fees}
        defaultFees={defaultFees}
        setFieldValue={handleFieldChange} />

      {/* <InputGroup
        setFieldValue={handleFieldChange}
        values={fees}
        errors={errors}
        defaultValues={defaultFees}
        name="shipping_fees"
        label="Shipping Fees"
        xs={12}
        sm={12}
        md={6}
        fieldWrapperProps={{ columns: { sm: 1, md: 1 } }}
      /> */}
      <Grid item xs={12}>
        <Divider />
        <Box display="flex" justifyContent="flex-end" m={2}>
          {allowReset && (
            <Button
              disabled={!customized}
              onClick={handleReset}
              variant="contained"
              className={classes.actionButtons}
            >
              Reset Default
            </Button>
          )}
          <>
            <Button
              disabled={!dirty || !!errors}
              onClick={onSave}
              variant="contained"
              color="primary"
              className={classes.actionButtons}
            >
              Save
            </Button>
            <Button
              disabled={!dirty}
              onClick={handleCancel}
              variant="contained"
              color="secondary"
              className={classes.actionButtons}
            >
              Cancel
            </Button>
          </>
        </Box>
      </Grid>
    </Grid>
  );
};

Container.defaultProps = {
  defaultFees: {},
  discountProps: {}
};

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fees: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  originalFees: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultFees: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  discountProps: PropTypes.object
};

export default Container;
