import React, { useCallback, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { produce } from 'immer';
import createLoadingSelector from '../../selectors/loading';
import DataTable from '../../components/DataTable';
import {
  deleteLicenseAction,
  FETCH_LICENSES_PREFIX,
  fetchLicensesAction
} from '../../reducers/licenses';
import SearchInput from '../../components/SearchInput';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%'
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  },
  addLicense: {
    marginLeft: 'auto'
  }
}));

const Licenses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(state => createLoadingSelector([FETCH_LICENSES_PREFIX])(state));
  const [additionalParams, setAdditionalParams] = useState({
    params: { searchFullNameQuery: '' },
    resetPage: false
  });
  const rows = useSelector(state => state.licenses.items);
  const total = useSelector(state => state.licenses.total);

  const handleUpdateData = useCallback(
    params => {
      dispatch(fetchLicensesAction(params));
    },
    [dispatch]
  );

  const handleOnChangeSearchInput = debounce(value => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.searchFullNameQuery = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleOnEdit = id => {
    history.push(`/edit-license/${id}`);
  };

  const handleOnDelete = id => {
    dispatch(deleteLicenseAction(id));
  };

  const columns = [
    {
      id: 'user.full_name',
      customSortId: 'full_name_sort',
      numeric: false,
      disablePadding: false,
      label: 'User'
    },
    {
      id: 'license_type.name',
      customSortId: 'license_type_id',
      numeric: false,
      disablePadding: false,
      label: 'License Type'
    },
    {
      id: 'activated_at',
      numeric: false,
      disablePadding: false,
      label: 'Activation Date',
      type: 'datetime'
    },
    {
      id: 'expiration_date',
      numeric: false,
      disablePadding: false,
      label: 'Expiration Date',
      type: 'datetime'
    },
    { id: 'seats', numeric: false, disablePadding: false, label: 'Seats' }
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by User"
                className={classes.searchInput}
                onChange={e => handleOnChangeSearchInput(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          total={total}
          updateData={handleUpdateData}
          defaultOrderBy="full_name_sort"
          actions={{ edit: true, handleOnEdit, delete: false, handleOnDelete }}
          additionalParams={additionalParams}
        />
      </div>
    </Container>
  );
};

Licenses.propTypes = {};

Licenses.defaultProps = {};

export default Licenses;
