import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Box, List } from '@material-ui/core';
import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadFilesDialogGeneral from '../../../components/UploadFilesDialogGeneral';
import RxFormsApiService from '../../../services/api/rxForms';

const UploadRxFormFile = ({ title, onChange, maxFiles, label, values, organization, ...props }) => {
  const [openFileUploader, setOpenFileUploader] = useState(false);
  const rxFormsApiService = new RxFormsApiService();

  const handleClose = response => {
    setOpenFileUploader(false);
    if (response) {
      onChange(values.concat(response));
    }
  };

  const getUploadUrl = name => {
    return rxFormsApiService.getUploadLink(name, organization.id);
  };

  const deleteFile = filePath => {
    onChange(values.filter(file => file.file_path !== filePath));
  };

  return (
    <Box my={1} width={1}>
      {values.length > 0 && (
        <List>
          {values.map(item => (
            <ListItem key={item.file_path}>
              <ListItemIcon>
                <AttachmentIcon />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => deleteFile(item.file_path)}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {values.length < maxFiles && (
        <Button color="primary" variant="contained" onClick={() => setOpenFileUploader(true)}>
          {label}
        </Button>
      )}
      <UploadFilesDialogGeneral
        handleClose={handleClose}
        open={openFileUploader}
        onFinishUpload={() => {}}
        onRemoveUpload={() => {}}
        title={title}
        getUploadUrl={getUploadUrl}
        maxFiles={maxFiles}
        {...props}
      />
    </Box>
  );
};

UploadRxFormFile.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

UploadRxFormFile.defaultProps = {
  maxFiles: 100
};

export default UploadRxFormFile;
