import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { decodeFormikValues, encodeFormikValues } from '../../../components/RxForm/helpers';
import { ATTRIBUTES } from '../../../components/RxForm/constants';
import { Button, Card, CardActions, Divider } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import DatePicker from '../../../components/DatePicker';
import DoctorSelectionField from '../../../components/RxForm/Fields/DoctorSelectionField';
import LoadingButton from '../../../components/LoadingButton';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import UploadRxFormFile from '../../RxForms/components/UploadRxFormFile';
import InputErrorMessage from '../../../components/InputErrorMessage';
import OrganizationInfo from '../../../components/OrganizationInfo';

const OldCaseForm = ({ organization, rxForm, onSubmit, onCancel, isSaving, doctors }) => {
  const encodedFormikValues = encodeFormikValues(rxForm);

  return (
    <div>
      <Formik
        initialValues={encodedFormikValues}
        enableReinitialize
        onSubmit={data => {
          const decodedValues = decodeFormikValues(data);
          return onSubmit(decodedValues);
        }}
      >
        {({
          submitForm,
          isValid,
          status,
          dirty,
          values,
          setFieldValue,
          isSubmitting,
          setFieldTouched
        }) => {
          return (
            <Card>
              <CardHeader
                title="Add Old Case"
                subheader={<OrganizationInfo organization={organization} />}
              />
              <CardContent>
                <Form noValidate>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        variant="outlined"
                        fullWidth
                        required
                        id="first_name"
                        label="Patient First Name"
                        name="first_name"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="last_name"
                        variant="outlined"
                        required
                        fullWidth
                        id="last_name"
                        label="Patient Last Name"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="case_number_text"
                        variant="outlined"
                        fullWidth
                        id="case_number_text"
                        label="Patient Case Number"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker label="Impression Date" name="impression_date" required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DoctorSelectionField
                        name={ATTRIBUTES.DOCTOR}
                        doctors={doctors}
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Upload IOS files here</FormLabel>
                        <UploadRxFormFile
                          accept="application/sla, model/ios, .ios"
                          name={ATTRIBUTES.IOS_FILES}
                          title="Upload IOS File"
                          onChange={files => {
                            setTimeout(() => setFieldValue(ATTRIBUTES.IOS_FILES, files));
                            setFieldTouched(ATTRIBUTES.IOS_FILES, true, false);
                          }}
                          values={values.ios_files}
                          label="Add IOS Files"
                          maxFiles={10}
                          organization={organization}
                          preferenceValue={[]}
                        />
                        <ErrorMessage
                          name="rx_intraoral_photos"
                          render={message => <InputErrorMessage>{message}</InputErrorMessage>}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Form>
              </CardContent>
              <Divider />

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <>
                  <Button
                    disabled={isSaving}
                    color="secondary"
                    variant="contained"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSaving}
                    disabled={!dirty || !isValid || !!status}
                    onClick={submitForm}
                  >
                    Save
                  </LoadingButton>
                </>
              </CardActions>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
};

OldCaseForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  doctors: PropTypes.array
};

OldCaseForm.defaultProps = {
  doctors: []
};

export default OldCaseForm;
