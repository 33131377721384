import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import ContractsApiService from '../services/api/contracts';
import OrganizationsApiService from '../services/api/organizations';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_CONTRACTS_PREFIX = 'FETCH_CONTRACTS';
export const FETCH_CONTRACTS_REQUEST_ACTION = `${FETCH_CONTRACTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_CONTRACTS_SUCCESS_ACTION = `${FETCH_CONTRACTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CONTRACTS_FAILURE_ACTION = `${FETCH_CONTRACTS_PREFIX}_FAILURE_ACTION`;

export const FETCH_CONTRACT_PREFIX = 'FETCH_CONTRACT';
export const FETCH_CONTRACT_REQUEST_ACTION = `${FETCH_CONTRACT_PREFIX}_REQUEST_ACTION`;
export const FETCH_CONTRACT_SUCCESS_ACTION = `${FETCH_CONTRACT_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CONTRACT_FAILURE_ACTION = `${FETCH_CONTRACT_PREFIX}_FAILURE_ACTION`;

export const ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX = 'ATTEMPT_CURRENT_PAYMENT_AGAIN';
export const ATTEMPT_CURRENT_PAYMENT_AGAIN_REQUEST_ACTION = `${ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX}_REQUEST_ACTION`;
export const ATTEMPT_CURRENT_PAYMENT_AGAIN_SUCCESS_ACTION = `${ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX}_SUCCESS_ACTION`;
export const ATTEMPT_CURRENT_PAYMENT_AGAIN_FAILURE_ACTION = `${ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX}_FAILURE_ACTION`;

export const CANCEL_CONTRACT_PREFIX = 'CANCEL_CONTRACT';
export const CANCEL_CONTRACT_REQUEST_ACTION = `${CANCEL_CONTRACT_PREFIX}_REQUEST_ACTION`;
export const CANCEL_CONTRACT_SUCCESS_ACTION = `${CANCEL_CONTRACT_PREFIX}_SUCCESS_ACTION`;
export const CANCEL_CONTRACT_FAILURE_ACTION = `${CANCEL_CONTRACT_PREFIX}_FAILURE_ACTION`;

export const SUSPEND_CONTRACT_PREFIX = 'SUSPEND_CONTRACT';
export const SUSPEND_CONTRACT_REQUEST_ACTION = `${SUSPEND_CONTRACT_PREFIX}_REQUEST_ACTION`;
export const SUSPEND_CONTRACT_SUCCESS_ACTION = `${SUSPEND_CONTRACT_PREFIX}_SUCCESS_ACTION`;
export const SUSPEND_CONTRACT_FAILURE_ACTION = `${SUSPEND_CONTRACT_PREFIX}_FAILURE_ACTION`;

export const RESUME_CONTRACT_PREFIX = 'RESUME_CONTRACT';
export const RESUME_CONTRACT_REQUEST_ACTION = `${RESUME_CONTRACT_PREFIX}_REQUEST_ACTION`;
export const RESUME_CONTRACT_SUCCESS_ACTION = `${RESUME_CONTRACT_PREFIX}_SUCCESS_ACTION`;
export const RESUME_CONTRACT_FAILURE_ACTION = `${RESUME_CONTRACT_PREFIX}_FAILURE_ACTION`;

export const CHANGE_CONTRACT_PLAN_PREFIX = 'CHANGE_CONTRACT_PLAN';
export const CHANGE_CONTRACT_PLAN_REQUEST_ACTION = `${CHANGE_CONTRACT_PLAN_PREFIX}_REQUEST_ACTION`;
export const CHANGE_CONTRACT_PLAN_SUCCESS_ACTION = `${CHANGE_CONTRACT_PLAN_PREFIX}_SUCCESS_ACTION`;
export const CHANGE_CONTRACT_PLAN_FAILURE_ACTION = `${CHANGE_CONTRACT_PLAN_PREFIX}_FAILURE_ACTION`;

export const FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX = 'FETCH_CONTRACT_ORGANIZATION_ORDERS';
export const FETCH_CONTRACT_ORGANIZATION_ORDERS_REQUEST_ACTION = `${FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_CONTRACT_ORGANIZATION_ORDERS_SUCCESS_ACTION = `${FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CONTRACT_ORGANIZATION_ORDERS_FAILURE_ACTION = `${FETCH_CONTRACT_ORGANIZATION_ORDERS_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  licenseTypes: [],
  globalParams: {
    from: null,
    to: null,
    order: 'desc',
    orderBy: 'custom_next_payment_date',
    paymentStatus: 'ALL',
    page: 1,
    perPage: 25,
    searchFullNameQuery: ''
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTRACTS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.contracts.data,
        total: action.payload.contracts.total,
        globalParams: action.payload.params
      };
    case FETCH_CONTRACT_SUCCESS_ACTION:
      return produce(state, draft => {
        const contractIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (contractIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case ATTEMPT_CURRENT_PAYMENT_AGAIN_SUCCESS_ACTION:
      return produce(state, draft => {
        const contractIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (contractIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case RESUME_CONTRACT_SUCCESS_ACTION:
    case SUSPEND_CONTRACT_SUCCESS_ACTION:
      return produce(state, draft => {
        const contractIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[contractIndex] = action.payload;
      });
    case CHANGE_CONTRACT_PLAN_SUCCESS_ACTION:
      return produce(state, draft => {
        const contractIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[contractIndex] = action.payload;
      });
    case FETCH_CONTRACT_ORGANIZATION_ORDERS_SUCCESS_ACTION:
      return produce(state, draft => {
        const contractIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[contractIndex].organization.orders = action.payload.orders;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchContractsAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_CONTRACTS_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_CONTRACTS_SUCCESS_ACTION,
          payload: { contracts: response.contracts, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CONTRACTS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchContractAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_CONTRACT_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .show(id)
      .then(response => {
        dispatch({ type: FETCH_CONTRACT_SUCCESS_ACTION, payload: response.contract });
      })
      .catch(error => {
        dispatch({ type: FETCH_CONTRACT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function attemptCurrentPaymentAgainAction(contractId) {
  return dispatch => {
    dispatch({ type: ATTEMPT_CURRENT_PAYMENT_AGAIN_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .attemptPaymentAgain(contractId)
      .then(({ contract, message }) => {
        dispatch({
          type: ATTEMPT_CURRENT_PAYMENT_AGAIN_SUCCESS_ACTION,
          payload: contract
        });
        dispatch(enqueueNotification('success', message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ATTEMPT_CURRENT_PAYMENT_AGAIN_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function cancelContractAction(contractId) {
  return dispatch => {
    dispatch({ type: CANCEL_CONTRACT_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .cancelContract(contractId)
      .then(({ message }) => {
        dispatch({
          type: CANCEL_CONTRACT_SUCCESS_ACTION
        });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: CANCEL_CONTRACT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function suspendContractAction(contractId) {
  return dispatch => {
    dispatch({ type: SUSPEND_CONTRACT_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .suspendContract(contractId)
      .then(({ message, contract }) => {
        dispatch({
          type: SUSPEND_CONTRACT_SUCCESS_ACTION,
          payload: contract
        });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: SUSPEND_CONTRACT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resumeContractAction(contractId) {
  return dispatch => {
    dispatch({ type: RESUME_CONTRACT_REQUEST_ACTION });
    const contractsService = new ContractsApiService();
    return contractsService
      .resumeContract(contractId)
      .then(({ message, contract }) => {
        dispatch({
          type: RESUME_CONTRACT_SUCCESS_ACTION,
          payload: contract
        });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: RESUME_CONTRACT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchContractOrganizationOrdersAction(id, organizationId) {
  return dispatch => {
    dispatch({ type: FETCH_CONTRACT_ORGANIZATION_ORDERS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.getOrganizationOrders(organizationId)
      .then(({ orders }) => {
        dispatch({
          type: FETCH_CONTRACT_ORGANIZATION_ORDERS_SUCCESS_ACTION,
          payload: { id, orders }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CONTRACT_ORGANIZATION_ORDERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
