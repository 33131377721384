import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MoneyFormat from '../../MoneyFormat';
import { UTCDateTimeToLocalTime } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0)
  }
}));

const V1 = ({ order, detailed }) => {
  const classes = useStyles();

  const formatOrderItemSecondary = orderItem => {
    const items = [];

    if (orderItem.action_date) {
      items.push(orderItem.action_date);
    }

    if (orderItem.details && orderItem.start_date && orderItem.end_date) {
      items.push(
        `From: ${UTCDateTimeToLocalTime(orderItem.start_date)} Until: ${UTCDateTimeToLocalTime(
          orderItem.end_date
        )}`
      );
    }

    return items.join(' | ');
  };

  const formatOrderItemPrimary = orderItem => {
    const items = [];

    items.push(orderItem.product.name);

    if (orderItem.patient) {
      let fullName = orderItem.patient.full_name;
      if (orderItem.patient.numeric_suffix) {
        fullName = `${fullName} (${orderItem.patient.numeric_suffix})`;
      }
      items.push(fullName);

      if (orderItem.patient.case_number_text) {
        items.push(`Case #${orderItem.patient.case_number_text}`);
      }
      if (orderItem.details && orderItem.details.doctor) {
        items.push(`Dr. ${orderItem.details.doctor.name}`);
      }
    }

    return items.join(' | ');
  };

  const getFormattedOrderItems = (currentOrder, showDetails) => {
    let formattedOrderItems = get(currentOrder, 'order_items', []);
    if (!showDetails) {
      formattedOrderItems = formattedOrderItems
        .filter(item => !item.is_hidden && item.product_id)
        .reduce((acc, item) => {
          const itemFoundIndex = acc.findIndex(accItem => accItem.product.id === item.product.id);
          if (itemFoundIndex === -1) {
            acc.push({ ...item, number: 1 });
          } else {
            // eslint-disable-next-line no-param-reassign
            acc[itemFoundIndex].number += 1;
            // eslint-disable-next-line no-param-reassign
            acc[itemFoundIndex].action_date = null;
            // eslint-disable-next-line no-param-reassign
            acc[itemFoundIndex].value = Number(acc[itemFoundIndex].value) + Number(item.value);
          }
          return acc;
        }, [])
        .map(orderItem => {
          const { product, id, number, value } = orderItem;
          return {
            primary: `${product.name} ${number > 1 ? `x${number}` : ''}`,
            secondary: formatOrderItemSecondary(orderItem),
            value: <MoneyFormat value={value} />,
            id
          };
        });
    } else {
      formattedOrderItems = formattedOrderItems
        .filter(item => !item.is_hidden && item.product_id)
        .slice()
        .sort(
          (a, b) =>
            b &&
            b.patient &&
            b.patient.full_name &&
            a &&
            a.patient &&
            a.patient.full_name &&
            a.patient.full_name.localeCompare(b.patient.full_name)
        )
        .map(orderItem => {
          const { id, value } = orderItem;

          return {
            primary: formatOrderItemPrimary(orderItem),
            secondary: formatOrderItemSecondary(orderItem),
            value: <MoneyFormat value={value} />,
            id
          };
        });
    }

    return formattedOrderItems;
  };

  return getFormattedOrderItems(order, detailed).map(({ primary, secondary, id, value }) => (
    <ListItem className={classes.listItem} key={id}>
      <ListItemText primary={primary} secondary={secondary} />
      <Typography variant="body2">{value}</Typography>
    </ListItem>
  ));
};

V1.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
  detailed: PropTypes.bool.isRequired
};

export default V1;
