import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import { formatAccountNumber } from '../../helpers';

const useStyles = makeStyles(() => ({
  userLabel: {
    fontWeight: 'bold'
  }
}));

const OrganizationInfo = ({ organization }) => {
  const classes = useStyles();
  const history = useHistory();
  const isManagement = useSelector(state => state.auth.isManagement);

  const openOrganization = id => {
    if (isManagement) {
      history.push(`/organizations/${id}`);
    } else {
      history.push(`/account?tab=6`);
    }
  };

  return (
    <>
      <span className={classes.userLabel}>Organization: </span>
      <Link href="#" onClick={() => openOrganization(organization.id)} color="inherit">
        {`${organization.name} | ${formatAccountNumber(organization.account_number)}`}
      </Link>
    </>
  );
};

OrganizationInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

OrganizationInfo.defaultProps = {};

export default OrganizationInfo;
