import { createTheme } from '@material-ui/core';

const v1 = createTheme({
  palette: {
    primary: {
      main: '#008CD2'
    }
  }
});

export default v1;
