import { Query } from 'cogent-js';
import RequestsApiService from './requests';

export default class OrganizationCustomerRequestsApiService extends RequestsApiService {
  constructor({ organization, ...rest }) {
    super(rest);
    this.query = new Query();
    this.organization = organization;
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    searchFullNameQuery = '',
    includes = [],
    searchDoctorFullNameQuery = '',
    statusId = '',
    organizationId = '',
    locations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for(`organizations/${this.organization.id}/customer_requests`)
        .include(...includes)
        .where('patient.user_id', userId)
        .where('patient_full_name_like', searchFullNameQuery)
        .where('doctor_full_name_like', searchDoctorFullNameQuery)
        .where('patient.organization_id', organizationId)
        .where('status_id', statusId)
        .whereIn('chosen_wi_patient.location_id', locations)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }
}
