import BaseApiService from './base';

export default class OrdersApiService extends BaseApiService {
  show(id) {
    return this.request({
      method: 'get',
      url: `/orders/${id}`
    });
  }

  getCurrentOrder(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/current_order`
    });
  }

  addDiscountOrChargeToOrder(orderId, data) {
    return this.request({
      method: 'post',
      url: `/orders/${orderId}/add_discount_or_charge`,
      data
    });
  }

  removeDiscountToOrder(orderId, discountId) {
    return this.request({
      method: 'delete',
      url: `/orders/${orderId}/remove_discount`,
      data: {
        discount_id: discountId
      }
    });
  }

  removeChargeToOrder(orderId, chargeId) {
    return this.request({
      method: 'delete',
      url: `/orders/${orderId}/remove_charge`,
      data: {
        charge_id: chargeId
      }
    });
  }

  downloadInvoice(id, type) {
    return this.request({
      responseType: 'blob',
      method: 'get',
      url: `/orders/${id}/download_invoice`,
      params: { type },
      handleResponse: res => res
    });
  }
}
