import parsePhoneNumber from 'libphonenumber-js';

export default number => {
  if (!number) {
    return '';
  }
  const parsedNumber = parsePhoneNumber(number);

  if (parsedNumber.country === 'US') {
    return `+1 ${parsedNumber.formatNational()}`;
  }

  return parsedNumber.formatInternational();
};
