import React from 'react';
import * as Yup from 'yup';

export const STEPS = [
  {
    title: 'Brackets',
    subSteps: [
      {
        title: 'OrthoSelect ships Welcome Kit to your practice',
        content:
          'OrthoSelect will ship a Welcome Kit to your practice. This Welcome Kit will include a prepaid shipping ' +
          'envelope with bracket containers, shipping instructions, and a bracket shipment form inside.',
        input: {
          metadataKey: 'tracking_number',
          type: 'text',
          label: 'Tracking Number',
          required: true,
          style: { width: '300px' },
          validation: Yup.string()
            .required('Field is empty.')
            .max(30, 'Up to 30 characters')
            .min(6, 'Minimum of 6 characters.')
            .matches(/^([A-Za-z0-9]+)$/, 'Must only contain alphanumeric characters')
        }
      },
      {
        title: 'Send physical brackets and completed bracket shipment form to OrthoSelect',
        content: (
          <>
            Instructions on how to do this will be included in the Welcome Kit that you receive by
            mail, but for digital copies of these documents click{' '}
            <a
              href="https://myorthoselect.com/wp-content/uploads/2021/06/Shipping-Brackets-for-new-and-domestic-accounts.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              HERE
            </a>{' '}
            for the shipping brackets document and{' '}
            <a
              href="https://myorthoselect.com/wp-content/uploads/2021/05/BracketShipmentFormFillable-1.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              HERE
            </a>{' '}
            for the bracket shipment form.
          </>
        ),
        tip: 'This will be checked when OrthoSelect receives your shipment.'
      },
      {
        title: 'Send part numbers to OrthoSelect',
        content: (
          <>
            Click{' '}
            <a
              href="https://myorthoselect.com/wp-content/uploads/2021/04/217-Sending-Bracket-Part-Numbers.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              HERE
            </a>{' '}
            for instructions on how to send your bracket part numbers to OrthoSelect.
          </>
        )
      },
      {
        title: 'Your digital brackets library will be created',
        content:
          'The bracket team has received your physical brackets and part numbers and has started to work on creating ' +
          'your digital bracket library.',
        input: {
          metadataKey: 'estimated_date_of_completion',
          type: 'date',
          label: 'Completion Date',
          helperText: 'Expected completion date of your first bracket.',
          required: true,
          style: { width: '300px' },
          validation: Yup.date().required('Field is empty.')
        }
      }
    ]
  },
  {
    title: 'Software',
    subSteps: [
      {
        title: 'Submit an initial case setup to OrthoSelect',
        content: (
          <>
            To do this,
            <ul>
              <li>
                Log in to{' '}
                <a href="http://syncdibsai.com/" target="_blank" rel="noreferrer noopener">
                  syncdibsai.com
                </a>
              </li>
              <li>
                Click 'Start a Case' on the left hand side of your dashboard to submit a request to
                OrthoSelect to do the initial case setup
              </li>
              <li>
                Enter the patient information, select that 'OrthoSelect' will do the initial case
                setup, upload (or send direct) intraoral scans and patient photos, choose which
                arches you want set up and click 'Create'
              </li>
            </ul>
            If you haven’t already, click{' '}
            <a
              href="https://myorthoselect.com/wp-content/uploads/2021/05/202-Intraoral-Scanner-Setup-Instructions.pdf"
              target="_black"
              rel="noreferrer noopener"
            >
              HERE
            </a>{' '}
            for instructions on how to connect your intraoral scanner to OrthoSelect so you can send
            scans directly.
          </>
        )
      },
      {
        title: 'Download software to your PC',
        content: meta => {
          // eslint-disable-next-line react/prop-types
          const installerLink = meta.links.installer;
          return (
            <>
              Click{' '}
              <a href={installerLink} target="_blank" rel="noreferrer noopener">
                HERE
              </a>{' '}
              to download the DSM software to your PC. After downloading the software, log in
              once; this will download a specific folder to your device.
            </>
          );
        }
      },
      {
        title: 'Download brackets to your PC',
        content: 'Click the link below to download your bracket set(s) to your device.'
      },
      {
        title: 'Review and approve your first case in the software',
        content:
          'Open the software and review and approve your first case on your own. Click HERE for a training ' +
          'video that covers all you need to know to get through your first case, click HERE to book a time to ' +
          'meet with our tech support and they can help you with any problems you might be having.'
      }
    ]
  },
  {
    title: 'Bonding',
    subSteps: [
      {
        title: "Review OrthoSelect's recommended products and procedures for bonding",
        content: (
          <>
            Click{' '}
            <a
              href="https://myorthoselect.com/wp-content/uploads/2021/04/203-DIBS-Clinical-Training-Manual-4.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              HERE
            </a>{' '}
            for OrthoSelect's clinical training manual that includes all recommended products and
            procedures for bonding.
          </>
        )
      },
      {
        title: 'Bond your first patient(s)',
        content:
          'The culmination of all your hard work comes to this point! Bond your first patient(s) and admire your ' +
          'glorious handiwork!'
      }
    ]
  },
  {
    title: 'Feedback',
    subSteps: [
      {
        title: 'Provide feedback on the onboarding process',
        content: (
          <>
            Click <a href="https://share.hsforms.com/1oJMuzOmIRbC9evIdSdauAQ2vfsr">HERE</a> to
            access our anonymous form to provide feedback on the DSM onboarding process.
          </>
        )
      }
    ]
  }
];

export function getStepIndex(stepNumber) {
  let totalIteratedSteps = 0;
  const data = {
    current: null,
    active: {
      parent: 0,
      child: 0
    }
  };
  STEPS.forEach((step, index) => {
    if (data.current !== null) {
      return;
    }
    if (totalIteratedSteps + step.subSteps.length >= stepNumber) {
      data.current = {
        parent: index,
        child: stepNumber - totalIteratedSteps - 1
      };
      if (data.current.child === step.subSteps.length - 1) {
        data.active = {
          parent: data.current.parent + 1,
          child: 0
        };
      } else {
        data.active = {
          parent: data.current.parent,
          child: data.current.child + 1
        };
      }
    } else {
      totalIteratedSteps += step.subSteps.length;
    }
  });
  return data;
}

export function getActualStepFromIndex(parent, child) {
  let step = 0;
  STEPS.forEach((mainStep, index) => {
    if (index < parent) {
      step += mainStep.subSteps.length;
    } else if (index === parent) {
      step += child + 1;
    }
  });
  return step;
}

export function getTotalSteps() {
  let step = 0;
  STEPS.forEach(mainStep => {
    step += mainStep.subSteps.length;
  });
  return step;
}

const stepLabels = {};

export function getStepLabel(step) {
  if (step === null) {
    return '';
  }
  if (stepLabels[step]) {
    return stepLabels[step];
  }
  const stepIndex = getStepIndex(step);
  stepLabels[step] = `${STEPS[stepIndex.current.parent].title} - ${
    STEPS[stepIndex.current.parent].subSteps[stepIndex.current.child].title
  }`;
  return stepLabels[step];
}
