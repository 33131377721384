import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { DELETE_MY_PAYMENT_METHOD_PREFIX, deleteMyPaymentMethodAction } from '../../reducers/auth';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../LoadingIndicator';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import PaymentMethodFormSelector from './components/PaymentMethodFormSelector';

const useStyles = makeStyles(theme => ({
  successIcon: {
    color: theme.palette.success.main,
    marginRight: '10px'
  },
  successMessage: {
    fontSize: '16px'
  }
}));

const SavePaymentInformation = ({
  isUpdating,
  showAlreadyAddedMessage,
  onAddedPaymentMethod,
  handleNext
}) => {
  const classes = useStyles();
  const [addedPaymentMethod, setAddedPaymentMethod] = useState(false);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const dispatch = useDispatch();
  const isLoading = useSelector(state =>
    createLoadingSelector([DELETE_MY_PAYMENT_METHOD_PREFIX])(state)
  );

  const onPaymentMethodSaved = () => {
    setAddedPaymentMethod(true);
    onAddedPaymentMethod(true);
  };

  const renderSuccessMessage = message => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={2}>
        <CheckCircleIcon className={classes.successIcon} fontSize="large" />
        <span className={classes.successMessage}>{message}</span>
      </Box>
    );
  };

  const handleDelete = id => {
    setAddedPaymentMethod(false);
    onAddedPaymentMethod(false);

    dispatch(deleteMyPaymentMethodAction(id));
  };

  useEffect(() => {
    if (addedPaymentMethod) {
      handleNext();
    }
  }, [handleNext, addedPaymentMethod]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isUpdating && get(currentOrganization, 'payment_info.payment_methods', []).length) {
    return (
      <div>
        {showAlreadyAddedMessage &&
          renderSuccessMessage('Your payment method has already been added.')}
        <Box display="flex" alignItems="center" justifyContent="center" p={1}>
          <ConfirmationDialogOnClickWrapper
            eventName="onDelete"
            confirmationBody="Are you sure you want to remove this payment method?"
            confirmationTitle="Remove Payment Method"
          >
            <Chip
              icon={<CreditCardIcon />}
              label={currentOrganization.payment_info.payment_methods[0].payment_method_nickname}
              onDelete={() => handleDelete(currentOrganization.payment_info.payment_methods[0].id)}
            />
          </ConfirmationDialogOnClickWrapper>
        </Box>
      </div>
    );
  }

  return <PaymentMethodFormSelector onPaymentMethodSaved={onPaymentMethodSaved} />;
};

SavePaymentInformation.propTypes = {
  isUpdating: PropTypes.bool,
  showAlreadyAddedMessage: PropTypes.bool,
  onAddedPaymentMethod: PropTypes.func,
  handleNext: PropTypes.func.isRequired
};

SavePaymentInformation.defaultProps = {
  isUpdating: false,
  showAlreadyAddedMessage: false,
  onAddedPaymentMethod: () => {}
};

export default SavePaymentInformation;
