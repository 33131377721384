import { Query } from 'cogent-js';
import PatientApiService from './patients';

const addQueryWhereClause = (
  query,
  {
    userId = '',
    organizationId = '',
    searchFullNameQuery = '',
    searchDoctorFullNameOrOrganizationQuery = '',
    patientStatusId = '',
    newPatientStatus = '',
    onlyDeleted = false,
    onlyOnhold = 'with',
    locations = []
  }
) => {
  return query
    .where('user_id', userId)
    .where('organization_id', organizationId)
    .where('patient_status_id', patientStatusId)
    .where('patient_status', newPatientStatus)
    .where('patient_full_name_like', searchFullNameQuery)
    .where('doctor_full_name_or_organization_like', searchDoctorFullNameOrOrganizationQuery)
    .where('trashed', onlyDeleted ? 'only' : '')
    .where('held', onlyOnhold)
    .whereIn('chosen_wi_location_id', locations);
};

export default class OrganizationPatientsApiService extends PatientApiService {
  constructor({ organization, ...rest }) {
    super(rest);
    this.query = new Query();
    this.organization = organization;
  }

  get(params) {
    const { perPage = 5, page = 1, order, orderBy, includes } = params;
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    const url = addQueryWhereClause(
      this.query.for(`organizations/${this.organization.id}/patients`).include(...includes),
      params
    )
      .sort(orderChar + orderByField)
      .params({ perPage, page })
      .get();
    return this.request({
      method: 'get',
      url
    });
  }

  patientCountsPerStatus(params) {
    const { newPatientStatus, ...rest } = params;
    return this.request({
      method: 'get',
      url: addQueryWhereClause(
        this.query.for(`organizations/${this.organization.id}/patient_counts_per_status`),
        rest
      ).get()
    });
  }
}
