import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

function SubscriptionStartNotice({ activeStep, showOnStep }) {
  return (
    activeStep === showOnStep && (
      <Alert severity="info">
        {`Your start up fee will be charged now. Then in 30 days your monthly billing cycle will begin (${moment()
          .add(1, 'M')
          .format('YYYY-MM-DD')}).`}
      </Alert>
    )
  );
}

SubscriptionStartNotice.propTypes = {
  activeStep: PropTypes.number.isRequired,
  showOnStep: PropTypes.number.isRequired
};

export default SubscriptionStartNotice;
