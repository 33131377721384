import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import PatientSummary from '../../components/PatientSummary';

const PrintPatientSummary = ({ match: { params } }) => {
  const patientId = parseInt(params.id, 10);
  const patient = useSelector(state => {
    return state.patients.items[state.patients.items.findIndex(item => item.id === patientId)];
  });
  const request = useSelector(state => {
    return state.requests.items[
      state.requests.items.findIndex(item => item.id === get(patient, 'customer_request.id'))
    ];
  });
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      window.print();
      history.push(`/patient-details/${patientId}`);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!patient) {
    history.push(`/patient-details/${patientId}`);
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{patient.formatted_name}</title>
      </Helmet>
      <PatientSummary patient={patient} request={request} printingPreview />
    </>
  );
};

PrintPatientSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

PrintPatientSummary.defaultProps = {};

export default PrintPatientSummary;
