import moment from 'moment';
import { enqueueNotification } from './notifications';
import DashboadApiService from '../services/api/dashboard';
import { get } from 'lodash';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const FETCH_PATIENTS_PREFIX = 'FETCH_DASHBOARD_DATA';
export const FETCH_DASHBOARD_DATA_REQUEST_ACTION = `${FETCH_PATIENTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_DASHBOARD_DATA_SUCCESS_ACTION = `${FETCH_PATIENTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_DASHBOARD_DATA_FAILURE_ACTION = `${FETCH_PATIENTS_PREFIX}_FAILURE_ACTION`;

export const FORCE_SYNC_PREFIX = 'FORCE_SYNC_DATA';
export const FORCE_SYNC_REQUEST_ACTION = `${FORCE_SYNC_PREFIX}_REQUEST_ACTION`;
export const FORCE_SYNC_SUCCESS_ACTION = `${FORCE_SYNC_PREFIX}_SUCCESS_ACTION`;
export const FORCE_SYNC_FAILURE_ACTION = `${FORCE_SYNC_PREFIX}_FAILURE_ACTION`;

export const RESET_DASHBOARD_DATA_ACTION = `RESET_DASHBOARD_DATA_ACTION`;
export const SET_BOARD_ACTION = `SET_BOARD_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////
const initialState = {
  items: [],
  globalParams: {
    dateFilterFrom: moment().startOf('month'),
    dateFilterTo: moment().endOf('month'),
    timeFilter: 'this_month',
    resetPage: false
  },
  board: 'organization'
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_SUCCESS_ACTION:
      // eslint-disable-next-line no-case-declarations
      const { data } = action.payload;

      // eslint-disable-next-line no-restricted-syntax
      for (const [parent, items] of Object.entries(action.payload.data)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, obj] of Object.entries(items)) {
          if (
            // eslint-disable-next-line no-prototype-builtins
            obj.hasOwnProperty('live_data') &&
            (obj.live_data.length === 0 || obj.live_data === -1)
          ) {
            // data[parent][key].live_data = state.items[parent][key].live_data;
            data[parent][key].live_data = get(state, `items.${parent}.${key}.live_data`, {})
          }
        }
      }

      return {
        ...state,
        items: data,
        globalParams: {
          ...action.payload.params,
          resetPage: false
        }
      };
    case FORCE_SYNC_SUCCESS_ACTION:
      if (!action.payload.status) return state;

      return {
        ...state,
        globalParams: {
          resetPage: true
        }
      };
    case RESET_DASHBOARD_DATA_ACTION:
      return {
        ...state,
        items: [],
        globalParams: {
          ...state.globalParams,
          resetPage: true
        }
      };
    case SET_BOARD_ACTION:
      return {
        ...state,
        board: action.payload.board
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////
function shouldLoadFromCache(newParams, currentState) {
  if (currentState.items.length === 0) return false;

  const { globalParams } = currentState;

  return newParams.timeFilter !== 'custom' && newParams.timeFilter === globalParams.timeFilter;
}

export function resetDashboardDataAction() {
  return dispatch => {
    dispatch({ type: RESET_DASHBOARD_DATA_ACTION });
  };
}

export function fetchDashboardDataAction(params) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_DASHBOARD_DATA_REQUEST_ACTION });

    const currentState = getState().dashboard;

    if (shouldLoadFromCache(params, currentState)) {
      dispatch({
        type: FETCH_DASHBOARD_DATA_SUCCESS_ACTION,
        payload: { data: currentState.items, params }
      });

      return true;
    }

    const withLiveData = currentState.items.length === 0;

    const patientsService = new DashboadApiService();

    return patientsService
      .get({ ...params, withLiveData })
      .then(response => {
        dispatch({
          type: FETCH_DASHBOARD_DATA_SUCCESS_ACTION,
          payload: { data: response.data, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_DASHBOARD_DATA_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setBoard(board = 'organization') {
  return dispatch => {
    dispatch({ type: SET_BOARD_ACTION, payload: { board } });
  };
}
