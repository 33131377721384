import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShipmentBundling from './ShipmentBundling';
import { findRolesInUser } from '../../../helpers';
import { ROLES } from '../../../constants';

const RowsIterator = ({ enabledGroupShipment, DefaultRowsIteratorComponent, ...props }) => {
  if (!enabledGroupShipment) {
    return <DefaultRowsIteratorComponent {...props} />;
  }
  return <ShipmentBundling {...props} />;
};

RowsIterator.propTypes = {
  enabledGroupShipment: PropTypes.bool.isRequired,
  DefaultRowsIteratorComponent: PropTypes.elementType.isRequired
};

export default connect((state, props) => {
  const is3PP = findRolesInUser([ROLES.labPartner], state.auth.user);
  return {
    enabledGroupShipment: is3PP ? false : props.enabledGroupShipment
  };
})(RowsIterator);
