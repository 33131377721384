import { getToken } from './connection';

export function createDesktopURI(path, params = {}) {
  const token = getToken();
  const overrideParams = {
    ...params,
    access_token: token,
    server: process.env.REACT_APP_SERVER_NAME
  };
  const paramsString = Object.keys(overrideParams)
    .map(key => {
      return `${key}=${overrideParams[key]}`;
    })
    .join('&');
  return `dibs://${path}?${paramsString}`;
}

export function create3DViewerURI(file, patient) {
  return `/3dviewer/${file.viewer_id}/organization/${patient.organization_id}/patient/${patient.id}/${patient.full_name}`;
}
