import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  InstantSearch,
  SearchBox,
  Pagination,
  Configure,
  Panel,
  HitsPerPage,
  ToggleRefinement
} from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { Container, Typography, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Hit from '../../components/Hits/HitWrapper';
import CustomHits from '../../components/Hits/Custom/Hits';
import CustomStats from '../../components/Hits/Custom/Stats';
import CustomClearRefinements from '../../components/Hits/Custom/ClearRefinements';
import CustomRefinementList from '../../components/Hits/Custom/RefinementList';
import CustomDateRange from '../../components/Hits/Custom/DateRange';
import { Roles, Payment, Onboarding, CustomizedFees, Statuses } from './Data';
import { ArchiveStatusKey } from './Data/Statuses';

import 'instantsearch.css/themes/satellite-min.css';
import './GlobalSearch.css';
import {
  fetchConfig,
  fetchOrganizationTypes,
  updateRefinements,
  resetFilters
} from '../../reducers/globalSearch';
import LoadingIndicator from '../../components/LoadingIndicator';
import InvoiceVersion from './Data/InvoiceVersion';
import Can from '../../components/Can';
import LoadingSnackbar from '../../components/LoadingSnackbar';
import DropdownRefinementList from '../../components/Hits/Custom/DropdownRefinementList';
import StateResult from '../../components/Hits/Custom/StateResult';
import SortBy from './CustomFilters/SortBy';
import hasPermission from '../../selectors/hasPermission';

const useStyles = makeStyles(() => ({
  panelFilter: {
    marginBottom: '1rem',
    '& .ais-Panel-header': {
      marginBottom: '0.5rem'
    }
  }
}));

const GlobalSearch = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [roles, newStatus] = useMemo(() => {
    const urlQueryParams = new URLSearchParams(location.search);
    const queryRoles = urlQueryParams.get('filter[roles]') || null;
    const queryStatus = urlQueryParams.get('filter[status]') || null;
    return [queryRoles ? queryRoles.split(',') : [], queryStatus ? queryStatus.split(',') : []];
  }, [location.search]);

  // Since selecting an object, redux will compare the references instead of values
  // Hence need to provide a comparison function to determine if the object is changed
  const globalSearch = useSelector(state => state.globalSearch, _.isEqual);
  const orgTypeNames = useMemo(() => globalSearch.organization_types, [
    globalSearch.organization_types
  ]);
  const indexName = useMemo(() => globalSearch.config.index_name, [globalSearch.config.index_name]);
  const configurationFetched = useMemo(
    () =>
      !_.isNil(globalSearch.config.url) &&
      !_.isNil(globalSearch.config.key) &&
      !_.isNil(globalSearch.config.index_name),
    [globalSearch.config.url, globalSearch.config.key, globalSearch.config.index_name]
  );

  const [searchState, setSearchState] = React.useState({});
  const [isGeneratingCsv, setIsGeneratingCsv] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const [resetting, setResetting] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const csvLink = React.useRef();

  useEffect(() => {
    if (resetting) {
      setResetting(false);
      history.replace('/organizations-users');
    }
  }, [resetting, history, setResetting]);

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false);
    }
  }, [refreshing, history, setResetting]);

  useEffect(() => {
    if (csvData.length && !isGeneratingCsv) {
      csvLink.current.link.click();
    }
  }, [csvData, isGeneratingCsv, csvLink]);

  const updateRequestParam = params => {
    let newParams = {
      ...params
    };

    const facetFilters = _.get(params, 'facetFilters', []);
    const allFilters = _.flatten(facetFilters);

    const isArchived = _.includes(allFilters, 'is_archived:true');
    const hasArchivedStatus = _.includes(allFilters, 'status:6');

    if (!isArchived && !hasArchivedStatus) {
      newParams = {
        ...newParams,
        facetFilters: [...facetFilters, ['is_archived:false']]
      };
    }
    return newParams;
  };

  const searchClient = useMemo(() => {
    if (configurationFetched) {
      const instantMeiliClient = instantMeiliSearch(
        globalSearch.config.url,
        globalSearch.config.key,
        {
          paginationTotalHits: 100000,
          finitePagination: true
        }
      );

      return {
        ...instantMeiliClient,
        search(requests) {
          window.savedSearchRequest = { ...requests };
          return instantMeiliClient.search(
            _.map(requests, request => {
              return {
                ...request,
                params: updateRequestParam(request.params)
              };
            })
          );
        }
      };
    }

    return null;
  }, [configurationFetched, globalSearch.config.key, globalSearch.config.url]);

  const readyToRender = useMemo(
    () => !_.isNil(searchClient) && !_.isNil(indexName) && !_.isNil(orgTypeNames),
    [searchClient, indexName, orgTypeNames]
  );

  const createUser = () => {
    history.push('/users/add');
  };

  const createOrganization = () => {
    history.push('/organizations/add');
  };

  const formatDate = date => {
    if (!date) {
      return null;
    }

    const d = moment(date);

    if (!d.isValid()) {
      return null;
    }

    return d.format('YYYY-MMM-DD h:mm a Z');
  };

  const downloadCsv = async () => {
    setCsvData([]);
    setIsGeneratingCsv(true);

    const requests = JSON.parse(JSON.stringify(window.savedSearchRequest));
    const initialRequest = await searchClient.search(requests);

    const hitsPerPage = _.get(initialRequest, 'results.0.hitsPerPage');
    const totalHits = _.get(initialRequest, 'results.0.nbHits');
    const totalPages = Math.ceil(totalHits / hitsPerPage);

    let hits = [];

    for (let i = 0; i < totalPages; i += 1) {
      const r = _.map(requests, request => {
        if (_.get(request, 'params.hitsPerPage', 0) < 10) {
          return request;
        }

        return {
          ...request,
          params: {
            ...request.params,
            page: i
          }
        };
      });

      // eslint-disable-next-line no-await-in-loop
      const response = await searchClient.search(r);

      const resultHits = _.map(_.get(response, 'results.0.hits', []), hit => {
        let {
          status,
          onboarding,
          payment,
          customized_fees: cusFee,
          organization_type: orgTypeName,
          invoice_version: invoiceVersion,
          organization_name: organizationName,
          full_name: fullName,
          filter: role
        } = hit;

        status = newStatus && Statuses[status] ? Statuses[status].label : null;

        onboarding = onboarding && Onboarding[onboarding] ? Onboarding[onboarding].label : null;

        payment = payment && Payment[payment] ? Payment[payment].label : null;

        cusFee = cusFee && CustomizedFees[cusFee] ? CustomizedFees[cusFee].label : null;

        orgTypeName =
          orgTypeName && orgTypeNames[orgTypeName] ? orgTypeNames[orgTypeName].label : null;

        role = role && Roles[role] ? Roles[role].label : null;

        invoiceVersion =
          invoiceVersion && InvoiceVersion[invoiceVersion]
            ? InvoiceVersion[invoiceVersion].label
            : null;

        if (hit.model === 'organization') {
          organizationName = fullName;
          fullName = null;
        }

        return {
          ...hit,
          organization_name: organizationName,
          full_name: fullName,
          status,
          onboarding,
          payment,
          customized_fees: cusFee,
          organization_type: orgTypeName,
          invoice_version: invoiceVersion,
          role,
          joined_at: formatDate(hit.joined_at),
          first_case_submission: formatDate(hit.first_case_submission),
          newest_case_submission: formatDate(hit.newest_case_submission)
        };
      });

      hits = [...hits, ...resultHits];
    }
    setCsvData(hits);
    setIsGeneratingCsv(false);
  };

  const onSearchStateChange = nextSearchState => {
    setSearchState(nextSearchState);
    const isArchived = _.get(nextSearchState, 'toggle.is_archived', false);

    dispatch(updateRefinements('is_archived_only', isArchived));
    dispatch(updateRefinements('page', nextSearchState.page));

    if (nextSearchState.sortBy) {
      dispatch(updateRefinements('sort', nextSearchState.sortBy));
    }
  };

  const onCustomRefinementChange = () => {
    setSearchState({
      ...searchState,
      page: 1
    });
    dispatch(updateRefinements('page', 1));
  };

  const onReset = () => {
    dispatch(resetFilters());
    setResetting(true);
    setSearchState({
      ...searchState,
      page: 1
    });
  };

  const [hasResults, setHasResults] = React.useState(true);
  const onSearch = _.debounce(resultCount => {
    setHasResults(resultCount && resultCount > 0);
  }, 10);

  const canViewArchived = useSelector(state =>
    hasPermission(state, { permissions: ['organization.view-archived'] })
  );
  let filteredStatuses = Statuses;
  if (!canViewArchived) {
    filteredStatuses = { ...Statuses };
    delete filteredStatuses[ArchiveStatusKey];
  }

  useEffect(() => {
    dispatch(fetchConfig());
    dispatch(fetchOrganizationTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roles && newStatus && roles.length && newStatus.length) {
      dispatch(resetFilters());
      dispatch(updateRefinements('filter', roles));
      dispatch(updateRefinements('status', newStatus));
      setRefreshing(true);
    }
  }, [roles, newStatus, dispatch]);

  if (!readyToRender) return <LoadingIndicator />;

  return (
    <Container disableGutters>
      <LoadingSnackbar show={isGeneratingCsv} message="Generating CSV..." />
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        onSearchStateChange={onSearchStateChange}
        searchState={searchState}
      >
        <StateResult onSearch={onSearch} />
        <div className="left-panel">
          {!(resetting || refreshing) && (
            <>
              <Can
                permissions={['organization.view-archived']}
                yes={() => (
                  <Panel>
                    <ToggleRefinement
                      attribute="is_archived"
                      label="Archived"
                      value
                      defaultRefinement={globalSearch.is_archived_only}
                    />
                  </Panel>
                )}
              />
              <Panel>
                <Typography
                  component="span"
                  className="joined-date-filter-label"
                  style={{
                    fontSize: '.875rem',
                    lineHeight: '1.75rem'
                  }}
                >
                  Joined Date Filter
                </Typography>
                <CustomDateRange
                  attribute="joined_at_unix"
                  defaultRefinement={{
                    min: globalSearch.joinedDateFromUnix,
                    max: globalSearch.joinedDateToUnix
                  }}
                  fromParamKey="joinedDateFromUnix"
                  toParamKey="joinedDateToUnix"
                  style={{
                    height: '2.5rem'
                  }}
                  disableFutureDates
                />
              </Panel>
              <Panel>
                <Typography
                  component="span"
                  className="joined-date-filter-label"
                  style={{
                    fontSize: '.875rem',
                    lineHeight: '1.75rem'
                  }}
                >
                  Sort By
                </Typography>
                <SortBy
                  disabled={!!globalSearch.query}
                  defaultRefinement={globalSearch.sort || 'main_index:joined_at_unix:desc'}
                  currentRefinement={globalSearch.sort}
                  items={[
                    {
                      value: 'main_index:joined_at_unix:desc',
                      label: 'Newest to join'
                    },
                    {
                      value: 'main_index:joined_at_unix:asc',
                      label: 'Oldest to join'
                    },
                    {
                      value: 'main_index:number_of_patients:desc',
                      label: 'Highest # of submissions'
                    },
                    {
                      value: 'main_index:number_of_patients:asc',
                      label: 'Lowest # of submissions'
                    },
                    {
                      value: 'main_index:newest_case_submission_unix:desc',
                      label: 'Newest case submission'
                    },
                    {
                      value: 'main_index:newest_case_submission_unix:asc',
                      label: 'Oldest case submission'
                    },
                    {
                      value: 'main_index:submission_average:desc',
                      label: 'Highest submission average'
                    },
                    {
                      value: 'main_index:submission_average:asc',
                      label: 'Lowest submission average'
                    }
                  ]}
                />
              </Panel>
            </>
          )}

          <Panel>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadCsv}
              style={{ width: '100%' }}
              disabled={isGeneratingCsv || !hasResults}
            >
              {isGeneratingCsv ? 'Generating CSV...' : 'Download CSV'}
            </Button>
            <CSVLink
              headers={[
                { label: 'Organization', key: 'organization_name' },
                { label: 'Name', key: 'full_name' },
                { label: 'Email', key: 'email' },
                { label: 'Role', key: 'role' },
                { label: 'Joined Date', key: 'joined_at' },
                { label: 'First Case Submitted', key: 'first_case_submission' },
                { label: 'Last Case Submitted', key: 'newest_case_submission' },
                { label: 'Status', key: 'status' },
                { label: 'Onboarding Status', key: 'onboarding' },
                { label: 'Payment Status', key: 'payment' },
                { label: 'Customized Fees', key: 'customized_fees' },
                { label: 'Organization Type', key: 'organization_type' },
                { label: 'Invoice Version', key: 'invoice_version' },
                { label: 'Number of Cases', key: 'number_of_patients' }
              ]}
              data={csvData}
              filename={`organizations-and-users-${Date.now()}.csv`}
              ref={csvLink}
              style={{
                display: 'none'
              }}
            />
          </Panel>

          <Panel header="Roles" className={classes.panelFilter}>
            <CustomClearRefinements attribute="filter" onClick={onCustomRefinementChange} />
            <CustomRefinementList
              attribute="filter"
              options={Roles}
              defaultRefinement={globalSearch.filter}
              onClick={onCustomRefinementChange}
            />
          </Panel>

          <Panel header="Status" className={classes.panelFilter}>
            <CustomClearRefinements attribute="status" onClick={onCustomRefinementChange} />
            <DropdownRefinementList
              attribute="status"
              options={filteredStatuses}
              defaultRefinement={globalSearch.status}
              onClick={onCustomRefinementChange}
            />
          </Panel>

          {false && (
            <Panel header="Onboarding Status" className={classes.panelFilter}>
              <CustomClearRefinements attribute="onboarding" onClick={onCustomRefinementChange} />
              <DropdownRefinementList
                attribute="onboarding"
                options={Onboarding}
                defaultRefinement={globalSearch.onboarding}
                onClick={onCustomRefinementChange}
              />
            </Panel>
          )}

          <Panel header="Payment Status" className={classes.panelFilter}>
            <CustomClearRefinements attribute="payment" onClick={onCustomRefinementChange} />
            <DropdownRefinementList
              attribute="payment"
              options={Payment}
              defaultRefinement={globalSearch.payment}
              onClick={onCustomRefinementChange}
            />
          </Panel>

          <Panel header="Customized Fees" className={classes.panelFilter}>
            <CustomClearRefinements
              attribute="customized_fees"
              onClick={onCustomRefinementChange}
            />
            <DropdownRefinementList
              attribute="customized_fees"
              options={CustomizedFees}
              defaultRefinement={globalSearch.customized_fees}
              onClick={onCustomRefinementChange}
            />
          </Panel>

          <Panel header="Organization Type" className={classes.panelFilter}>
            <CustomClearRefinements
              attribute="organization_type"
              onClick={onCustomRefinementChange}
            />
            <DropdownRefinementList
              attribute="organization_type"
              options={orgTypeNames}
              defaultRefinement={globalSearch.organization_type}
              onClick={onCustomRefinementChange}
              limit={20}
            />
          </Panel>

          <Panel header="Invoice Version" className={classes.panelFilter}>
            <CustomClearRefinements
              attribute="invoice_version"
              onClick={onCustomRefinementChange}
            />
            <DropdownRefinementList
              attribute="invoice_version"
              options={InvoiceVersion}
              defaultRefinement={globalSearch.invoice_version}
              onClick={onCustomRefinementChange}
            />
          </Panel>
        </div>

        <div className="right-panel">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Organizations & Users</Typography>
            <Box display="flex" justifyContent="baseline">
              <Button color="secondary" title="Reset Filters" onClick={onReset} variant="contained">
                Reset Filters
              </Button>
              <Box ml={2}>
                <Button variant="contained" color="primary" onClick={createOrganization}>
                  Create Organization
                </Button>
              </Box>
              <Box ml={2}>
                <Button variant="contained" color="primary" onClick={createUser}>
                  Create User
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography variant="caption">
            Type any keyword that can narrow down the results.
          </Typography>

          <SearchBox
            defaultRefinement={globalSearch.query}
            onChange={event => {
              dispatch(updateRefinements('query', event.currentTarget.value || ''));
              onCustomRefinementChange();
            }}
            onReset={() => dispatch(updateRefinements('query', ''))}
            autoFocus
            searchAsYouType={false}
            showLoadingIndicator
          />

          <CustomStats />

          <CustomHits hitComponent={Hit} />

          <Configure attributeForDistinct="model" distinct />

          <Box className="pagination-wrapper">
            <HitsPerPage
              defaultRefinement={10}
              items={[
                { value: 10, label: 'Show 10 hits' },
                { value: 15, label: 'Show 15 hits' },
                { value: 25, label: 'Show 25 hits' },
                { value: 50, label: 'Show 50 hits' },
                { value: 100, label: 'Show 100 hits' }
              ]}
            />

            <Pagination showLast defaultRefinement={globalSearch.page} />
          </Box>
        </div>
      </InstantSearch>
    </Container>
  );
};

GlobalSearch.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

GlobalSearch.defaultProps = {};

export default GlobalSearch;
