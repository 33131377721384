import { PAYMENT_STATUS } from '../../../constants';

export default {
  PENDING: {
    label: PAYMENT_STATUS.PENDING.label,
    filter: PAYMENT_STATUS.PENDING.key
  },
  NOT_PAID: {
    label: PAYMENT_STATUS.NOT_PAID.label,
    filter: PAYMENT_STATUS.NOT_PAID.key
  },
  PAID: {
    label: PAYMENT_STATUS.PAID.label,
    filter: PAYMENT_STATUS.PAID.key
  },
  BLOCKED: {
    label: PAYMENT_STATUS.BLOCKED.label,
    filter: PAYMENT_STATUS.BLOCKED.key
  },
  SUSPENDED: {
    label: PAYMENT_STATUS.SUSPENDED.label,
    filter: PAYMENT_STATUS.SUSPENDED.key
  }
};
