import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeIOS3DViewerWarning } from '../../reducers/modals';
import InfoDialog from '../InfoDialog';

const IOS3DViewerWarning = ({ onClose }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modals.IS_IOS_3D_VIEWER_WARNING);

  const handleClose = () => {
    dispatch(closeIOS3DViewerWarning());

    if (onClose) {
      onClose();
    }
  };

  return (
    <InfoDialog
      open={isOpen}
      onClose={handleClose}
      title="3D Viewer Not Ready"
      content="The 3D Viewer is still processing the uploaded file. Please try again in a few minutes."
    />
  );
};

IOS3DViewerWarning.propTypes = {
  onClose: PropTypes.func
};

IOS3DViewerWarning.defaultProps = {
  onClose: () => {}
};
export default IOS3DViewerWarning;
